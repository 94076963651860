import _ from 'lodash';
import * as React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import MuiAlertTitle from '@material-ui/lab/AlertTitle';
import classNames from 'classnames';

import { makeStylesHook } from 'onescreen/styles';
import { AlertType } from 'onescreen/types';

/** ======================== Types ========================================= */
type AlertProps = React.PropsWithChildren<{
  className?: string;
  onClose?: () => void;
  outlined?: boolean;
  title?: React.ReactNode;
  type: AlertType;
}>;

/** ======================== Styles ======================================== */
const useStyles = makeStylesHook<AlertProps>(
  (theme) => ({
    alert: (props) => ({
      border: props.outlined ? `1px solid ${theme.palette[props.type].dark}` : 'none',
    }),
  }),
  'OneScreenAlert'
);

/** ======================== Components ==================================== */
function makeAlertOfType(type: AlertType) {
  const AlertOfType: React.FC<Omit<AlertProps, 'type'>> = (props) => (
    <Alert type={type} {...props} />
  );

  AlertOfType.displayName = _.capitalize(type) + ' Error';
  return AlertOfType;
}

export const Alert = Object.assign(
  React.forwardRef<HTMLDivElement, AlertProps>(function OneScreenAlert(props, ref) {
    const { children, className, title, type, ...rest } = props;
    const classes = useStyles(props);

    // If no children were provided, don't render
    if (!children) return null;

    const alertProps = {
      ..._.omit(rest, 'outlined'),
      className: classNames(className, classes.alert),
      ref,
      severity: type,
    };

    return (
      <MuiAlert {...alertProps}>
        {title && <MuiAlertTitle>{title}</MuiAlertTitle>}
        {children}
      </MuiAlert>
    );
  }),
  {
    Error: makeAlertOfType('error'),
    Info: makeAlertOfType('info'),
    Success: makeAlertOfType('success'),
    Warning: makeAlertOfType('warning'),
  }
);
