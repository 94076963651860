import { slices, store } from 'onescreen/store';
import { Query, Maybe, ObjectWithoutType } from 'onescreen/types';
import { formatters, Logger } from 'onescreen/utils';
import { ServiceAnalysis } from '../analysis';

import { BaseAPI } from '../base';
import { DateRange } from '../dates';

/** ======================== Types ========================================= */
type CommonAttrs = {
  bill_csv?: string;
  bill_html?: string;
  id: number;
  object_type: 'Bill';
  total: number;
};

export declare namespace Bill {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs, DateRange.Raw {}

  // Network interface
  export namespace API {
    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResponse = { bill: Bill.Raw };
    export type RetrieveResult = { bill: Bill };
  }
}

/** ======================== API =========================================== */
class BillAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.bills;

  static download(bill: Bill, analysis?: ServiceAnalysis) {
    const { bill_csv, start_date } = bill;

    // If the CSV data URL is missing, print a warning and return
    // TODO: fetch the CSV and return a promise instead
    if (!bill_csv) {
      Logger.warn('bill object is missing CSV data, unable to download');
      return;
    }

    const startDate = start_date ? formatters.date.yearMonthDay(start_date) : '';
    return this.downloadDataURL(bill_csv, `${analysis?.name}_bill_${startDate}.csv`);
  }

  static async retrieve(
    id: Bill['id'],
    params?: Bill.API.RetrieveParams
  ): Promise<Bill.API.RetrieveResult> {
    const { json } = await this.get<Bill.API.RetrieveResponse>(this.route(id), params);

    // Parse response
    const bill = new Bill(json.bill);
    store.dispatch(slices.data.updateModels(bill));
    return { bill };
  }
}

/** ======================== Model ========================================= */
export interface Bill extends CommonAttrs {}
export class Bill extends DateRange {
  /** ====================== Static fields ================================= */
  static api = BillAPI;
  /**
   * Parses an array of raw Bill objects by passing each in turn to Bill.fromObject. Note that
   * calling `raw.map(this.fromObject)` does not work, because TypeScript is unable to resolve the
   * correct overload for fromObject when it is passed to the `map` method. This is a TypeScript
   * design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {Bill.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: Bill.Raw[]): Bill[];
  static fromObjects(raw: Maybe<Bill.Raw[]>): Maybe<Bill[]>;
  static fromObjects(raw: Maybe<Bill.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: Bill.Raw): Bill;
  static fromObject(raw: Maybe<Bill.Raw>): Maybe<Bill>;
  static fromObject(raw: Maybe<Bill.Raw>) {
    return raw ? new Bill(raw) : undefined;
  }

  static fromStore(id: Bill['id']): Bill;
  static fromStore(id: Maybe<Bill['id']>): Maybe<Bill>;
  static fromStore(id: Maybe<Bill['id']>) {
    return id ? Bill.fromObject(store.getState().data.bills[id]) : undefined;
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'Bill';
  constructor(raw: Bill.Raw) {
    super(raw);

    this.bill_csv = raw.bill_csv;
    this.bill_html = raw.bill_html;
    this.id = raw.id;
    this.total = raw.total;
  }

  serialize(): Bill.Serialized {
    return {
      ...this.serializedDateRange,
      bill_csv: this.bill_csv,
      bill_html: this.bill_html,
      id: this.id,
      object_type: this.object_type,
      total: this.total,
    };
  }
}
