import React, { useState } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { IdType } from '../types';
import { List } from './List';
import { Grid } from './Grid';
import { TextField } from './TextField';
import { Button } from './Button';
import { Typography } from './Typography';
import { Box } from '.';
import { makeStylesHook } from '../styles';

/** ================================ Types ================================= */
type FilterListProps<T extends { id: IdType; name: string }> = {
  url: string;
  data: T[];
  onClickAdd?: (event: React.MouseEvent) => void;
  secondaryFunc: (item: T) => any;
  dense?: boolean;
  title?: string;
};

/** ======================== Styles ======================================== */
const useStyles = makeStylesHook(
  () => ({
    header: {
      backgroundColor: '#eee',
    },
  }),
  'OneScreenListItem'
);

/** ============================== Components ============================== */

export function FilterList<T extends { id: IdType; name: string }>(props: FilterListProps<T>) {
  let [filter, setFilter] = useState('');
  const navigate = useNavigate();
  const classes = useStyles();
  const { url, data = [], onClickAdd, secondaryFunc, title, dense = false } = props;

  const filteredData = data.filter((item) =>
    filter.length ? item.name.toLowerCase().includes(filter.toLowerCase()) : true
  );
  return (
    <List dense={dense}>
      <List.Item className={classes.header} button={false}>
        <Grid justify="space-between">
          <Grid.Item>
            {onClickAdd && <Button color="primary" size="small" onClick={onClickAdd} icon="plus" />}
          </Grid.Item>
          <Grid.Item>
            <Typography variant="h4">{title}</Typography>
          </Grid.Item>
          <Grid.Item>
            <Grid>
              <Grid.Item>
                <Box>
                  <Typography variant="subtitle1">Showing {filteredData.length} results</Typography>
                </Box>
              </Grid.Item>
              <Grid.Item>
                <TextField
                  icon="filterList"
                  placeholder="Filter"
                  onChange={setFilter}
                  value={filter}
                  InputProps={
                    filter.length
                      ? {
                          endAdornment: (
                            <TextField.Adornment position="end">
                              <Button
                                size="small"
                                _variant="text"
                                icon="close"
                                onClick={() => setFilter('')}
                              ></Button>
                            </TextField.Adornment>
                          ),
                        }
                      : {}
                  }
                />
              </Grid.Item>
            </Grid>
          </Grid.Item>
        </Grid>
      </List.Item>
      {data &&
        _.sortBy(filteredData, 'name').map((item) => (
          <List.Item key={item.id} onClick={() => navigate(`${url}/${item.id}`)}>
            <Grid justify="space-between">
              <Grid.Item>
                <List.Item.Text>
                  <Typography variant="button">{item.name}</Typography>
                </List.Item.Text>
              </Grid.Item>
              <Grid.Item>
                <List.Item.Text>{secondaryFunc ? secondaryFunc(item) : 'poo'}</List.Item.Text>
              </Grid.Item>
            </Grid>
          </List.Item>
        ))}
    </List>
  );
}
