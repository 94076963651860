import { Grid, Button, Card } from 'onescreen/components';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DynamicSolarYesterdayTable, DynamicStorageYesterdayTable } from './DynamicYesterdayTable';

export const Monitoring: React.FC = () => {
  const { view } = useParams<{ view: string }>();
  const navigate = useNavigate();

  return (
    <Grid justify="center">
      <Grid.Item>
        <Button
          size="large"
          icon="sun"
          color={view === 'solar' ? 'secondary' : undefined}
          onClick={() => navigate('../solar', { relative: 'path' })}
        >
          Solar
        </Button>
      </Grid.Item>
      <Grid.Item>
        <Button
          size="large"
          icon="battery"
          color={view === 'storage' ? 'secondary' : undefined}
          onClick={() => navigate('../storage', { relative: 'path' })}
        >
          Storage
        </Button>
      </Grid.Item>
      <Grid.Item span={12}>
        <Card>
          {view === 'solar' && <DynamicSolarYesterdayTable />}
          {view === 'storage' && <DynamicStorageYesterdayTable />}
        </Card>
      </Grid.Item>
    </Grid>
  );
};
