import { zip } from '../../data';

export const monitorReducer = (state = {}, action) => {
  switch (action.type) {
    case 'RECEIVE_MONITORS': {
      let output = Object.assign({}, state);
      const data = action.data;
      if (data.monitoring_platforms) {
        output.monitors = {
          ...output.monitors,
          ...state.monitors,
          ...zip(data.monitoring_platforms),
        };
        output[action.prefix + 'MonitorCount'] = data.meta.total_results;
        output[action.prefix + 'Received'] = true;
      }
      const auxDataList = ['solar_generators', 'storages', 'service_drops', 'sites', 'orgs'];
      for (let field of auxDataList) {
        if (data[field]) {
          output[field] = { ...output[field], ...state[field], ...zip(data[field]) };
        }
      }
      //Object.assign(output, { isFetching: data.next !== null });
      return output;
    }

    case 'UPDATE_MONITOR': {
      let output = Object.assign({}, state);
      Object.assign(
        output['monitors'],
        { [action.monitor.id]: output['monitors'][action.monitor.id] },
        { [action.monitor.id]: action.monitor }
      );
      return output;
    }

    case 'REQUEST_BASELINES':
      return Object.assign({}, state, { fetchingBaselines: true });

    case 'RECEIVE_BASELINES': {
      let output = Object.assign({}, state);
      const data = action.data;
      const updatedPlatform = data.monitoring_platform;
      Object.assign(output['monitors'][updatedPlatform.id], updatedPlatform);
      if (!('baselines' in state)) output['baselines'] = {};
      if (data.baseline_months) {
        Object.assign(output['baselines'], zip(data.baseline_months));
      }
      output.fetchingBaselines = false;
      return output;
    }

    case 'RECEIVE_ISSUE': {
      let output = Object.assign({}, state);
      const device = action.deviceName;
      output[device][action.data.known_issue.owner_id].known_issues.push(action.data.known_issue);
      return output;
    }

    case 'REMOVE_ISSUE': {
      let output = Object.assign({}, state);
      let list = output[action.deviceName][action.deviceId].known_issues;
      list = list.filter((ki) => parseInt(ki.id) !== parseInt(action.issueId));
      output[action.deviceName][action.deviceId].known_issues = list;
      return output;
    }

    default:
      return state;
  }
};
