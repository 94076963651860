import _ from 'lodash';

import { store } from 'onescreen/store';
import { IdType, Maybe, ObjectWithoutType, ObjectWithType } from 'onescreen/types';
import { Rate, UtilityRatePlan } from '.';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'CustomerClassRatePlan'> & {
  friendly_name: string;
  id: IdType;
  rates?: Array<Rate['id']>;
  utility_rate_plan: UtilityRatePlan['id'];
};

export declare namespace CustomerClassRatePlan {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}
}

/** ======================== Model ========================================= */
export interface CustomerClassRatePlan extends CommonAttrs {}
export class CustomerClassRatePlan {
  /** ====================== Static fields ================================= */
  static readonly rawFields = ['friendly_name', 'id', 'rates', 'utility_rate_plan'] as const;

  /**
   * Parses an array of raw CustomerClassRatePlan objects by passing each in turn to
   * CustomerClassRatePlan.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {CustomerClassRatePlan.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: CustomerClassRatePlan.Raw[]): CustomerClassRatePlan[];
  static fromObjects(raw: Maybe<CustomerClassRatePlan.Raw[]>): Maybe<CustomerClassRatePlan[]>;
  static fromObjects(raw: Maybe<CustomerClassRatePlan.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: CustomerClassRatePlan.Raw): CustomerClassRatePlan;
  static fromObject(raw: Maybe<CustomerClassRatePlan.Raw>): Maybe<CustomerClassRatePlan>;
  static fromObject(raw: Maybe<CustomerClassRatePlan.Raw>) {
    return raw ? new CustomerClassRatePlan(raw) : undefined;
  }

  static fromStore(): CustomerClassRatePlan[];
  static fromStore(id: Maybe<CustomerClassRatePlan['id']>): Maybe<CustomerClassRatePlan>;
  static fromStore(id?: CustomerClassRatePlan['id']) {
    const { customerClassRatePlans } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(customerClassRatePlans)).map(CustomerClassRatePlan.fromObject);
    } else {
      return id ? CustomerClassRatePlan.fromObject(customerClassRatePlans[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'CustomerClassRatePlan';
  constructor(raw: CustomerClassRatePlan.Raw) {
    Object.assign(this, _.pick(raw, CustomerClassRatePlan.rawFields));
  }

  get UtilityRatePlan() {
    return UtilityRatePlan.fromStore(this.utility_rate_plan);
  }

  get Rates() {
    return this.rates?.map(Rate.fromStore) || [];
  }

  serialize(): CustomerClassRatePlan.Serialized {
    return { ..._.pick(this, CustomerClassRatePlan.rawFields), object_type: this.object_type };
  }
}
