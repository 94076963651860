import * as React from 'react';
import MuiPaper from '@material-ui/core/Paper';
import { makeStylesHook } from '../styles';
import classNames from 'classnames';

/** ============================ Types ===================================== */
type Elevation =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24;
export type PaperProps = {
  className?: string;
  elevation?: Elevation;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

/** ============================ Styles ==================================== */
const useStyles = makeStylesHook<PaperProps>(
  (theme) => ({
    paper: ({ onClick }) => ({ cursor: !!onClick ? 'pointer' : 'default' }),
  }),
  'OneScreenPaper'
);

/** ============================ Components ================================ */
export const Paper: React.FC<PaperProps> = (props) => {
  const classes = useStyles(props);
  return <MuiPaper {...props} className={classNames(classes.paper, props.className)} />;
};
