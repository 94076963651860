import React from 'react';
import { useSelector } from 'react-redux';

import { IntervalFile, IntervalStream } from 'onescreen/models';
import { slices } from 'onescreen/store';
import { Loader, Maybe } from 'onescreen/types';

import { useAsync } from './useAsync';
import _ from 'lodash';

/**
 * Retrieves a single IntervalFile object given its ID
 *
 * @param {number} id: IntervalFile ID
 * @param {IntervalFile.API.RetrieveParams} params: query parameters
 */
export function useIntervalFile(
  id: Maybe<IntervalFile['id']>,
  params?: IntervalFile.API.RetrieveParams
): Loader<IntervalFile, 'intervalFile'> {
  const intervalFile = useSelector(slices.data.selectIntervalFile(id));

  const loading = useAsync(async () => {
    if (!id) return;
    return IntervalFile.api.retrieve(id, params);
  }, [id]);

  return { loading, intervalFile: IntervalFile.fromObject(intervalFile) };
}

/**
 * Retrieves interval data for a single IntervalFile given its ID
 *
 * @param {number} id: IntervalFile ID
 * @param {IntervalFile.API.RetrieveIntervalsParams} params: query parameters
 */
export function useIntervalFileIntervals(
  id: IntervalFile['id'],
  params?: IntervalFile.API.RetrieveIntervalsParams,
  shouldUpdate: boolean = true,
  dependencies?: any[],
  onSuccess: (response: any) => void = _.noop
): Loader<IntervalStream, 'stream'> {
  const [stream, setStream] = React.useState<IntervalStream>();

  const loading = useAsync(
    async () => {
      if (!shouldUpdate) return;
      const { stream } = await IntervalFile.api.intervals(id, params);
      setStream(stream);
      return stream;
    },
    dependencies,
    onSuccess
  );

  return { loading, stream };
}
