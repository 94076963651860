import * as React from 'react';

import { Alert } from './Alert';

/** ======================== Types ========================================= */
type ErrorBoundaryProps = { fallback?: React.ReactNode };
type ErrorBoundaryState = { error?: Error };

/** ======================== Component ===================================== */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {};

  static defaultProps = {
    fallback: <Alert type="error">Something went wrong.</Alert>,
  };

  /**
   * This lifecycle is invoked after an error has been thrown by a descendant component. It receives
   * the error that was thrown as a parameter and should return a value to update state.
   */
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  /**
   * Could be used to log the error to a service, which could subsequently send a notification that
   * an error was triggered.
   *
   * @param {Error} error: the error that was thrown by the descendant component
   * @param {ErrorInfo} errorInfo: object containing the component stack
   */
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { children, fallback } = this.props;
    return this.state.error ? fallback : children;
  }
}
