import React, { useCallback, useState } from 'react';

import { SiteMultiSelect, DownloadModalFooter } from './common';
import { Org, Site } from 'onescreen/models';
import { Dialog, Grid, Loading, Typography, DateSpanPicker } from 'onescreen/components';
import { useOrg } from 'onescreen/hooks';
import { DateTime } from 'luxon';
import { DateTuple } from 'onescreen/types';
import _ from 'lodash';

/** =============================== Types ================================== */
type OrgBillDownloadModalProps = {
  orgId: Org['id'];
  open: boolean;
  onClose: () => void;
};

/** ============================ Components ================================ */
export const OrgBillDownloadModal: React.FC<OrgBillDownloadModalProps> = ({
  orgId,
  open,
  onClose,
}) => {
  const { org } = useOrg(orgId, {
    include: ['sites.*'],
  });
  const today = new Date();
  const end = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const newYearsDay = new Date(today.getFullYear(), 0, 1);

  const [siteList, setSiteList] = useState<Site[]>(org?.Sites!);
  const [dateSpan, setDateSpan] = useState<DateTuple>([
    DateTime.fromJSDate(newYearsDay),
    DateTime.fromJSDate(end),
  ]);

  const datesDidChange = useCallback((start_date: DateTime, end_date: DateTime) => {
    setDateSpan([start_date, end_date]);
  }, []);

  if (!org) return <Loading />;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <Dialog.Content>
        <Grid justify="center">
          <Grid.Item span={12}>
            <Typography variant="h4">Bills for {org?.name}</Typography>
          </Grid.Item>
          <Grid.Item span={12}>
            <SiteMultiSelect siteList={org.Sites} sitesChanged={setSiteList} />
          </Grid.Item>
          <Grid.Item span={10}>
            <DateSpanPicker
              disabled={false}
              defaultStartDate={DateTime.fromJSDate(newYearsDay)}
              defaultEndDate={DateTime.fromJSDate(end)}
              datesDidChange={datesDidChange}
              options={{}}
            />
          </Grid.Item>
        </Grid>
      </Dialog.Content>
      <Dialog.Actions>
        <DownloadModalFooter disabled={false} getDownloadURL={getDownloadURL} onClose={onClose} />
      </Dialog.Actions>
    </Dialog>
  );
  /** =========================== Callback ================================= */
  async function getDownloadURL() {
    return await Org.api.downloadBills(orgId, {
      start_date: dateSpan[0].toISODate(),
      end_date: dateSpan[1].toISODate(),
      site_list: _.map(siteList, 'id'),
    });
  }
};
