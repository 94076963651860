import _ from 'lodash';

import { store } from 'onescreen/store';
import { IdType, Maybe, ObjectWithoutType, ObjectWithType } from 'onescreen/types';

/** ======================== Types ========================================= */
type UnitName = 'day' | 'kW' | 'kWh' | 'hp' | 'kVar' | 'pf_var_kwh' | 'bill';
type ChargeType = 'fixed' | 'demand' | 'usage';
type CommonAttrs = ObjectWithType<'BillingUnit'> & {
  name: UnitName;
  id: IdType;
  charge_type: ChargeType;
};

export declare namespace BillingUnit {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}
}

/** ======================== Model ========================================= */
export interface BillingUnit extends CommonAttrs {}
export class BillingUnit {
  /** ====================== Static fields ================================= */
  static readonly rawFields = ['name', 'id', 'charge_type'] as const;

  /**
   * Parses an array of raw BillingUnit objects by passing each in turn to
   * BillingUnit.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {BillingUnit.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: BillingUnit.Raw[]): BillingUnit[];
  static fromObjects(raw: Maybe<BillingUnit.Raw[]>): Maybe<BillingUnit[]>;
  static fromObjects(raw: Maybe<BillingUnit.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: BillingUnit.Raw): BillingUnit;
  static fromObject(raw: Maybe<BillingUnit.Raw>): Maybe<BillingUnit>;
  static fromObject(raw: Maybe<BillingUnit.Raw>) {
    return raw ? new BillingUnit(raw) : undefined;
  }

  static fromStore(): BillingUnit[];
  static fromStore(id: Maybe<BillingUnit['id']>): Maybe<BillingUnit>;
  static fromStore(id?: BillingUnit['id']) {
    const { billingUnits } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(billingUnits)).map(BillingUnit.fromObject);
    } else {
      return id ? BillingUnit.fromObject(billingUnits[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'BillingUnit';
  constructor(raw: BillingUnit.Raw) {
    Object.assign(this, _.pick(raw, BillingUnit.rawFields));
  }

  serialize(): BillingUnit.Serialized {
    return { ..._.pick(this, BillingUnit.rawFields), object_type: this.object_type };
  }
}
