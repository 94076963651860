import _ from 'lodash';

import { store } from 'onescreen/store';
import { IdType, Maybe, ObjectWithoutType, ObjectWithType } from 'onescreen/types';
import { Meter } from '..';
import { DateRange } from '../dates';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'ApiBill'> & {
  id: IdType;
  utilityapi_id: string;
  total_cost: number;
  total_volume: number;
  total_volume_unit: string;
  demand: number;
  demand_cost: number;
  source_url: string;
  meter: Meter['id'];
};

export declare namespace ApiBill {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs, DateRange.Raw {}
}

/** ======================== Model ========================================= */
export interface ApiBill extends CommonAttrs {}
export class ApiBill extends DateRange {
  /** ====================== Static fields ================================= */
  static readonly rawFields = [
    'id',
    'utilityapi_id',
    'total_cost',
    'total_volume',
    'total_volume_unit',
    'demand',
    'demand_cost',
    'source_url',
    'meter',
  ] as const;

  /**
   * Parses an array of raw ApiBill objects by passing each in turn to
   * ApiBill.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {ApiBill.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: ApiBill.Raw[]): ApiBill[];
  static fromObjects(raw: Maybe<ApiBill.Raw[]>): Maybe<ApiBill[]>;
  static fromObjects(raw: Maybe<ApiBill.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: ApiBill.Raw): ApiBill;
  static fromObject(raw: Maybe<ApiBill.Raw>): Maybe<ApiBill>;
  static fromObject(raw: Maybe<ApiBill.Raw>) {
    return raw ? new ApiBill(raw) : undefined;
  }

  static fromStore(): ApiBill[];
  static fromStore(id: Maybe<ApiBill['id']>): Maybe<ApiBill>;
  static fromStore(id?: ApiBill['id']) {
    const { apiBills } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(apiBills)).map(ApiBill.fromObject);
    } else {
      return id ? ApiBill.fromObject(apiBills[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */

  readonly object_type = 'ApiBill';
  constructor(raw: ApiBill.Raw) {
    super(raw);
    Object.assign(this, _.pick(raw, ApiBill.rawFields));
  }

  serialize(): ApiBill.Serialized {
    return {
      ..._.pick(this, ApiBill.rawFields),
      ...this.serializedDateRange,
      object_type: this.object_type,
    };
  }
}
