import _ from 'lodash';
import * as React from 'react';

import { DateTime } from 'luxon';

import { PeriodStack, Grid, Button } from 'onescreen/components';
import { AnalysisPeriod, ServiceAgreement, ServiceAnalysis, ServicePeriod } from 'onescreen/models';
import { Maybe, Tuple } from 'onescreen/types';
import { useAnalysisPeriod } from '../../common/hooks';

/** ======================== Types ========================================= */
type PeriodSelectorProps = {
  onChange: (period: ServicePeriod) => void;
  selected: Maybe<ServicePeriod>;
  serviceAgreement: ServiceAgreement;
  serviceAnalysis: ServiceAnalysis;
};

enum BillStatus {
  noBill = 'noBill',
  hasBill = 'hasBill',
  outOfRange = 'outOfRange',
}

/** ======================== Styles ======================================== */
const colors = {
  hasBill: { color: 'green', name: 'Succeeded', shade: 'A100' },
  noBill: { color: 'orange', name: 'No Bill', shade: 'A100' },
  outOfRange: { color: 'grey', name: 'Out of Bounds', shade: 'A100' },
} as const;

export const PeriodSelector: React.FC<PeriodSelectorProps> = (props) => {
  const { onChange, selected, serviceAgreement, serviceAnalysis } = props;
  const periods = serviceAnalysis.ServicePeriods;
  const simulations = serviceAnalysis.ServiceSimulations[serviceAgreement.id];
  const analysisPeriods = serviceAnalysis.AnalysisPeriods;
  const analysisPeriodId = _.find(analysisPeriods, { service_agreement: serviceAgreement.id })?.id;
  const { analysisPeriod } = useAnalysisPeriod(analysisPeriodId);

  // Create a mapping from a ServicePeriod to a BillStatus enum value
  const periodStatus = React.useMemo(
    () =>
      new Map(
        periods.map((p) => {
          const simulation = simulations[p.id];
          const status = _.iife(() => {
            if (
              p.end_date < analysisPeriod?.start_date! ||
              p.start_date > analysisPeriod?.end_date!
            )
              return BillStatus.outOfRange;
            if (!simulation?.bill) return BillStatus.noBill;
            return BillStatus.hasBill;
          });
          return [p.id, status];
        })
      ),
    [periods, simulations, analysisPeriod]
  );
  const [bounds, setBounds] = React.useState<Maybe<Tuple<DateTime>>>(
    analysisPeriod ? [analysisPeriod.start_date, analysisPeriod.end_date] : undefined
  );
  const [editingBounds, setEditingBounds] = React.useState<boolean>(false);
  const [editingPeriods, setEditingPeriods] = React.useState<boolean>(false);
  const [managingPeriods, setManagingPeriods] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!managingPeriods) {
      setEditingBounds(false);
      setEditingPeriods(false);
    }
  }, [managingPeriods, setEditingPeriods, setEditingBounds]);

  return (
    <>
      <Grid justify="flex-end">
        <Grid.Item>
          <Button
            size="small"
            onClick={() => setManagingPeriods((val) => !val)}
            icon={managingPeriods ? 'chevronRight' : 'chevronLeft'}
          />
        </Grid.Item>
        {managingPeriods && (
          <>
            <Grid.Item>
              <Button
                size="small"
                disabled={editingBounds || editingPeriods}
                onClick={createPeriods}
                color="secondary"
              >
                {!periods.length ? 'Auto-Create' : 'Refresh'} Billing Periods
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                size="small"
                disabled={editingBounds}
                onClick={() => setEditingPeriods((val) => !val)}
                color="primary"
              >
                {editingPeriods ? 'Done Editing' : 'Edit'} Billing Periods
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                size="small"
                disabled={editingPeriods || !periods.length}
                color={editingBounds ? 'secondary' : 'primary'}
                onClick={() => {
                  if (editingBounds) {
                    editBoundary(bounds!);
                  }
                  setEditingBounds((b) => !b);
                }}
              >
                {editingBounds ? 'Save Bounds' : 'Edit Bounds'}
              </Button>
            </Grid.Item>
          </>
        )}
      </Grid>

      <PeriodStack
        colorFn={getPeriodColor}
        data={periods}
        onClick={onChange}
        selected={selected}
        showLabels={true}
        bounds={bounds!}
        setBounds={setBounds}
        editingBounds={editingBounds}
        editingPeriods={editingPeriods}
        editPeriod={editPeriod}
        createPeriod={createPeriod}
        deletePeriod={deletePeriod}
      />
    </>
  );

  /** ====================== Helpers ======================================= */
  function getPeriodColor(period: ServicePeriod) {
    const status = periodStatus.get(period.id);
    return status ? colors[status] : colors.outOfRange;
  }
  function editBoundary(range: Tuple<DateTime>) {
    const params = {
      start_date: range[0].toISODate(),
      end_date: range[1].toISODate(),
    };
    AnalysisPeriod.api.update(analysisPeriod?.id!, params);
  }
  async function createPeriods() {
    const { task } = await ServiceAnalysis.api.createServicePeriods(serviceAnalysis.id);
    task.waitForTask((resp) => {
      if (resp.status === 'SUCCESS') {
        ServiceAnalysis.api.retrieve(serviceAnalysis.id, {
          include: ['service_periods.service_simulations.*'],
        });
      }
    });
  }
  async function editPeriod(servicePeriod: ServicePeriod, startDate: DateTime, endDate: DateTime) {
    await ServicePeriod.api.update(servicePeriod.id, {
      start_date: startDate.toISODate(),
      end_date: endDate.toISODate(),
    });
    ServiceAnalysis.api.retrieve(serviceAnalysis.id, {
      include: ['service_periods.service_simulations.*'],
    });
  }
  async function createPeriod(startDate: DateTime, endDate: DateTime) {
    await ServicePeriod.api.create({
      service_analysis: serviceAnalysis.id,
      start_date: startDate.toISODate(),
      end_date: endDate.toISODate(),
    });
    ServiceAnalysis.api.retrieve(serviceAnalysis.id, {
      include: ['service_periods.service_simulations.*'],
    });
  }
  async function deletePeriod(servicePeriod: ServicePeriod) {
    await ServicePeriod.api.destroy(servicePeriod.id);
    ServiceAnalysis.api.retrieve(serviceAnalysis.id, {
      include: ['service_periods.service_simulations.*'],
    });
  }
};
