import * as React from 'react';

import { Box, Menu } from 'onescreen/components';

export const FabMenu: React.FC = ({ children }) => (
  <Box position="absolute" right="-1rem" top="-0.5rem">
    <Menu
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      fabIcon="verticalDots"
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      {children}
    </Menu>
  </Box>
);
