import _ from 'lodash';

import { ErrorArray, IdType, Maybe, ObjectWithoutType } from 'onescreen/types';
import { cookieManager } from 'onescreen/utils';
import { DateUtils } from '.';
import { slices, store } from '../store';

import { BaseAPI } from './base';
import { TimeStamp } from './dates';

/** ======================== Types ========================================= */
type PermissionGroups =
  | 'AMSPortfolioViewers'
  | 'AMSPortfolioEditors'
  | 'AMSPortfolioFullAccessEditors'
  | 'BucketingUsers'
  | 'EnergyEngineers'
  | 'MonitorViewers'
  | 'MonitorEditors'
  | 'MonitorFullAccessEditors'
  | 'OrgViewers'
  | 'OrgEditors'
  | 'OrgFullAccessEditors'
  | 'TVECustomerAccess';

type CommonAttrs = {
  id: IdType;
  email: string;
  first_name: string;
  is_active: boolean;
  is_staff: boolean;
  last_name: string;
  object_type: 'User';
  opt_in_meters_email: boolean;
  groups: PermissionGroups[];

  // TODO: write these
  account: null;
};

export declare namespace User {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs, TimeStamp.Raw {}

  // Network interface
  export namespace API {
    export type LoginParams = { password: string; username: string };
    export type LoginResponse = { key?: string; non_field_errors?: ErrorArray };

    export type LogoutResponse = { detail: string };

    export type RetrieveResponse = User.Raw;
    export type RetrieveResult = { user: User };
  }
}

/** ======================== API =========================================== */
class UserAPI extends BaseAPI {
  private static routes = {
    base: BaseAPI.endpoints.v1.user,
    login: BaseAPI.endpoints.v1.login,
    logout: BaseAPI.endpoints.v1.logout,
  };

  static async retrieve(): Promise<User.API.RetrieveResult> {
    const { json } = await this.get<User.API.RetrieveResponse>(this.routes.base);

    const user = User.fromObject(json);
    store.dispatch(slices.data.updateModels(user));
    return { user };
  }

  static async login(params: User.API.LoginParams) {
    const { json, response } = await this.post<User.API.LoginResponse>(this.routes.login, params);

    // Store the token
    if (response.ok) cookieManager.authToken = [params.username, json.key];

    return {
      response,
      error: (json.non_field_errors || [])[0],
    };
  }

  static async logout(): Promise<User.API.LogoutResponse> {
    return (await this.post<User.API.LogoutResponse>(this.routes.logout)).json;
  }
}

/** ======================== Model ========================================= */
export interface User extends TimeStamp.Parsed, CommonAttrs {}
export class User {
  /** ====================== Static fields ================================= */
  static api = UserAPI;
  static readonly rawFields = [
    'id',
    'email',
    'first_name',
    'is_active',
    'is_staff',
    'last_name',
    'object_type',
    'opt_in_meters_email',
    'groups',
    'account',
  ] as const;

  /**
   * Parses an array of raw User objects by passing each in turn to User.fromObject. Note that calling
   * `raw.map(this.fromObject)` does not work, because TypeScript is unable to resolve the correct
   * overload for fromObject when it is passed to the `map` method. This is a TypeScript design
   * limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {User.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: User.Raw[]): User[];
  static fromObjects(raw: Maybe<User.Raw[]>): Maybe<User[]>;
  static fromObjects(raw: Maybe<User.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: User.Raw): User;
  static fromObject(raw: Maybe<User.Raw>): Maybe<User>;
  static fromObject(raw: Maybe<User.Raw>) {
    return raw ? new User(raw) : undefined;
  }

  static fromStore(): User[];
  static fromStore(id: Maybe<User['id']>): Maybe<User>;
  static fromStore(id?: User['id']) {
    const { users } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(users)).map(User.fromObject);
    } else {
      return id ? User.fromObject(users[id]) : undefined;
    }
  }

  static isAuthenticated() {
    const [, token] = cookieManager.authToken;
    return token !== undefined;
  }

  static login(username: string, password: string) {
    return this.api.login({ username, password });
  }

  static logout() {
    // Delete the session token and invalidate it on the server too
    cookieManager.remove.authToken();
    this.api.logout().catch();

    // Use the Location API to navigate to the login screen instead of React Router so that page
    // state is reset. Using a redirect (as React Router does) would maintain the contents/history
    // of the redux store and leak data outside the user's session.
    window.location.href = '/login';
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'User';
  constructor(raw: User.Raw) {
    Object.assign(this, _.pick(raw, User.rawFields));
    this.created_at = DateUtils.parseDate(raw.created_at);
    this.updated_at = DateUtils.parseDate(raw.updated_at);
  }

  serialize(): User.Serialized {
    return {
      ..._.pick(this, User.rawFields),
      object_type: this.object_type,
      created_at: DateUtils.serializeDate(this.created_at),
      updated_at: DateUtils.serializeDate(this.updated_at),
    };
  }
}
