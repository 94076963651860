import React from 'react';

import { Menu } from './Menu';
import { IdType } from '../types';

/** ================================ Types ================================= */

type MenuObject = {
  label: string;
  callback: (id: IdType) => void;
  hide?: boolean;
};
type MenuItem = {
  [key: string]: MenuObject;
};
type CardCornerMenuProps = {
  objId: IdType;
  menu: MenuItem;
};

/** ============================= Components =============================== */

export const CardCornerMenu: React.FC<CardCornerMenuProps> = ({ objId, menu }) => {
  return (
    <Menu icon="expand">
      {Object.keys(menu).map((key) =>
        !menu[key].hide ? (
          <Menu.Item key={key} onClick={menu[key].callback.bind(null, objId)}>
            {menu[key].label}
          </Menu.Item>
        ) : null
      )}
    </Menu>
  );
};
