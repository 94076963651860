/**
 * Singleton class to handle application error reporting and logging
 */
export class Logger {
  /**
   * Prints a warning message when in dev mode
   *
   * @param {string} msg: the message to print
   * @param {any[]} args: additional arguments to include in the warning message
   */
  static warn(msg: string, ...args: any[]) {
    if (!this.isProduction) {
      console.warn(msg, ...args);
    }
  }

  /**
   * Logs an error. If an actual error-logging system is ever set up, this can be used to hook
   * into it.
   *
   * @param {string} msg: error message to log
   */
  static error(msg: string) {
    if (!this.isProduction) {
      console.error(msg);
    }
  }

  /**
   * Checks if the application is running in a production environment
   */
  private static get isProduction() {
    return process.env.NODE_ENV === 'production';
  }
}
