import * as React from 'react';
import { Flex } from '.';
import { Typography } from './Typography';

type TabDatumProps = { field: string; value: React.ReactNode };

export const TabDatum: React.FC<TabDatumProps> = ({ field, value }) => (
  <Flex.Container>
    <Flex.Item grow>
      <Typography color="textSecondary" variant="body2">
        {field}:
      </Typography>
    </Flex.Item>
    <Flex.Item>
      <Typography variant="body2">{value}</Typography>
    </Flex.Item>
  </Flex.Container>
);
