import classNames from 'classnames';
import _ from 'lodash';
import * as React from 'react';
import MuiCard from '@material-ui/core/Card';
import MuiCardActionArea from '@material-ui/core/CardActionArea';
import MuiCardActions from '@material-ui/core/CardActions';
import MuiCardContent from '@material-ui/core/CardContent';
import MuiCardMedia from '@material-ui/core/CardMedia';

import { makeStylesHook } from 'onescreen/styles';
import { Maybe } from 'onescreen/types';

import { Chip, ChipProps } from './Chip';

/** ======================== Types ========================================= */
type ChipLabelProp = string | React.ReactElement<ChipProps>;
type ChipLabelProps = { label: Maybe<ChipLabelProp> };
export type CardProps = React.HTMLAttributes<HTMLDivElement> & {
  chipLabel?: ChipLabelProp;
  raised?: boolean;

  // style props
  padding?: number | string;
  outlined?: boolean;
};

/** ======================== Styles ======================================== */
const useStyles = makeStylesHook<CardProps>(
  () => ({
    card: (props) => ({
      padding: props.padding,
      position: 'relative',
      overflow: props.chipLabel ? 'visible' : undefined,
    }),
  }),
  'OnescreenCard'
);

const useChipLabelStyles = makeStylesHook(
  (theme) => ({
    chip: {
      left: theme.spacing(1),
      marginTop: -theme.spacing(2),
      position: 'absolute',
      top: 0,
      zIndex: 10,
    },
  }),
  'ChipLabel'
);

/** ======================== Components ==================================== */
const ChipLabel: React.FC<ChipLabelProps> = ({ label }) => {
  const classes = useChipLabelStyles();
  if (!label) return null;

  // Get the chip props
  const chipProps: ChipProps = _.defaults({}, _.isString(label) ? { label } : label.props, {
    className: classes.chip,
    color: 'secondary',
  });

  return <Chip {...chipProps} />;
};

export const Card = Object.assign(
  React.forwardRef<HTMLDivElement, CardProps>((props, ref) => {
    const { children, chipLabel, className, outlined, ...rest } = props;
    const classes = classNames(className, useStyles(props).card);
    const variant = outlined ? 'outlined' : 'elevation';
    return (
      <MuiCard variant={variant} className={classes} {...rest} ref={ref}>
        <ChipLabel label={chipLabel} />
        {children}
      </MuiCard>
    );
  }),
  {
    displayName: 'OnescreenCard',
    ActionArea: MuiCardActionArea,
    Actions: MuiCardActions,
    Content: MuiCardContent,
    Media: MuiCardMedia,
  }
);

Card.defaultProps = {
  padding: '1rem',
};
