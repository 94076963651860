import * as React from 'react';

import { api_host } from 'onescreen/requests';
import { useNavigate } from 'react-router-dom';

export default function ForgotPassword(props) {
  const navigate = useNavigate();
  return (
    <div className="container">
      <div className="row valign-wrapper card-panel">
        <form
          className="col s12"
          onSubmit={(event) => {
            event.preventDefault();
            let body = { email: event.target.email.value };
            fetch(api_host + '/v1/password/reset/', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
            }).then((resp) => {
              if (resp.ok) navigate('/login');
            });
          }}
        >
          <h4 className="col s12">Forgot Password?</h4>
          <div className="input-field col s12">
            <input placeholder="email" id="email" type="text" className="validate" />
            <label htmlFor="email">Email Address</label>
          </div>
          <input className="btn col offset-s9 s3" type="submit" value="Send Recovery Email"></input>
        </form>
      </div>
    </div>
  );
}
