import _ from 'lodash';

import { store } from 'onescreen/store';
import { IdType, Maybe, Nullable, ObjectWithoutType, ObjectWithType } from 'onescreen/types';
import { BillingUnit } from '.';
import { Bucket, Season } from '..';

/** ======================== Types ========================================= */
type RateTypeType = 'generation' | 'distribution' | 'transmission' | 'tax' | 'other';
type Scope = 'BASE' | 'CUSTOMER_CLASS' | 'UTILITY' | 'REGULATOR';
type CommonAttrs = ObjectWithType<'RateType'> & {
  id: IdType;
  description: string;
  type: RateTypeType;
  per_connection_level: boolean;
  unit: BillingUnit['id'];
  season: Nullable<Season['id']>;
  bucket: Nullable<Bucket['id']>;
  _rate_scope: Scope;
  _rate_type_scope: Scope;
};

export declare namespace RateType {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}
}

/** ======================== Model ========================================= */
export interface RateType extends CommonAttrs {}
export class RateType {
  /** ====================== Static fields ================================= */
  static readonly rawFields = [
    'id',
    'description',
    'type',
    'per_connection_level',
    'unit',
    'season',
    'bucket',
    '_rate_scope',
    '_rate_type_scope',
  ] as const;

  /**
   * Parses an array of raw RateType objects by passing each in turn to
   * RateType.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {RateType.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: RateType.Raw[]): RateType[];
  static fromObjects(raw: Maybe<RateType.Raw[]>): Maybe<RateType[]>;
  static fromObjects(raw: Maybe<RateType.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: RateType.Raw): RateType;
  static fromObject(raw: Maybe<RateType.Raw>): Maybe<RateType>;
  static fromObject(raw: Maybe<RateType.Raw>) {
    return raw ? new RateType(raw) : undefined;
  }

  static fromStore(): RateType[];
  static fromStore(id: Maybe<RateType['id']>): Maybe<RateType>;
  static fromStore(id?: RateType['id']) {
    const { rateTypes } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(rateTypes)).map(RateType.fromObject);
    } else {
      return id ? RateType.fromObject(rateTypes[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'RateType';
  constructor(raw: RateType.Raw) {
    Object.assign(this, _.pick(raw, RateType.rawFields));
  }

  get Season() {
    return Season.fromStore(this.season!);
  }

  get Bucket() {
    return Bucket.fromStore(this.bucket!);
  }

  get Unit() {
    return BillingUnit.fromStore(this.unit);
  }

  serialize(): RateType.Serialized {
    return { ..._.pick(this, RateType.rawFields), object_type: this.object_type };
  }
}
