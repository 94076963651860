import * as React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { Box, Button, Card, Flex, Link, TextField, Typography } from 'onescreen/components';
import { User } from 'onescreen/models';

export function Login() {
  const navigate = useNavigate();

  // State
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState(false);

  // If the user is already signed in, redirect to the homepage
  if (User.isAuthenticated()) return <Navigate to="/" />;

  return (
    <div className="container">
      <Card raised>
        <Typography variant="h2">Log In</Typography>

        <form onSubmit={onSubmit}>
          <Box marginTop={1} marginBottom={1}>
            <TextField
              error={error}
              id="username"
              label="Username or Email"
              name="username"
              onChange={setUsername}
            />
          </Box>

          <TextField
            autoComplete="current-password"
            error={error}
            helperText={error ? 'Invalid email/username or password' : ' '}
            id="outlined-secondary"
            label="Password"
            name="password"
            onChange={setPassword}
            type="password"
          />

          <Box marginTop={2}>
            <Flex.Container>
              <Flex.Item grow={1}>
                <Button color="primary" icon="send" type="submit">
                  Log in
                </Button>
              </Flex.Item>

              <Flex.Item>
                <Link color="textSecondary" to="/user/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </Flex.Item>
            </Flex.Container>
          </Box>
        </form>
      </Card>
    </div>
  );

  /** ====================== Callbacks ===================================== */
  async function onSubmit(event: React.FormEvent) {
    event.preventDefault();
    if (!username || !password) return;

    try {
      const { response } = await User.login(username, password);

      // Navigate to the dashboard if login was successful
      if (response.ok) {
        navigate('/');
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
  }
}
