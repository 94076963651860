import React, { ChangeEvent } from 'react';
import MuiSlider, { SliderProps as MuiSliderProps } from '@material-ui/core/Slider';
import { makeStylesHook } from '../styles';

/** ================================ Types ================================= */
type SliderProps = Omit<MuiSliderProps, 'onChange'> & {
  onChange: (value: number) => void;
};

/** =============================== Styles ================================= */
const useStyles = makeStylesHook(
  (theme) => ({
    sliderBar: {
      'height': 7,
      '& .MuiSlider-rail': {
        height: 5,
        backgroundColor: theme.palette.grey[700],
      },
      '& .MuiSlider-track': {
        height: 7,
      },
      '& .MuiSlider-thumb': {
        backgroundColor: theme.palette.primary.main,
        height: 15,
        width: 15,
      },
    },
  }),
  'Slider'
);

/** ============================= Components =============================== */

export const Slider: React.FC<SliderProps> = (props) => {
  const { onChange, ...rest } = props;
  const classes = useStyles();
  return (
    <MuiSlider
      className={classes.sliderBar}
      {...rest}
      onChange={(event: ChangeEvent<{}>, newValue: number | number[]) =>
        onChange(newValue as number)
      }
    />
  );
};
