import { zip } from '../../data';

export const ratesReducer = (state = {}, action) => {
  switch (action.type) {
    case 'RECEIVE_RATE_PLANS': {
      let output = Object.assign({}, state);
      if (action.data.bundled_rate_plans) {
        output.bundled_rate_plans = Object.assign(
          {},
          output.bundled_rate_plans,
          zip(action.data.bundled_rate_plans)
        );
      }
      if (action.data.customer_class_rate_plans)
        output.customer_class_rate_plans = Object.assign(
          {},
          output.customer_class_rate_plans,
          zip(action.data.customer_class_rate_plans)
        );
      if (action.data.utility_rate_plans)
        output.utility_rate_plans = Object.assign(
          {},
          output.utility_rate_plans,
          zip(action.data.utility_rate_plans)
        );
      if (action.data.utilities)
        output.utilities = Object.assign({}, output.utilities, zip(action.data.utilities));
      if (action.data.cca_rate_plans) {
        output.cca_rate_plans = Object.assign(
          {},
          output.cca_rate_plans,
          zip(action.data.cca_rate_plans)
        );
      }
      if (action.data.ccas) output.ccas = Object.assign({}, output.ccas, zip(action.data.ccas));
      return output;
    }

    case 'RECEIVE_RATE_PLAN_RATES': {
      let output = Object.assign({}, state);
      let rate_plan_type = action.data.bundled_rate_plan ? 'bundled_rate_plan' : 'cca_rate_plan';
      const rate_plan_type_plural = rate_plan_type + 's';
      output[rate_plan_type_plural] = Object.assign({}, output[rate_plan_type_plural], {
        [action.data[rate_plan_type].id]: action.data[rate_plan_type],
      });
      if (action.data.sources) {
        output.sources = Object.assign({}, output.sources, zip(action.data.sources));
        if (action.data.rates) {
          output.rates = Object.assign({}, output.rates, zip(action.data.rates));
        }
      }
      if (action.data.customer_class_rate_plans) {
        output.customer_class_rate_plans = Object.assign(
          {},
          output.customer_class_rate_plans,
          zip(action.data.customer_class_rate_plans)
        );
      }
      if (action.data.utility_rate_plans) {
        output.utility_rate_plans = Object.assign(
          {},
          output.utility_rate_plans,
          zip(action.data.utility_rate_plans)
        );
      }
      if (action.data.regulator_rate_plans) {
        output.regulator_rate_plans = Object.assign(
          {},
          output.regulator_rate_plans,
          zip(action.data.regulator_rate_plans)
        );
      }
      if (action.data.rate_types)
        output.rate_types = Object.assign({}, output.rate_types, zip(action.data.rate_types));
      if (action.data.connection_levels) {
        output.connection_levels = Object.assign(
          {},
          output.connection_levels,
          zip(action.data.connection_levels)
        );
      }
      if (action.data.buckets)
        output.buckets = Object.assign({}, output.buckets, zip(action.data.buckets));
      if (action.data.seasons)
        output.seasons = Object.assign({}, output.seasons, zip(action.data.seasons));
      if (action.data.billing_units)
        output.billing_units = Object.assign(
          {},
          output.billing_units,
          zip(action.data.billing_units)
        );
      return output;
    }

    case 'RECEIVE_RATE_PLAN_AGREEMENTS': {
      let output = Object.assign({}, state);
      if (action.data.service_agreements)
        output.service_agreements = Object.assign(
          {},
          output.service_agreements,
          zip(action.data.service_agreements)
        );
      if (action.data.validation_cases)
        output.validation_cases = Object.assign(
          {},
          output.validation_cases,
          zip(action.data.validation_cases)
        );
      if (action.data.bill_validations)
        output.bill_validations = Object.assign(
          {},
          output.bill_validations,
          zip(action.data.bill_validations)
        );
      if (action.data.meters)
        output.meters = Object.assign({}, output.meters, zip(action.data.meters));
      if (action.data.rate_type_variations) {
        output.rate_type_variations = Object.assign(
          {},
          output.rate_type_variations,
          zip(action.data.rate_type_variations)
        );
      }
      return output;
    }
    case 'RECEIVE_BILL_HTML': {
      let output = Object.assign({}, state);
      if (output.bill_validations[action.bvId]) {
        if (action.data.bill) {
          output.bill_validations[action.bvId].bill = action.data.bill;
        }
      }
      return output;
    }
    default:
      return state;
  }
};
