import _ from 'lodash';

import { slices, store } from 'onescreen/store';
import {
  ErrorMessage,
  Maybe,
  Nullable,
  ObjectWithoutType,
  ObjectWithType,
  Query,
  StringTuple,
} from 'onescreen/types';
import { Meter } from '../apis';

import { ServiceAgreement, SolarGenerator, Storage } from '../assets';
import { DateRange, DateRanges } from '../dates';
import { IntervalFile } from '../interval';
import { AsyncTask, BaseAPI } from '../base';
import { ServiceSimulation } from './service_simulation';
import { MonitoringPlatform } from '../monitoring';
import { ServiceDrop, Site } from '../orgs';
import { BundledRatePlan, ConnectionLevel, RateTypeVariation, CCARatePlan } from '../rates';
import { CostSavings } from './cost_savings';
import { ServicePeriod } from './service_period';
import { AnalysisPeriod } from './analysis_period';

/** ======================== Types ========================================= */

type CommonAttrs = ObjectWithType<'ServiceAnalysis'> & {
  cost_savings?: CostSavings['id'];
  exclude: boolean;
  id: number;
  analysis_periods: Array<AnalysisPeriod['id']>;
  service_drop: ServiceDrop['id'];
  service_periods: Array<ServicePeriod['id']>;
  utility_usage: IntervalFile['id'];
  generation_data: IntervalFile['id'];
  storage_data: IntervalFile['id'];
  actual_usage?: IntervalFile['id'];
};

export declare namespace ServiceAnalysis {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {
    data_gaps?: DataGaps.Raw;
  }

  export namespace DataGaps {
    export type RawArray = Nullable<Array<StringTuple>>;
    export type ParsedSet = Nullable<DateRanges>;

    export interface Raw {
      generation_data: RawArray;
      storage_data: RawArray;
      utility_usage: RawArray;
    }

    export interface Parsed {
      generation_data: ParsedSet;
      storage_data: ParsedSet;
      utility_usage: ParsedSet;
    }
  }

  // Network interface
  export namespace API {
    export type CreateParams = { costSavings: CostSavings; service_drop: ServiceDrop['id'] };
    export type CreateResponse = { service_analysis: ServiceAnalysis.Raw };
    export type CreateResult = { serviceAnalysis: ServiceAnalysis };

    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResponse = {
      bundled_rate_plans?: BundledRatePlan.Raw[];
      cca_rate_plans?: CCARatePlan.Raw[];
      connection_levels?: ConnectionLevel.Raw[];
      rate_type_variations?: RateTypeVariation.Raw[];
      analysis_periods?: AnalysisPeriod.Raw[];
      service_agreements?: ServiceAgreement.Raw[];
      service_analysis: ServiceAnalysis.Raw;
      service_simulations?: ServiceSimulation.Raw[];
      service_periods?: ServicePeriod.Raw[];
      service_drop: ServiceDrop.Raw;
      sites: Site.Raw[];
      meters?: Meter.Raw[];
      solar_generators?: SolarGenerator.Raw[];
      storages?: Storage.Raw[];
      monitoring_platforms: MonitoringPlatform.Raw[];
    };

    export type RetrieveResult = {
      bundledRatePlans: Maybe<BundledRatePlan[]>;
      ccaRatePlans: Maybe<CCARatePlan[]>;
      connectionLevels: Maybe<ConnectionLevel[]>;
      rateTypeVariations: Maybe<RateTypeVariation[]>;
      analysisPeriods: Maybe<AnalysisPeriod[]>;
      serviceAgreements: Maybe<ServiceAgreement[]>;
      serviceAnalysis: ServiceAnalysis;
      servicePeriods: Maybe<ServicePeriod[]>;
      serviceSimulations: Maybe<ServiceSimulation[]>;
      serviceDrop: Maybe<ServiceDrop>;
    };

    export type CollectIntervalKeys = 'utility' | 'solar_generator' | 'storage';
    export type CollectIntervalParams = {
      utility_type?: 'utility' | 'file' | 'monitor';
      utility_file?: File;
      utility_monitor_id?: MonitoringPlatform['id'];
      utility_interpolate?: boolean;
      utility_overwrite?: boolean;
      solar_generator_type?: 'solar_generator' | 'file' | 'monitor';
      solar_generator_file?: File;
      solar_generator_monitor_id?: MonitoringPlatform['id'];
      solar_generator_interpolate?: boolean;
      solar_generator_overwrite?: boolean;
      storage_type?: 'storage' | 'file' | 'monitor';
      storage_file?: File;
      storage_monitor_id?: MonitoringPlatform['id'];
      storage_interpolate?: boolean;
      storage_overwrite?: boolean;
    };
    export type CollectIntervalResult = {
      task: AsyncTask;
    };
    export type CollectIntervalResponse = {
      task: AsyncTask['task_id'];
    };

    export type CreateServicePeriodsResult = CollectIntervalResult;
    export type CreateServicePeriodsResponse = CollectIntervalResponse;

    type ServicePeriodAnalysisResponse = {
      service_period_id: ServicePeriod['id'];
      task_id: AsyncTask<boolean>['task_id'];
    };
    type AnalysisPeriodAnalysisResponse = {
      analysis_period_id: AnalysisPeriod['id'];
      task_id: AsyncTask<Array<ServicePeriodAnalysisResponse>>['task_id'];
    };
    type SingleAnalysisResult = {
      analysisPeriod?: AnalysisPeriod;
      servicePeriod?: ServicePeriod;
      result?: boolean | ErrorMessage;
    };
    export type AnalyzeResult = Array<SingleAnalysisResult>;
    export type AnalyzeResponse = {
      task_id: AsyncTask<Array<AnalysisPeriodAnalysisResponse>>['task_id'];
    };
  }
}

/** ======================== API =========================================== */
class ServiceAnalysisAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.serviceAnalysis;

  static async create(
    params: ServiceAnalysis.API.CreateParams
  ): Promise<ServiceAnalysis.API.CreateResult> {
    const { costSavings, ...rest } = params;

    // Substitute the portfolio param with its ID
    const { json } = await this.post<ServiceAnalysis.API.CreateResponse>(this.route, {
      ...rest,
      cost_savings: costSavings.id,
    });

    // Parse response and add to store
    const serviceAnalysis = new ServiceAnalysis(json.service_analysis);
    store.dispatch(slices.data.updateModels(serviceAnalysis));
    costSavings.addServiceAnalysis(serviceAnalysis);

    return { serviceAnalysis };
  }

  static async destroy(id: ServiceAnalysis['id']): Promise<void> {
    await this.delete(this.route(id));
    store.dispatch(slices.data.removeModel(ServiceAnalysis.fromStore(id)!));
  }

  static async retrieve(
    id: ServiceAnalysis['id'],
    params?: ServiceAnalysis.API.RetrieveParams
  ): Promise<ServiceAnalysis.API.RetrieveResult> {
    const { json } = await this.get<ServiceAnalysis.API.RetrieveResponse>(this.route(id), params);

    // Parse results
    const bundledRatePlans = BundledRatePlan.fromObjects(json.bundled_rate_plans);
    const ccaRatePlans = CCARatePlan.fromObjects(json.cca_rate_plans);
    const connectionLevels = ConnectionLevel.fromObjects(json.connection_levels);
    const rateTypeVariations = RateTypeVariation.fromObjects(json.rate_type_variations);
    const analysisPeriods = AnalysisPeriod.fromObjects(json.analysis_periods);
    const serviceAgreements = ServiceAgreement.fromObjects(json.service_agreements);
    const serviceAnalysis = ServiceAnalysis.fromObject(json.service_analysis);
    const servicePeriods = ServicePeriod.fromObjects(json.service_periods);
    const serviceSimulations = ServiceSimulation.fromObjects(json.service_simulations);
    const serviceDrop = ServiceDrop.fromObject(json.service_drop);
    const sites = Site.fromObjects(json.sites);
    const meters = Meter.fromObjects(json.meters);
    const solarGenerators = SolarGenerator.fromObjects(json.solar_generators);
    const storages = Storage.fromObjects(json.storages);
    const monitoringPlatforms = MonitoringPlatform.fromObjects(json.monitoring_platforms);

    store.dispatch(
      slices.data.updateModels(
        bundledRatePlans,
        ccaRatePlans,
        connectionLevels,
        rateTypeVariations,
        analysisPeriods,
        serviceAgreements,
        serviceAnalysis,
        servicePeriods,
        serviceSimulations,
        serviceDrop,
        sites,
        meters,
        solarGenerators,
        storages,
        monitoringPlatforms
      )
    );

    return {
      bundledRatePlans,
      ccaRatePlans,
      connectionLevels,
      rateTypeVariations,
      analysisPeriods,
      serviceAgreements,
      serviceAnalysis,
      servicePeriods,
      serviceSimulations,
      serviceDrop,
    };
  }

  static async waitForAnalyses(
    task_id: AsyncTask['task_id']
  ): Promise<ServiceAnalysis.API.AnalyzeResult> {
    const task = new AsyncTask<Array<ServiceAnalysis.API.AnalysisPeriodAnalysisResponse>>(task_id);
    const analysisPeriodResults = await task.getResult();
    let resultList = [];
    for (let { analysis_period_id, task_id } of analysisPeriodResults) {
      const apTask = new AsyncTask<Array<ServiceAnalysis.API.ServicePeriodAnalysisResponse>>(
        task_id
      );
      const apResult = await apTask.getResult();
      const analysisPeriod = AnalysisPeriod.fromStore(analysis_period_id);
      for (let { service_period_id, task_id } of apResult) {
        const spTask = new AsyncTask<boolean | ErrorMessage>(task_id);
        const spResult = await spTask.getResult();
        const servicePeriod = ServicePeriod.fromStore(service_period_id);
        resultList.push({
          analysisPeriod,
          servicePeriod,
          result: spResult,
        });
      }
    }
    return resultList;
  }

  static async analyze(id: ServiceAnalysis['id']): Promise<ServiceAnalysis.API.AnalyzeResult> {
    const { json } = await this.get<ServiceAnalysis.API.AnalyzeResponse>(
      this.route(id) + 'analyze/'
    );
    const { task_id } = json;
    return this.waitForAnalyses(task_id);
  }

  static async collectIntervalData(
    id: ServiceAnalysis['id'],
    params?: ServiceAnalysis.API.CollectIntervalParams
  ): Promise<ServiceAnalysis.API.CollectIntervalResult> {
    const { json } = await this.postForm<ServiceAnalysis.API.CollectIntervalResponse>(
      this.route(id) + 'collect-intervals/',
      params
    );
    let { task } = json;
    const asyncTask = new AsyncTask<ServiceAnalysis.Raw>(task);
    const resp = await asyncTask.getResult();
    const serviceAnalysis = ServiceAnalysis.fromObject(resp);
    store.dispatch(slices.data.updateModels(serviceAnalysis));
    return { task: asyncTask };
  }

  static async lockUnlock(id: ServiceAnalysis['id']): Promise<ServiceAnalysis.API.CreateResult> {
    const { json } = await this.post<ServiceAnalysis.API.CreateResponse>(this.route(id) + 'lock/');
    const { service_analysis } = json;
    const serviceAnalysis = ServiceAnalysis.fromObject(service_analysis);
    store.dispatch(slices.data.updateModels(serviceAnalysis));
    return { serviceAnalysis };
  }

  static async createServicePeriods(
    id: ServiceAnalysis['id']
  ): Promise<ServiceAnalysis.API.CreateServicePeriodsResult> {
    const { json } = await this.post<ServiceAnalysis.API.CreateServicePeriodsResponse>(
      this.route(id) + 'create-service-periods/'
    );
    const { task } = json;
    const asyncTask = new AsyncTask(task);
    return { task: asyncTask };
  }
}

/** ======================== Model ========================================= */
export interface ServiceAnalysis extends CommonAttrs {}
export class ServiceAnalysis {
  /** ====================== Static fields ================================= */
  static api = ServiceAnalysisAPI;
  static readonly rawFields = [
    'id',
    'exclude',
    'analysis_periods',
    'service_drop',
    'service_periods',
    'cost_savings',
    'utility_usage',
    'generation_data',
    'storage_data',
    'actual_usage',
  ] as const;

  /**
   * Parses an array of raw ServiceAnalysis objects by passing each in turn to
   * ServiceAnalysis.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {ServiceAnalysis.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: ServiceAnalysis.Raw[]): ServiceAnalysis[];
  static fromObjects(raw: Maybe<ServiceAnalysis.Raw[]>): Maybe<ServiceAnalysis[]>;
  static fromObjects(raw: Maybe<ServiceAnalysis.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: ServiceAnalysis.Raw): ServiceAnalysis;
  static fromObject(raw: Maybe<ServiceAnalysis.Raw>): Maybe<ServiceAnalysis>;
  static fromObject(raw: Maybe<ServiceAnalysis.Raw>) {
    return raw ? new ServiceAnalysis(raw) : undefined;
  }

  static fromStore(): ServiceAnalysis[];
  static fromStore(id: Maybe<ServiceAnalysis['id']>): Maybe<ServiceAnalysis>;
  static fromStore(id?: ServiceAnalysis['id']) {
    const { serviceAnalyses } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(serviceAnalyses)).map(ServiceAnalysis.fromObject);
    } else {
      return id ? ServiceAnalysis.fromObject(serviceAnalyses[id]) : undefined;
    }
  }

  /**
   * Parser the raw data_gaps field, if provided
   *
   * @param {DataGaps.Raw} raw: the raw data gaps
   */
  static parseDataGaps(
    raw: Maybe<ServiceAnalysis.DataGaps.Raw>
  ): Maybe<ServiceAnalysis.DataGaps.Parsed> {
    if (!raw) return;
    return {
      generation_data: DateRanges.parseRanges(raw.generation_data),
      storage_data: DateRanges.parseRanges(raw.storage_data),
      utility_usage: DateRanges.parseRanges(raw.utility_usage),
    };
  }

  /**
   * Serializes the data_gaps field, if provided
   *
   * @param {DataGaps.Parsed} gaps: the parsed data gaps
   */
  static serializeDataGaps(
    gaps: Maybe<ServiceAnalysis.DataGaps.Parsed>
  ): Maybe<ServiceAnalysis.DataGaps.Raw> {
    if (!gaps) return;

    const serializeGapsArray = (gaps: ServiceAnalysis.DataGaps.ParsedSet) =>
      gaps ? gaps.serialize() : null;

    return {
      generation_data: serializeGapsArray(gaps.generation_data),
      storage_data: serializeGapsArray(gaps.storage_data),
      utility_usage: serializeGapsArray(gaps.utility_usage),
    };
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'ServiceAnalysis';
  data_gaps?: ServiceAnalysis.DataGaps.Parsed;

  constructor(raw: ServiceAnalysis.Raw) {
    Object.assign(this, _.pick(raw, ServiceAnalysis.rawFields));
    this.data_gaps = ServiceAnalysis.parseDataGaps(raw.data_gaps);
  }

  get dateRange() {
    const servicePeriods = this.ServicePeriods;
    if (servicePeriods.length === 0) return null;
    const sorted = _.sortBy(servicePeriods, ({ start_date }) => +start_date.toJSDate());
    return new DateRange([_.first(sorted)!.start_date, _.last(sorted)!.end_date]);
  }

  get name() {
    const serviceDrop = this.ServiceDrop;
    const site = serviceDrop?.Site;
    return `${site?.name} ${serviceDrop?.name}`;
  }

  serialize(): ServiceAnalysis.Serialized {
    return {
      ..._.pick(this, ServiceAnalysis.rawFields),
      data_gaps: ServiceAnalysis.serializeDataGaps(this.data_gaps),
      object_type: this.object_type,
    };
  }

  get ServiceDrop() {
    return ServiceDrop.fromStore(this.service_drop);
  }

  get AnalysisPeriods() {
    return _.truthy(this.analysis_periods.map(AnalysisPeriod.fromStore));
  }

  get ServicePeriods() {
    return _.truthy(this.service_periods.map(ServicePeriod.fromStore));
  }

  get CostSavings() {
    return CostSavings.fromStore(this.cost_savings);
  }

  get UtilityUsage() {
    return IntervalFile.fromStore(this.utility_usage);
  }

  get GenerationData() {
    return IntervalFile.fromStore(this.generation_data);
  }

  get StorageData() {
    return IntervalFile.fromStore(this.storage_data);
  }

  get ActualUsage() {
    return IntervalFile.fromStore(this.actual_usage);
  }

  get ServiceSimulations() {
    const serviceSimulations = ServiceSimulation.fromStore();
    return _.fromPairs(
      // For every service agreement...
      this.analysis_periods.map((analysisPeriodId) => {
        const analysisPeriod = AnalysisPeriod.fromStore(analysisPeriodId);
        return [
          analysisPeriod?.service_agreement,
          _.fromPairs(
            // ...and for every service period...
            this.service_periods.map((periodId) => {
              // ...find the simulation matching it, if one exists in the store.
              const serviceSimulation = _.find(serviceSimulations, {
                service_agreement: analysisPeriod?.service_agreement,
                service_period: periodId,
              });

              return [periodId, serviceSimulation];
            })
          ),
        ];
      })
    );
  }
}
