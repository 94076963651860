import _ from 'lodash';

import { slices, store } from 'onescreen/store';
import { Maybe, ObjectWithoutType, ObjectWithType, Pagination, Query } from 'onescreen/types';

import { AsyncTask, BaseAPI } from '../base';
import { DateRange } from '../dates';
import { Org, ServiceDrop, Site } from '../orgs';
import { AMSPortfolio } from './ams_portfolio';
import { ServiceAnalysis } from './service_analysis';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'CostSavings'> & {
  id: number;
  name: string;
  ams_portfolio?: AMSPortfolio.Raw;
  org: Org['id'];
  service_analyses?: Array<ServiceAnalysis['id']>;
};

export declare namespace CostSavings {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs, DateRange.Raw {}

  // Network interface
  export namespace API {
    type SideloadedResponses = {
      service_analyses?: ServiceAnalysis.Raw[];
      service_drops?: ServiceDrop.Raw[];
      sites?: Site.Raw[];
    };

    export type CreateResponse = { cost_savings: CostSavings.Raw };
    export type CreateResult = { costSavings: CostSavings };
    export type CreateParams = {
      portfolio: AMSPortfolio;
      end_date?: string;
      name: string;
      org: Org['id'];
      start_date?: string;
    };

    export type ListParams = RetrieveParams & Query.PaginationParams;
    export type ListResponse = Pagination.Response.Raw<
      { cost_savings: CostSavings.Raw[] } & SideloadedResponses
    >;
    export type ListResult = Pagination.Response.Parsed<CostSavings>;

    export type RetrieveResponse = { cost_savings: CostSavings.Raw } & SideloadedResponses;
    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResult = {
      costSavings: CostSavings;
      serviceAnalyses?: ServiceAnalysis[];
      serviceDrops?: ServiceDrop[];
      sites?: Site[];
    };

    export type DefaultDataCollectionParams = { refresh: Boolean };
    type ServiceAnalysisTaskMapResponse = {
      task_id: AsyncTask['task_id'];
      service_analysis_id: ServiceAnalysis['id'];
    };
    export type DefaultDataCollectionResponse = { tasks: ServiceAnalysisTaskMapResponse[] };
    type ServiceAnalysisTaskMapResult = {
      task: AsyncTask;
      serviceAnalysis: ServiceAnalysis;
    };
    export type DefaultDataCollectionResult = { tasks: ServiceAnalysisTaskMapResult[] };

    type ServiceAnalysisSingleAnalyzeResponse = {
      service_analysis_id: ServiceAnalysis['id'];
      task_id: AsyncTask['task_id'];
    };
    export type ServiceAnalysisAnalyzeResponse = Array<ServiceAnalysisSingleAnalyzeResponse>;
    type ServiceAnalysisAnalyzeResult = {
      serviceAnalysis?: ServiceAnalysis;
      task?: AsyncTask<ServiceAnalysis.API.AnalysisPeriodAnalysisResponse>;
    };
    export type AnalyzeResponse = { task_id: AsyncTask['task_id'] };
    export type AnalyzeResult = Array<ServiceAnalysisAnalyzeResult>;

    export type DownloadReportResponse = AnalyzeResponse;
    export type DownloadReportResult = { task: AsyncTask };
  }
}

/** ======================== API =========================================== */
class CostSavingsAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.costSavings;

  static async create(params: CostSavings.API.CreateParams): Promise<CostSavings.API.CreateResult> {
    const { portfolio, ...rest } = params;

    // Substitute the portfolio param with its ID
    const { json } = await this.post<CostSavings.API.CreateResponse>(this.route, {
      ...rest,
      ams_portfolio: portfolio.id,
    });

    // Parse response and add to store
    const costSavings = new CostSavings(json.cost_savings);
    store.dispatch(slices.data.updateModels(costSavings));
    portfolio.addCostSavings(costSavings);

    return { costSavings };
  }

  static async list(params?: CostSavings.API.ListParams): Promise<CostSavings.API.ListResult> {
    const { json } = await this.get<CostSavings.API.ListResponse>(this.route, params);

    // Parse response
    const costSavings = this.parsePaginationSet(json, ({ cost_savings }) =>
      CostSavings.fromObjects(cost_savings)
    );

    store.dispatch(slices.data.updateModels(costSavings.results));
    return costSavings;
  }

  static async retrieve(
    id: CostSavings['id'],
    params?: CostSavings.API.RetrieveParams
  ): Promise<CostSavings.API.RetrieveResult> {
    const { json } = await this.get<CostSavings.API.RetrieveResponse>(this.route(id), params);
    const { cost_savings, service_analyses, service_drops, sites } = json;

    // Parse response
    const costSavings = new CostSavings(cost_savings);
    const serviceAnalyses = service_analyses?.map((sa) => ServiceAnalysis.fromObject(sa));
    const serviceDrops = ServiceDrop.fromObjects(service_drops);
    const parsedSites = Site.fromObjects(sites);

    store.dispatch(
      slices.data.updateModels(costSavings, serviceAnalyses, serviceDrops, parsedSites)
    );

    return {
      costSavings,
      serviceAnalyses,
      serviceDrops,
      sites: parsedSites,
    };
  }

  static async destroy(id: CostSavings['id']): Promise<void> {
    await this.delete(this.route(id));
    slices.data.removeModel(CostSavings.fromStore(id)!);
  }

  static async analyze(id: CostSavings['id']): Promise<CostSavings.API.AnalyzeResult> {
    const { json } = await this.get<CostSavings.API.AnalyzeResponse>(this.route(id) + 'analyze/');
    const { task_id } = json;
    const task = new AsyncTask<CostSavings.API.ServiceAnalysisAnalyzeResponse>(task_id);
    const saResponses = await task.getResult();
    return saResponses.map(({ service_analysis_id, task_id }) => ({
      serviceAnalysis: ServiceAnalysis.fromStore(service_analysis_id),
      task: new AsyncTask(task_id),
    }));
  }

  static async downloadReport(
    id: CostSavings['id']
  ): Promise<CostSavings.API.DownloadReportResult> {
    const { json } = await this.get<CostSavings.API.AnalyzeResponse>(
      this.route(id) + 'download-report/'
    );
    const { task_id } = json;
    const task = new AsyncTask(task_id);
    return { task };
  }

  static async downloadGraphs(
    id: CostSavings['id']
  ): Promise<CostSavings.API.DownloadReportResult> {
    const { json } = await this.get<CostSavings.API.AnalyzeResponse>(
      this.route(id) + 'download-graphs/'
    );
    const { task_id } = json;
    const task = new AsyncTask(task_id);
    return { task };
  }

  static async default_data_collection(
    id: CostSavings['id'],
    params?: CostSavings.API.DefaultDataCollectionParams
  ): Promise<CostSavings.API.DefaultDataCollectionResult> {
    const { json } = await this.post<CostSavings.API.DefaultDataCollectionResponse>(
      this.route(id) + 'default-data-collection/',
      params
    );
    const { tasks } = json;
    return {
      tasks: tasks.map((task) => {
        return {
          task: new AsyncTask(task.task_id),
          serviceAnalysis: ServiceAnalysis.fromStore(task.service_analysis_id)!,
        };
      }),
    };
  }
}

/** ======================== Model ========================================= */
export interface CostSavings extends CommonAttrs {}
export class CostSavings extends DateRange {
  /** ====================== Static fields ================================= */
  static api = CostSavingsAPI;
  static readonly rawFields = ['id', 'name', 'org', 'service_analyses'] as const;

  /**
   * Parses an array of raw CostSavings objects by passing each in turn to
   * CostSavings.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {CostSavings.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: CostSavings.Raw[]): CostSavings[];
  static fromObjects(raw: Maybe<CostSavings.Raw[]>): Maybe<CostSavings[]>;
  static fromObjects(raw: Maybe<CostSavings.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: CostSavings.Raw): CostSavings;
  static fromObject(raw: Maybe<CostSavings.Raw>): Maybe<CostSavings>;
  static fromObject(raw: Maybe<CostSavings.Raw>) {
    return raw ? new CostSavings(raw) : undefined;
  }

  static fromStore(): CostSavings[];
  static fromStore(id: Maybe<CostSavings['id']>): Maybe<CostSavings>;
  static fromStore(id?: CostSavings['id']) {
    const { costSavings } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(costSavings)).map(CostSavings.fromObject);
    } else {
      return id ? CostSavings.fromObject(costSavings[id]) : undefined;
    }
  }

  /** ====================== Static fields ================================= */
  readonly object_type = 'CostSavings';
  constructor(raw: CostSavings.Raw) {
    super(raw);
    Object.assign(this, _.pick(raw, CostSavings.rawFields));
  }

  /**
   * Adds a ServiceAnalysis object to the cost savings and updates the store model
   *
   * @param {ServiceAnalysis} serviceAnalysis: the ServiceAnalysis model to add to the CostSavings
   */
  addServiceAnalysis(serviceAnalysis: ServiceAnalysis) {
    // If the service_analyses array already includes this ServiceAnalysis object, no update needed
    if (this.service_analyses?.includes(serviceAnalysis.id)) return;

    const serialized = this.serialize();
    const serviceAnalyses = serialized.service_analyses ?? [];
    const costSavings = new CostSavings({
      ...serialized,
      service_analyses: [...serviceAnalyses, serviceAnalysis.id],
    });

    store.dispatch(slices.data.updateModels(costSavings));
  }

  get Org() {
    return Org.fromStore(this.org);
  }

  serialize(): CostSavings.Serialized {
    return {
      ...this.serializedDateRange,
      ..._.pick(this, CostSavings.rawFields),
      object_type: this.object_type,
    };
  }

  get ServiceAnalyses() {
    return this.service_analyses
      ? _.truthy(this.service_analyses?.map(ServiceAnalysis.fromStore))
      : undefined;
  }
}
