import { store } from 'onescreen/store';
import { Query, Maybe, ObjectWithoutType, IdType } from 'onescreen/types';

/** ======================== Types ========================================= */
type CommonAttrs = {
  id: IdType;
  object_type: 'MonitoringPlatformAccountType';
  name: string;
  required_fields: any;
};

export declare namespace MonitoringPlatformAccountType {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}

  // Network interface
  export namespace API {
    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResponse = {
      monitoring_platform_account_type: MonitoringPlatformAccountType.Raw;
    };
    export type RetrieveResult = { monitoringPlatformAccountType: MonitoringPlatformAccountType };
  }
}

/** ======================== Model ========================================= */
export interface MonitoringPlatformAccountType extends CommonAttrs {}
export class MonitoringPlatformAccountType {
  /**
   * Parses an array of raw MonitoringPlatformAccountType objects by passing each in turn to MonitoringPlatformAccountType.fromObject. Note that
   * calling `raw.map(this.fromObject)` does not work, because TypeScript is unable to resolve the
   * correct overload for fromObject when it is passed to the `map` method. This is a TypeScript
   * design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {MonitoringPlatformAccountType.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: MonitoringPlatformAccountType.Raw[]): MonitoringPlatformAccountType[];
  static fromObjects(
    raw: Maybe<MonitoringPlatformAccountType.Raw[]>
  ): Maybe<MonitoringPlatformAccountType[]>;
  static fromObjects(raw: Maybe<MonitoringPlatformAccountType.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: MonitoringPlatformAccountType.Raw): MonitoringPlatformAccountType;
  static fromObject(
    raw: Maybe<MonitoringPlatformAccountType.Raw>
  ): Maybe<MonitoringPlatformAccountType>;
  static fromObject(raw: Maybe<MonitoringPlatformAccountType.Raw>) {
    return raw ? new MonitoringPlatformAccountType(raw) : undefined;
  }

  static fromStore(id: MonitoringPlatformAccountType['id']): MonitoringPlatformAccountType;
  static fromStore(
    id: Maybe<MonitoringPlatformAccountType['id']>
  ): Maybe<MonitoringPlatformAccountType>;
  static fromStore(id: Maybe<MonitoringPlatformAccountType['id']>) {
    return id
      ? MonitoringPlatformAccountType.fromObject(
          store.getState().data.monitoringPlatformAccountTypes[id]
        )
      : undefined;
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'MonitoringPlatformAccountType';
  constructor(raw: MonitoringPlatformAccountType.Raw) {
    this.required_fields = raw.required_fields;
    this.id = raw.id;
    this.name = raw.name;
  }

  serialize(): MonitoringPlatformAccountType.Serialized {
    return {
      required_fields: this.required_fields,
      id: this.id,
      object_type: this.object_type,
      name: this.name,
    };
  }
}
