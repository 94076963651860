import * as React from 'react';

import { Loading, LoadingCircle } from 'onescreen/components';
import { zip } from 'onescreen/data';
import fetchWithAuth, { postWithAuth, WaitForTask } from 'onescreen/requests';

export default class Bucketing extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filesReady: false, plansChosen: false };
  }

  componentDidMount() {
    fetchWithAuth('/v1/tou-plans/?include[]=provider.*', (data) => {
      this.setState({
        data,
        providers: zip(data.results.providers),
      });
    });
  }

  addFileToObj(file, count) {
    const reader = new FileReader();
    reader.onload = (data) => {
      let f = this.state.filesObj || {};
      f[file.name] = data.target.result;
      const cur_count = Object.keys(f).length;
      this.setState({ filesObj: f, filesReady: cur_count === count });
    };
    reader.readAsDataURL(file);
  }

  changeFiles(event) {
    this.setState({ filesObj: undefined });
    const files = event.target.files;
    const count = files.length;
    for (let file of files) {
      this.addFileToObj(file, count);
    }
    if (files.length === 0) this.setState({ filesReady: false });
  }

  changePlans(event) {
    const num = event.target.querySelectorAll(':checked').length;
    this.setState({ plansChosen: num > 0 });
  }

  submit(event) {
    event.preventDefault();
    this.setState({ outFile: undefined, waiting: true });
    let tou_ids = [];
    event.target['plans']
      .querySelectorAll(':checked')
      .forEach((option) => tou_ids.push(option.value));
    let options = {
      trim: event.target['trim'].checked,
      leap: event.target['leap-day'].checked,
      convert: event.target['convert'].checked,
      csvs: this.state.filesObj,
      tou_ids,
    };
    // The backend returns the future name of the file to download so I can
    // keep querying for it until it exists.
    postWithAuth('/v1/tou-plans/bucketing/', options, (data, err) => {
      if (data && !err) {
        WaitForTask(data, (result) => {
          if (result.status === 'SUCCESS') {
            this.setState({
              outFile: {
                url: result.result.replace(/"/g, ''),
              },
              waiting: false,
            });
          } else {
            this.setState({ downloadError: result.result, waiting: false });
          }
        });
      }
    });
  }

  render() {
    if (!this.state.data) return <Loading />;
    const plans = this.state.data.results.plans;
    let providers = this.state.providers;
    let grouped_plans = {};
    for (let plan of plans) {
      const prov_name = providers[plan.provider].name;
      if (!(prov_name in grouped_plans)) {
        grouped_plans[prov_name] = [];
      }
      grouped_plans[prov_name].push(
        <option key={plan.id} value={plan.id}>
          {plan.id} - {plan.name}
        </option>
      );
    }
    let groups = [];
    Object.keys(grouped_plans).forEach((key) => {
      groups.push(
        <optgroup key={key} label={key}>
          {grouped_plans[key]}
        </optgroup>
      );
    });
    const count = plans.length + Object.keys(providers).length;
    return (
      <div className="row">
        <div className="col s12 card-wrapper">
          <div className="card">
            <div className="card-content">
              <h4>Bucketing</h4>
              <form onSubmit={this.submit.bind(this)} className="row">
                <div className="col s6">
                  <select
                    multiple={true}
                    className="browser-default"
                    style={{ height: count * 20 }}
                    name="plans"
                    onChange={this.changePlans.bind(this)}
                  >
                    {groups}
                  </select>
                </div>
                <div className="col s6">
                  <input type="file" name="csvs" multiple onChange={this.changeFiles.bind(this)} />
                  <p>
                    <label>
                      <input type="checkbox" name="trim" />
                      <span>Trim</span>
                    </label>
                  </p>
                  <p>
                    <label>
                      <input type="checkbox" name="leap-day" />
                      <span>Remove Leap day</span>
                    </label>
                  </p>
                  <p>
                    <label>
                      <input type="checkbox" name="convert" />
                      <span>Convert Buckets and Seasons to Numbers</span>
                    </label>
                  </p>
                  <p>
                    <input
                      type="submit"
                      className="btn"
                      disabled={!this.state.filesReady || !this.state.plansChosen}
                    />
                  </p>
                </div>
              </form>
              <div className="col s6">{this.state.waiting ? <LoadingCircle /> : ''}</div>
              <div hidden={!this.state.downloadError} className="col s6">
                <span className="red-text">{this.state.downloadError}</span>
              </div>
              <div hidden={!this.state.outFile} className="col s6">
                <a className="btn" href={this.state.outFile ? this.state.outFile.url : '#'}>
                  Click to Download
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
