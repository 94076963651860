import React from 'react';
import { Navigate } from 'react-router-dom';

import { User } from 'onescreen/models';

/** ============================= Components =============================== */

export const RequireAuth: React.FC<{ children: JSX.Element }> = ({ children }) => {
  return User.isAuthenticated() ? children : <Navigate to="/login" />;
};
