import * as React from 'react';

import { Maybe } from 'onescreen/types';
import { LocalStorage } from 'onescreen/utils';

/** ======================== Types ========================================= */
type UseStateReturnValue<T> = [T, React.Dispatch<React.SetStateAction<T>>];

/** ======================== Hook ========================================== */
/**
 * Syncs a state parameter with a localStorage item. The state field will be initialized with the
 * current value in localStorage, and subsequent updates to state will also update the localStorage
 * field.
 *
 * @param {LocalStorage.Key} key: the local storage key to access/write to
 */
export function useLocalStorageState(key: LocalStorage.Key): UseStateReturnValue<Maybe<number>>;
/**
 * Syncs a state parameter with a localStorage item. The state field will be initialized with the
 * current value in localStorage (or the default value if the item isn't present), and subsequent
 * updates to state will also update the localStorage field.
 *
 * @param {LocalStorage.Key} key: the local storage key to access/write to
 * @param {number | string} defaultValue: a default value to initialize state with if there's no
 *   value in localStorage.
 */
export function useLocalStorageState(
  key: LocalStorage.Key,
  defaultValue: number
): UseStateReturnValue<number>;
export function useLocalStorageState(key: any, defaultValue?: any): UseStateReturnValue<any> {
  const [state, setState] = React.useState(LocalStorage.get(key, defaultValue));
  const setStorageState = (value: any) => {
    LocalStorage.set(key, value);
    setState(value);
  };

  return [state, setStorageState];
}
