import * as React from 'react';
import { Card, Grid, Typography } from '../common/components';

export default class NotFound extends React.Component {
  render() {
    return (
      <Grid>
        <Grid.Item>
          <Card>
            <Typography>Page Not Found!</Typography>
          </Card>
        </Grid.Item>
      </Grid>
    );
  }
}
