import _ from 'lodash';
import { DateTime } from 'luxon';

import { slices, store } from 'onescreen/store';
import { Maybe, ObjectWithoutType, Pagination, Query } from 'onescreen/types';

import { BaseAPI } from '../base';
import { Org } from '../orgs';
import { Meter } from './meter';

/** ======================== Types ========================================= */
type CommonAttrs = {
  id: number;
  object_type: 'Authorization';
  utilityapi_id: string;
  customer_email: string;
  utilityapi_utility_id: string;
  created?: DateTime;
  expires?: DateTime;
  revoked?: DateTime;
  status: 'pending' | 'updated' | 'errored';
  status_ts: DateTime;
  is_test?: boolean;
  account?: number;
  org: Org['id'];
  demo_org: Org['id'];
  meters?: Array<Meter['id']>;
};

export declare namespace Authorization {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}

  // Network interface
  export namespace API {
    export type SideloadedResponses = { meters?: Meter.Raw[] };

    export type ListParams = RetrieveParams & Query.PaginationParams;
    export type ListResult = Pagination.Response.Parsed<Authorization>;
    export type ListResponse = Pagination.Response.Raw<
      SideloadedResponses & { authorizations: Authorization.Raw[] }
    >;

    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResponse = SideloadedResponses & { authorization: Authorization.Raw };
    export type RetrieveResult = { authorization: Authorization.Serialized };
  }
}

/** ======================== API =========================================== */
class AuthorizationAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.authorization;

  static async list(params?: Authorization.API.ListParams): Promise<Authorization.API.ListResult> {
    const { json } = await this.get<Authorization.API.ListResponse>(this.route, params);

    // Parse response
    const { meters } = this.parseSideloadedObjects(json.results);
    const authorizations = this.parsePaginationSet(json, ({ authorizations }) =>
      Authorization.fromObjects(authorizations)
    );

    store.dispatch(slices.data.updateModels(authorizations.results, meters));
    return authorizations;
  }

  static async retrieve(
    id: Authorization.Raw['id'],
    params?: Authorization.API.RetrieveParams
  ): Promise<Authorization.API.RetrieveResult> {
    const { json } = await this.get<Authorization.API.RetrieveResponse>(this.route(id), params);

    // Parse response
    const { meters } = this.parseSideloadedObjects(json);
    const authorization = new Authorization(json.authorization);
    store.dispatch(slices.data.updateModels(authorization, meters));
    return { authorization };
  }

  static parseSideloadedObjects(json: Authorization.API.SideloadedResponses) {
    return { meters: Meter.fromObjects(json.meters) };
  }
}

/** ======================== Model ========================================= */
export interface Authorization extends CommonAttrs {}
export class Authorization {
  /** ====================== Static fields ================================= */
  static api = AuthorizationAPI;
  static readonly rawFields = [
    'id',
    'utilityapi_id',
    'customer_email',
    'utilityapi_utility_id',
    'created',
    'expires',
    'revoked',
    'status',
    'status_ts',
    'is_test',
    'account',
    'org',
    'demo_org',
    'meters',
  ] as const;

  /**
   * Parses an array of raw Authorization objects by passing each in turn to
   * Authorization.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {Authorization.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: Authorization.Raw[]): Authorization[];
  static fromObjects(raw: Maybe<Authorization.Raw[]>): Maybe<Authorization[]>;
  static fromObjects(raw: Maybe<Authorization.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: Authorization.Raw): Authorization;
  static fromObject(raw: Maybe<Authorization.Raw>): Maybe<Authorization>;
  static fromObject(raw: Maybe<Authorization.Raw>) {
    return raw ? new Authorization(raw) : undefined;
  }

  static fromStore(): Authorization[];
  static fromStore(id: Maybe<Authorization['id']>): Maybe<Authorization>;
  static fromStore(id?: Authorization['id']) {
    const { authorizations } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(authorizations)).map(Authorization.fromObject);
    } else {
      return id ? Authorization.fromObject(authorizations[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'Authorization';
  constructor(raw: Authorization.Raw) {
    Object.assign(this, _.pick(raw, Authorization.rawFields));
  }

  get Meters() {
    return this.meters?.map(Meter.fromStore);
  }

  serialize(): Authorization.Serialized {
    return { ..._.pick(this, Authorization.rawFields), object_type: this.object_type };
  }
}
