import _ from 'lodash';
import { store } from 'onescreen/store';
import { IdType, Maybe, ObjectWithoutType, ObjectWithType } from 'onescreen/types';
import { KnownDiscrepancy } from '.';
import { ApiBill, Bill } from '..';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'BillValidation'> & {
  id: IdType;
  bill: Bill['id'];
  is_validated: boolean;
  api_bill: ApiBill['id'];
  known_discrepancies: Array<KnownDiscrepancy['id']>;
};

export declare namespace BillValidation {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}
}

/** ======================== Model ========================================= */
export interface BillValidation extends CommonAttrs {}
export class BillValidation {
  /** ====================== Static fields ================================= */
  static readonly rawFields = [
    'id',
    'bill',
    'is_validated',
    'api_bill',
    'known_discrepancies',
  ] as const;

  /**
   * Parses an array of raw BillValidation objects by passing each in turn to
   * BillValidation.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {BillValidation.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: BillValidation.Raw[]): BillValidation[];
  static fromObjects(raw: Maybe<BillValidation.Raw[]>): Maybe<BillValidation[]>;
  static fromObjects(raw: Maybe<BillValidation.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: BillValidation.Raw): BillValidation;
  static fromObject(raw: Maybe<BillValidation.Raw>): Maybe<BillValidation>;
  static fromObject(raw: Maybe<BillValidation.Raw>) {
    return raw ? new BillValidation(raw) : undefined;
  }

  static fromStore(): BillValidation[];
  static fromStore(id: Maybe<BillValidation['id']>): Maybe<BillValidation>;
  static fromStore(id?: BillValidation['id']) {
    const { billValidations } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(billValidations)).map(BillValidation.fromObject);
    } else {
      return id ? BillValidation.fromObject(billValidations[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */

  readonly object_type = 'BillValidation';
  constructor(raw: BillValidation.Raw) {
    Object.assign(this, _.pick(raw, BillValidation.rawFields));
  }

  get ApiBill() {
    return ApiBill.fromStore(this.api_bill);
  }

  get Bill() {
    return Bill.fromStore(this.bill);
  }

  get KnownDiscrepancies() {
    return this.known_discrepancies.map(KnownDiscrepancy.fromStore);
  }

  serialize(): BillValidation.Serialized {
    return {
      ..._.pick(this, BillValidation.rawFields),
      object_type: this.object_type,
    };
  }
}
