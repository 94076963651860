import _ from 'lodash';

import { IdType, Maybe, ObjectWithoutType, Pagination, Query } from 'onescreen/types';
import { slices, store } from '../../store';
import { BaseAPI } from '../base';

import { RateTypeVariation } from './rate_type_variation';

/** ======================== Types ========================================= */
type CommonAttrs = {
  id: IdType;
  name: string;
  object_type: 'RatePlanVariation';
  rate_type_variations?: Array<RateTypeVariation['id']>;
};

export declare namespace RatePlanVariation {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}

  export namespace API {
    export type SideloadedResponses = {
      rate_type_variations: RateTypeVariation.Raw[];
    };

    export type ListParams = Query.DynamicRestParams & Query.PaginationParams;
    export type ListResult = Pagination.Response.Parsed<RatePlanVariation>;
    export type ListResponse = Pagination.Response.Raw<
      SideloadedResponses & {
        rate_plan_variations: RatePlanVariation.Raw[];
      }
    >;
  }
}

/** ======================== API =========================================== */
class RatePlanVariationAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.ratePlanVariation;

  static async list(
    params?: RatePlanVariation.API.ListParams
  ): Promise<RatePlanVariation.API.ListResult> {
    const { json } = await this.get<RatePlanVariation.API.ListResponse>(this.route, params);

    // Parse response
    const { rate_type_variations } = this.parseSideloadedObjects(json.results);
    const rate_plan_variations = this.parsePaginationSet(json, ({ rate_plan_variations }) =>
      RatePlanVariation.fromObjects(rate_plan_variations)
    );

    store.dispatch(slices.data.updateModels(rate_plan_variations.results, rate_type_variations));
    return rate_plan_variations;
  }

  static parseSideloadedObjects(json: RatePlanVariation.API.SideloadedResponses) {
    return {
      rate_type_variations: RateTypeVariation.fromObjects(json.rate_type_variations),
    };
  }
}

/** ======================== Model ========================================= */
export interface RatePlanVariation extends CommonAttrs {}
export class RatePlanVariation {
  /** ====================== Static fields ================================= */
  static api = RatePlanVariationAPI;
  static readonly rawFields = ['id', 'name', 'rate_type_variations'] as const;

  /**
   * Parses an array of raw RatePlanVariation objects by passing each in turn to
   * RatePlanVariation.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {RatePlanVariation.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: RatePlanVariation.Raw[]): RatePlanVariation[];
  static fromObjects(raw: Maybe<RatePlanVariation.Raw[]>): Maybe<RatePlanVariation[]>;
  static fromObjects(raw: Maybe<RatePlanVariation.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: RatePlanVariation.Raw): RatePlanVariation;
  static fromObject(raw: Maybe<RatePlanVariation.Raw>): Maybe<RatePlanVariation>;
  static fromObject(raw: Maybe<RatePlanVariation.Raw>) {
    return raw ? new RatePlanVariation(raw) : undefined;
  }

  static fromStore(): RatePlanVariation[];
  static fromStore(id: Maybe<RatePlanVariation['id']>): Maybe<RatePlanVariation>;
  static fromStore(id?: RatePlanVariation['id']) {
    const { ratePlanVariations } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(ratePlanVariations)).map(RatePlanVariation.fromObject);
    } else {
      return id ? RatePlanVariation.fromObject(ratePlanVariations[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'RatePlanVariation';
  private _rateTypeVariations?: RateTypeVariation[];

  constructor(raw: RatePlanVariation.Raw) {
    Object.assign(this, _.pick(raw, RatePlanVariation.rawFields));
  }

  serialize(): RatePlanVariation.Serialized {
    return { ..._.pick(this, RatePlanVariation.rawFields), object_type: this.object_type };
  }

  get RateTypeVariations() {
    if (this._rateTypeVariations) return this._rateTypeVariations;
    return (this._rateTypeVariations = _.truthy(
      this.rate_type_variations?.map(RateTypeVariation.fromStore) || []
    ));
  }
}
