import { useSelector } from 'react-redux';

import { Bill } from 'onescreen/models';
import { slices } from 'onescreen/store';
import { Loader } from 'onescreen/types';

import { useAsync } from './useAsync';

/**
 * Retrieves a bill given the proper bill id
 *
 * @param {Bill.id} id: the id to fetch the bill of
 * @param {Bill.API.RetrieveParams} [params]: parameters to use in the fetch request
 */
export function useBill(id?: Bill['id'], params?: Bill.API.RetrieveParams): Loader<Bill, 'bill'> {
  const loading = useAsync(async () => {
    if (!id) return;
    return Bill.api.retrieve(id, params);
  }, [id]);

  const bill = useSelector(slices.data.selectBill(id));
  return { loading, bill: Bill.fromObject(bill) };
}
