import * as React from 'react';
import { Button, Grid, List, Typography } from '.';
import { makeStylesHook } from '../styles';

type RadioListItemProps = {
  disabled?: boolean;
  selected?: boolean;
  hidden?: boolean;
  onClick?: React.EventHandler<React.MouseEvent>;
};
type FileSelectorRadioListItemProps = RadioListItemProps & {
  file?: File;
  onChange: (file: File) => void;
};

/** ============================= Styles =================================== */
const useRadioStyles = makeStylesHook(
  () => ({ radioButton: { paddingRight: 0 } }),
  'RadioListStyles'
);

/** ======================== Components ==================================== */
export const RadioListItem: React.FC<RadioListItemProps> = ({
  selected,
  disabled,
  onClick,
  children,
}) => {
  const classes = useRadioStyles();
  return (
    <List.Item selected={selected} disabled={disabled} button={false}>
      <Grid justify="flex-start">
        <Grid.Item span={1}>
          <Button
            _variant="text"
            size="small"
            disabled={disabled}
            onClick={onClick}
            className={classes.radioButton}
            icon={selected ? 'radio_button_checked' : 'radio_button_unchecked'}
          />
        </Grid.Item>
        <Grid.Item span={11}>{children}</Grid.Item>
      </Grid>
    </List.Item>
  );
};

export const FileSelectorListItem: React.FC<FileSelectorRadioListItemProps> = ({
  disabled,
  selected,
  onClick,
  onChange,
  file,
}) => {
  return (
    <RadioListItem disabled={disabled} selected={selected} onClick={onClick}>
      <Button
        size="small"
        disabled={!selected}
        onClick={(event) => {
          event.preventDefault();
          const input = event.currentTarget.parentElement?.querySelector(
            '.file-input'
          ) as HTMLInputElement;
          if (!!input) {
            input.click();
          }
        }}
      >
        Select file
      </Button>
      <Typography>{!!file?.name.length && `[${file.name}]`}</Typography>
      <input
        type="file"
        className="file-input"
        hidden={true}
        onChange={(evt) => {
          onChange && onChange(evt.target.files![0]);
        }}
      ></input>
    </RadioListItem>
  );
};
