import { Card, Typography } from 'onescreen/components';
import * as React from 'react';

import ReactMarkdown from 'react-markdown';

/** ======================== Component ===================================== */

export const ChangeLog: React.FC = () => {
  const [text, setText] = React.useState<string>('');
  fetch('/CHANGELOG.md')
    .then((value) => value.text())
    .then((txt) => setText(txt));
  return (
    <Card>
      <Card.Content>
        <Typography variant="h2">Change Log</Typography>
        <Typography>
          <ReactMarkdown
            renderers={{
              list: (props) => <ul className="browser-default">{props.children}</ul>,
            }}
          >
            {text}
          </ReactMarkdown>
        </Typography>
      </Card.Content>
    </Card>
  );
};
