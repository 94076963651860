import _ from 'lodash';
import * as React from 'react';
import MuiFormControl from '@material-ui/core/FormControl';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiListSubheader from '@material-ui/core/ListSubheader';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';

import { useRandomString } from 'onescreen/hooks';
import { makeStylesHook } from 'onescreen/styles';
import { Nullable } from 'onescreen/types';
import { Logger } from 'onescreen/utils';

import { Tooltip } from '../Tooltip';

/** ======================== Types ========================================= */
type OptionSection<T> = { title: Nullable<string>; options: T[] };
type SelectProps<T> = {
  className?: string;
  fullWidth?: boolean;
  id?: string;
  label?: string;
  onChange?: (value: T) => void;
  options?: T[];
  optionSections?: OptionSection<T>[];
  optionTooltip?: (option: T) => string | undefined;
  renderOption?: ((option: T) => string) | keyof T;
  sorted?: boolean;
  value: T | undefined;
  valueOption?: keyof T;
  disabled?: boolean;
};

type MultiSelectProps<T> = {
  className?: string;
  fullWidth?: boolean;
  id?: string;
  label?: string;
  onChange?: (value: T[]) => void;
  options?: T[];
  renderOption?: ((option: T) => string) | keyof T;
  sorted?: boolean;
  value: T[] | undefined;
  valueOption?: keyof T;
  disabled?: boolean;
};

type SectionOption<T> = {
  datum: T;
  index: number;
  text: string | T | T[keyof T];
  tooltip?: string;
};

type FormattedSection<T> = {
  title: Nullable<string>;
  options: Array<SectionOption<T>>;
};

/** ======================== Styles ======================================== */
const useStyles = makeStylesHook(
  () => ({
    formControl: {
      maxWidth: '100%',
      minWidth: 120,
    },
    menuItem: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    subheader: {
      cursor: 'default',
      pointerEvents: 'none',
    },
  }),
  'OneScreenSelect'
);

/** ======================== Components ==================================== */
export function Select<T>(props: SelectProps<T>) {
  const {
    fullWidth,
    id: idProp,
    label,
    onChange = () => {},
    options,
    optionSections,
    optionTooltip,
    renderOption = (option: T) => option,
    sorted = false,
    value,
    valueOption,
    ...rest
  } = props;
  const classes = useStyles();

  const randomString = useRandomString();
  const id = idProp || randomString;

  let inputLabel: React.ReactNode = null;
  if (label) {
    inputLabel = <MuiInputLabel id={id}>{label}</MuiInputLabel>;
  }

  if (!options && !optionSections) Logger.warn(warnings.noOptions);
  if (options && optionSections) Logger.warn(warnings.tooManyOptions);

  const sections: OptionSection<T>[] = options ? [{ options, title: null }] : optionSections || [];

  // Format the sections
  let i = 0;
  const formattedSections: FormattedSection<T>[] = sections.map((section) => ({
    title: section.title,
    options: section.options.map((option) => ({
      datum: option,
      index: i++,
      text: getOptionRendering(option),
      tooltip: getOptionTooltip(option),
    })),
  }));

  // If requested, sort the options by their text value
  i = 0;
  const sortedSections = sorted
    ? formattedSections.map((section) => ({
        title: section.title,
        options: _.sortBy(section.options, 'text').map((option) => ({ ...option, index: i++ })),
      }))
    : formattedSections;

  const sortedOptions = _.flatMap(sortedSections, 'options');
  const unselectedValue = '';

  return (
    <MuiFormControl className={classes.formControl} fullWidth={fullWidth}>
      {inputLabel}
      <MuiSelect
        labelId={label ? id : undefined}
        onChange={handleChange}
        value={getSelection()}
        {...rest}
      >
        {_.flatten(
          sortedSections.map((section) =>
            _.truthy([
              section.title && (
                <MuiListSubheader className={classes.subheader} key={`header-${section.title}`}>
                  {section.title}
                </MuiListSubheader>
              ),
              section.options.map((option) => (
                <MuiMenuItem key={option.index} value={option.index}>
                  {option.tooltip ? (
                    <Tooltip placement="left" title={option.tooltip}>
                      <div>
                        <span className={classes.menuItem} />
                        {option.text}
                      </div>
                    </Tooltip>
                  ) : (
                    option.text
                  )}
                </MuiMenuItem>
              )),
            ])
          )
        )}
      </MuiSelect>
    </MuiFormControl>
  );

  /** ====================== Callbacks ===================================== */
  function handleChange(event: React.ChangeEvent<{ name?: string; value: unknown }>) {
    const index = +(event.target.value as string);
    onChange(sortedOptions[index].datum);
  }

  /** ====================== Helpers ======================================= */
  function getOptionRendering(option: T) {
    return typeof renderOption === 'function' ? renderOption(option) : option[renderOption];
  }

  function getOptionTooltip(option: T) {
    return optionTooltip && optionTooltip(option);
  }

  function getSelection() {
    if (_.isUndefined(value)) return unselectedValue;
    return _.findIndex(
      sortedOptions,
      valueOption
        ? (option) => option.datum[valueOption] === value[valueOption]
        : (option) => option.datum === value
    );
  }
}

export function MultiSelect<T>(props: MultiSelectProps<T>) {
  const {
    fullWidth,
    id: idProp,
    label,
    onChange = () => {},
    options,
    renderOption = (option: T) => option,
    sorted = false,
    value,
    valueOption,
    ...rest
  } = props;
  const classes = useStyles();

  const randomString = useRandomString();
  const id = idProp || randomString;

  let inputLabel: React.ReactNode = null;
  if (label) {
    inputLabel = <MuiInputLabel id={id}>{label}</MuiInputLabel>;
  }

  if (!options) Logger.warn(warnings.noOptions);

  const section: OptionSection<T> = { options: options || [], title: null };

  // Format the sections
  let i = 0;
  const formattedSections: FormattedSection<T>[] = [
    {
      title: section.title,
      options: section.options.map((option) => ({
        datum: option,
        index: i++,
        text: getOptionRendering(option),
      })),
    },
  ];

  // If requested, sort the options by their text value
  i = 0;
  i = 0;
  const sortedSections = sorted
    ? formattedSections.map((section) => ({
        title: section.title,
        options: _.sortBy(section.options, 'text').map((option) => ({ ...option, index: i++ })),
      }))
    : formattedSections;

  const sortedOptions = _.flatMap(sortedSections, 'options');
  const unselectedValue: T[] = [];

  return (
    <MuiFormControl className={classes.formControl} fullWidth={fullWidth}>
      {inputLabel}
      <MuiSelect
        multiple
        labelId={label ? id : undefined}
        onChange={handleChange}
        value={getSelection()}
        {...rest}
      >
        {_.flatten(
          sortedSections.map((section) =>
            _.truthy([
              section.title && (
                <MuiListSubheader className={classes.subheader} key={`header-${section.title}`}>
                  {section.title}
                </MuiListSubheader>
              ),
              section.options.map((option) => (
                <MuiMenuItem key={option.index} value={option.index}>
                  {option.text}
                </MuiMenuItem>
              )),
            ])
          )
        )}
      </MuiSelect>
    </MuiFormControl>
  );

  /** ====================== Callbacks ===================================== */
  function handleChange(event: React.ChangeEvent<{ name?: string; value: unknown }>) {
    const indeces = event.target.value as number[];
    onChange(indeces.map((index) => sortedOptions[index].datum));
  }

  /** ====================== Helpers ======================================= */
  function getOptionRendering(option: T) {
    return typeof renderOption === 'function' ? renderOption(option) : option[renderOption];
  }

  function getSelection() {
    if (_.isEmpty(value)) return unselectedValue;
    else if (valueOption)
      return _.filter(sortedOptions, ({ datum }) =>
        value?.map((v) => v[valueOption]).includes(datum[valueOption])
      ).map((obj) => obj.index);
    return _.filter(sortedOptions, (option) => value?.includes(option.datum)).map(
      (obj) => obj.index
    );
  }
}

/** ======================== Helpers ======================================= */
const warnings = {
  noOptions:
    '`Select` component did not receive `options` prop or `optionSections` prop. One of the two, ' +
    'but not both, is expected.',

  tooManyOptions:
    '`Select` component received `options` prop and `optionSections` prop. One of the two, but ' +
    'not both, is expected.',
};
