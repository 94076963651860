import { configureStore } from '@reduxjs/toolkit';

import { monitorReducer, ratesReducer } from './reducers';
import * as slices from './slices';

export const store = configureStore({
  reducer: {
    data: slices.data.reducer,
    monitoring: monitorReducer,
    rates: ratesReducer,
    ui: slices.ui.reducer,
  },
});

// Updates the default state type used by `react-redux` so we don't need to manually specify the
// state type in every `useSelector` call
declare module 'react-redux' {
  interface DefaultRootState extends ReturnType<typeof store.getState> {}
}
