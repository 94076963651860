import _ from 'lodash';

import { store } from 'onescreen/store';
import { Maybe, ObjectWithoutType, ObjectWithType } from 'onescreen/types';
import { RegulatorRatePlan, Utility } from '.';
import { Rate } from '..';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'UtilityRatePlan'> & {
  friendly_name: string;
  id: number;
  rates?: Array<Rate['id']>;
  utility: Utility['id'];
  regulator_rate_plan: RegulatorRatePlan['id'];
};

export declare namespace UtilityRatePlan {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}
}

/** ======================== Model ========================================= */
export interface UtilityRatePlan extends CommonAttrs {}
export class UtilityRatePlan {
  /** ====================== Static fields ================================= */

  static readonly rawFields = [
    'friendly_name',
    'id',
    'rates',
    'utility',
    'regulator_rate_plan',
  ] as const;

  /**
   * Parses an array of raw UtilityRatePlan objects by passing each in turn to
   * UtilityRatePlan.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {UtilityRatePlan.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: UtilityRatePlan.Raw[]): UtilityRatePlan[];
  static fromObjects(raw: Maybe<UtilityRatePlan.Raw[]>): Maybe<UtilityRatePlan[]>;
  static fromObjects(raw: Maybe<UtilityRatePlan.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: UtilityRatePlan.Raw): UtilityRatePlan;
  static fromObject(raw: Maybe<UtilityRatePlan.Raw>): Maybe<UtilityRatePlan>;
  static fromObject(raw: Maybe<UtilityRatePlan.Raw>) {
    return raw ? new UtilityRatePlan(raw) : undefined;
  }

  static fromStore(): UtilityRatePlan[];
  static fromStore(id: Maybe<UtilityRatePlan['id']>): Maybe<UtilityRatePlan>;
  static fromStore(id?: UtilityRatePlan['id']) {
    const { utilityRatePlans } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(utilityRatePlans)).map(UtilityRatePlan.fromObject);
    } else {
      return id ? UtilityRatePlan.fromObject(utilityRatePlans[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'UtilityRatePlan';
  constructor(raw: UtilityRatePlan.Raw) {
    Object.assign(this, _.pick(raw, UtilityRatePlan.rawFields));
  }

  get RegulatorRatePlan() {
    return RegulatorRatePlan.fromStore(this.regulator_rate_plan);
  }

  get Utility() {
    return Utility.fromStore(this.utility);
  }

  get Rates() {
    return this.rates?.map(Rate.fromStore) || [];
  }

  serialize(): UtilityRatePlan.Serialized {
    return { ..._.pick(this, UtilityRatePlan.rawFields), object_type: this.object_type };
  }
}
