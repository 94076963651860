import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { Nullable } from 'onescreen/types';
import { cookieManager } from 'onescreen/utils';
import { AppBar } from 'onescreen/components/AppBar';
import { Button } from 'onescreen/components';
import { useUser } from 'onescreen/hooks/user';
import _ from 'lodash';

/** ======================== Constants ===================================== */
const pathRegex = /^\/([^/]+)/;

/** ======================== Types ========================================= */
type TabProps = { activeTab: Nullable<string>; route: string; text: string };

/** ======================== Component ===================================== */
const Tab: React.FC<TabProps> = ({ activeTab, route, text }) => {
  const active = activeTab === route;

  return (
    <Button _variant="text" color={active ? 'secondary' : undefined} href={`/${route}`}>
      {text}
    </Button>
  );
};

export const NavBar: React.FC = () => {
  const { pathname } = useLocation();
  const tabMatch = pathname.match(pathRegex);
  const activeTab = tabMatch ? tabMatch[1] : null;

  const [email] = cookieManager.authToken;

  const { user } = useUser();

  const tabs = [
    {
      route: 'orgs',
      text: 'Organizations',
      requiredPermissions: [
        'OrgViewers',
        'OrgEditors',
        'OrgFullAccessEditors',
        'TVECustomerAccess',
      ],
    },
    { route: 'rates', text: 'Rates', requiredPermissions: ['EnergyEngineers'] },
    {
      route: 'analysis',
      text: 'Analysis',
      requiredPermissions: [
        'EnergyEngineers',
        'AMSPortfolioEditors',
        'AMSPortfolioFullAccessEditors',
        'AMSPortfolioViewers',
      ],
    },
    {
      route: 'monitoring',
      text: 'Monitoring',
      requiredPermissions: [
        'TVECustomerAccess',
        'MonitorViewers',
        'MonitorEditors',
        'MonitorFullAccessEditors',
      ],
    },
    { route: 'bucketing', text: 'Bucketing', requiredPermissions: ['BucketingUsers'] },
  ];

  return (
    <AppBar email={email}>
      <div hidden={!email}>
        {tabs.map(({ route, text, requiredPermissions }) => {
          if (_.intersection(user?.groups || [], requiredPermissions).length) {
            return <Tab key={route} activeTab={activeTab} route={route} text={text} />;
          }
          return null;
        })}
      </div>
    </AppBar>
  );
};
