import _ from 'lodash';

import { Nullable, Pagination, Query } from 'onescreen/types';
import { cookieManager, Logger } from 'onescreen/utils';

import { makeURLCreator, URLCreator } from './url_creator';

/** ======================== Types ========================================= */
type HttpMethodType = 'DELETE' | 'GET' | 'PATCH' | 'POST';
type JSONResponse<JSONType> = { response: Response; json: JSONType };
type QueryParamPair = [string, Query.Primitive | Query.Primitive[]];
type URL = URLCreator | string;
export type ProgressCallback = (receivedLength: number, contentLength: number) => void;

/** ======================== Constants ===================================== */
export const HTTP_STATUSES = {
  created: 201,
  noContent: 204,
  badRequest: 400,
  forbidden: 403,
};
export const ERROR_HTTP_STATUSES = [400, 401, 403, 404, 500];

/** ======================== API =========================================== */
export class BaseAPI {
  static endpoints = makeURLCreator(process.env.REACT_APP_API_URL);

  /** ====================== Requests ====================================== */
  /**
   * Makes a request using the fetch API
   *
   * @param {HttpMethodType} method: the HTTP method to use for the request (e.g. GET, POST, etc)
   * @param {URL} url: the URL to send the request to. May be either a string or a `URLCreator`
   * @param {object} body: the body of the request. Typically this will be JSON.
   */
  private static async makeJsonRequest<T>(
    method: HttpMethodType,
    url: URL,
    body?: string | FormData
  ) {
    return await this.formatResponse<T>(
      fetch(String(url), {
        body,
        headers: this.getRequestHeaders(!(body instanceof FormData)),
        method,
      })
    );
  }

  /**
   * Given a Promise that will resolve to a Response object (returned by the fetch API), returns a
   * new promise that will resolve to a JSONResponse object if the initial promise resolves, and
   * will reject with an error if the initial promise rejects.
   *
   * @param {Promise<Response>} promise: the promise returned by the fetch API
   * @private
   */
  private static formatResponse<T>(promise: Promise<Response>) {
    return new Promise<JSONResponse<T>>(async (resolve, reject) => {
      try {
        const response = await promise;
        // If there's no content `response.json()` throws an error
        const noContent = response.status === HTTP_STATUSES.noContent;
        const json = noContent ? undefined : await response.json();
        if (!ERROR_HTTP_STATUSES.includes(response.status)) {
          resolve({ response, json });
        } else {
          const { detail } = json;
          reject(detail);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Makes a JSON DELETE request
   *
   * @param {string} url: the URL of the DELETE request
   * @protected
   */
  protected static delete<T = any>(url: URL) {
    const response = this.makeJsonRequest<T>('DELETE', url);
    return response;
  }

  /**
   * Makes a JSON GET request
   *
   * @param {string} url: the URL of the GET request
   * @param {Query.Params} queryParams: parameters to include in the querystring
   * @protected
   */
  protected static get<T = any>(url: URL, queryParams?: Query.Params) {
    const response = this.makeJsonRequest<T>(
      'GET',
      QueryCompiler.appendQueryString(url, queryParams)
    );
    return response;
  }

  /**
   * Makes a JSON POST request
   *
   * @param {string} url: the URL of the POST request
   * @param {object} body: the body of the request
   * @protected
   */
  protected static post<T = any>(url: URL, body: object = {}) {
    const response = this.makeJsonRequest<T>('POST', url, JSON.stringify(body));
    return response;
  }

  /**
   * Emulates a form submission using the fetch API
   *
   * @param {URL} url: the URL to send the request to. May be either a string or a `URLCreator`
   * @param {object} formFields: an object mapping form data fields to their values
   */
  protected static postForm<T = any>(url: URL, formFields?: object) {
    const body = this.objToFormData(formFields!);
    const response = this.makeJsonRequest<T>('POST', url, body);
    return response;
  }

  /**
   * Makes a JSON PATCH request
   *
   * @param {string} url: the URL of the PATCH request
   * @param {object} body: the body of the request
   * @protected
   */
  protected static patch<T = any>(url: URL, body: object, queryParams?: Query.Params) {
    const fullUrl = QueryCompiler.appendQueryString(url, queryParams);
    const response = this.makeJsonRequest<T>('PATCH', fullUrl, JSON.stringify(body));
    return response;
  }

  /** ====================== Parsing ======================================= */
  /**
   * Parses a raw pagination set (the raw response from the back end for a paginated endpoint) into
   * a parsed pagination set.
   *
   * @param {Pagination.Response.Raw} paginationSet: the raw server response to parse
   * @param {Function} [parseFn]: a function that parses an individual result from its raw version
   *   to its parsed version. Defaults to the identity function
   */
  protected static parsePaginationSet<RawSchema, Datum>(
    paginationSet: Pagination.Response.Raw<RawSchema>,
    parseFn: (schema: RawSchema) => Datum[]
  ): Pagination.Response.Parsed<Datum> {
    return {
      count: paginationSet.count,
      results: parseFn(paginationSet.results),
      next: paginationSet.next,
    };
  }

  protected static getRequestHeaders(includeContentType: boolean = true) {
    const [, token] = cookieManager.authToken;
    return new Headers(
      _.truthy({
        'Authorization': token && `Bearer ${token}`,
        'Content-Type': includeContentType && 'application/json',
        'X-CSRFToken': cookieManager.csrfToken,
      })
    );
  }

  /**
   * Given an object, creates a FormData object with the object's keys and corresponding values as
   * fields
   *
   * @param {object} formFields: the object to convert to a FormData object
   */
  private static objToFormData(formFields: object) {
    const formData = new FormData();
    Object.entries(formFields).forEach(([fieldName, value]) => {
      if (!!value) formData.append(fieldName, value);
    });
    return formData;
  }

  /** ====================== File Management =============================== */
  /**
   * Downloads a file and saves it to disk
   *
   * @param {URL} url: the URL to send the request to. May be either a string or a `URLCreator`
   * @param {string} defaultFileName: the name to give the file if no `Content-Disposition` header
   *   is returned with the response
   * @param {ProgressCallback} onProgress: a callback to run when a new chunk is downloaded
   */
  protected static async downloadFile(
    url: URL,
    defaultFileName: string,
    onProgress?: ProgressCallback
  ) {
    const { fileName, blob } = await this.fetchFile(String(url), onProgress);
    this.saveBlob(blob, fileName ?? defaultFileName);
  }

  /**
   * Downloads a file from a data URL (i.e. a URL that begins with "data:")
   *
   * @param {string} url: the data URL. If this does not begin with "data:" an error is thrown
   * @param {string} name: the name to give to the file
   */
  protected static downloadDataURL(url: string, name: string) {
    // Validate URL
    if (!url.startsWith('data:')) throw new Error('Invalid URL provided to downloadDataURL method');

    // Create a link to perform the download...
    const link = document.createElement('a');
    link.className = 'data-url-anchor';
    link.download = name;
    link.href = url;

    // Append it to the document body, click it and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /**
   * Inspects the `Content-Disposition` header of the response to determine the filename to use for
   * the download
   *
   * @param {Response} response: the `Response` object returned by fetch
   */
  private static getFileNameFromContentDispositionHeader(response: Response): Nullable<string> {
    const contentDisposition = response.headers.get('content-disposition');
    if (!contentDisposition) return null;

    const standardPattern = /filename=(["']?)(.+)\1/i;
    const wrongPattern = /filename=([^"'][^;"'\n]+)/i;

    if (standardPattern.test(contentDisposition)) {
      return contentDisposition.match(standardPattern)![2];
    }

    if (wrongPattern.test(contentDisposition)) {
      return contentDisposition.match(wrongPattern)![1];
    }

    return null;
  }

  /**
   * Saves the Blob to a file
   *
   * @param {Blob} blob: the blob to save
   * @param {string} fileName: the name of the file
   */
  private static saveBlob(blob: Blob, fileName: string) {
    // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary
    // to use msSaveOrOpenBlob
    if (window.navigator?.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    }

    // For other browsers: create a link pointing to the ObjectURL containing the blob.
    const objUrl = window.URL.createObjectURL(blob);

    let link = document.createElement('a');
    link.href = objUrl;
    link.download = fileName;
    link.click();

    // For Firefox it is necessary to delay revoking the ObjectURL.
    setTimeout(() => {
      window.URL.revokeObjectURL(objUrl);
    }, 250);
  }

  /**
   * Fetches the file to download. If provided, the `onProgress` callback is called with every
   * chunk loaded.
   *
   * @param {string} url: the URL of the file
   * @param {ProgressCallback} onProgress: a callback to run when a new chunk is downloaded
   */
  protected static async fetchFile(url: string, onProgress?: ProgressCallback) {
    // Fetch the file
    const response = await fetch(url, {
      method: 'GET',
      headers: this.getRequestHeaders(),
    });

    if (!response.ok || !response.body) {
      const responseBody = await response.text();
      throw new Error(responseBody ?? 'Unable to fetch file');
    }

    const reader = response.body.getReader();
    const contentLength = Number(response.headers.get('content-length'));

    let receivedLength = 0;
    const chunks = [];
    while (true) {
      const stream = await reader.read();

      if (stream.done) break;

      chunks.push(stream.value);
      receivedLength += stream.value.length;

      if (typeof onProgress !== 'undefined') {
        onProgress(receivedLength, contentLength);
      }
    }

    const type = response.headers.get('content-type')?.split(';')[0];
    return {
      fileName: this.getFileNameFromContentDispositionHeader(response),
      blob: new Blob(chunks, { type }),
    };
  }
}

/** ======================== Query compilation ============================= */
class QueryCompiler {
  /**
   * Produces the querystring for a request, given an object representing the key-value pairs to
   * include in the querystring.
   *
   * @param {string} route: the base route to which the querystring will be appended
   * @param {Query.Params} params: the object of key-value pairs that should be converted into a
   *   querystring
   */
  static appendQueryString(route: string | URL, params?: Query.Params): string {
    if (!params) return String(route);

    // Handle any dynamic rest params
    const drQueryParamPairs = [
      ...this.makeIncludeExcludeQueryParam('include', params.include),
      ...this.makeIncludeExcludeQueryParam('exclude', params.exclude),
      ...this.makeFilterQueryParams(params.filter),
      ...this.makePaginationQueryParams(params),
    ];

    // Handle all other params
    const nonDynamicRestParams = _.omit(params, [
      'exclude',
      'include',
      'filter',
      'page',
      'pageSize',
      'sortKey',
      'sortDir',
    ]);

    const nonDRQueryParamPairs: QueryParamPair[] = _.truthy(
      Object.entries(nonDynamicRestParams).map(([key, value]) => {
        // Apply some basic validation on the `unknown` type
        if (typeof value === 'string' || typeof value === 'number' || Array.isArray(value)) {
          return [key, value];
        }

        // Print a warning and return `null`. The `null` value will be removed by `_.truthy`
        Logger.warn(`Query parameter "${key}" received invalid value: ${value}`);
        return null;
      })
    );

    // Reduce the array of pairs
    const queryString = [...drQueryParamPairs, ...nonDRQueryParamPairs]
      .map(([param, value]) => [param, encodeURIComponent(value.toString())].join('='))
      .join('&');

    return String(route).concat(queryString.length === 0 ? '' : `?${queryString}`);
  }

  /**
   * Creates query params from a DynamicRestFilters object
   *
   * @param {DynamicRestFilters} filterClauses: object mapping keys to filter clauses
   */
  private static makeFilterQueryParams(
    filterClauses: Query.DynamicRestParams['filter']
  ): QueryParamPair[] {
    if (!filterClauses) return [];

    // Each of the filters gets its own `filter` query parameter
    const queryParamPairs: QueryParamPair[] = [];
    Object.entries(filterClauses).forEach(([field, filterClause]) => {
      if (filterClause.operation === 'in') {
        // Every value in the `IN` clause gets its own query parameter
        const paramKey = `filter{${field}.in}`;
        queryParamPairs.push(...filterClause.value.map((v): QueryParamPair => [paramKey, v]));
      } else {
        queryParamPairs.push([`filter{${field}}`, filterClause.value]);
      }
    });

    return queryParamPairs;
  }

  /**
   * Creates query params from a PaginationQueryParams object
   *
   * @param {Partial<Pagination.QueryParams>} params: object of pagination fields mapping parameter
   *   name to value
   */
  private static makePaginationQueryParams(params: Partial<Pagination.QueryParams>) {
    const { limit, offset, sortDir, sortKey } = params;
    const queryParamPairs: QueryParamPair[] = [];

    // Handle the 1-indexing for the backend
    if (!_.isUndefined(offset) && !_.isUndefined(limit))
      queryParamPairs.push(['offset', offset + limit]);
    if (limit) queryParamPairs.push(['limit', limit]);

    // The sortKey can either be a string or an array of strings. If an array, give each key its own
    // `sort[]` query parameter.
    if (sortKey) {
      if (_.isString(sortKey)) {
        queryParamPairs.push(['sort[]', addSortDir(sortKey)]);
      } else {
        queryParamPairs.push(...sortKey.map((k): QueryParamPair => ['sort[]', addSortDir(k)]));
      }
    }

    return queryParamPairs;

    /**
     * Minor helper function for combining a sortKey and the sortDir
     *
     * @param {string} key: the key to sort on
     */
    function addSortDir(key: string) {
      return (sortDir === 'desc' ? '-' : '') + key;
    }
  }

  /**
   * Creates query params for the `include[]` and `exclude[]` fields
   *
   * @param {string} key:
   * @param {Query.IncludeExcludeFields} fields: object of pagination fields mapping parameter name
   *   to value
   */
  private static makeIncludeExcludeQueryParam(
    key: 'include' | 'exclude',
    fields?: Query.IncludeExcludeFields
  ): QueryParamPair[] {
    if (!fields) return [];

    // If the value is an array, repeat the parameter key once per element
    const paramKey = `${key}[]`;
    return Array.isArray(fields) ? fields.map((v) => [paramKey, v]) : [[paramKey, fields]];
  }
}

type Callback = (results: any, err?: any) => void;
export interface AsyncTask<T = any> {
  task_id: string;
  status?: string;
  result?: T;
}
export class AsyncTask<T> extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.taskResult;

  constructor(id: AsyncTask<T>['task_id']) {
    super();
    Object.assign(this, { task_id: id });
  }

  async waitForTask(callback: Callback) {
    const makeTheCall = async () => {
      const { response, json } = await AsyncTask.get<{ task_result: AsyncTask<T> }>(
        AsyncTask.route(this.task_id)
      );
      if (response.ok && response.status === 204) {
        setTimeout(makeTheCall, 2000);
      } else if (!response.ok) {
        callback(undefined, json.task_result);
      } else {
        const { task_result } = json;
        callback(task_result as AsyncTask<T>);
      }
    };
    makeTheCall();
  }

  async getResult(): Promise<T> {
    return new Promise(async (resolve, reject) => {
      const makeTheCall = async () => {
        const { response, json } = await AsyncTask.get<{ task_result: AsyncTask<T> }>(
          AsyncTask.route(this.task_id)
        );
        if (response.ok && response.status === 204) {
          setTimeout(makeTheCall, 2000);
        } else if (!response.ok) {
          reject({ msg: 'An error occured', error: json.task_result.result });
        } else {
          const { task_result } = json;
          resolve(task_result.result!);
        }
      };
      await makeTheCall();
    });
  }
}
