import _ from 'lodash';

import { store } from 'onescreen/store';
import { IdType, Maybe, ObjectWithoutType, ObjectWithType } from 'onescreen/types';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'Utility'> & {
  name: string;
  id: IdType;
  short_name: string;
};

export declare namespace Utility {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}
}

/** ======================== Model ========================================= */
export interface Utility extends CommonAttrs {}
export class Utility {
  /** ====================== Static fields ================================= */
  static readonly rawFields = ['name', 'id', 'short_name'] as const;

  /**
   * Parses an array of raw Utility objects by passing each in turn to
   * Utility.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {Utility.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: Utility.Raw[]): Utility[];
  static fromObjects(raw: Maybe<Utility.Raw[]>): Maybe<Utility[]>;
  static fromObjects(raw: Maybe<Utility.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: Utility.Raw): Utility;
  static fromObject(raw: Maybe<Utility.Raw>): Maybe<Utility>;
  static fromObject(raw: Maybe<Utility.Raw>) {
    return raw ? new Utility(raw) : undefined;
  }

  static fromStore(): Utility[];
  static fromStore(id: Maybe<Utility['id']>): Maybe<Utility>;
  static fromStore(id?: Utility['id']) {
    const { utilities } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(utilities)).map(Utility.fromObject);
    } else {
      return id ? Utility.fromObject(utilities[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'Utility';
  constructor(raw: Utility.Raw) {
    Object.assign(this, _.pick(raw, Utility.rawFields));
  }

  serialize(): Utility.Serialized {
    return { ..._.pick(this, Utility.rawFields), object_type: this.object_type };
  }
}
