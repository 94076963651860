import * as React from 'react';

/** ======================== Types ========================================= */
type DownloadLinkProps = {
  url: string | undefined;
  alwaysHidden?: boolean;
};

/** ======================== Components ==================================== */
export const DownloadLink: React.FC<DownloadLinkProps> = ({ url, alwaysHidden = false }) => {
  const [hasDownloaded, setHasDownloaded] = React.useState(false);

  React.useEffect(() => {
    if (url && !hasDownloaded) {
      setHasDownloaded(true);
      (document.querySelector('a#download-link') as HTMLAnchorElement).click();
    }
  }, [url, setHasDownloaded, hasDownloaded]);

  return (
    <a hidden={!alwaysHidden && !url} id="download-link" href={url}>
      Click here if download did not start automatically
    </a>
  );
};
