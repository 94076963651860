import _ from 'lodash';
import { useState } from 'react';

import { MonitoringPlatform } from 'onescreen/models';
import { Loader, Maybe, OnFailCallback } from 'onescreen/types';

import { useAsync } from './useAsync';
import { slices } from '../store';
import { useSelector } from 'react-redux';

/**
 * Retrieves all MonitoringPlatform objects
 *
 * @param {MonitoringPlatform.API.ListParams} params: query parameters
 * @param {OnFailCallback} onFail: callback for when the request fails
 */
export function useMonitoringPlatforms(
  params?: MonitoringPlatform.API.ListParams,
  onFail?: OnFailCallback
): Loader<MonitoringPlatform.API.ListResult, 'monitoring_platforms'> {
  const [monitors, setMonitors] = useState<MonitoringPlatform.API.ListResult>();
  const allMonitors = useSelector(slices.data.selectMonitoringPlatforms());
  const loading = useAsync(
    async () => MonitoringPlatform.api.list(params),
    [],
    setMonitors,
    onFail
  );

  return {
    loading,
    monitoring_platforms: {
      ...monitors,
      results: allMonitors,
    } as MonitoringPlatform.API.ListResult,
  };
}

/**
 * Retrieves one MonitoringPlatform object
 *
 * @param {MonitoringPlatform.API.RetrieveParams} params: query parameters
 * @param {OnFailCallback} onFail: callback for when the request fails
 */
export function useMonitoringPlatform(
  id?: MonitoringPlatform['id'],
  params?: MonitoringPlatform.API.RetrieveParams,
  shouldUpdate: boolean = true,
  onFail?: OnFailCallback
): Loader<Maybe<MonitoringPlatform>, 'monitoringPlatform'> {
  const monitor = useSelector(slices.data.selectMonitoringPlatform(id));
  const loading = useAsync(
    async () => {
      if (!id || !shouldUpdate) return;
      return MonitoringPlatform.api.retrieve(id, params);
    },
    [id, shouldUpdate],
    () => _.noop(),
    onFail
  );

  return {
    loading,
    monitoringPlatform: MonitoringPlatform.fromObject(monitor),
  };
}
