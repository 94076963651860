import _ from 'lodash';
import { DefaultRootState } from 'react-redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  User,
  AMSPortfolio,
  Bill,
  ApiBill,
  BundledRatePlan,
  CustomerClassRatePlan,
  UtilityRatePlan,
  RegulatorRatePlan,
  RateType,
  Rate,
  Source,
  Utility,
  CCA,
  BillingUnit,
  Bucket,
  Season,
  ValidationCase,
  BillValidation,
  KnownDiscrepancy,
  CCARatePlan,
  ConnectionLevel,
  CostSavings,
  AnalysisPeriod,
  Org,
  RatePlanVariation,
  RateTypeVariation,
  ServiceAgreement,
  ServiceAnalysis,
  ServiceDrop,
  ServicePeriod,
  ServiceSimulation,
  Site,
  MonitoringPlatform,
  MonitoringPlatformAccountType,
  SolarGenerator,
  Storage,
  BaselineMonth,
  IntervalFile,
  Meter,
  Authorization,
} from 'onescreen/models';
import { Maybe, Nullable, ObjectWithId } from 'onescreen/types';

/** ============================ Types ===================================== */
type RemoveModelAction = PayloadAction<ModelClassSerialized>;
type UpdateModelsAction = PayloadAction<ModelClassSerialized[]>;
type IdMap<T extends ObjectWithId> = Record<T['id'], Maybe<T>>;

type DataSlice = {
  users: IdMap<User.Serialized>;
  bills: IdMap<Bill.Serialized>;
  apiBills: IdMap<ApiBill.Serialized>;
  bundledRatePlans: IdMap<BundledRatePlan.Serialized>;
  customerClassRatePlans: IdMap<CustomerClassRatePlan.Serialized>;
  utilityRatePlans: IdMap<UtilityRatePlan.Serialized>;
  regulatorRatePlans: IdMap<RegulatorRatePlan.Serialized>;
  rateTypes: IdMap<RateType.Serialized>;
  rates: IdMap<Rate.Serialized>;
  sources: IdMap<Source.Serialized>;
  utilities: IdMap<Utility.Serialized>;
  ccas: IdMap<CCA.Serialized>;
  billingUnits: IdMap<BillingUnit.Serialized>;
  buckets: IdMap<Bucket.Serialized>;
  seasons: IdMap<Season.Serialized>;
  validationCases: IdMap<ValidationCase.Serialized>;
  billValidations: IdMap<BillValidation.Serialized>;
  knownDiscrepancies: IdMap<KnownDiscrepancy.Serialized>;
  ccaRatePlans: IdMap<CCARatePlan.Serialized>;
  connectionLevels: IdMap<ConnectionLevel.Serialized>;
  costSavings: IdMap<CostSavings.Serialized>;
  analysisPeriod: IdMap<AnalysisPeriod.Serialized>;
  intervalFiles: IdMap<IntervalFile.Serialized>;
  orgs: IdMap<Org.Serialized>;
  portfolios: IdMap<AMSPortfolio.Serialized>;
  ratePlanVariations: IdMap<RatePlanVariation.Serialized>;
  rateTypeVariations: IdMap<RateTypeVariation.Serialized>;
  serviceAgreements: IdMap<ServiceAgreement.Serialized>;
  serviceAnalyses: IdMap<ServiceAnalysis.Serialized>;
  serviceDrops: IdMap<ServiceDrop.Serialized>;
  servicePeriods: IdMap<ServicePeriod.Serialized>;
  serviceSimulations: IdMap<ServiceSimulation.Serialized>;
  sites: IdMap<Site.Serialized>;
  monitoringPlatforms: IdMap<MonitoringPlatform.Serialized>;
  monitoringPlatformAccountTypes: IdMap<MonitoringPlatformAccountType.Serialized>;
  solarGenerators: IdMap<SolarGenerator.Serialized>;
  storages: IdMap<Storage.Serialized>;
  baselineMonths: IdMap<BaselineMonth.Serialized>;
  meters: IdMap<Meter.Serialized>;
  authorizations: IdMap<Authorization.Serialized>;
};

// The `Serialized` vs. `Parsed` dichotomy distinguishes between model objects internal to the
// store (i.e. those returned from selectors) and those external to the store (i.e. those provided
// to the action creators).
type ModelClassSerialized =
  | User.Serialized
  | AMSPortfolio.Serialized
  | Bill.Serialized
  | ApiBill.Serialized
  | BundledRatePlan.Serialized
  | CustomerClassRatePlan.Serialized
  | UtilityRatePlan.Serialized
  | RegulatorRatePlan.Serialized
  | RateType.Serialized
  | Rate.Serialized
  | Source.Serialized
  | Utility.Serialized
  | CCA.Serialized
  | BillingUnit.Serialized
  | Bucket.Serialized
  | Season.Serialized
  | ValidationCase.Serialized
  | BillValidation.Serialized
  | KnownDiscrepancy.Serialized
  | CCARatePlan.Serialized
  | ConnectionLevel.Serialized
  | CostSavings.Serialized
  | AnalysisPeriod.Serialized
  | IntervalFile.Serialized
  | Org.Serialized
  | RatePlanVariation.Serialized
  | RateTypeVariation.Serialized
  | ServiceAgreement.Serialized
  | ServiceAnalysis.Serialized
  | ServiceDrop.Serialized
  | ServicePeriod.Serialized
  | ServiceSimulation.Serialized
  | Site.Serialized
  | MonitoringPlatform.Serialized
  | MonitoringPlatformAccountType.Serialized
  | SolarGenerator.Serialized
  | Storage.Serialized
  | BaselineMonth.Serialized
  | Meter.Serialized
  | Authorization.Serialized;

type ModelClassParsed =
  | User
  | AMSPortfolio
  | Bill
  | ApiBill
  | BundledRatePlan
  | CustomerClassRatePlan
  | UtilityRatePlan
  | RegulatorRatePlan
  | RateType
  | Rate
  | Source
  | Utility
  | CCA
  | BillingUnit
  | Bucket
  | Season
  | ValidationCase
  | BillValidation
  | KnownDiscrepancy
  | CCARatePlan
  | ConnectionLevel
  | CostSavings
  | AnalysisPeriod
  | IntervalFile
  | Org
  | RatePlanVariation
  | RateTypeVariation
  | ServiceAgreement
  | ServiceAnalysis
  | ServiceDrop
  | ServicePeriod
  | ServiceSimulation
  | Site
  | MonitoringPlatform
  | MonitoringPlatformAccountType
  | SolarGenerator
  | Storage
  | BaselineMonth
  | Meter
  | Authorization;

/** ============================ Slice ===================================== */
const initialState: DataSlice = {
  users: {},
  bills: {},
  apiBills: {},
  bundledRatePlans: {},
  customerClassRatePlans: {},
  utilityRatePlans: {},
  regulatorRatePlans: {},
  rateTypes: {},
  rates: {},
  sources: {},
  utilities: {},
  ccas: {},
  billingUnits: {},
  buckets: {},
  seasons: {},
  validationCases: {},
  billValidations: {},
  knownDiscrepancies: {},
  ccaRatePlans: {},
  connectionLevels: {},
  costSavings: {},
  analysisPeriod: {},
  intervalFiles: {},
  orgs: {},
  portfolios: {},
  ratePlanVariations: {},
  rateTypeVariations: {},
  serviceAgreements: {},
  serviceAnalyses: {},
  serviceDrops: {},
  servicePeriods: {},
  serviceSimulations: {},
  sites: {},
  monitoringPlatforms: {},
  monitoringPlatformAccountTypes: {},
  solarGenerators: {},
  storages: {},
  baselineMonths: {},
  meters: {},
  authorizations: {},
};

const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    removeModel: {
      prepare: (model: ModelClassParsed) => ({ payload: model.serialize() }),
      reducer: (state, action: RemoveModelAction) => {
        const model = action.payload;
        const slice = getSliceForModel(state, model);
        delete slice[model.id];
      },
    },
    updateModels: {
      prepare: (...models: Array<ModelClassParsed | ModelClassParsed[] | undefined>) => ({
        payload: _.truthy(models.flat()).map((m) => m.serialize()),
      }),
      reducer: (state, action: UpdateModelsAction) => {
        action.payload.forEach((model) => addOrUpdateModel(state, model));
      },
    },
  },
});

export const { reducer } = slice;
export const { removeModel, updateModels } = slice.actions;

/** ============================ Selectors ================================= */
function makeDatumSelector<T extends keyof DataSlice>(slice: T) {
  return (id: Maybe<Nullable<number>>) => (state: DefaultRootState): DataSlice[T][number] =>
    id ? state.data[slice][id] : undefined;
}
function makeDataSelector<T extends keyof DataSlice>(slice: T) {
  return () => (state: DefaultRootState): DataSlice[T] => state.data[slice] || undefined;
}

export const selectUsers = makeDataSelector('users');
export const selectBill = makeDatumSelector('bills');
export const selectBundledRatePlan = makeDatumSelector('bundledRatePlans');
export const selectCCARatePlan = makeDatumSelector('ccaRatePlans');
export const selectConnectionLevel = makeDatumSelector('connectionLevels');
export const selectCostSavings = makeDatumSelector('costSavings');
export const selectAnalysisPeriod = makeDatumSelector('analysisPeriod');
export const selectIntervalFile = makeDatumSelector('intervalFiles');
export const selectOrg = makeDatumSelector('orgs');
export const selectPortfolio = makeDatumSelector('portfolios');
export const selectRatePlanVariation = makeDatumSelector('ratePlanVariations');
export const selectRateTypeVariation = makeDatumSelector('rateTypeVariations');
export const selectServiceAgreement = makeDatumSelector('serviceAgreements');
export const selectServiceAnalysis = makeDatumSelector('serviceAnalyses');
export const selectServiceDrop = makeDatumSelector('serviceDrops');
export const selectServicePeriod = makeDatumSelector('servicePeriods');
export const selectServiceSimulation = makeDatumSelector('serviceSimulations');
export const selectSite = makeDatumSelector('sites');
export const selectMonitoringPlatform = makeDatumSelector('monitoringPlatforms');
export const selectMonitoringPlatformAccountType = makeDatumSelector(
  'monitoringPlatformAccountTypes'
);
export const selectSolarGenerator = makeDatumSelector('solarGenerators');
export const selectStorage = makeDatumSelector('storages');
export const selectBaselineMonth = makeDatumSelector('baselineMonths');
export const selectMeter = makeDatumSelector('meters');
export const selectAuthorizations = makeDatumSelector('authorizations');

export const selectBundledRatePlans = makeDataSelector('bundledRatePlans');
export const selectCustomerClassRatePlans = makeDataSelector('customerClassRatePlans');
export const selectUtilityRatePlans = makeDataSelector('utilityRatePlans');
export const selectRegulatorRatePlans = makeDataSelector('regulatorRatePlans');
export const selectCCARatePlans = makeDataSelector('ccaRatePlans');
export const selectUtilities = makeDataSelector('utilities');
export const selectCCAs = makeDataSelector('ccas');
export const selectMonitoringPlatforms = makeDataSelector('monitoringPlatforms');
export const selectSolarGenerators = makeDataSelector('solarGenerators');
export const selectStorages = makeDataSelector('storages');
export const selectServiceDrops = makeDataSelector('serviceDrops');
export const selectSites = makeDataSelector('sites');
export const selectOrgs = makeDataSelector('orgs');
export const selectPortfolios = makeDataSelector('portfolios');
export const selectRatePlanVariations = makeDataSelector('ratePlanVariations');
export const selectServiceAgreements = makeDataSelector('serviceAgreements');
export const selectMeters = makeDataSelector('meters');

/** ============================ Reducer methods =========================== */
/**
 * Updates a model in state if it is already present, or adds it to state if it is not. The model's
 * `id` and `object_type` are used to access the model within the slice
 *
 * @param {DataSlice} state: the current state of the `models` slice
 * @param {ModelClassSerialized} model: the model to add or update to the store
 */
function addOrUpdateModel(state: DataSlice, model: ModelClassSerialized) {
  const slice = getSliceForModel(state, model);
  const storeModel = slice[model.id];

  if (!storeModel) {
    // Add it to the store
    slice[model.id] = model;
    return;
  }

  // Splice it into the slice
  Object.assign(slice[model.id], model);
}

/** ============================ Helpers =================================== */
const sliceMap: Record<ModelClassParsed['object_type'], keyof DataSlice> = {
  User: 'users',
  Bill: 'bills',
  ApiBill: 'apiBills',
  BundledRatePlan: 'bundledRatePlans',
  CustomerClassRatePlan: 'customerClassRatePlans',
  UtilityRatePlan: 'utilityRatePlans',
  RegulatorRatePlan: 'regulatorRatePlans',
  RateType: 'rateTypes',
  Rate: 'rates',
  Source: 'sources',
  Utility: 'utilities',
  CCA: 'ccas',
  BillingUnit: 'billingUnits',
  Bucket: 'buckets',
  Season: 'seasons',
  ValidationCase: 'validationCases',
  BillValidation: 'billValidations',
  KnownDiscrepancy: 'knownDiscrepancies',
  CCARatePlan: 'ccaRatePlans',
  ConnectionLevel: 'connectionLevels',
  CostSavings: 'costSavings',
  AnalysisPeriod: 'analysisPeriod',
  IntervalFile: 'intervalFiles',
  Org: 'orgs',
  Portfolio: 'portfolios',
  RatePlanVariation: 'ratePlanVariations',
  RateTypeVariation: 'rateTypeVariations',
  ServiceAgreement: 'serviceAgreements',
  ServiceAnalysis: 'serviceAnalyses',
  ServiceDrop: 'serviceDrops',
  ServicePeriod: 'servicePeriods',
  ServiceSimulation: 'serviceSimulations',
  Site: 'sites',
  MonitoringPlatform: 'monitoringPlatforms',
  MonitoringPlatformAccountType: 'monitoringPlatformAccountTypes',
  SolarGenerator: 'solarGenerators',
  Storage: 'storages',
  BaselineMonth: 'baselineMonths',
  Meter: 'meters',
  Authorization: 'authorizations',
};

function getSliceForModel(
  state: DataSlice,
  model: Pick<ModelClassParsed, 'object_type'>
): IdMap<ModelClassSerialized> {
  return state[sliceMap[model.object_type]];
}
