import _ from 'lodash';

import { store } from 'onescreen/store';
import { IdType, Maybe, ObjectWithoutType, ObjectWithType } from 'onescreen/types';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'KnownDiscrepancy'> & {
  id: IdType;
  reason: string;
  value: number;
};

export declare namespace KnownDiscrepancy {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}
}

/** ======================== Model ========================================= */
export interface KnownDiscrepancy extends CommonAttrs {}
export class KnownDiscrepancy {
  /** ====================== Static fields ================================= */
  static readonly rawFields = ['id', 'reason', 'value'] as const;

  /**
   * Parses an array of raw KnownDiscrepancy objects by passing each in turn to
   * KnownDiscrepancy.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {KnownDiscrepancy.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: KnownDiscrepancy.Raw[]): KnownDiscrepancy[];
  static fromObjects(raw: Maybe<KnownDiscrepancy.Raw[]>): Maybe<KnownDiscrepancy[]>;
  static fromObjects(raw: Maybe<KnownDiscrepancy.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: KnownDiscrepancy.Raw): KnownDiscrepancy;
  static fromObject(raw: Maybe<KnownDiscrepancy.Raw>): Maybe<KnownDiscrepancy>;
  static fromObject(raw: Maybe<KnownDiscrepancy.Raw>) {
    return raw ? new KnownDiscrepancy(raw) : undefined;
  }

  static fromStore(): KnownDiscrepancy[];
  static fromStore(id: Maybe<KnownDiscrepancy['id']>): Maybe<KnownDiscrepancy>;
  static fromStore(id?: KnownDiscrepancy['id']) {
    const { knownDiscrepancies } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(knownDiscrepancies)).map(KnownDiscrepancy.fromObject);
    } else {
      return id ? KnownDiscrepancy.fromObject(knownDiscrepancies[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */

  readonly object_type = 'KnownDiscrepancy';
  constructor(raw: KnownDiscrepancy.Raw) {
    Object.assign(this, _.pick(raw, KnownDiscrepancy.rawFields));
  }

  serialize(): KnownDiscrepancy.Serialized {
    return {
      ..._.pick(this, KnownDiscrepancy.rawFields),
      object_type: this.object_type,
    };
  }
}
