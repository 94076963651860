import _ from 'lodash';
import { useState } from 'react';

import { Authorization, Org, ServiceDrop, Site } from 'onescreen/models';
import { Loader, OnFailCallback } from 'onescreen/types';

import { useAsync } from './useAsync';
import { useSelector } from 'react-redux';
import { slices } from '../store';

/**
 * Retrieves a page of Org objects
 *
 * @param {Org.API.RetrieveParams} params: query parameters
 * @param {OnFailCallback} onFail: callback for when the request fails
 */
export function useOrgs(
  params?: Org.API.ListParams,
  onFail?: OnFailCallback
): Loader<Org.API.ListResult, 'orgs'> {
  const [response, setResponse] = useState<Org.API.ListResult>();
  const loading = useAsync(() => Org.api.list(params), [], setResponse, onFail);
  return { loading, orgs: response };
}

/**
 * Retrieves a single Org object
 *
 * @param {Org.Raw.id} id: The org id
 * @param {Org.API.RetrieveParams} params: query parameters
 * @param {OnFailCallback} onFail: callback for when the request fails
 */
export function useOrg(
  id: Org['id'],
  params?: Org.API.RetrieveParams,
  onFail?: OnFailCallback
): Loader<Org, 'org'> {
  const org = useSelector(slices.data.selectOrg(id));
  const loading = useAsync(
    () => Org.api.retrieve(id, params),
    [],
    () => _.noop(),
    onFail
  );
  return { loading, org: Org.fromObject(org) };
}

/**
 * Retrieves a page of ServiceDrop objects
 *
 * @param {ServiceDrop.API.RetrieveParams} params: query parameters
 * @param {OnFailCallback} onFail: callback for when the request fails
 */
export function useServiceDrops(
  params?: ServiceDrop.API.ListParams,
  onFail?: OnFailCallback
): Loader<ServiceDrop.API.ListResult, 'serviceDrops'> {
  const [response, setResponse] = useState<ServiceDrop.API.ListResult>();
  const loading = useAsync(() => ServiceDrop.api.list(params), [], setResponse, onFail);
  return { loading, serviceDrops: response };
}

/**
 * Retrieves a single ServiceDrop object
 *
 * @param {ServiceDrop.Raw.id} id: the ServiceDrop id
 * @param {ServiceDrop.API.RetrieveParams} params: query parameters
 * @param {OnFailCallback} onFail: callback for when the request fails
 */
export function useServiceDrop(
  id: ServiceDrop['id'],
  params?: ServiceDrop.API.RetrieveParams,
  onFail?: OnFailCallback
): Loader<ServiceDrop, 'serviceDrop'> {
  const serviceDrop = useSelector(slices.data.selectServiceDrop(id));
  const loading = useAsync(
    () => ServiceDrop.api.retrieve(id, params),
    [id],
    () => _.noop(),
    onFail
  );
  return { loading, serviceDrop: ServiceDrop.fromObject(serviceDrop) };
}

/**
 * Retrieves a single Site object
 *
 * @param {Site.Raw.id} id: the Site id
 * @param {Site.API.RetrieveParams} params: query parameters
 * @param {OnFailCallback} onFail: callback for when the request fails
 */
export function useSite(
  id: Site['id'],
  params?: Site.API.RetrieveParams,
  onFail?: OnFailCallback
): Loader<Site, 'site'> {
  const site = useSelector(slices.data.selectSite(id));
  const loading = useAsync(() => Site.api.retrieve(id, params), [id], _.noop, onFail);
  return { loading, site: Site.fromObject(site) };
}

/**
 * Retrieves a page of Authorization objects
 *
 * @param {Authorization.API.RetrieveParams} params: query parameters
 * @param {OnFailCallback} onFail: callback for when the request fails
 */
export function useAuthorizations(
  params?: Authorization.API.ListParams,
  onFail?: OnFailCallback
): Loader<Authorization.API.ListResult, 'authorizations'> {
  const [response, setResponse] = useState<Authorization.API.ListResult>();
  const loading = useAsync(() => Authorization.api.list(params), [], setResponse, onFail);
  return { loading, authorizations: response };
}
