import _ from 'lodash';

import { slices, store } from 'onescreen/store';
import {
  DateTuple,
  ErrorMessage,
  Maybe,
  ObjectWithoutType,
  Pagination,
  Query,
} from 'onescreen/types';
import { Authorization, Meter } from '../apis';
import { SolarGenerator, Storage } from '../assets';

import { AsyncTask, BaseAPI } from '../base';
import { DateUtils, TimeStamp } from '../dates';
import { IntervalFile } from '../interval';
import { MonitoringPlatform, MonitoringPlatformAccountType } from '../monitoring';
import { ServiceDrop } from './service_drops';
import { Site } from './site';
import { User } from '../user';

/** ======================== Types ========================================= */
type CommonAttrs = {
  id: number;
  is_ams_customer: boolean;
  is_test: boolean;
  name: string;
  account_owner: User['id'] | null;
  object_type: 'Org';
  report_cost_savings: boolean;
  sites: number[];
  tve_customer_id: number;
  authorizations: Array<Authorization['id']>;
  demo_authorizations: Array<Authorization['id']>;
  solar_monitor_types?: Array<MonitoringPlatformAccountType['id']>;
  storage_monitor_types?: Array<MonitoringPlatformAccountType['id']>;
  users?: Array<User['id']>;
};

export declare namespace Org {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends TimeStamp.Raw, CommonAttrs {
    meter_date_range?: [string, string];
    solar_date_range?: [string, string];
    storage_date_range?: [string, string];
  }

  // Network interface
  export namespace API {
    export type SideloadedResponses = {
      sites?: Site.Raw[];
      service_drops?: ServiceDrop.Raw[];
      solar_generators?: SolarGenerator.Raw[];
      storages?: Storage.Raw[];
      meters?: Meter.Raw[];
      authorizations?: Authorization.Raw[];
      demo_authorizations?: Authorization.Raw[];
      monitoring_platform_account_types?: MonitoringPlatformAccountType.Raw[];
      users?: User.Raw[];
    };

    export type ListParams = RetrieveParams & Query.PaginationParams;
    export type ListResponse = Pagination.Response.Raw<{ orgs: Org.Raw[] }>;
    export type ListResult = Pagination.Response.Parsed<Org>;

    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResponse = SideloadedResponses & { org: Org.Raw };
    export type RetrieveResult = { org: Org };

    export type CreateParams = Pick<Raw, 'name'>;
    export type UpdateParams = {
      name: Raw['name'];
      is_ams_customer?: Raw['is_ams_customer'];
      report_cost_savings: Raw['report_cost_savings'];
      tve_customer_id: Raw['tve_customer_id'];
      authorizations?: Raw['authorizations'];
      demo_authorizations?: Raw['demo_authorizations'];
      account_owner: Raw['account_owner'];
    };

    export type UploadIntervalTask = {
      task_id: AsyncTask['task_id'];
      service_drop_id: ServiceDrop['id'];
      device: 'solar' | 'storage' | 'utility';
      column: string;
    };
    export type UploadIntervalsParams = {
      gen_csv?: File;
      gen_monitor?: MonitoringPlatformAccountType['id'];
      batt_csv?: File;
      batt_monitor?: MonitoringPlatformAccountType['id'];
      util_csv?: File;
      util_meter?: boolean;
    };
    export type UploadIntervalsResult = {
      tasks: UploadIntervalTask[];
    };

    export type DownloadIntervalParams = {
      start_date?: string;
      end_date?: string;
      interpolate: boolean;
      site_list: Array<Site['id']>;
      solar_params?: { unit: 'kW' | 'kWh'; preferred_monitor: MonitoringPlatform['id'] };
      storage_params?: { unit: 'kW' | 'kWh'; preferred_monitor: MonitoringPlatform['id'] };
      utility_params?: { unit: 'kW' | 'kWh' };
    };
    export type DownloadIntervalResponse = {
      task_id: AsyncTask['task_id'];
    };
    export type DownloadIntervalResult = {
      url: string;
      error?: ErrorMessage;
    };

    export type DownloadBillParams = {
      start_date: string;
      end_date: string;
      site_list: Array<Site['id']>;
    };
    export type DownloadBillResponse = DownloadIntervalResponse;
    export type DownloadBillResult = DownloadIntervalResult;

    export type RefreshAuthorizationsResponse = {
      task_ids: Array<AsyncTask['task_id']>;
    };

    export type CollectIntervalOrBillResult = {
      result: null | boolean | {
        interval_file_id: IntervalFile['id'];
      };
    }

    export type CollectIntrevalsAndBillsResponse = RefreshAuthorizationsResponse;
  }
}

/** ======================== API =========================================== */
class OrgAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.org;

  static async list(params?: Org.API.ListParams): Promise<Org.API.ListResult> {
    const { json } = await this.get<Org.API.ListResponse>(this.route, params);

    // Parse response
    const orgs = this.parsePaginationSet(json, ({ orgs }) => Org.fromObjects(orgs));
    store.dispatch(slices.data.updateModels(orgs.results));
    return orgs;
  }

  static async retrieve(
    id: Org.Raw['id'],
    params?: Org.API.RetrieveParams
  ): Promise<Org.API.RetrieveResult> {
    const { json } = await this.get<Org.API.RetrieveResponse>(this.route(id), params);

    // Parse response
    const org = new Org(json.org);
    const {
      sites,
      service_drops,
      meters,
      solar_generators,
      storages,
      authorizations,
      demo_authorizations,
      monitoring_platform_account_types,
      users,
    } = this.parseSideloadedObjects(json);
    store.dispatch(
      slices.data.updateModels(
        org,
        sites,
        service_drops,
        meters,
        solar_generators,
        storages,
        authorizations,
        demo_authorizations,
        monitoring_platform_account_types,
        users
      )
    );
    return { org };
  }

  static async create(params: Org.API.CreateParams): Promise<Org.API.RetrieveResult> {
    const { json } = await this.post<Org.API.RetrieveResponse>(this.route, params);

    // Parse response
    const org = new Org(json.org);
    store.dispatch(slices.data.updateModels(org));
    return { org };
  }

  static async update(
    id: Org['id'],
    params: Org.API.UpdateParams
  ): Promise<Org.API.RetrieveResult> {
    const { json } = await this.patch<Org.API.RetrieveResponse>(this.route(id), params);

    // Parse response
    const org = new Org(json.org);
    store.dispatch(slices.data.updateModels(org));
    return { org };
  }

  static async uploadIntervals(
    id: Org['id'],
    params?: Org.API.UploadIntervalsParams
  ): Promise<Org.API.UploadIntervalsResult> {
    const { json } = await this.postForm<Org.API.UploadIntervalsResult>(
      this.route(id) + 'upload/',
      params
    );
    let { tasks } = json;
    return { tasks };
  }

  static async downloadIntervals(
    id: Org['id'],
    params: Org.API.DownloadIntervalParams
  ): Promise<Org.API.DownloadIntervalResult> {
    const { json } = await this.post<Org.API.DownloadIntervalResponse>(
      this.route(id) + 'interval-download/',
      params
    );
    const task = new AsyncTask<string | ErrorMessage>(json.task_id);
    const resp = await task.getResult();
    const url = resp as string;
    const error = resp as ErrorMessage;
    if (error.exc_message) {
      return { url: '', error };
    }
    return { url };
  }

  static async downloadBills(
    id: Org['id'],
    params: Org.API.DownloadBillParams
  ): Promise<Org.API.DownloadBillResult> {
    const { json } = await this.post<Org.API.DownloadBillResponse>(
      this.route(id) + 'bill-download/',
      params
    );
    const task = new AsyncTask<string | ErrorMessage>(json.task_id);
    const resp = await task.getResult();
    const url = resp as string;
    const error = resp as ErrorMessage;
    if (error.exc_message) {
      return { url: '', error };
    }
    return { url };
  }

  static async collectIntervalsAndBills(
    id: Org['id']
  ): Promise<boolean> {
    const { json } = await this.get<Org.API.CollectIntrevalsAndBillsResponse>(
      this.route(id) + 'collect-intervals-bills/',
    );

    await Promise.all(json.task_ids.map(async (task_id) => {
      const task = new AsyncTask<Org.API.CollectIntervalOrBillResult>(task_id);
      await task.getResult();
    }));
    return true;
  }

  static async refreshAuthorizations(id: Org['id']): Promise<Org.API.RetrieveResult> {
    const org = Org.fromStore(id);
    const { json } = await this.post<Org.API.RefreshAuthorizationsResponse>(
      this.route(id) + 'refresh-authorizations/'
    );
    const tasks = json.task_ids.map(
      (task_id) => new AsyncTask<{ authorization: Authorization }>(task_id)
    );
    let auths = [];
    for (let task of tasks) {
      const auth = await task.getResult();
      auths.push(auth.authorization);
    }
    const { authorizations, demo_authorizations } = this.parseSideloadedObjects(
      !org?.is_test ? { authorizations: auths } : { demo_authorizations: auths});
    store.dispatch(slices.data.updateModels(authorizations, demo_authorizations));
    return await this.retrieve(id);
  }

  static parseSideloadedObjects(json: Org.API.SideloadedResponses) {
    return {
      sites: Site.fromObjects(json.sites),
      meters: Meter.fromObjects(json.meters),
      solar_generators: SolarGenerator.fromObjects(json.solar_generators),
      storages: Storage.fromObjects(json.storages),
      service_drops: ServiceDrop.fromObjects(json.service_drops),
      authorizations: Authorization.fromObjects(json.authorizations),
      demo_authorizations: Authorization.fromObjects(json.demo_authorizations),
      monitoring_platform_account_types: MonitoringPlatformAccountType.fromObjects(
        json.monitoring_platform_account_types
      ),
      users: User.fromObjects(json.users),
    };
  }
}

/** ======================== Model ========================================= */
export interface Org extends TimeStamp.Parsed, CommonAttrs {
  meter_date_range?: DateTuple;
  solar_date_range?: DateTuple;
  storage_date_range?: DateTuple;
}
export class Org {
  /** ====================== Static fields ================================= */
  static api = OrgAPI;
  static readonly rawFields = [
    'id',
    'is_ams_customer',
    'is_test',
    'name',
    'account_owner',
    'object_type',
    'report_cost_savings',
    'sites',
    'tve_customer_id',
    'authorizations',
    'demo_authorizations',
    'solar_monitor_types',
    'storage_monitor_types',
    'users',
  ] as const;

  /**
   * Parses an array of raw Org objects by passing each in turn to Org.fromObject. Note that calling
   * `raw.map(this.fromObject)` does not work, because TypeScript is unable to resolve the correct
   * overload for fromObject when it is passed to the `map` method. This is a TypeScript design
   * limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {Org.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: Org.Raw[]): Org[];
  static fromObjects(raw: Maybe<Org.Raw[]>): Maybe<Org[]>;
  static fromObjects(raw: Maybe<Org.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: Org.Raw): Org;
  static fromObject(raw: Maybe<Org.Raw>): Maybe<Org>;
  static fromObject(raw: Maybe<Org.Raw>) {
    return raw ? new Org(raw) : undefined;
  }

  static fromStore(): Org[];
  static fromStore(id: Maybe<Org['id']>): Maybe<Org>;
  static fromStore(id?: Org['id']) {
    const { orgs } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(orgs)).map(Org.fromObject);
    } else {
      return id ? Org.fromObject(orgs[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'Org';
  constructor(raw: Org.Raw) {
    Object.assign(this, _.pick(raw, Org.rawFields));
    this.created_at = DateUtils.parseDate(raw.created_at);
    this.updated_at = DateUtils.parseDate(raw.updated_at);
    this.meter_date_range = raw.meter_date_range
      ? [
          DateUtils.parseDate(raw.meter_date_range![0]),
          DateUtils.parseDate(raw.meter_date_range![1]),
        ]
      : undefined;
    this.solar_date_range = raw.solar_date_range
      ? [
          DateUtils.parseDate(raw.solar_date_range![0]),
          DateUtils.parseDate(raw.solar_date_range![1]),
        ]
      : undefined;
    this.storage_date_range = raw.storage_date_range
      ? [
          DateUtils.parseDate(raw.storage_date_range![0]),
          DateUtils.parseDate(raw.storage_date_range![1]),
        ]
      : undefined;
  }

  get AccountOwner() {
    return this.account_owner ? User.fromStore(this.account_owner) : undefined;
  }

  get Sites() {
    return _.truthy(this.sites.map(Site.fromStore));
  }

  get Authorizations() {
    return _.truthy((!this.is_test ? this.authorizations : this.demo_authorizations || []).map(Authorization.fromStore));
  }

  get SolarMonitorTypes() {
    return _.truthy((this.solar_monitor_types || []).map(MonitoringPlatformAccountType.fromStore));
  }

  get StorageMonitorTypes() {
    return _.truthy(
      (this.storage_monitor_types || []).map(MonitoringPlatformAccountType.fromStore)
    );
  }

  addSite(siteId: Site['id']) {
    if (this.sites?.includes(siteId)) return;
    const serialized = this.serialize();
    const sites = serialized.sites ?? [];
    const newOrg = new Org({
      ...serialized,
      sites: [...sites, siteId],
    });
    store.dispatch(slices.data.updateModels(newOrg));
  }

  serialize(): Org.Serialized {
    return {
      ..._.pick(this, Org.rawFields),
      created_at: DateUtils.serializeDate(this.created_at),
      object_type: this.object_type,
      updated_at: DateUtils.serializeDate(this.updated_at),
      meter_date_range: this.meter_date_range
        ? [
            DateUtils.serializeDate(this.meter_date_range![0]),
            DateUtils.serializeDate(this.meter_date_range![1]),
          ]
        : undefined,
      solar_date_range: this.solar_date_range
        ? [
            DateUtils.serializeDate(this.solar_date_range![0]),
            DateUtils.serializeDate(this.solar_date_range![1]),
          ]
        : undefined,
      storage_date_range: this.storage_date_range
        ? [
            DateUtils.serializeDate(this.storage_date_range![0]),
            DateUtils.serializeDate(this.storage_date_range![1]),
          ]
        : undefined,
    };
  }
}
