import * as React from 'react';

/**
 * Convenient method for generating dialog state and open/closing functions
 *
 * @param {boolean} [initialState = false]: the initial state of the dialog
 */
export function useDialogState(initialState: boolean = false) {
  const [open, setOpen] = React.useState(initialState);
  return [open, () => setOpen(true), () => setOpen(false)] as const;
}
