import _ from 'lodash';
import * as React from 'react';

import { Maybe, OnFailCallback } from 'onescreen/types';

/** ======================== Types ========================================= */
type AsyncFunction<T> = () => Promise<T>;

/** ======================== Hook ========================================== */
/**
 * Hook for calling an asynchronous method
 *
 * @param {function} fn: the asynchronous function to call
 * @param {any[]} dependencies: the dependency array
 * @param {function} [onSuccess]: a callback to execute once the function has completed
 * @param {OnFailCallback} [onFail]: callback for when the request fails
 */
export function useAsync<T>(
  fn: AsyncFunction<Maybe<T>>,
  dependencies: any[] = [],
  onSuccess: (response: T) => void = _.noop,
  onFail: OnFailCallback = _.noop
) {
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let shouldUpdate = true;

    // If another request is already underway, return
    if (loading) return;

    // Update loading state and begin the async call
    setLoading(true);
    fn()
      .then((res) => shouldUpdate && res && onSuccess(res))
      .catch((e) => shouldUpdate && onFail(e))
      .finally(() => shouldUpdate && setLoading(false));

    // Cleanup function that will be called on
    // 1. Unmount
    // 2. Dependency array change
    return () => {
      shouldUpdate = false;
    };
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

  return loading;
}
