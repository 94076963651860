import * as React from 'react';

import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CommonDialogProps,
  Dialog,
  Grid,
  List,
  Typography,
} from 'onescreen/components';
import { MonitoringPlatform, ServiceAnalysis } from 'onescreen/models';
import { DateTuple, Maybe } from 'onescreen/types';
import { IntervalFileBar } from 'onescreen/components/Interval';
import { useDialogState } from 'onescreen/hooks';
import _ from 'lodash';
import { FileSelectorListItem, RadioListItem } from 'onescreen/components/RadioListItem';

/** ======================== Types ========================================= */
type DataPanelProps = { analysis: ServiceAnalysis };
type DataPanelUpdateDialogProps = DataPanelProps &
  CommonDialogProps & {
    setWaiting: React.Dispatch<React.SetStateAction<boolean>>;
  };

type UtilityDataCollectionParams = {
  utility_type: 'utility' | 'meter' | 'monitor' | 'file' | 'delete' | null;
  utility_monitor_id?: MonitoringPlatform['id'];
  utility_file?: File;
  utility_interpolate?: boolean;
  utility_overwrite?: boolean;
};
type SolarGeneratorDataCollectionParams = {
  solar_generator_type: 'solar_generator' | 'monitor' | 'file' | 'delete' | null;
  solar_generator_monitor_id?: MonitoringPlatform['id'];
  solar_generator_file?: File;
  solar_generator_interpolate?: boolean;
  solar_generator_overwrite?: boolean;
};
type StorageDataCollectionParams = {
  storage_type: 'storage' | 'monitor' | 'file' | 'delete' | null;
  storage_monitor_id?: MonitoringPlatform['id'];
  storage_file?: File;
  storage_interpolate?: boolean;
  storage_overwrite?: boolean;
};

type DataPanelUpdateSettingsCardProps = {
  deviceParam:
    | UtilityDataCollectionParams
    | SolarGeneratorDataCollectionParams
    | StorageDataCollectionParams;
  prefix: string;
  setDeviceParam:
    | React.Dispatch<React.SetStateAction<Maybe<UtilityDataCollectionParams>>>
    | React.Dispatch<React.SetStateAction<Maybe<SolarGeneratorDataCollectionParams>>>
    | React.Dispatch<React.SetStateAction<Maybe<StorageDataCollectionParams>>>;
  dataExists: boolean;
};

/** ======================== Components ==================================== */

export const DataPanelUpdateSettingsCard: React.FC<DataPanelUpdateSettingsCardProps> = ({
  deviceParam,
  prefix,
  setDeviceParam,
  dataExists,
}) => {
  if (!deviceParam) return null;
  const deviceType = `${prefix}_type` as keyof typeof deviceParam;
  const deviceInterpolate = `${prefix}_interpolate` as keyof typeof deviceParam;
  const deviceOverwrite = `${prefix}_overwrite` as keyof typeof deviceParam;
  return (
    <Card raised={false} outlined={false}>
      <Card.Content>
        <Grid>
          <Grid.Item span={12}>
            <Typography>Settings</Typography>
          </Grid.Item>
          <Grid.Item span={12}>
            <Checkbox
              disabled={deviceParam[deviceType] === 'delete'}
              label="Interpolate"
              checked={!!deviceParam[deviceInterpolate]}
              onChange={() => {
                setDeviceParam((d: any) => ({
                  ...d,
                  [deviceInterpolate]: !d[deviceInterpolate],
                }));
              }}
            />
          </Grid.Item>
          {dataExists && (
            <Grid.Item span={12}>
              <Checkbox
                disabled={deviceParam[deviceType] === 'delete'}
                label="Overwrite"
                checked={!!deviceParam[deviceOverwrite]}
                onChange={() => {
                  setDeviceParam((d: any) => ({
                    ...d,
                    [deviceOverwrite]: !d[deviceOverwrite],
                  }));
                }}
              />
            </Grid.Item>
          )}
        </Grid>
      </Card.Content>
    </Card>
  );
};

export const DataPanelUpdateDialog: React.FC<DataPanelUpdateDialogProps> = ({
  analysis,
  open,
  onClose,
  setWaiting,
}) => {
  const [utility, setUtility] = React.useState<Maybe<UtilityDataCollectionParams>>();
  const [solar_generator, setSolar] = React.useState<Maybe<SolarGeneratorDataCollectionParams>>();
  const [storage, setStorage] = React.useState<Maybe<StorageDataCollectionParams>>();

  const checkedIcon = (value: boolean) => (value ? 'checked' : 'unchecked');

  const canSubmit = React.useCallback(() => {
    const payload = { ...utility, ...storage, ...solar_generator };
    if (_.isEmpty(payload)) {
      return false;
    }
    if (
      payload['utility_type'] === null ||
      (payload['utility_type'] === 'file' && !payload['utility_file'])
    )
      return false;
    if (
      payload['solar_generator_type'] === null ||
      (payload['solar_generator_type'] === 'file' && !payload['solar_generator_file'])
    )
      return false;
    if (
      payload['storage_type'] === null ||
      (payload['storage_type'] === 'file' && !payload['storage_file'])
    )
      return false;
    return true;
  }, [utility, solar_generator, storage]);

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Content>
        <Grid noMargin={false} justify="space-around">
          <Grid.Item span={12}>
            <Typography variant="h3">Update interval data</Typography>
          </Grid.Item>
          <Grid.Item span={12}>
            <Alert.Info>
              Uploaded files should be CSV format with two columns and a single header row (header
              names do not matter). The first column should be datetimes and the second column
              should be kWh values.
            </Alert.Info>
          </Grid.Item>
          <Grid.Item span={12}>
            <Card
              raised={false}
              outlined={true}
              chipLabel={
                <Chip
                  label="Utility"
                  icon={checkedIcon(!!utility)}
                  color={utility ? 'primary' : 'default'}
                  onClick={() =>
                    setUtility((u) =>
                      !u ? { utility_type: null, utility_interpolate: true } : undefined
                    )
                  }
                />
              }
            >
              <Grid>
                <Grid.Item span={8}>
                  <List>
                    {analysis.ServiceDrop?.interval_file && (
                      <RadioListItem
                        selected={utility?.utility_type === 'utility'}
                        disabled={!utility}
                        onClick={() => setUtility((d) => ({ ...d, utility_type: 'utility' }))}
                      >
                        Utility cleaned intervals
                      </RadioListItem>
                    )}
                    {analysis.ServiceDrop?.Meter?.interval_file && (
                      <RadioListItem
                        selected={utility?.utility_type === 'meter'}
                        disabled={!utility}
                        onClick={() => setUtility((d) => ({ ...d, utility_type: 'meter' }))}
                      >
                        Meter [{analysis.ServiceDrop?.Meter?.service_identifier}]
                      </RadioListItem>
                    )}
                    {analysis.ServiceDrop?.Storage?.Monitors.filter(
                      (monitor) => !!monitor?.meterx_interval_file
                    ).map((monitor) => (
                      <RadioListItem
                        key={monitor?.id}
                        disabled={!utility}
                        selected={
                          utility?.utility_type === 'monitor' &&
                          utility?.utility_monitor_id === monitor?.id
                        }
                        onClick={() =>
                          setUtility((d) => ({
                            ...d,
                            utility_type: 'monitor',
                            utility_monitor_id: monitor?.id,
                          }))
                        }
                      >
                        {monitor?.monitor_name} [{monitor?.identifier}]
                      </RadioListItem>
                    ))}
                    <FileSelectorListItem
                      disabled={!utility}
                      selected={utility?.utility_type === 'file'}
                      onClick={() => setUtility((d) => ({ ...d, utility_type: 'file' }))}
                      onChange={(file) => {
                        if (!!file) {
                          setUtility((d) => ({ ...d, utility_type: 'file', utility_file: file }));
                        }
                      }}
                      file={utility?.utility_file}
                    />
                    {analysis.utility_usage && (
                      <RadioListItem
                        selected={utility?.utility_type === 'delete'}
                        disabled={!utility}
                        onClick={() => setUtility((d) => ({ ...d, utility_type: 'delete' }))}
                      >
                        DELETE
                      </RadioListItem>
                    )}
                  </List>
                </Grid.Item>
                <Grid.Item span={4}>
                  <DataPanelUpdateSettingsCard
                    deviceParam={utility!}
                    setDeviceParam={setUtility}
                    prefix="utility"
                    dataExists={!!analysis.utility_usage}
                  />
                </Grid.Item>
              </Grid>
            </Card>
          </Grid.Item>
          <Grid.Item span={12}>
            <Card
              raised={false}
              outlined={true}
              chipLabel={
                <Chip
                  label="Solar Generation"
                  icon={checkedIcon(!!solar_generator)}
                  color={solar_generator ? 'primary' : 'default'}
                  onClick={() =>
                    setSolar((sg) => (!sg ? { solar_generator_type: null } : undefined))
                  }
                />
              }
            >
              <Grid>
                <Grid.Item span={8}>
                  <List>
                    {analysis.ServiceDrop?.SolarGenerator?.interval_file && (
                      <RadioListItem
                        selected={solar_generator?.solar_generator_type === 'solar_generator'}
                        disabled={!solar_generator}
                        onClick={() =>
                          setSolar((d) => ({ ...d, solar_generator_type: 'solar_generator' }))
                        }
                      >
                        Solar Generator cleaned intervals
                      </RadioListItem>
                    )}
                    {analysis.ServiceDrop?.SolarGenerator?.Monitors.filter(
                      (monitor) => !!monitor?.interval_file
                    ).map((monitor) => (
                      <RadioListItem
                        key={monitor?.id}
                        disabled={!solar_generator}
                        selected={
                          solar_generator?.solar_generator_type === 'monitor' &&
                          solar_generator?.solar_generator_monitor_id === monitor?.id
                        }
                        onClick={() =>
                          setSolar((d) => ({
                            ...d,
                            solar_generator_type: 'monitor',
                            solar_generator_monitor_id: monitor?.id,
                          }))
                        }
                      >
                        {monitor?.monitor_name} [{monitor?.identifier}]
                      </RadioListItem>
                    ))}
                    <FileSelectorListItem
                      disabled={!solar_generator}
                      selected={solar_generator?.solar_generator_type === 'file'}
                      onClick={() => setSolar((d) => ({ ...d, solar_generator_type: 'file' }))}
                      onChange={(file) => {
                        if (!!file) {
                          setSolar((d) => ({
                            ...d,
                            solar_generator_type: 'file',
                            solar_generator_file: file,
                          }));
                        }
                      }}
                      file={solar_generator?.solar_generator_file}
                    />
                    {analysis.generation_data && (
                      <RadioListItem
                        selected={solar_generator?.solar_generator_type === 'delete'}
                        disabled={!solar_generator}
                        onClick={() => setSolar((d) => ({ ...d, solar_generator_type: 'delete' }))}
                      >
                        DELETE
                      </RadioListItem>
                    )}
                  </List>
                </Grid.Item>
                <Grid.Item span={4}>
                  <DataPanelUpdateSettingsCard
                    deviceParam={solar_generator!}
                    setDeviceParam={setSolar}
                    prefix="solar_generator"
                    dataExists={!!analysis.generation_data}
                  />
                </Grid.Item>
              </Grid>
            </Card>
          </Grid.Item>
          <Grid.Item span={12}>
            <Card
              raised={false}
              outlined={true}
              chipLabel={
                <Chip
                  label="Storage"
                  icon={checkedIcon(!!storage)}
                  color={storage ? 'primary' : 'default'}
                  onClick={() => setStorage((s) => (!s ? { storage_type: null } : undefined))}
                />
              }
            >
              <Grid>
                <Grid.Item span={8}>
                  <List>
                    {analysis.ServiceDrop?.Storage?.interval_file && (
                      <RadioListItem
                        selected={storage?.storage_type === 'storage'}
                        disabled={!storage}
                        onClick={() => setStorage((d) => ({ ...d, storage_type: 'storage' }))}
                      >
                        Storage cleaned intervals
                      </RadioListItem>
                    )}
                    {analysis.ServiceDrop?.Storage?.Monitors.filter(
                      (monitor) => !!monitor?.interval_file
                    ).map((monitor) => (
                      <RadioListItem
                        key={monitor?.id}
                        disabled={!storage}
                        selected={
                          storage?.storage_type === 'monitor' &&
                          storage?.storage_monitor_id === monitor?.id
                        }
                        onClick={() =>
                          setStorage((d) => ({
                            ...d,
                            storage_type: 'monitor',
                            storage_monitor_id: monitor?.id,
                          }))
                        }
                      >
                        {monitor?.monitor_name} [{monitor?.identifier}]
                      </RadioListItem>
                    ))}
                    <FileSelectorListItem
                      disabled={!storage}
                      selected={storage?.storage_type === 'file'}
                      onClick={() => setStorage((d) => ({ ...d, storage_type: 'file' }))}
                      onChange={(file) => {
                        if (!!file) {
                          setStorage((d) => ({ ...d, storage_type: 'file', storage_file: file }));
                        }
                      }}
                      file={storage?.storage_file}
                    />
                    {analysis.storage_data && (
                      <RadioListItem
                        selected={storage?.storage_type === 'delete'}
                        disabled={!storage}
                        onClick={() => setStorage((d) => ({ ...d, storage_type: 'delete' }))}
                      >
                        DELETE
                      </RadioListItem>
                    )}
                  </List>
                </Grid.Item>
                <Grid.Item span={4}>
                  <DataPanelUpdateSettingsCard
                    deviceParam={storage!}
                    setDeviceParam={setStorage}
                    prefix="storage"
                    dataExists={!!analysis.storage_data}
                  />
                </Grid.Item>
              </Grid>
            </Card>
          </Grid.Item>
        </Grid>
      </Dialog.Content>
      <Dialog.Actions>
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
        <Button color="secondary" onClick={submit} disabled={!canSubmit()}>
          Submit
        </Button>
      </Dialog.Actions>
    </Dialog>
  );

  /** =============================== Callbacks ============================ */
  async function submit() {
    const payload = {
      ...utility,
      ...storage,
      ...solar_generator,
    } as ServiceAnalysis.API.CollectIntervalParams;
    setWaiting(true);
    onClose();
    await ServiceAnalysis.api.collectIntervalData(analysis.id, payload);
    setWaiting(false);
  }
};

export const DataPanel: React.FC<DataPanelProps> = ({ analysis }) => {
  const [dialogOpen, openDialog, closeDialog] = useDialogState();
  const [waiting, setWaiting] = React.useState<boolean>(false);

  const bounds = React.useMemo(() => {
    const costSavings = analysis.CostSavings;
    return costSavings ? ([costSavings.start_date, costSavings.end_date] as DateTuple) : undefined;
  }, [analysis]);

  const locked = !!analysis.actual_usage;
  return (
    <>
      <Box marginTop={1}>
        <Card chipLabel={<Chip color="primary" label="Data" />} raised>
          <IntervalFileBar
            id={analysis.utility_usage}
            title="Utility Usage"
            bounds={bounds}
            iconName="plug"
            fetching={waiting}
          />
          <IntervalFileBar
            id={analysis.generation_data}
            title="Generation"
            bounds={bounds}
            iconName="sun"
          />
          <IntervalFileBar
            id={analysis.storage_data}
            title="Storage"
            bounds={bounds}
            iconName="battery"
          />
          <IntervalFileBar
            id={analysis.actual_usage!}
            title="Simulated"
            bounds={bounds}
            iconName="plus"
          />
          <Card.Actions>
            <Button
              color="secondary"
              icon={locked ? 'lock' : 'lock_open'}
              onClick={lockUnlock}
              disabled={waiting}
            >
              {locked ? 'Unlock' : 'Lock'}
            </Button>
            <Button color="primary" disabled={locked || waiting} onClick={openDialog}>
              Update Data
            </Button>
          </Card.Actions>
        </Card>
      </Box>
      <DataPanelUpdateDialog
        analysis={analysis}
        open={dialogOpen}
        onClose={closeDialog}
        setWaiting={setWaiting}
      />
    </>
  );

  /** =============================== Callbacks ============================ */
  async function lockUnlock() {
    await ServiceAnalysis.api.lockUnlock(analysis.id);
    ServiceAnalysis.api.retrieve(analysis.id, {
      include: ['service_periods.service_simulations.bill.*'],
    });
  }
};
