import React, { useEffect, useState } from 'react';

import {
  Button,
  Card,
  CardCornerMenu,
  Grid,
  Select,
  TextField,
  Typography,
} from 'onescreen/components';
import fetchWithAuth from 'onescreen/requests';
import { IntervalFileBar } from 'onescreen/components/Interval';
import { MonitoringPlatform, SolarGenerator, Storage, IntervalFile } from '../../common/models';
import { useMonitoringPlatform } from '../../common/hooks/monitoring';
import { HasUserPermissions } from 'onescreen/hooks/user';

/** ================================= Types ================================ */

type MonitoringPlatformAccount = {
  name: string;
  id: number;
};

type MonitoringPlatformItemProps = {
  mpId: MonitoringPlatform['id'];
};

type MonitoringPlatformCreateFormProps = {
  device: 'solar_generator' | 'storage';
  deviceId: SolarGenerator['id'] | Storage['id'];
  onAdd: () => void;
  onCancel: () => void;
};

/** =============================== Components ============================= */

export const MonitoringPlatformItem: React.FC<MonitoringPlatformItemProps> = ({ mpId }) => {
  const { monitoringPlatform, loading } = useMonitoringPlatform(mpId);
  const [fetching, setFetching] = useState(false);
  const hasPermission = HasUserPermissions(['MonitorEditors', 'MonitorFullAccessEditors']);

  if (!monitoringPlatform || loading) return null;
  const mpInfo = (
    <div>
      <span className="grey-text right truncate" style={{ maxWidth: '30%' }}>
        {monitoringPlatform.identifier}
      </span>
      <span className="card-title">
        <a href={monitoringPlatform.access_link} target="_blank" rel="noopener noreferrer">
          {monitoringPlatform.monitor_name}
        </a>
      </span>
    </div>
  );

  const ifileInfo = (
    <>
      <IntervalFileBar
        id={monitoringPlatform.interval_file}
        title={`${monitoringPlatform.monitor_name} Monitor data`}
        fetching={fetching}
      />
      {monitoringPlatform.meterx_interval_file && (
        <>
          <br />
          <Typography variant="caption">Auxilary Usage data</Typography>
          <IntervalFileBar
            id={monitoringPlatform.meterx_interval_file}
            title={`${monitoringPlatform.monitor_name} Auxilary usage data`}
            fetching={fetching}
          />
        </>
      )}
    </>
  );

  const menu = {
    collect: {
      label: 'COLLECT INTERVALS',
      callback: async (mpId: MonitoringPlatform['id']) => {
        setFetching(true);
        const { interval_file_id } = await MonitoringPlatform.api.collect_intervals(mpId);
        if (interval_file_id) {
          await MonitoringPlatform.api.retrieve(mpId, { include: ['interval_file'] });
          await IntervalFile.api.retrieve(interval_file_id);
        }
        setFetching(false);
      },
    },
    hide: {
      label: monitoringPlatform.hidden ? 'UNHIDE' : 'HIDE',
      callback: (mpId: MonitoringPlatform['id']) => {
        MonitoringPlatform.api.update(mpId, { hidden: !monitoringPlatform.hidden });
      },
      hide: !hasPermission,
    },
    delete: {
      label: 'DELETE',
      callback: async (mpId: MonitoringPlatform['id']) => {
        if (monitoringPlatform.interval_file) {
          const msg = ' This monitor contains intervals that will also be deleted.';
          window.confirm('Are you sure?' + msg);
        }
        MonitoringPlatform.api.destroy(mpId);
      },
      hide: !hasPermission,
    },
  };

  return (
    <Grid.Item span={12}>
      <Card>
        {monitoringPlatform.hidden && (
          <i
            style={{
              position: 'absolute',
              pointerEvents: 'none',
              padding: '3px',
            }}
            className="material-icons"
          >
            visibility_off
          </i>
        )}
        <Grid justify="flex-end">
          <Grid.Item>
            <CardCornerMenu objId={monitoringPlatform.id} menu={menu} />
          </Grid.Item>
        </Grid>
        <Card.Content>
          {mpInfo}
          {ifileInfo}
        </Card.Content>
      </Card>
    </Grid.Item>
  );
};

export const MonitoringPlatformCreateForm: React.FC<MonitoringPlatformCreateFormProps> = ({
  device,
  deviceId,
  onAdd,
  onCancel,
}) => {
  const [options, setOptions] = useState<MonitoringPlatformAccount[]>([]);
  const [monitoringAccount, setMonitoringAccount] = useState<MonitoringPlatformAccount>();
  const [identifier, setIdentifier] = useState<string>('');

  useEffect(() => {
    fetchWithAuth('/v1/monitors/all-accounts/', (data, err) => {
      if (data && !err) {
        setOptions(data.results.monitoring_platform_accounts);
      }
    });
  }, [setOptions]);

  return (
    <Card>
      <Card.Content>
        <Grid justify="flex-end">
          <Grid.Item span={12}>
            <Typography variant="h5">Add a Monitor</Typography>
          </Grid.Item>
          <Grid.Item span={12}>
            <Typography>Monitoring Platform Account</Typography>
            <Select
              fullWidth
              onChange={setMonitoringAccount}
              options={options}
              renderOption="name"
              value={monitoringAccount}
              valueOption="id"
            />
          </Grid.Item>
          <Grid.Item span={12}>
            <Typography>Identifier</Typography>
            <TextField value={identifier} onChange={setIdentifier} />
          </Grid.Item>
          <Grid.Item>
            <Button onClick={onClickAdd} color="primary">
              Add
            </Button>
          </Grid.Item>
          <Grid.Item>
            <Button onClick={onCancel}>Cancel</Button>
          </Grid.Item>
        </Grid>
      </Card.Content>
    </Card>
  );

  /** ============================= Callbacks ===============================*/
  async function onClickAdd() {
    if (!!monitoringAccount && identifier.length) {
      let payload: MonitoringPlatform.API.CreateParams = {
        monitoring_account: monitoringAccount.id,
        identifier,
      };
      payload[device] = deviceId;
      MonitoringPlatform.api.create(payload).then(onAdd);
    }
  }
};
