import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStylesHook } from 'onescreen/styles';

import { Drawer, Dialog, Grid, Loading, List, Button } from 'onescreen/components';
import { Typography } from '@material-ui/core';
import { ServiceAgreementCard } from './serviceAgreement';
import { useBundledRatePlan, useCCARatePlan } from 'onescreen/hooks/rates';
import _ from 'lodash';
import { BillValidation, BundledRatePlan, CCARatePlan, ValidationCase } from 'onescreen/models';
import { useBill } from 'onescreen/hooks';
import { formatters } from 'onescreen/utils';
import { RatePlanParam } from 'onescreen/types';

/** ================================ Types ================================= */
type BillComparisonDialogProps = {
  billValidation: BillValidation;
  setBill: (billValidation: BillValidation | undefined) => void;
  validationCase: ValidationCase;
  open: boolean;
};
type RateValidationProps = {
  ratePlan: BundledRatePlan | CCARatePlan;
};

/** ================================ Styles ================================ */
const useValidationStyles = makeStylesHook(
  (theme) => ({
    valid: { backgroundColor: theme.palette.success.main },
    invalid: { backgroundColor: theme.palette.error.main },
  }),
  'Validation'
);

/** ============================== Component =============================== */
const BillComparisonDialog: React.FC<BillComparisonDialogProps> = ({
  billValidation,
  setBill,
  validationCase,
  open,
}) => {
  const { loading } = useBill(billValidation?.bill, { include: ['bill_csv', 'bill_html'] });
  if (loading) return <Loading />;
  return (
    <Drawer open={open} onClose={() => setBill(undefined)} anchor="top">
      <Dialog.Content>
        <Grid>
          <Grid.Item span={12}>
            <Button icon="close" onClick={() => setBill(undefined)}>
              close
            </Button>
          </Grid.Item>
          {billValidation && (
            <Grid.Item span={6}>
              <>
                Actual Bill for {validationCase.Meter?.service_identifier}
                <a
                  href={billValidation?.ApiBill?.source_url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {(() => {
                    const arr = billValidation?.ApiBill?.source_url.split('.') || [];
                    return arr[arr.length - 1];
                  })()}
                </a>
                <List>
                  {_.truthy(billValidation?.KnownDiscrepancies).map((kd) => (
                    <List.Item key={kd.id}>
                      {kd.reason}: {kd.value}
                    </List.Item>
                  ))}
                </List>
              </>
            </Grid.Item>
          )}
          {billValidation && (
            <Grid.Item span={6}>
              <a
                href={billValidation.Bill.bill_csv!}
                download={`bill_${billValidation.Bill.start_date}.csv`}
              >
                Unbundled CSV
              </a>
              <iframe
                width="100%"
                height="750px"
                src={billValidation.Bill.bill_html!}
                title={`${validationCase.name}_${billValidation}`}
              />
            </Grid.Item>
          )}
        </Grid>
      </Dialog.Content>
    </Drawer>
  );
};

const RateValidation: React.FC<RateValidationProps> = ({ ratePlan }) => {
  const [chosenVc, setChosenVc] = useState<ValidationCase>();
  const [showingBill, setShowingBill] = useState<BillValidation>();

  const classes = useValidationStyles();

  return (
    <>
      <Grid>
        <Grid.Item span={6}>
          <Typography variant="h5">Service Agreements</Typography>
          {_.truthy(ratePlan?.ServiceAgreements!).map((sa) => {
            return (
              <ServiceAgreementCard
                key={sa.id}
                serviceAgreementId={sa.id}
                clickValidationCase={setChosenVc}
              />
            );
          })}
        </Grid.Item>
        <Grid.Item span={6}>
          {chosenVc && (
            <>
              <Typography variant="h4">{chosenVc.name}</Typography>
              <List>
                {_.sortBy(_.truthy(chosenVc.BillValidations), (bv) => bv?.ApiBill?.start_date).map(
                  (bv) => (
                    <List.Item
                      key={bv.id}
                      button={!!bv.bill}
                      onClick={() => setShowingBill(bv.bill ? bv : undefined)}
                      className={bv.is_validated ? classes.valid : classes.invalid}
                    >
                      <Grid justify="space-between">
                        <Grid.Item>
                          <Typography>
                            {bv.id}: {formatters.date.standard(bv.ApiBill?.start_date!)} -{' '}
                            {formatters.date.standard(bv.ApiBill?.end_date!)}
                          </Typography>
                        </Grid.Item>
                        <Grid.Item>
                          <Typography>
                            ${bv.ApiBill?.total_cost} :{bv.bill ? `$${bv.Bill.total}` : 'No Bill'}
                          </Typography>
                        </Grid.Item>
                      </Grid>
                    </List.Item>
                  )
                )}
              </List>
            </>
          )}
        </Grid.Item>
      </Grid>
      <BillComparisonDialog
        open={!!showingBill}
        billValidation={showingBill!}
        setBill={setShowingBill}
        validationCase={chosenVc!}
      />
    </>
  );
};

export const BundledRateValidation: React.FC = () => {
  const { rpId } = useParams<RatePlanParam>() as RatePlanParam;
  const { bundledRatePlan, loading } = useBundledRatePlan(+rpId, {
    include: ['service_agreements.*'],
  });
  if (!bundledRatePlan || loading) return <Loading />;
  return <RateValidation ratePlan={bundledRatePlan} />;
};

export const CCARateValidation: React.FC = () => {
  const { rpId } = useParams<RatePlanParam>() as RatePlanParam;
  const { ccaRatePlan, loading } = useCCARatePlan(+rpId, {
    include: ['service_agreements.*'],
  });
  if (!ccaRatePlan || loading) return <Loading />;
  return <RateValidation ratePlan={ccaRatePlan} />;
};
