import _ from 'lodash';

import { store, slices } from 'onescreen/store';
import { Query, Pagination, Maybe, ObjectWithType, ObjectWithoutType } from 'onescreen/types';

import { AsyncTask, BaseAPI } from '../base';
import { DateRange } from '../dates';
import { Org } from '../orgs';
import { CostSavings } from './cost_savings';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'Portfolio'> & {
  cost_savings?: Array<CostSavings['id']>;
  id: number;
  name: string;
};

export declare namespace AMSPortfolio {
  // Related types
  export interface Serialized extends CommonAttrs, DateRange.Raw {}
  export interface Raw extends ObjectWithoutType<Serialized> {}

  // Network interface
  export namespace API {
    export type SideloadedResponses = { cost_savings?: CostSavings.Raw[]; orgs?: Org.Raw[] };

    export type CreateParams = RetrieveParams &
      Pick<Raw, 'name' | 'start_date' | 'end_date'> & { clone?: AMSPortfolio['id'] };
    export type CreateResponse = RetrieveResponse;
    export type CreateResult = Serialized;

    export type ListParams = RetrieveParams & Query.PaginationParams;
    export type ListResponse = Pagination.Response.Raw<
      { ams_portfolios: AMSPortfolio.Raw[] } & SideloadedResponses
    >;
    export type ListResult = Pagination.Response.Parsed<AMSPortfolio>;

    export type RetrieveResponse = { ams_portfolio: AMSPortfolio.Raw } & SideloadedResponses;
    export type RetrieveParams = Query.DynamicRestParams;

    export type RetrieveIntervalGapReport = { min_days: number };
    export type RetrieveBillGapReport = { start_date: string; end_date: string };
    export type RetrieveURLResponse = { task: AsyncTask['task_id'] };

    export type DefaultDataCollectionParams = { refresh: Boolean };
    type CostSavingsTaskMapResponse = {
      task_id: AsyncTask['task_id'];
      cost_savings_id: CostSavings['id'];
    };
    export type DefaultDataCollectionResponse = { tasks: CostSavingsTaskMapResponse[] };
    type CostSavingsTaskMapResult = {
      task: AsyncTask;
      costSavings: CostSavings;
    };
    export type DefaultDataCollectionResult = { tasks: CostSavingsTaskMapResult[] };
  }
}

/** ======================== API =========================================== */
class AMSPortfolioAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.amsPortfolio;

  static async create(
    params: AMSPortfolio.API.CreateParams
  ): Promise<AMSPortfolio.API.CreateResult> {
    // Substitute the portfolio param with its ID
    const { json } = await this.post<AMSPortfolio.API.CreateResponse>(this.route, params);

    // Parse response and add to store
    const portfolio = new AMSPortfolio(json.ams_portfolio);
    store.dispatch(slices.data.updateModels(portfolio));

    return portfolio;
  }

  static async list(params?: AMSPortfolio.API.ListParams): Promise<AMSPortfolio.API.ListResult> {
    const { json } = await this.get<AMSPortfolio.API.ListResponse>(this.route, params);

    // Parse response
    const { costSavings, orgs } = this.parseSideloadedObjects(json.results);
    const portfolios = this.parsePaginationSet(json, ({ ams_portfolios }) =>
      AMSPortfolio.fromObjects(ams_portfolios)
    );

    store.dispatch(slices.data.updateModels(portfolios.results, costSavings, orgs));
    return portfolios;
  }

  static async retrieve(id: AMSPortfolio['id'], params?: AMSPortfolio.API.RetrieveParams) {
    const { json } = await this.get<AMSPortfolio.API.RetrieveResponse>(this.route(id), params);

    // Parse response
    const { costSavings, orgs } = this.parseSideloadedObjects(json);
    const portfolio = new AMSPortfolio(json.ams_portfolio);

    // Add to the store
    store.dispatch(slices.data.updateModels(portfolio, costSavings, orgs));
  }

  static async utility_interval_gap_report(
    id: AMSPortfolio['id'],
    params?: AMSPortfolio.API.RetrieveIntervalGapReport
  ) {
    const { json } = await this.get<AMSPortfolio.API.RetrieveURLResponse>(
      this.route(id) + 'utility-interval-gap-report/',
      params
    );
    // Parse response
    const taskId = json.task;
    const task = new AsyncTask(taskId);
    return { task };
  }

  static async utility_bill_gap_report(
    id: AMSPortfolio['id'],
    params: AMSPortfolio.API.RetrieveBillGapReport
  ) {
    const { json } = await this.get<AMSPortfolio.API.RetrieveURLResponse>(
      this.route(id) + 'utility-bill-gap-report/',
      params
    );
    // Parse response
    const taskId = json.task;
    const task = new AsyncTask(taskId);
    return { task };
  }

  static async default_data_collection(
    id: AMSPortfolio['id'],
    params?: AMSPortfolio.API.DefaultDataCollectionParams
  ): Promise<AMSPortfolio.API.DefaultDataCollectionResult> {
    const { json } = await this.post<AMSPortfolio.API.DefaultDataCollectionResponse>(
      this.route(id) + 'default-data-collection/',
      params
    );
    const { tasks } = json;
    return {
      tasks: tasks.map((task) => {
        return {
          task: new AsyncTask(task.task_id),
          costSavings: CostSavings.fromStore(task.cost_savings_id)!,
        };
      }),
    };
  }

  static parseSideloadedObjects(json: AMSPortfolio.API.SideloadedResponses) {
    return {
      costSavings: CostSavings.fromObjects(json.cost_savings),
      orgs: Org.fromObjects(json.orgs),
    };
  }
}

/** ======================== Model ========================================= */
export interface AMSPortfolio extends CommonAttrs {}
export class AMSPortfolio extends DateRange {
  /** ====================== Static fields ================================= */
  static api = AMSPortfolioAPI;
  static readonly rawFields = ['cost_savings', 'id', 'name'] as const;

  /**
   * Parses an array of raw AMSPortfolio objects by passing each in turn to
   * AMSPortfolio.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {AMSPortfolio.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: AMSPortfolio.Raw[]): AMSPortfolio[];
  static fromObjects(raw: Maybe<AMSPortfolio.Raw[]>): Maybe<AMSPortfolio[]>;
  static fromObjects(raw: Maybe<AMSPortfolio.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: AMSPortfolio.Raw): AMSPortfolio;
  static fromObject(raw: Maybe<AMSPortfolio.Raw>): Maybe<AMSPortfolio>;
  static fromObject(raw: Maybe<AMSPortfolio.Raw>) {
    return raw ? new AMSPortfolio(raw) : undefined;
  }

  static fromStore(): AMSPortfolio[];
  static fromStore(id: Maybe<AMSPortfolio['id']>): Maybe<AMSPortfolio>;
  static fromStore(id?: AMSPortfolio['id']) {
    const { portfolios } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(portfolios)).map(AMSPortfolio.fromObject);
    } else {
      return id ? AMSPortfolio.fromObject(portfolios[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'Portfolio';
  constructor(raw: AMSPortfolio.Raw) {
    super(raw);
    Object.assign(this, _.pick(raw, AMSPortfolio.rawFields));
  }

  /**
   * Adds a CostSavings object to the portfolio and updates the store model
   *
   * @param {CostSavings} costSavings: the CostSavings model to add to the AMSPortfolio
   */
  addCostSavings(costSavings: CostSavings) {
    // If the cost_savings array already includes this CostSavings object, no update necessary
    if (this.cost_savings?.includes(costSavings.id)) return;

    const serialized = this.serialize();
    const cost_savings = serialized.cost_savings ?? [];
    const newPortfolio = new AMSPortfolio({
      ...serialized,
      cost_savings: [...cost_savings, costSavings.id],
    });

    store.dispatch(slices.data.updateModels(newPortfolio));
  }

  get CostSavings() {
    return this.cost_savings ? _.truthy(this.cost_savings.map(CostSavings.fromStore)) : undefined;
  }

  serialize(): AMSPortfolio.Serialized {
    return {
      // Use the objects for start and end dates.
      // Serialized versions(strings) are causing trouble.
      ..._.pick(this, ['start_date', 'end_date']),
      ..._.pick(this, AMSPortfolio.rawFields),
      object_type: this.object_type,
    };
  }
}
