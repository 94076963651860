import * as React from 'react';
import MuiDrawer from '@material-ui/core/Drawer';

/** ======================== Types ========================================= */
type DrawerProps = React.HTMLAttributes<HTMLDivElement> & {
  anchor: 'left' | 'right' | 'top' | 'bottom';
  onClose: () => void;
  open: boolean;
};

/** ======================== Components ==================================== */
export const Drawer: React.FC<DrawerProps> = (props) => <MuiDrawer {...props} />;
