import _ from 'lodash';
import { DateMillisTuple } from 'onescreen/types';
import { DateTime, Duration } from 'luxon';
import { Maybe } from 'onescreen/types';
import { RawIntervalStream as IntervalStream } from 'onescreen/models/interval';
import {
  MonitoringPlatform,
  BatteryYesterdayPerformance,
  SolarPastYearPerformance,
  BatteryPastYearPerformance,
} from 'onescreen/models';

const dayMillis = 24 * 60 * 60 * 1000;
/*================================Functions==================================*/
export const defaultEndDate = () => {
  return DateTime.fromJSDate(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

export const defaultStartDate = () => {
  return defaultEndDate().minus(Duration.fromObject({ day: 1 }));
};

const intervalValuesInDateRange = (stream: Maybe<IntervalStream>, dateSpan: DateMillisTuple) => {
  let values: Array<number> = [];

  // Use the stream start and end to clip the date-range as needed.
  const streamKeys = _.keys(stream);
  let [start, end] = [Number(_.first(streamKeys)), Number(_.last(streamKeys))];

  if (start < dateSpan[0]) {
    start = dateSpan[0];
  }
  if (end > dateSpan[1]) {
    end = dateSpan[1];
  }
  _.range(start, end, dayMillis).forEach((date) => {
    values.push(stream?.[date] || 0);
  });
  return values;
};

export const getSolarPerformanceForDateRange = (
  mp: Maybe<MonitoringPlatform>,
  dateSpan: DateMillisTuple
) => {
  let dateRangePerf = (mp?.yesterday || 0) as number;

  if (dateSpan[0] !== defaultStartDate().toMillis()) {
    const pastYear = mp?.past_year_performance as SolarPastYearPerformance;
    const pvEnergy = _.sum(intervalValuesInDateRange(pastYear?.value, dateSpan));
    const predictedEnergy = _.sum(intervalValuesInDateRange(pastYear?.baseline, dateSpan));
    dateRangePerf = predictedEnergy ? pvEnergy / predictedEnergy : dateRangePerf;
  }

  return dateRangePerf;
};

export const getBaselineForDateRange = (
  mp: Maybe<MonitoringPlatform>,
  dateSpan: DateMillisTuple
) => {
  let baseline = mp?.baseline?.value || 0;
  let weather_adjusted = mp?.baseline?.weather_adjusted || false;

  if (dateSpan[0] !== defaultStartDate().toMillis()) {
    const pastYear = mp?.past_year_performance as SolarPastYearPerformance;
    const baselines = intervalValuesInDateRange(pastYear?.baseline, dateSpan);
    baseline = _.mean(baselines) || 0;
    weather_adjusted = baseline ? weather_adjusted : false;
  }
  return { baseline: Math.round(baseline * 10) / 10, weather_adjusted: weather_adjusted };
};

export const getStoragePerformanceForDateRange = (
  mp: Maybe<MonitoringPlatform>,
  dateSpan: DateMillisTuple
) => {
  const yesterday = (mp?.yesterday || 0) as BatteryYesterdayPerformance;
  let preBattery = yesterday.preBattery;
  let postBattery = yesterday.postBattery;

  if (dateSpan[0] !== defaultStartDate().toMillis()) {
    const pastYear = mp?.past_year_performance as BatteryPastYearPerformance;
    postBattery = _.sum(intervalValuesInDateRange(pastYear?.postBattery, dateSpan));
    preBattery = _.sum(intervalValuesInDateRange(pastYear?.preBattery, dateSpan));
  }

  return postBattery && preBattery ? 1 - postBattery / preBattery : 0;
};
