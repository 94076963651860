import _ from 'lodash';

import { store } from 'onescreen/store';
import { IdType, Maybe, ObjectWithoutType, ObjectWithType } from 'onescreen/types';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'ConnectionLevel'> & {
  id: IdType;
  name: string;
};

export declare namespace ConnectionLevel {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}
}

/** ======================== Model ========================================= */
export interface ConnectionLevel extends CommonAttrs {}
export class ConnectionLevel {
  /** ====================== Static fields ================================= */
  static readonly rawFields = ['id', 'name'] as const;

  /**
   * Parses an array of raw ConnectionLevel objects by passing each in turn to
   * ConnectionLevel.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {ConnectionLevel.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: ConnectionLevel.Raw[]): ConnectionLevel[];
  static fromObjects(raw: Maybe<ConnectionLevel.Raw[]>): Maybe<ConnectionLevel[]>;
  static fromObjects(raw: Maybe<ConnectionLevel.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: ConnectionLevel.Raw): ConnectionLevel;
  static fromObject(raw: Maybe<ConnectionLevel.Raw>): Maybe<ConnectionLevel>;
  static fromObject(raw: Maybe<ConnectionLevel.Raw>) {
    return raw ? new ConnectionLevel(raw) : undefined;
  }

  static fromStore(): ConnectionLevel[];
  static fromStore(id: Maybe<ConnectionLevel['id']>): Maybe<ConnectionLevel>;
  static fromStore(id?: ConnectionLevel['id']) {
    const { connectionLevels } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(connectionLevels)).map(ConnectionLevel.fromObject);
    } else {
      return id ? ConnectionLevel.fromObject(connectionLevels[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'ConnectionLevel';
  constructor(raw: ConnectionLevel.Raw) {
    Object.assign(this, _.pick(raw, ConnectionLevel.rawFields));
  }

  serialize(): ConnectionLevel.Serialized {
    return { ..._.pick(this, ConnectionLevel.rawFields), object_type: this.object_type };
  }
}
