import _ from 'lodash';
import M from 'materialize-css';

import { cookieManager } from 'onescreen/utils/storage';

type Callback = (results: any, err?: any) => void;
export const api_host = process.env.REACT_APP_API_URL ?? '';

export default function fetchWithAuth(url: string, callback: Callback, extraData = null) {
  let fullUrl = url;
  if (url.substring(0, 4) !== 'http') {
    fullUrl = api_host + url;
  }

  fetch(fullUrl, { method: 'GET', headers: getHeaders(), ...(extraData || {}) })
    .then((response) => {
      if (response.ok) return response.json();
      throw new Error();
    })
    .then((data) => callback(data))
    .catch((err) => {
      console.error(err);
      callback(null, err);
      if (err.name !== 'AbortError') M.toast({ html: err });
    });
}

export function postWithAuth(
  url: string,
  data: any,
  callback: Callback,
  basic = false,
  method = 'POST'
) {
  const fullUrl = url.substring(0, 4) !== 'http' ? api_host + url : url;
  const notForm = !(data instanceof FormData);
  const headers = getHeaders(notForm);
  const body = notForm ? JSON.stringify(data) : data;

  fetch(fullUrl, { method, headers, body })
    .then((response) => {
      if (response.status === 204 || basic) return response;
      if (response.ok) return response.json();
      throw new Error();
    })
    .then((data) => callback(data))
    .catch((err) => {
      callback(null, err);
      M.toast({ html: err });
    });
}

export async function WaitForTask(taskId: string, callback: Callback) {
  const url = `/v1/task-result/${taskId}/?exclude[]=*&include[]=status&include[]=result`;
  const makeTheCall = () => {
    fetch(api_host + url, {
      method: 'GET',
      headers: getHeaders(),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          setTimeout(makeTheCall, 2000);
        }
      })
      .then((data) => {
        if (data && data.task_result) callback(data.task_result);
      });
  };
  setTimeout(makeTheCall, 2000);
}

function getHeaders(includeContentType: boolean = true): HeadersInit {
  const [, token] = cookieManager.authToken;
  return _.truthy({
    'Authorization': token && `Bearer ${token}`,
    'Content-Type': includeContentType && 'application/json',
  });
}
