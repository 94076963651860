import { BaseAPI } from '../base';

/** ======================== Types ========================================= */
export type KnownIssue = {
  id: number;
  severity: 1 | 2 | 3;
  identifier: string;
  description: string;
  owner_type: number;
  owner_id: number;
};

export declare namespace KnownIssueNS {
  // Network interface
  export namespace API {
    export type CreateParams = Omit<KnownIssue, 'id'>;
    export type CreateResponse = { known_issue: KnownIssue };
    export type CreateResult = { knownIssue: KnownIssue };
  }
}

export class KnownIssueAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.knownIssue;

  static async create(
    params: KnownIssueNS.API.CreateParams
  ): Promise<KnownIssueNS.API.CreateResult> {
    const { json } = await this.post<KnownIssueNS.API.CreateResponse>(this.route, params);

    return { knownIssue: json.known_issue };
  }

  static async destroy(id: KnownIssue['id']): Promise<void> {
    await this.delete<KnownIssueNS.API.CreateResponse>(this.route(id));
  }
}
