import React, { useEffect, useState } from 'react';

import {
  Alert,
  Button,
  Card,
  Dialog,
  Grid,
  Icon,
  Loading,
  Paper,
  Select,
  Typography,
} from 'onescreen/components';
import { Org, MonitoringPlatformAccountType } from '../../common/models';
import { useOrg } from 'onescreen/hooks';
import { FileSelectorListItem, RadioListItem } from 'onescreen/components/RadioListItem';
import { AsyncTask } from 'onescreen/models/base';
import { ErrorMessage } from 'onescreen/types';

/** =============================== Types ================================== */

type IntervalUploadTaskProgressProps = {
  task: Org.API.UploadIntervalTask;
};

type IntervalUploadModalProps = {
  orgId: Org['id'];
  modalOpen: boolean;
  closeModal: () => void;
};

/** ============================ Components ================================ */

export const IntervalUploadTaskProgress: React.FC<IntervalUploadTaskProgressProps> = ({ task }) => {
  const [error, setError] = useState<ErrorMessage>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const t = new AsyncTask<ErrorMessage | undefined>(task.task_id);
    t.getResult().then((data) => {
      setError(data);
      setLoading(false);
    });
  }, [task]);
  const failed = !!error;
  const statusText = (
    <Typography color={failed ? 'error' : 'success'}>{failed ? 'Failed' : 'Success'}</Typography>
  );
  const icon =
    task.device === 'solar' ? (
      <Icon name="sun" />
    ) : task.device === 'utility' ? (
      <Icon name="plug" />
    ) : (
      <Icon name="battery" />
    );
  return (
    <Paper>
      <Grid>
        <Grid.Item>
          <Typography>{icon}</Typography>
        </Grid.Item>
        <Grid.Item>
          <Typography>{task.column}: </Typography>
          {loading ? <Loading /> : statusText}
        </Grid.Item>
        <Grid.Item>
          <Typography color="error">{error ? error.exc_message : ''}</Typography>
        </Grid.Item>
      </Grid>
    </Paper>
  );
};

export const IntervalUploadModal: React.FC<IntervalUploadModalProps> = ({
  orgId,
  modalOpen,
  closeModal,
}) => {
  const [tasks, setTasks] = useState<Org.API.UploadIntervalTask[]>([]);
  const [solarFile, setSolarFile] = useState<File>();
  const [storageFile, setStorageFile] = useState<File>();
  const [utilityFile, setUtilityFile] = useState<File>();
  const [solarMonitor, setSolarMonitor] = useState<MonitoringPlatformAccountType>();
  const [storageMonitor, setStorageMonitor] = useState<MonitoringPlatformAccountType>();

  const [config, setConfig] = useState({
    utilityFile: true,
    solarFile: true,
    storageFile: true,
  });

  const { org } = useOrg(orgId, {
    include: ['solar_monitor_types.*', 'storage_monitor_types.*'],
  });

  return (
    <Dialog open={modalOpen} onClose={closeModal}>
      <Dialog.Content>
        <Grid>
          <Grid.Item>
            <Typography variant="h4">Upload cleaned and validated intervals</Typography>
          </Grid.Item>
          <Grid.Item>
            <Alert.Info>
              Either upload a file with Service Drop IDs as headers, or select a monitor type to use
              raw data as cleaned data. On the Analysis page, the cleaned intervals will be used as
              "default" data.
            </Alert.Info>
          </Grid.Item>
          <Grid.Item span={12}>
            <Card>
              <Typography variant="h5">Usage data</Typography>
              <Card.Content>
                <Grid>
                  <Grid.Item span={6}>
                    <FileSelectorListItem
                      selected={config.utilityFile}
                      onClick={() => setConfig((c) => ({ ...c, utilityFile: true }))}
                      onChange={setUtilityFile}
                      file={utilityFile}
                    />
                  </Grid.Item>
                  <Grid.Item span={6}>
                    <RadioListItem
                      selected={!config.utilityFile}
                      onClick={() => {
                        setConfig((c) => ({ ...c, utilityFile: false }));
                      }}
                    >
                      <Typography>Primary Meter</Typography>
                    </RadioListItem>
                  </Grid.Item>
                </Grid>
              </Card.Content>
            </Card>
          </Grid.Item>
          <Grid.Item span={12}>
            <Card>
              <Typography variant="h5">Solar data</Typography>
              <Card.Content>
                <Grid>
                  <Grid.Item span={6}>
                    <FileSelectorListItem
                      selected={config.solarFile}
                      onClick={() => setConfig((c) => ({ ...c, solarFile: true }))}
                      onChange={setSolarFile}
                      file={solarFile}
                    />
                  </Grid.Item>
                  <Grid.Item span={6}>
                    <RadioListItem
                      selected={!config.solarFile}
                      disabled={!org?.solar_monitor_types?.length}
                      onClick={() => {
                        setConfig((c) => ({ ...c, solarFile: false }));
                        setSolarMonitor(org?.SolarMonitorTypes[0]);
                        setSolarFile(undefined);
                      }}
                    >
                      <Select
                        fullWidth
                        disabled={!org?.solar_monitor_types?.length || config.solarFile}
                        options={org?.SolarMonitorTypes}
                        valueOption="id"
                        renderOption="name"
                        value={solarMonitor}
                        onChange={setSolarMonitor}
                        label="Select solar monitoring type"
                      />
                    </RadioListItem>
                  </Grid.Item>
                </Grid>
              </Card.Content>
            </Card>
          </Grid.Item>
          <Grid.Item span={12}>
            <Card>
              <Typography variant="h5">Storage data</Typography>
              <Card.Content>
                <Grid>
                  <Grid.Item span={6}>
                    <FileSelectorListItem
                      selected={config.storageFile}
                      onClick={() => setConfig((c) => ({ ...c, storageFile: true }))}
                      onChange={setStorageFile}
                      file={storageFile}
                    />
                  </Grid.Item>
                  <Grid.Item span={6}>
                    <RadioListItem
                      selected={!config.storageFile}
                      disabled={!org?.storage_monitor_types?.length}
                      onClick={() => {
                        setConfig((c) => ({ ...c, storageFile: false }));
                        setStorageMonitor(org?.StorageMonitorTypes[0]);
                        setStorageFile(undefined);
                      }}
                    >
                      <Select
                        fullWidth
                        disabled={!org?.storage_monitor_types?.length || config.storageFile}
                        options={org?.StorageMonitorTypes}
                        valueOption="id"
                        renderOption="name"
                        value={storageMonitor}
                        onChange={setStorageMonitor}
                        label="Select storage monitoring type"
                      />
                    </RadioListItem>
                  </Grid.Item>
                </Grid>
              </Card.Content>
            </Card>
          </Grid.Item>
        </Grid>
        {tasks.map((task) => (
          <IntervalUploadTaskProgress task={task} key={task.task_id} />
        ))}
      </Dialog.Content>
      <Dialog.Actions>
        <Button color="secondary" onClick={submit}>
          Submit
        </Button>
        <Button onClick={closeModal}>Close</Button>
      </Dialog.Actions>
    </Dialog>
  );
  /** ============================== Callbacks ============================= */
  async function submit() {
    let formData: Org.API.UploadIntervalsParams = {};
    if (config.solarFile) formData['gen_csv'] = solarFile;
    else formData['gen_monitor'] = solarMonitor?.id;
    if (config.storageFile) formData['batt_csv'] = storageFile;
    else formData['batt_monitor'] = storageMonitor?.id;
    if (config.utilityFile) formData['util_csv'] = utilityFile;
    else formData['util_meter'] = !config.utilityFile;

    const { tasks } = await Org.api.uploadIntervals(orgId, formData);
    setTasks(tasks);
  }
};
