import _ from 'lodash';
import { DateTime } from 'luxon';

import { store } from 'onescreen/store';
import { IdType, Maybe, ObjectWithoutType, ObjectWithType } from 'onescreen/types';
import { CCA, Utility } from '.';
import { DateUtils } from '..';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'Source'> & {
  id: IdType;
  name: string;
  file: string;
  provider_id: Utility['id'] | CCA['id'];
};

export declare namespace Source {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {
    effective: string | DateTime;
  }
}

/** ======================== Model ========================================= */
export interface Source extends CommonAttrs {}
export class Source {
  /** ====================== Static fields ================================= */
  static readonly rawFields = ['id', 'name', 'file', 'provider_id'] as const;

  /**
   * Parses an array of raw Source objects by passing each in turn to
   * Source.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {Source.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: Source.Raw[]): Source[];
  static fromObjects(raw: Maybe<Source.Raw[]>): Maybe<Source[]>;
  static fromObjects(raw: Maybe<Source.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: Source.Raw): Source;
  static fromObject(raw: Maybe<Source.Raw>): Maybe<Source>;
  static fromObject(raw: Maybe<Source.Raw>) {
    return raw ? new Source(raw) : undefined;
  }

  static fromStore(): Source[];
  static fromStore(id: Maybe<Source['id']>): Maybe<Source>;
  static fromStore(id?: Source['id']) {
    const { sources } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(sources)).map(Source.fromObject);
    } else {
      return id ? Source.fromObject(sources[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  effective: DateTime;
  readonly object_type = 'Source';
  constructor(raw: Source.Raw) {
    Object.assign(this, _.pick(raw, Source.rawFields));
    this.effective = DateUtils.parseDate(raw.effective);
  }

  serialize(): Source.Serialized {
    return {
      ..._.pick(this, Source.rawFields),
      object_type: this.object_type,
      effective: DateUtils.serializeDate(this.effective),
    };
  }
}
