import { store } from './store';

export const zip = (objList, alterFunc = null, key = 'id') => {
  let out = {};
  if (!objList) return objList;
  objList.forEach((obj) => {
    let finalObj = obj;
    if (alterFunc) {
      finalObj = alterFunc(finalObj);
    }
    out[obj[key]] = finalObj;
  });
  return out;
};

export const ServiceDrop = (monitoringPlatform) => {
  const state = store.getState().monitoring;
  let deviceName = monitoringPlatform.storage === null ? 'solar_generator' : 'storage';
  let devicePlural = deviceName + 's';
  if (monitoringPlatform[deviceName] && state) {
    let dev = state[devicePlural][monitoringPlatform[deviceName]];
    return state.service_drops[dev.service_drops[0]];
  }
};

export const Site = (monitoringPlatform) => {
  const state = store.getState().monitoring;
  let sd = ServiceDrop(monitoringPlatform);
  if (!sd) return null;
  return state.sites[sd.site];
};

export const Org = (monitoringPlatform) => {
  const state = store.getState().monitoring;
  let site = Site(monitoringPlatform);
  if (!site) return null;
  return state.orgs[site.org];
};
