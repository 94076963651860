import _ from 'lodash';
import React, { useCallback } from 'react';

import { Loading } from 'onescreen/components';
import { BaselineMonth, IntervalFile, IntervalStream, MonitoringPlatform } from 'onescreen/models';
import { useMonitoringPlatform } from 'onescreen/hooks/monitoring';
import { Dialog, Drawer, IntervalGraph } from 'onescreen/components';
import { DateTime, Duration } from 'luxon';
import { useIntervalFileIntervals } from 'onescreen/hooks/interval';

/*===============================Types=======================================*/
type PerformanceGraphProps = {
  monitoringPlatformId?: MonitoringPlatform['id'];
  dialogOpen: boolean;
  closeDialog: () => void;
};

/** ============================ Functions ================================= */

/*============================ Components ===================================*/
export const SolarPerformanceGraph: React.FC<PerformanceGraphProps> = ({
  monitoringPlatformId,
  closeDialog,
  dialogOpen,
}) => {
  const { monitoringPlatform, loading } = useMonitoringPlatform(
    monitoringPlatformId,
    {
      include: ['solar_generator.baselines.*'],
    },
    dialogOpen
  );

  const now = DateTime.fromJSDate(new Date());
  const year = Duration.fromObject({ years: 1 });

  return (
    <Drawer open={dialogOpen} onClose={closeDialog} anchor="bottom">
      <Dialog.Content>
        {loading ? (
          <Loading />
        ) : (
          <IntervalGraph
            id={monitoringPlatform?.interval_file!}
            title={`${monitoringPlatform?.monitor_name} production data for ${monitoringPlatform?.SolarGenerator?.ServiceDrop?.Site?.Org?.name} - ${monitoringPlatform?.SolarGenerator?.ServiceDrop?.Site?.name} ${monitoringPlatform?.SolarGenerator?.ServiceDrop?.name}`}
            defaultKey={'sum*1D'}
            bounds={[now.minus(year), now]}
            streams={[
              {
                key: 1,
                name: 'Solar Production',
                color: 'green',
                dataFn: mainData,
              },
              { key: 2, name: 'Monthly Baselines', color: 'tomato', dataFn: baselineData },
            ]}
          />
        )}
      </Dialog.Content>
    </Drawer>
  );
  /**================================ Callbacks ============================ */
  function mainData(stream: IntervalStream) {
    return stream;
  }

  function baselineData(stream: IntervalStream) {
    const values = stream.value;
    const origBaselines = _.truthy(
      (monitoringPlatform?.SolarGenerator?.baselines || []).map(BaselineMonth.fromStore)
    );
    let output: IntervalStream = { key: stream.key, value: {} };
    Object.keys(values).forEach((key) => {
      const date = new Date(Number(key));
      output.value[Number(key)] =
        (_.find(origBaselines, { month: date.getMonth() + 1 }) as BaselineMonth)?.baseline || 100;
    });
    if (stream.key === 'sum*1D') {
      return output;
    }
  }
};

export const StoragePerformanceGraph: React.FC<PerformanceGraphProps> = ({
  monitoringPlatformId,
  closeDialog,
  dialogOpen,
}) => {
  const now = DateTime.fromJSDate(new Date());
  const year = Duration.fromObject({ years: 1 });

  const [params, setParams] = React.useState<IntervalFile.API.RetrieveIntervalsParams>({
    agg: 'max',
    freq: '1D',
    start: now.minus(year).toISODate(),
    end: now.toISODate(),
  });
  const [shouldFetch, setShouldFetch] = React.useState(false);

  const { monitoringPlatform, loading } = useMonitoringPlatform(
    monitoringPlatformId,
    undefined,
    dialogOpen
  );

  const meterxStream = useIntervalFileIntervals(
    monitoringPlatform?.meterx_interval_file!,
    params,
    shouldFetch,
    [shouldFetch, params]
  );

  const battStream = useIntervalFileIntervals(
    monitoringPlatform?.interval_file!,
    params,
    shouldFetch,
    [shouldFetch, params]
  );

  React.useEffect(() => {
    setShouldFetch(!!monitoringPlatform);
  }, [monitoringPlatform]);

  const updateParams = useCallback(
    (params: IntervalFile.API.RetrieveIntervalsParams) => {
      setShouldFetch(true);
      setParams(params);
    },
    [setParams]
  );

  return (
    <Drawer open={dialogOpen} onClose={closeDialog} anchor="bottom">
      <Dialog.Content>
        {loading ? (
          <Loading />
        ) : (
          <IntervalGraph
            id={monitoringPlatform?.meterx_interval_file!}
            title={`${monitoringPlatform?.monitor_name} storage data for ${monitoringPlatform?.Storage?.ServiceDrop?.Site?.Org?.name} - ${monitoringPlatform?.Storage?.ServiceDrop?.Site?.name} ${monitoringPlatform?.Storage?.ServiceDrop?.name}`}
            bounds={[now.minus(year), now]}
            defaultKey="max*1D"
            override={true}
            paramsUpdated={updateParams}
            streams={[
              {
                key: 1,
                name: 'Pre-Battery',
                color: 'red',
                stream: combineStreams(battStream.stream!, meterxStream.stream!),
              },
              {
                key: 2,
                name: 'Post-Battery (actual)',
                color: 'green',
                stream: meterxStream.stream,
              },
            ]}
          />
        )}
      </Dialog.Content>
    </Drawer>
  );
  /**================================ Helpers ============================== */

  function combineStreams(stream1?: IntervalStream, stream2?: IntervalStream) {
    if (!stream1 || !stream2)
      return { key: `${params.agg}*${params.freq}` as IntervalStream['key'], value: {} };
    let preBatt: IntervalStream['value'] = {};
    _.keys(stream1?.value).forEach((timestamp) => {
      const first = +stream1.value[+timestamp]! || 0;
      const second = +stream2.value[+timestamp]! || 0;
      preBatt[+timestamp] = first + second;
    });
    return { key: stream1.key, value: preBatt };
  }
};
