import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ForgotPassword from './forgot_password';
import SetPassword from './set_password';

export function UserPage() {
  return (
    <div>
      <Routes>
        <Route path={'password/:uid/:token'} element={<SetPassword />} />
        <Route path={'forgot-password'} element={<ForgotPassword />} />
      </Routes>
    </div>
  );
}
