import _ from 'lodash';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
  Alert,
  Box,
  Button,
  Dialog,
  Progress,
  Select,
  TextField,
  Checkbox,
  DownloadLink,
  CommonDialogProps as DialogProps,
  List,
  Typography,
  Grid,
  Tooltip,
  SortingTable,
  Drawer,
  Loading,
  Card,
  MultiSelect,
  DateSpanPicker,
} from 'onescreen/components';
import { useOrgs, usePortfolios, useServiceAgreements, useServiceDrops } from 'onescreen/hooks';
import {
  AMSPortfolio,
  AnalysisPeriod,
  BundledRatePlan,
  CCARatePlan,
  ConnectionLevel,
  CostSavings,
  Org,
  RatePlanVariation,
  ServiceAgreement,
  ServiceAnalysis,
  ServiceDrop,
} from 'onescreen/models';
import { slices } from 'onescreen/store';
import { DateTuple } from 'onescreen/types';
import { filterClause, formatters } from 'onescreen/utils';
import { AsyncTask } from 'onescreen/models/base';
import { useBundledRatePlans, useRatePlanVariations } from '../../common/hooks/rates';
import { useState, useCallback } from 'react';
import { DateTime, Duration } from 'luxon';

/** ======================== Types ========================================= */
type DialogContentProps = Omit<DialogProps, 'open'>;

// CreatePortfolioDialog props
type CreatePortfolioProps = {};
type CreatePortfolioDialogProps = DialogProps & CreatePortfolioProps;
type CreatePortfolioDialogContentProps = DialogContentProps & CreatePortfolioProps;

// CreateOrganizationSavingsDialog props
type CreateOSProps = { portfolio: AMSPortfolio; setActiveCostSavings: (cs: CostSavings) => void };
type CreateOSDialogProps = DialogProps & CreateOSProps;
type CreateOSDialogContentProps = DialogContentProps & CreateOSProps;

// CreateServiceDropAnalysisDialog props
type CreateSDADialogProps = DialogProps & CreateSDAProps;
type CreateSDADialogContentProps = DialogContentProps & CreateSDAProps;
type CreateSDAProps = {
  costSavings: CostSavings;
  setActiveServiceAnalysis: (cs: ServiceAnalysis) => void;
};

// BOM prop types
type PortfolioProps = {
  portfolio: AMSPortfolio;
};
type CostSavingsProps = {
  costSavings: CostSavings;
};
type ServiceAnalysisProps = {
  serviceAnalysis: ServiceAnalysis;
};

// GapReportDialog props
type GapReportDialogProps = PortfolioProps & DialogProps;
type GapReportDialogContentProps = PortfolioProps & DialogContentProps;

// CollectDefaultDataDialog props
type PortfolioTasksType = AMSPortfolio.API.DefaultDataCollectionResult['tasks'] | undefined;
type OrgTasksType = CostSavings.API.DefaultDataCollectionResult['tasks'] | undefined;
type CollectDefaultPortfolioDataDialogProps = PortfolioProps & DialogProps;
type CollectDefaultPortfolioDataDialogContentProps = PortfolioProps &
  DialogContentProps & {
    tasks: PortfolioTasksType;
    setTasks: React.Dispatch<React.SetStateAction<PortfolioTasksType>>;
  };
type CollectDefaultOrgDataDialogProps = CostSavingsProps & DialogProps;
type CollectDefaultOrgDataDialogContentProps = CostSavingsProps &
  DialogContentProps & {
    tasks: OrgTasksType;
    setTasks: React.Dispatch<React.SetStateAction<OrgTasksType>>;
  };

type SelectServiceAgreementDialogProps = DialogProps & ServiceAnalysisProps;
type SelectServiceAgreementDialogContentProps = DialogContentProps & ServiceAnalysisProps;

type CreateServiceAgreementDialogProps = DialogProps & ServiceAnalysisProps;
type CreateServiceAgreementDialogContentProps = DialogContentProps & ServiceAnalysisProps;

/** ======================== Dialog contents ==================================
 *  The dialog content components. These are only used by the dialog wrapper
 *  components, and will only be rendered when the dialogs are open. This
 *  pattern makes it easier to use data-loading hooks in the dialogs, which
 *  will only be called when the data is needed.
 */

const IntervalGapReportDialogContent: React.FC<GapReportDialogContentProps> = (props) => {
  const [minDays, setMinDays] = React.useState<number>(30);
  const [url, setUrl] = React.useState<string>();
  const { portfolio } = props;
  return (
    <>
      <Dialog.Title>Utility Interval Gap Report</Dialog.Title>
      <Dialog.Content>
        <TextField
          label="Minimum Day Span"
          type="number"
          onChange={(val) => setMinDays(parseInt(val))}
          value={minDays.toString()}
        ></TextField>
      </Dialog.Content>
      <Dialog.Actions>
        <Button color="primary" onClick={getIntervalGapReport}>
          Download
        </Button>
        <DownloadLink url={url}></DownloadLink>
      </Dialog.Actions>
    </>
  );
  /** ========================== Callback ================================== */
  async function getIntervalGapReport() {
    const { task } = await AMSPortfolio.api.utility_interval_gap_report(portfolio.id, {
      min_days: minDays,
    });
    task.waitForTask((resp) => {
      setUrl(resp.result.url);
    });
  }
};

const BillGapReportDialogContent: React.FC<GapReportDialogContentProps> = (props) => {
  const [url, setUrl] = React.useState<string>();
  const { portfolio } = props;
  const [dateSpan, setDateSpan] = React.useState<DateTuple>([
    portfolio.start_date,
    portfolio.end_date,
  ]);

  const datesDidChange = useCallback((start_date: DateTime, end_date: DateTime) => {
    setDateSpan([start_date, end_date]);
  }, []);

  return (
    <>
      <Dialog.Title>Utility Bill Gap Report</Dialog.Title>
      <Dialog.Content>
        <DateSpanPicker
          disabled={false}
          datesDidChange={datesDidChange}
          defaultStartDate={portfolio.start_date}
          defaultEndDate={portfolio.end_date}
          options={{
            minDate: portfolio.start_date.toJSDate(),
            maxDate: portfolio.end_date.toJSDate(),
          }}
        ></DateSpanPicker>
      </Dialog.Content>
      <Dialog.Actions>
        <Button color="primary" onClick={getBillGapReport}>
          Download
        </Button>
        <DownloadLink url={url}></DownloadLink>
      </Dialog.Actions>
    </>
  );
  /** ========================== Callback ================================== */
  async function getBillGapReport() {
    const { task } = await AMSPortfolio.api.utility_bill_gap_report(portfolio.id, {
      start_date: dateSpan[0].toISODate(),
      end_date: dateSpan[1].toISODate(),
    });
    task.waitForTask((resp) => {
      setUrl(resp.result.url);
    });
  }
};

const CreatePortfolioDialogContent: React.FC<CreatePortfolioDialogContentProps> = (props) => {
  const { onClose } = props;
  const dispatch = useDispatch();

  // State
  const [name, setName] = React.useState('');
  const end = DateTime.fromJSDate(new Date());
  let start = end.minus(Duration.fromISO('P1Y'));
  const [startDate, setStartDate] = React.useState<DateTime>(start);
  const [endDate, setEndDate] = React.useState<DateTime>(end);
  const [isClone, setIsClone] = React.useState(false);
  const [portfolio, setPortfolio] = React.useState<AMSPortfolio>();
  const [creating, setCreating] = React.useState(false);

  // Load the orgs
  const { loading, portfolios } = usePortfolios();

  const canSubmit = !!name && ((!!portfolio && isClone) || !isClone) && !creating;
  return (
    <>
      <Dialog.Title>Create Portfolio</Dialog.Title>
      <Dialog.Content>
        <Progress condition={loading} />
        <Box marginTop={1}>
          <TextField id="portfolio-name-input" label="Name" onChange={setName} value={name} />
        </Box>
        <Box marginTop={1}>
          <TextField
            id="portfolio-start-input"
            label="Start Date"
            onChange={(val) => setStartDate(DateTime.fromISO(val))}
            value={startDate.toISODate()}
            type="date"
          />
          <TextField
            id="portfolio-end-input"
            label="End Date"
            onChange={(val) => setEndDate(DateTime.fromISO(val))}
            value={endDate.toISODate()}
            type="date"
          />
        </Box>
        {!!Object.keys(portfolios || {}).length && (
          <>
            <Checkbox
              label="Clone from existing?"
              checked={isClone}
              onChange={() => setIsClone((clone) => !clone)}
            />
            <Select
              disabled={!isClone}
              label="Portfolio"
              fullWidth
              onChange={(value) => {
                setPortfolio(value);
                setName((prev) => (prev.length ? prev : value.name));
              }}
              options={Object.values(portfolios || {}) as AMSPortfolio[]}
              renderOption="name"
              value={portfolio}
            />
          </>
        )}
      </Dialog.Content>
      <Progress condition={creating} />
      <Dialog.Actions>
        <Button.Text onClick={onClose}>Cancel</Button.Text>
        <Button.Text color="primary" disabled={!canSubmit} onClick={submit}>
          Create
        </Button.Text>
      </Dialog.Actions>
    </>
  );

  async function submit() {
    setCreating(true);
    if (!canSubmit) return;

    // Create new AMSPortfolio object
    await AMSPortfolio.api.create({
      name,
      start_date: startDate.toISODate(),
      end_date: endDate.toISODate(),
      clone: isClone ? portfolio?.id : undefined,
    });

    dispatch(slices.ui.setMessage({ msg: 'Portfolio created.', type: 'success' }));
    onClose();
  }
};

const CreateOrganizationSavingsDialogContent: React.FC<CreateOSDialogContentProps> = (props) => {
  const { onClose, portfolio, setActiveCostSavings } = props;
  const dispatch = useDispatch();

  // State
  const [name, setName] = React.useState('');
  const [org, setOrg] = React.useState<Org>();
  const [creating, setCreating] = React.useState(false);
  const [error, setError] = React.useState<string>();

  // Load the orgs
  const { loading, orgs } = useOrgs(undefined, setError);

  const canSubmit = !!org && !!name && !creating;
  return (
    <>
      <Dialog.Title>Create Organization Savings Analysis</Dialog.Title>
      <Dialog.Content>
        <Progress condition={loading} />
        <Alert.Error>{error}</Alert.Error>
        {orgs && (
          <Select
            label="Organization"
            fullWidth
            onChange={setOrg}
            options={orgs.results}
            renderOption="name"
            value={org}
          />
        )}
        <Box marginTop={1}>
          <TextField id="cs-name-input" label="Name" onChange={setName} value={name} />
        </Box>
      </Dialog.Content>
      <Progress condition={creating} />
      <Dialog.Actions>
        <Button.Text onClick={onClose}>Cancel</Button.Text>
        <Button.Text color="primary" disabled={!canSubmit} onClick={submit}>
          Create
        </Button.Text>
      </Dialog.Actions>
    </>
  );

  async function submit() {
    setCreating(true);
    if (!org || !name) return;

    // Create new CostSavings object
    const { costSavings } = await CostSavings.api.create({ portfolio, name, org: org.id });

    // If the request succeeds, open a snackbar to say so, select the newly created cost savings
    // object and close the dialog
    setActiveCostSavings(costSavings);
    dispatch(
      slices.ui.setMessage({ msg: 'Organization cost savings analysis created.', type: 'success' })
    );
    onClose();
  }
};

const CreateServiceDropAnalysisDialogContent: React.FC<CreateSDADialogContentProps> = (props) => {
  const { costSavings, onClose, setActiveServiceAnalysis } = props;
  const dispatch = useDispatch();

  // State
  const [serviceDrop, setServiceDrop] = React.useState<ServiceDrop>();
  const [creating, setCreating] = React.useState(false);
  const [error, setError] = React.useState<string>();

  // Load the service drops
  const { loading, serviceDrops } = useServiceDrops(
    {
      filter: { 'site.org': filterClause.equals(costSavings.org) },
      include: 'site.name',
      sortKey: ['site.name', 'name'],
      limit: 100,
      offset: 0,
    },
    () => setError('Unable to load service drops')
  );

  const canSubmit = !!serviceDrop && !creating;

  return (
    <>
      <Dialog.Title>Create Service Drop Analysis</Dialog.Title>
      <Dialog.Content>
        <Progress condition={loading} />
        <Alert.Error>{error}</Alert.Error>
        {serviceDrops && (
          <Select
            label="Service Drops"
            fullWidth
            onChange={setServiceDrop}
            options={serviceDrops.results}
            renderOption={(serviceDrop) => `${serviceDrop.Site?.name} ${serviceDrop?.name}`}
            value={serviceDrop}
          />
        )}
      </Dialog.Content>
      <Progress condition={creating} />
      <Dialog.Actions>
        <Button.Text onClick={onClose}>Cancel</Button.Text>
        <Button.Text color="primary" disabled={!canSubmit} onClick={submit}>
          Create
        </Button.Text>
      </Dialog.Actions>
    </>
  );

  async function submit() {
    setCreating(true);
    if (!serviceDrop) return;

    // Create new ServiceAnalysis object
    const { serviceAnalysis } = await ServiceAnalysis.api.create({
      costSavings,
      service_drop: serviceDrop.id,
    });

    // If the request succeeds, open a snackbar to say so, select the newly created service analysis
    // object and close the dialog
    setActiveServiceAnalysis(serviceAnalysis);
    dispatch(slices.ui.setMessage({ msg: 'Service drop analysis created.', type: 'success' }));
    onClose();
  }
};

const AsyncTaskProgressView: React.FC<{ task: AsyncTask }> = ({ task }) => {
  return (
    <>
      {task.status ? (
        task.status === 'SUCCESS' ? (
          <Typography color="success">
            {task.status} {Math.round(task.result * 1000) / 10}%
            <Progress color="secondary" value={task.result * 100} />
          </Typography>
        ) : (
          <>
            <Tooltip
              placement="top"
              title={`${task.result.exc_type}: ${task.result.exc_message[0]}`}
            >
              <Typography color="error">{task.status}</Typography>
            </Tooltip>
          </>
        )
      ) : (
        <Progress color="primary" condition={true} />
      )}
    </>
  );
};

const CollectDefaultPortfolioDataDialogContent: React.FC<CollectDefaultPortfolioDataDialogContentProps> = (
  props
) => {
  const { portfolio, onClose, tasks, setTasks } = props;

  return (
    <>
      <Dialog.Title>Collecting Default Data for {portfolio.name}</Dialog.Title>
      <Dialog.Content>
        <Grid justify="space-around">
          <Grid.Item span={6}>
            <Button color="primary" onClick={collect}>
              Collect
            </Button>
          </Grid.Item>
          <Grid.Item>
            <Button color="secondary" onClick={eraseAndCollect}>
              Erase and Collect
            </Button>
          </Grid.Item>
          <Grid.Item span={12}>
            <List dense>
              {_.sortBy(tasks, 'costSavings.name')?.map((task) => (
                <List.Item button={false} key={task.task.task_id}>
                  <Grid>
                    <Grid.Item span={6}>
                      <Typography>{task.costSavings.name}</Typography>
                    </Grid.Item>
                    <Grid.Item span={6}>
                      <AsyncTaskProgressView task={task.task} />
                    </Grid.Item>
                  </Grid>
                </List.Item>
              ))}
            </List>
          </Grid.Item>
        </Grid>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={onClose}>Close</Button>
      </Dialog.Actions>
    </>
  );
  async function collect() {
    const resp = await AMSPortfolio.api.default_data_collection(portfolio.id);
    updateTasks(resp);
  }
  async function eraseAndCollect() {
    const resp = await AMSPortfolio.api.default_data_collection(portfolio.id, { refresh: true });
    updateTasks(resp);
  }
  async function updateTasks(resp: AMSPortfolio.API.DefaultDataCollectionResult) {
    const { tasks } = resp;
    setTasks(tasks);
    tasks?.forEach((entry) => {
      const { task, costSavings } = entry;
      task.waitForTask((t) => {
        setTasks(
          (oldTasks) =>
            oldTasks && [
              { costSavings, task: t },
              ...oldTasks.filter((t) => t.costSavings.id !== costSavings.id),
            ]
        );
      });
    });
  }
};

const CollectDefaultOrgDataDialogContent: React.FC<CollectDefaultOrgDataDialogContentProps> = (
  props
) => {
  const { costSavings, onClose, tasks, setTasks } = props;

  return (
    <>
      <Dialog.Title>Collecting Default Data for {costSavings.name}</Dialog.Title>
      <Dialog.Content>
        <Grid justify="space-around">
          <Grid.Item span={6}>
            <Button color="primary" onClick={collect}>
              Collect
            </Button>
          </Grid.Item>
          <Grid.Item>
            <Button color="secondary" onClick={eraseAndCollect}>
              Erase and Collect
            </Button>
          </Grid.Item>
          <Grid.Item span={12}>
            <List dense>
              {_.sortBy(tasks, 'serviceAnalysis.name')?.map((task) => (
                <List.Item button={false} key={task.task.task_id}>
                  <Grid>
                    <Grid.Item span={6}>
                      <Typography>{task.serviceAnalysis.name}</Typography>
                    </Grid.Item>
                    <Grid.Item span={6}>
                      <AsyncTaskProgressView task={task.task} />
                    </Grid.Item>
                  </Grid>
                </List.Item>
              ))}
            </List>
          </Grid.Item>
        </Grid>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={onClose}>Close</Button>
      </Dialog.Actions>
    </>
  );
  async function collect() {
    const resp = await CostSavings.api.default_data_collection(costSavings.id);
    updateTasks(resp);
  }
  async function eraseAndCollect() {
    const resp = await CostSavings.api.default_data_collection(costSavings.id, { refresh: true });
    updateTasks(resp);
  }
  async function updateTasks(resp: CostSavings.API.DefaultDataCollectionResult) {
    const { tasks } = resp;
    setTasks(tasks);
    tasks?.forEach((entry) => {
      const { task, serviceAnalysis } = entry;
      task.waitForTask((t) => {
        setTasks(
          (oldTasks) =>
            oldTasks && [
              { serviceAnalysis, task: t },
              ...oldTasks.filter((t) => t.serviceAnalysis.id !== serviceAnalysis.id),
            ]
        );
        ServiceAnalysis.api.retrieve(serviceAnalysis.id, {
          include: ['cost_savings', 'service_periods.service_simulations.service_period'],
        });
      });
    });
  }
};

const SelectServiceAgreementDialogContent: React.FC<SelectServiceAgreementDialogContentProps> = (
  props
) => {
  const { serviceAnalysis, onClose } = props;
  const { serviceAgreements, loading } = useServiceAgreements({
    include: [
      'bundled_rate_plan.*',
      'generation_rate_plan.*',
      'connection_level.*',
      'bundled_variations.rate_type_variations.*',
    ],
  });

  if (loading) return <Loading />;

  const items = _.truthy(Object.values(serviceAgreements!).map(ServiceAgreement.fromObject) || []);

  return (
    <>
      <Dialog.Title>
        <Typography>Select a Service Agreement</Typography>
        <Button icon="close" className="right" onClick={onClose} />
      </Dialog.Title>
      <Dialog.Content>
        <SortingTable
          name={'ServiceAgreements'}
          data={items}
          columns={[
            { header: 'Name', key: 'name' },
            {
              header: 'Utility',
              key: 'utility',
              value: (serviceAgreement) => serviceAgreement.BundledRatePlan?.utility,
            },
            {
              header: 'Rate Plan',
              key: 'bundled_rate_plan',
              value: (serviceAgreement) => serviceAgreement.BundledRatePlan?.friendly_name,
            },
            {
              header: 'Generation Rate Plan',
              key: 'generation_rate_plan',
              value: (serviceAgreement) => serviceAgreement.GenerationRatePlan?.friendly_name || '',
              render: (serviceAgreement) => (
                <Tooltip placement="top" title={serviceAgreement.GenerationRatePlan?.friendly_name}>
                  <Typography>
                    {formatters.truncateAtLength(
                      serviceAgreement.GenerationRatePlan?.friendly_name,
                      20
                    )}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              header: 'Connection Level',
              key: 'connection_level',
              value: (serviceAgreement) => serviceAgreement.ConnectionLevel?.name,
            },
            {
              header: 'Variations',
              key: 'bundled_variations',
              value: (serviceAgreement) =>
                serviceAgreement.BundledVariations?.map((bv) => bv.name).toString(),
              render: (serviceAgreement) => (
                <Tooltip
                  placement="top"
                  title={serviceAgreement.BundledVariations?.map((bv) => bv.name).toString()}
                >
                  <Typography>
                    {formatters.truncateAtLength(
                      serviceAgreement.BundledVariations?.map((bv) => bv.name).toString(),
                      20
                    )}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              header: 'Select',
              key: 'button',
              value: () => 0,
              render: (serviceAgreement) => (
                <Button
                  size="small"
                  color="primary"
                  onClick={() => selectServiceAgreement(serviceAgreement)}
                >
                  SELECT
                </Button>
              ),
              noFilter: true,
            },
          ]}
          total={items.length}
        />
      </Dialog.Content>
    </>
  );
  /** ========================== Callbacks ================================= */
  async function selectServiceAgreement(serviceAgreement: ServiceAgreement) {
    await AnalysisPeriod.api.create({
      serviceAgreement,
      serviceAnalysis,
    });
    ServiceAnalysis.api.retrieve(serviceAnalysis.id, {
      include: ['analysis_periods.service_agreement.bundled_variations.rate_type_variations.*'],
    });
    onClose();
  }
};

const CreateServiceAgreementDialogContent: React.FC<CreateServiceAgreementDialogContentProps> = (
  props
) => {
  const { serviceAnalysis, onClose } = props;
  const { bundledRatePlans, loading } = useBundledRatePlans({
    include: ['cca_rate_plans.*'],
    limit: 100,
    offset: 0,
  });
  const { ratePlanVariations } = useRatePlanVariations({
    include: ['rate_type_variations.*'],
    limit: 100,
    offset: 0,
  });
  const ratePlanIdList = _.truthy(
    _.sortBy(Object.values(bundledRatePlans || {}), 'friendly_name')
  ).map((brp) => brp.id);
  const [selectedRatePlan, setSelectedRatePlan] = useState<BundledRatePlan['id']>();
  const [powerFactor, setPowerFactor] = useState<number>(85);
  const [selectedConnectionLevel, setSelectedConnectionLevel] = useState<ConnectionLevel['id']>();
  const [selectedCCARatePlan, setSelectedCCARatePlan] = useState<CCARatePlan['id']>();
  const [ccaVintage, setCCAVintage] = useState<number>(2017);
  const [selectedVariations, setSelectedVariations] = useState<RatePlanVariation['id'][]>([]);
  const [name, setName] = useState<string>('');
  const [nameChanged, setNameChanged] = useState<boolean>(false);

  React.useEffect(() => {
    if (!nameChanged) {
      let n = '';
      if (selectedRatePlan) {
        n = bundledRatePlans![selectedRatePlan]?.friendly_name || '';
        if (selectedConnectionLevel) {
          n +=
            ' ' +
            _.find(
              BundledRatePlan.fromObject(bundledRatePlans![selectedRatePlan])?.ConnectionLevels,
              { id: selectedConnectionLevel }
            )?.name;
        }
      }
      setName(n);
    }
  }, [selectedRatePlan, selectedConnectionLevel, bundledRatePlans, nameChanged]);

  if (!bundledRatePlans || loading) return <Loading />;

  return (
    <>
      <Dialog.Title>Create a Service Agreement</Dialog.Title>
      <Dialog.Content>
        <Grid>
          <Grid.Item span={8}>
            <Card>
              <Card.Content>
                <Typography>Select a Rate Plan</Typography>
                <Select
                  fullWidth
                  options={ratePlanIdList}
                  renderOption={(brpId) => bundledRatePlans[brpId]?.friendly_name || ''}
                  value={selectedRatePlan}
                  onChange={(value) => {
                    setSelectedRatePlan(value);
                    setSelectedConnectionLevel(undefined);
                  }}
                ></Select>
              </Card.Content>
            </Card>
          </Grid.Item>
          <Grid.Item span={4}>
            <Card>
              <Card.Content>
                <Typography>Power Factor</Typography>
                <TextField
                  type="number"
                  value={powerFactor.toString()}
                  onChange={(val) => setPowerFactor(parseInt(val))}
                  InputProps={{
                    endAdornment: <TextField.Adornment position="end">%</TextField.Adornment>,
                  }}
                />
              </Card.Content>
            </Card>
          </Grid.Item>
          {selectedRatePlan && (
            <Grid.Item span={12}>
              <Card>
                <Card.Content>
                  <Typography>Select a Connection Level</Typography>
                  <Select
                    fullWidth
                    options={bundledRatePlans[selectedRatePlan]?.connection_levels}
                    renderOption={(clId) =>
                      _.find(
                        BundledRatePlan.fromObject(bundledRatePlans[selectedRatePlan])
                          ?.ConnectionLevels,
                        { id: clId }
                      )?.name || ''
                    }
                    value={selectedConnectionLevel}
                    onChange={setSelectedConnectionLevel}
                  ></Select>
                </Card.Content>
              </Card>
            </Grid.Item>
          )}
          {selectedConnectionLevel && selectedRatePlan && (
            <Grid.Item span={selectedCCARatePlan ? 8 : 12}>
              <Card>
                <Card.Content>
                  <Typography>Select a Generation Rate Plan (Optional)</Typography>
                  <Grid>
                    <Grid.Item span={11}>
                      <Select
                        fullWidth
                        sorted={true}
                        options={bundledRatePlans[selectedRatePlan]?.cca_rate_plans}
                        renderOption={(ccaRPId) =>
                          _.find(
                            BundledRatePlan.fromObject(bundledRatePlans[selectedRatePlan])
                              ?.CCARatePlans,
                            { id: ccaRPId }
                          )?.friendly_name || ''
                        }
                        value={selectedCCARatePlan}
                        onChange={setSelectedCCARatePlan}
                      />
                    </Grid.Item>
                    <Grid.Item span={1}>
                      <Button
                        onClick={() => setSelectedCCARatePlan(undefined)}
                        icon="close"
                        size="small"
                      />
                    </Grid.Item>
                  </Grid>
                </Card.Content>
              </Card>
            </Grid.Item>
          )}
          {selectedCCARatePlan && (
            <Grid.Item span={4}>
              <Card>
                <Card.Content>
                  <Typography>CCA Vintage</Typography>
                  <TextField
                    type="number"
                    value={ccaVintage.toString()}
                    onChange={(val) => setCCAVintage(parseInt(val))}
                    helperText="Year"
                  />
                </Card.Content>
              </Card>
            </Grid.Item>
          )}
          {selectedConnectionLevel && selectedRatePlan && ratePlanVariations && (
            <Grid.Item span={12}>
              <Card>
                <Card.Content>
                  <Typography>Select any Variations (Optional)</Typography>
                  <Grid>
                    <Grid.Item span={12}>
                      <MultiSelect
                        fullWidth
                        sorted={true}
                        options={_.truthy(Object.values(ratePlanVariations!)).map((rpv) => rpv?.id)}
                        value={selectedVariations}
                        onChange={setSelectedVariations}
                        renderOption={(rpvId) => ratePlanVariations[rpvId]?.name || ''}
                      />
                    </Grid.Item>
                  </Grid>
                </Card.Content>
              </Card>
            </Grid.Item>
          )}
          {selectedConnectionLevel && selectedRatePlan && (
            <Grid.Item span={12}>
              <Typography>Name</Typography>
              <Grid>
                <Grid.Item span={12}>
                  <TextField
                    value={name}
                    onChange={(newName) => {
                      setNameChanged(true);
                      setName(newName);
                    }}
                  />
                </Grid.Item>
              </Grid>
            </Grid.Item>
          )}
        </Grid>
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          onClick={create}
          color="secondary"
          disabled={!selectedRatePlan || !selectedConnectionLevel}
        >
          Create
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </Dialog.Actions>
    </>
  );
  /**========================== Callbacks ================================== */
  async function create() {
    if (!selectedRatePlan || !selectedConnectionLevel || !name) return;
    let params: ServiceAgreement.API.CreateParams = {
      name,
      bundled_rate_plan: selectedRatePlan,
      connection_level: selectedConnectionLevel,
      power_factor: powerFactor / 100,
      bundled_variations: selectedVariations,
    };
    if (selectedCCARatePlan) {
      params['generation_rate_plan'] = selectedCCARatePlan;
      params['cca_vintage'] = ccaVintage;
    }

    const { serviceAgreement } = await ServiceAgreement.api.create(params);
    await AnalysisPeriod.api.create({
      serviceAgreement,
      serviceAnalysis,
    });
    ServiceAnalysis.api.retrieve(serviceAnalysis.id, {
      include: ['analysis_periods.service_agreement.bundled_variations.rate_type_variations.*'],
    });
    onClose();
  }
};

/** ======================== Dialog wrappers ==================================
 *  The top-level dialog components. These are exported and used externally.
 *  Their children-- the dialog content components --will only be rendered when
 *  the dialogs are opened.
 */
export const IntervalGapReportDialog: React.FC<GapReportDialogProps> = (props) => {
  const { onClose, open, ...rest } = props;
  return (
    <Dialog onClose={onClose} open={open}>
      <IntervalGapReportDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

export const BillGapReportDialog: React.FC<GapReportDialogProps> = (props) => {
  const { onClose, open, ...rest } = props;
  return (
    <Dialog onClose={onClose} open={open}>
      <BillGapReportDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

export const CreatePortfolioDialog: React.FC<CreatePortfolioDialogProps> = (props) => {
  const { onClose, open, ...rest } = props;
  return (
    <Dialog onClose={onClose} open={open}>
      <CreatePortfolioDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

export const CreateOrganizationSavingsDialog: React.FC<CreateOSDialogProps> = (props) => {
  const { onClose, open, ...rest } = props;
  return (
    <Dialog onClose={onClose} open={open}>
      <CreateOrganizationSavingsDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

export const CreateServiceDropAnalysisDialog: React.FC<CreateSDADialogProps> = (props) => {
  const { onClose, open, ...rest } = props;
  return (
    <Dialog onClose={onClose} open={open}>
      <CreateServiceDropAnalysisDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};

export const CollectDefaultPortfolioDataDialog: React.FC<CollectDefaultPortfolioDataDialogProps> = (
  props
) => {
  const { onClose, open, ...rest } = props;
  const [tasks, setTasks] = React.useState<PortfolioTasksType>();
  return (
    <Dialog onClose={onClose} open={open} fullWidth={true}>
      <CollectDefaultPortfolioDataDialogContent
        onClose={onClose}
        tasks={tasks}
        setTasks={setTasks}
        {...rest}
      />
    </Dialog>
  );
};

export const CollectDefaultOrgDataDialog: React.FC<CollectDefaultOrgDataDialogProps> = (props) => {
  const { onClose, open, ...rest } = props;
  const [tasks, setTasks] = React.useState<OrgTasksType>();
  return (
    <Dialog onClose={onClose} open={open} fullWidth={true}>
      <CollectDefaultOrgDataDialogContent
        onClose={onClose}
        tasks={tasks}
        setTasks={setTasks}
        {...rest}
      />
    </Dialog>
  );
};

export const SelectServiceAgreementDialog: React.FC<SelectServiceAgreementDialogProps> = (
  props
) => {
  const { onClose, open, ...rest } = props;
  return (
    <Drawer onClose={onClose} open={open} anchor="top">
      <SelectServiceAgreementDialogContent onClose={onClose} {...rest} />
    </Drawer>
  );
};

export const CreateServiceAgreementDialog: React.FC<CreateServiceAgreementDialogProps> = (
  props
) => {
  const { onClose, open, ...rest } = props;
  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <CreateServiceAgreementDialogContent onClose={onClose} {...rest} />
    </Dialog>
  );
};
