import _ from 'lodash';

import { slices, store } from 'onescreen/store';
import { Maybe, ObjectWithoutType, Pagination, Query } from 'onescreen/types';

import { AsyncTask, BaseAPI } from '../base';
import { SolarGenerator, Storage } from '../assets';
import { BaselineMonth } from '../util';
import { Org, ServiceDrop, Site } from '../orgs';
import { IntervalFile, RawIntervalStream } from '../interval';
import { User } from '../user';

/** ======================== Types ========================================= */
type SolarYesterdayPerformance = number;
export type BatteryYesterdayPerformance = {
  postBattery: number;
  preBattery: number;
};
type YesterdayPerformance = SolarYesterdayPerformance | BatteryYesterdayPerformance;

export type SolarPastYearPerformance = {
  value: RawIntervalStream;
  baseline: RawIntervalStream;
  weather_adjusted: RawIntervalStream;
};
export type BatteryPastYearPerformance = {
  postBattery: RawIntervalStream;
  preBattery: RawIntervalStream;
};
type PastYearPerformance = SolarPastYearPerformance | BatteryPastYearPerformance;

type CurrentBaseline = {
  value: number;
  weather_adjusted: boolean;
};

type CommonAttrs = {
  id: number;
  identifier: string;
  monitor_name: string;
  hidden: boolean;
  access_link: string;
  baseline?: CurrentBaseline;
  yesterday?: YesterdayPerformance;
  past_year_performance?: PastYearPerformance;
  object_type: 'MonitoringPlatform';
  solar_generator?: SolarGenerator['id'];
  storage?: Storage['id'];
  interval_file?: IntervalFile['id'];
  meterx_interval_file?: IntervalFile['id'];
};

/** ======================== Namespaces ==================================== */

export declare namespace MonitoringPlatform {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}

  // Network interface
  export namespace API {
    export type CreateParams = Pick<
      MonitoringPlatform.Raw,
      'identifier' | 'solar_generator' | 'storage'
    > & {
      monitoring_account: number;
    };
    export type ListParams = RetrieveParams & Maybe<Query.PaginationParams>;
    export type ListResponse = Pagination.Response.Raw<{
      monitoring_platforms: MonitoringPlatform.Raw[];
      solar_generators: SolarGenerator.Raw[];
      storages: Storage.Raw[];
      service_drops: ServiceDrop.Raw[];
      sites: Site.Raw[];
      orgs: Org.Raw[];
      users: User.Raw[];
    }>;
    export type ListResult = Pagination.Response.Parsed<MonitoringPlatform>;

    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResponse = {
      monitoring_platform: MonitoringPlatform.Raw;
      baseline_months: BaselineMonth.Raw[];
      storages: Storage.Raw[];
      solar_generators: SolarGenerator.Raw[];
    };
    export type RetrieveResult = { monitoringPlatform: MonitoringPlatform };

    export type UpdateParams = Pick<MonitoringPlatform.Raw, 'hidden'>;

    export type CollectIntervalsResult = {
      interval_file_id: IntervalFile['id'];
    };
    export type CollectIntervalsResponse = {
      task_id: AsyncTask<CollectIntervalsResult>['task_id'];
    };
  }
}

/** ======================== API =========================================== */
class MonitoringPlatformAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.monitors;

  static async list(
    params?: MonitoringPlatform.API.ListParams
  ): Promise<MonitoringPlatform.API.ListResult> {
    return this._list(String(this.route), params);
  }

  static async _list(
    route: string,
    params?: MonitoringPlatform.API.ListParams
  ): Promise<MonitoringPlatform.API.ListResult> {
    const { json } = await this.get<MonitoringPlatform.API.ListResponse>(route, params);
    // Parse response
    const monitoring_platforms = this.parsePaginationSet(
      json,
      ({ monitoring_platforms, storages, solar_generators, service_drops, sites, orgs, users }) => {
        store.dispatch(slices.data.updateModels(SolarGenerator.fromObjects(solar_generators)));
        store.dispatch(slices.data.updateModels(Storage.fromObjects(storages)));
        store.dispatch(slices.data.updateModels(ServiceDrop.fromObjects(service_drops)));
        store.dispatch(slices.data.updateModels(Site.fromObjects(sites)));
        store.dispatch(slices.data.updateModels(Org.fromObjects(orgs)));
        store.dispatch(slices.data.updateModels(User.fromObjects(users)));
        return MonitoringPlatform.fromObjects(monitoring_platforms);
      }
    );
    store.dispatch(slices.data.updateModels(monitoring_platforms.results));
    if (monitoring_platforms.next) {
      this._list(monitoring_platforms.next);
    }
    return monitoring_platforms;
  }

  static async create(
    params?: MonitoringPlatform.API.CreateParams
  ): Promise<MonitoringPlatform.API.RetrieveResult> {
    const { json } = await this.post<MonitoringPlatform.API.RetrieveResponse>(this.route, params);

    const monitoringPlatform = new MonitoringPlatform(json.monitoring_platform);
    store.dispatch(slices.data.updateModels(monitoringPlatform));
    monitoringPlatform.SolarGenerator?.addMonitoringPlatform(monitoringPlatform);
    monitoringPlatform.Storage?.addMonitoringPlatform(monitoringPlatform);
    return { monitoringPlatform };
  }

  static async update(
    id: MonitoringPlatform.Raw['id'],
    body: MonitoringPlatform.API.UpdateParams,
    params?: MonitoringPlatform.API.RetrieveParams
  ): Promise<MonitoringPlatform.API.RetrieveResult> {
    const { json } = await this.patch(this.route(id), body, params);
    const { monitoring_platform } = json;
    store.dispatch(slices.data.updateModels(MonitoringPlatform.fromObject(monitoring_platform)));
    return monitoring_platform;
  }

  static async retrieve(
    id: MonitoringPlatform.Raw['id'],
    params?: MonitoringPlatform.API.RetrieveParams
  ): Promise<MonitoringPlatform.API.RetrieveResult> {
    const { json } = await this.get<MonitoringPlatform.API.RetrieveResponse>(
      this.route(id),
      params
    );
    const { monitoring_platform, baseline_months, storages, solar_generators } = json;

    // Parse response
    const monitoringPlatform = new MonitoringPlatform(monitoring_platform);
    store.dispatch(slices.data.updateModels(BaselineMonth.fromObjects(baseline_months)));
    store.dispatch(slices.data.updateModels(Storage.fromObjects(storages)));
    store.dispatch(slices.data.updateModels(SolarGenerator.fromObjects(solar_generators)));
    store.dispatch(slices.data.updateModels(monitoringPlatform));
    return { monitoringPlatform };
  }

  static async destroy(id: MonitoringPlatform['id']): Promise<void> {
    await this.delete(this.route(id));
    const monitoringPlatform = MonitoringPlatform.fromStore(id);
    monitoringPlatform?.SolarGenerator?.removeMonitoringPlatform(id);
    monitoringPlatform?.Storage?.removeMonitoringPlatform(id);
    store.dispatch(slices.data.removeModel(MonitoringPlatform.fromStore(id)!));
  }

  static async collect_intervals(
    id: MonitoringPlatform['id']
  ): Promise<MonitoringPlatform.API.CollectIntervalsResult> {
    const { json } = await this.get<MonitoringPlatform.API.CollectIntervalsResponse>(
      this.route(id) + 'collect-intervals/'
    );
    const task = new AsyncTask<MonitoringPlatform.API.CollectIntervalsResult>(json.task_id);
    const result = await task.getResult();
    return result;
  }
}

/** ======================== Model ========================================= */
export interface MonitoringPlatform extends CommonAttrs {}
export class MonitoringPlatform {
  /** ====================== Static fields ================================= */
  static api = MonitoringPlatformAPI;
  static readonly rawFields = [
    'id',
    'identifier',
    'monitor_name',
    'yesterday',
    'past_year_performance',
    'baseline',
    'hidden',
    'solar_generator',
    'storage',
    'access_link',
    'interval_file',
    'meterx_interval_file',
  ] as const;

  /**
   * Parses an array of raw MonitoringPlatform objects by passing each in turn to MonitoringPlatform.fromObject. Note that calling
   * `raw.map(this.fromObject)` does not work, because TypeScript is unable to resolve the correct
   * overload for fromObject when it is passed to the `map` method. This is a TypeScript design
   * limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {MonitoringPlatform.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: MonitoringPlatform.Raw[]): MonitoringPlatform[];
  static fromObjects(raw: Maybe<MonitoringPlatform.Raw[]>): Maybe<MonitoringPlatform[]>;
  static fromObjects(raw: Maybe<MonitoringPlatform.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: MonitoringPlatform.Raw): MonitoringPlatform;
  static fromObject(raw: Maybe<MonitoringPlatform.Raw>): Maybe<MonitoringPlatform>;
  static fromObject(raw: Maybe<MonitoringPlatform.Raw>) {
    return raw ? new MonitoringPlatform(raw) : undefined;
  }

  static fromStore(): MonitoringPlatform[];
  static fromStore(id: Maybe<MonitoringPlatform['id']>): Maybe<MonitoringPlatform>;
  static fromStore(id?: MonitoringPlatform['id']) {
    const { monitoringPlatforms } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(monitoringPlatforms)).map(MonitoringPlatform.fromObject);
    } else {
      return id ? MonitoringPlatform.fromObject(monitoringPlatforms[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'MonitoringPlatform';
  constructor(raw: MonitoringPlatform.Raw) {
    Object.assign(this, _.pick(raw, MonitoringPlatform.rawFields));
  }

  get SolarGenerator() {
    return SolarGenerator.fromStore(this.solar_generator);
  }

  get Storage() {
    return Storage.fromStore(this.storage);
  }

  get IntervalFile() {
    return IntervalFile.fromStore(this.interval_file);
  }

  get MeterXIntervalFile() {
    return IntervalFile.fromStore(this.meterx_interval_file);
  }

  serialize(): MonitoringPlatform.Serialized {
    return {
      ..._.pick(this, MonitoringPlatform.rawFields),
      object_type: this.object_type,
    };
  }
}
