import _ from 'lodash';

import { store } from 'onescreen/store';
import { Maybe, ObjectWithoutType, ObjectWithType } from 'onescreen/types';
import { Rate } from '..';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'RegulatorRatePlan'> & {
  friendly_name: string;
  id: number;
  rates?: Array<Rate['id']>;
};

export declare namespace RegulatorRatePlan {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}
}

/** ======================== Model ========================================= */
export interface RegulatorRatePlan extends CommonAttrs {}
export class RegulatorRatePlan {
  /** ====================== Static fields ================================= */
  static readonly rawFields = ['friendly_name', 'id', 'rates'] as const;

  /**
   * Parses an array of raw RegulatorRatePlan objects by passing each in turn to
   * RegulatorRatePlan.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {RegulatorRatePlan.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: RegulatorRatePlan.Raw[]): RegulatorRatePlan[];
  static fromObjects(raw: Maybe<RegulatorRatePlan.Raw[]>): Maybe<RegulatorRatePlan[]>;
  static fromObjects(raw: Maybe<RegulatorRatePlan.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: RegulatorRatePlan.Raw): RegulatorRatePlan;
  static fromObject(raw: Maybe<RegulatorRatePlan.Raw>): Maybe<RegulatorRatePlan>;
  static fromObject(raw: Maybe<RegulatorRatePlan.Raw>) {
    return raw ? new RegulatorRatePlan(raw) : undefined;
  }

  static fromStore(): RegulatorRatePlan[];
  static fromStore(id: Maybe<RegulatorRatePlan['id']>): Maybe<RegulatorRatePlan>;
  static fromStore(id?: RegulatorRatePlan['id']) {
    const { regulatorRatePlans } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(regulatorRatePlans)).map(RegulatorRatePlan.fromObject);
    } else {
      return id ? RegulatorRatePlan.fromObject(regulatorRatePlans[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'RegulatorRatePlan';
  constructor(raw: RegulatorRatePlan.Raw) {
    Object.assign(this, _.pick(raw, RegulatorRatePlan.rawFields));
  }

  get Rates() {
    return this.rates?.map(Rate.fromStore) || [];
  }

  serialize(): RegulatorRatePlan.Serialized {
    return { ..._.pick(this, RegulatorRatePlan.rawFields), object_type: this.object_type };
  }
}
