import classNames from 'classnames';
import _ from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';
import {
  Bar,
  LineSegment,
  LineSegmentProps,
  VictoryAxis,
  VictoryBar,
  VictoryBrushContainer,
  VictoryContainer,
  VictoryLabel,
  VictoryLabelProps,
  VictoryLegend,
  VictoryLegendProps,
  VictoryStack,
  VictoryTooltip,
  VictoryTooltipProps,
  VictoryPortal,
} from 'victory';
import {
  VictoryNumberCallback,
  VictoryStringCallback,
  VictoryStringOrNumberCallback,
} from 'victory-core';

import {
  Chart,
  Box,
  Dialog,
  Grid,
  Button,
  CommonDialogProps,
  Typography,
} from 'onescreen/components';
import { DateRange, DateRanges } from 'onescreen/models';
import {
  Color,
  makeStylesHook,
  MaterialColor,
  materialColorNames,
  materialColors,
} from 'onescreen/styles';
import { Maybe, Tuple } from 'onescreen/types';
import { formatters } from 'onescreen/utils';
import { useDialogState } from '../../hooks';
import { TextField } from '../TextField';

/** ======================== Types ========================================= */
export type ColorInfo = { color: MaterialColor; name: string; shade: keyof Color };
type ColorFunction<T> = (range: T) => ColorInfo;
type CommonStackProps<T extends DateRange> = {
  colorFn: ColorFunction<T>;
  data: T[];
  selected?: T;
  bounds?: Tuple<DateTime>;
  showLabels?: boolean;
  legendData?: VictoryLegendProps['data'];
  showToolTips?: boolean;
};

type PeriodStackProps<T extends DateRange> = CommonStackProps<T> & {
  setBounds: React.Dispatch<React.SetStateAction<Maybe<Tuple<DateTime>>>>;
  editingBounds: boolean;
  editingPeriods: boolean;
  editPeriod: (obj: T, startDate: DateTime, endDate: DateTime) => void;
  createPeriod: (startDate: DateTime, endDate: DateTime) => void;
  deletePeriod: (obj: T) => void;
  onClick?: (range: T) => void;
};

type BarStackProps<T extends DateRange> = CommonStackProps<T> & {
  onClick?: () => void;
  hoverAll?: boolean;
};

type PeriodDialogProps = CommonDialogProps & {
  title: string;
  startDate: DateTime;
  endDate: DateTime;
  setStartDate: React.Dispatch<React.SetStateAction<DateTime>>;
  setEndDate: React.Dispatch<React.SetStateAction<DateTime>>;
  submit: () => void;
  onDelete?: () => void;
};

type RangeDatum = {
  color: ColorInfo;
  label: string;
  range: DateRange;
  x: number;
  y: Date;
  y0: Date;
};

type VictoryCallbackArg<T> = { datum?: T };
type CustomLabelProps = VictoryLabelProps & {
  showLabels: boolean;
  showToolTip: boolean;
  rangeDatum: RangeDatum;
};
type CustomTickProps = LineSegmentProps & { dx: VictoryNumberCallback };
type Coordinate = { x: number; y: number };

/** ======================== Styles ======================================== */
const gridColor = materialColors.blue[100];
const chartStyles = _.iife(() => {
  const barWidth = 30;
  const height = 60;
  const tickShift = 0.5;
  return {
    axis: {
      axis: { strokeWidth: 0 },
      grid: { stroke: gridColor, strokeWidth: 4, strokeDashArray: '1' },
      ticks: { stroke: gridColor },
    },
    axisLabel: {
      inwardShift: 5,
      downShift: 10,
    },
    barStyle: {
      data: {
        stroke: '#000000',
        strokeWidth: 0,
      },
    },
    tickShift,
    barWidth,
    height,
    labelShift: -5,
    padding: { left: tickShift, right: tickShift, top: height - barWidth },
  };
});

// Creates the styling for the bar colors. The '!important' is required to override the default fill
// that Victory provides. While it's possible to tell Victory what colors you want the bars to be
// (using the `colorScale` prop) it doesn't appear to be possible to instruct Victory **not to
// assign** any color at all, which would be ideal for using a pure CSS solution.
const fill = (color: string) => ({ fill: `${color} !important` });
const makeBarStyles = (fillColor: MaterialColor, textColor?: string) => {
  const baseColor = materialColors[fillColor];
  return {
    [`&.${fillColor}`]: Object.assign(fill(baseColor.A100), {
      '&.selected': fill(baseColor.A200),
      '&:hover': fill(baseColor.A200),
      '& + text > tspan': textColor && fill(textColor),
    }),
    [`&.${fillColor}-hover`]: fill(baseColor.A200),
  };
};

const barStyles = materialColorNames.reduce(
  (styles, colorName) => ({
    ...styles,
    ...makeBarStyles(colorName),
  }),
  {}
);

const useStyles = makeStylesHook<BarStackProps<any> | PeriodStackProps<any>>(() => {
  return {
    chart: (props) => ({
      '& .bar': { ...barStyles, cursor: props.onClick ? 'pointer' : 'default' },
      '& .clickable': { cursor: props.onClick ? 'pointer' : 'default' },
    }),
  };
}, 'BarStack');

/** ======================== Components ==================================== */
class CustomTooltip extends VictoryTooltip {
  /**
   * Overrides the VictoryTooltip.constrainTooltip method, which is intended to constrain tooltips
   * to the visible area of the chart. With the BarStack, we want the tooltip not to overflow
   * the chart to the left or right, but overflows above and below are okay. This method will
   * delegate to the parent class to manage lateral constraints, but override its vertical
   * constraints.
   *
   * @param {Coordinate} center: the previously calculated position of the tooltip's center
   * @param {VictoryTooltipProps} props: component props
   * @param {any[]} args: array of all other arguments
   */
  constrainTooltip(center: Coordinate, props: VictoryTooltipProps, ...args: any[]) {
    // @ts-ignore-next-line: the component type does not document its class methods
    const newCenter = super.constrainTooltip(
      { x: this.getCenterX(props), y: center.y },
      props,
      ...args
    );

    return { ...newCenter, y: center.y };
  }

  /**
   * Produces props for the tooltip's flyoutComponent. This is necessary position the tooltip's
   * arrow correctly (as opposed to its body, which is handled in constrainTooltip)
   *
   * @param {VictoryTooltipProps} props: component props
   * @param {any[]} args: array of all other arguments
   */
  getFlyoutProps(props: VictoryTooltipProps, ...args: any[]) {
    const midpoint = (props.datum as RangeDatum).range.midpoint;

    // @ts-ignore-next-line: the `scale` prop isn't provided by the user but is passed to the
    // Tooltip component by its parent
    const centerX = props.scale.y(midpoint.toJSDate());

    // @ts-ignore-next-line: again, the component type does not document its class methods
    return super.getFlyoutProps({ ...props, x: centerX }, ...args);
  }

  /**
   * Finds the center x-coordinate of the bar
   */
  getCenterX(props: VictoryTooltipProps) {
    const midpoint = (props.datum as RangeDatum).range.midpoint;

    // @ts-ignore: the `scale` prop isn't provided by the user
    return props.scale.y(midpoint.toJSDate());
  }
}

/**
 * Renders both a label and a tooltip for each bar in the chart
 */
const CustomLabel = Object.assign(
  function CustomLabel({ showLabels, showToolTip, rangeDatum, ...props }: CustomLabelProps) {
    const style = { ...props.style, pointerEvents: 'none' };
    const tooltipProps = _.omit(props, 'angle', 'dx', 'dy', 'events', 'text');
    return (
      <g>
        {showToolTip && (
          <VictoryPortal>
            <CustomTooltip
              {...tooltipProps}
              text={`${rangeDatum.color.name}\n${rangeDatum.range.formatDateRange(
                formatters.date.monthDayYear
              )}`}
              constrainToVisibleArea
              dy={-chartStyles.barWidth / 3}
              orientation="top"
              renderInPortal={false}
            />
          </VictoryPortal>
        )}
        {showLabels && (
          <VictoryLabel {...props} dx={chartStyles.labelShift} style={style} textAnchor="end" />
        )}
      </g>
    );
  },
  { defaultEvents: VictoryTooltip.defaultEvents }
);

/**
 * Shifts the axis ticks by a small amount so they aren't obscured by the first/last bars
 */
const CustomTick: React.FC<CustomTickProps> = (props) => {
  const { datum, dx, x1, x2 } = props;
  const shift = dx({ datum, index: 0 });
  return (
    <LineSegment
      {...props}
      x1={_.isUndefined(x1) ? x1 : x1 + shift}
      x2={_.isUndefined(x2) ? x2 : x2 + shift}
    />
  );
};

function BarStackWithData<T extends DateRange>(props: BarStackProps<T>) {
  const {
    colorFn,
    data,
    onClick,
    bounds,
    legendData,
    showLabels = false,
    hoverAll = false,
    showToolTips = true,
  } = props;
  const classes = useStyles(props);
  const [hovered, setHovered] = React.useState(false);

  // Sorts the date ranges
  const ranges = React.useMemo(() => new DateRangesWrapper(data), [data]);

  const dataDomain = ranges.domain.dateRange;
  const start = bounds ? _.min([dataDomain[0], bounds[0]]) : dataDomain[0];
  const end = bounds ? _.max([dataDomain[1], bounds[1]]) : dataDomain[1];
  let domain = start && end ? [start, end] : dataDomain;
  domain = [_.min([domain[0], dataDomain[0]])!, _.max([domain[1], dataDomain[1]])!];
  const dateDomain = _.invokeMap(domain, 'toJSDate') as Tuple<Date>;
  const shiftedTick = <CustomTick dx={axisTickFn(-chartStyles.tickShift, chartStyles.tickShift)} />;

  const getColor = (color: MaterialColor, shade: ColorInfo['shade'], hover: boolean): string => {
    if (hover) {
      const keys = Object.keys(materialColors[color]);
      const index = keys.findIndex((key) => key === shade.toString());
      if (index + 1 <= keys.length - 1)
        return materialColors[color][keys[index + 1] as keyof Color];
    }
    return materialColors[color][shade];
  };

  return (
    <Chart
      className={classes.chart}
      domain={{ y: dateDomain }}
      height={chartStyles.height}
      padding={chartStyles.padding}
      scale={{ y: 'time' }}
    >
      {legendData && (
        <VictoryLegend x={250} data={legendData} style={{ data: { stroke: 'black' } }} />
      )}
      <VictoryStack horizontal>
        {ranges.formatData(colorFn).map((datum, i) => (
          <VictoryBar
            barWidth={chartStyles.barWidth}
            data={[datum]}
            style={{
              data: {
                fill: getColor(datum.color.color, datum.color.shade, hoverAll && hovered),
                strokeWidth: 0,
              },
            }}
            dataComponent={<Bar className={classNames('clickable')} />}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onClick: () => {
                    if (onClick) onClick();
                    return [];
                  },
                  onMouseOver: () => {
                    if (hoverAll) {
                      setHovered(true);
                      return [];
                    }
                    return [
                      {
                        target: 'data',
                        mutation: () => ({
                          style: { fill: getColor(datum.color.color, datum.color.shade, true) },
                        }),
                      },
                      {
                        target: 'labels',
                        mutation: () => ({ active: true }),
                      },
                    ];
                  },
                  onMouseOut: () => {
                    if (hoverAll) {
                      setHovered(false);
                      return [];
                    }
                    return [
                      {
                        target: 'data',
                        mutation: () => ({
                          style: { fill: getColor(datum.color.color, datum.color.shade, false) },
                        }),
                      },
                      {
                        target: 'labels',
                        mutation: () => ({ active: undefined }),
                      },
                    ];
                  },
                },
              },
            ]}
            key={i}
            labelComponent={
              <CustomLabel rangeDatum={datum} showToolTip={showToolTips} showLabels={showLabels} />
            }
          />
        ))}
      </VictoryStack>
      <VictoryAxis
        dependentAxis
        gridComponent={shiftedTick}
        orientation="top"
        style={chartStyles.axis}
        tickComponent={shiftedTick}
        tickFormat={(tick: typeof domain[0]) => formatters.date.monthDayYear(tick)}
        tickValues={bounds ? bounds : dataDomain}
        tickLabelComponent={
          <VictoryLabel
            dx={axisTickFn(chartStyles.axisLabel.inwardShift, -chartStyles.axisLabel.inwardShift)}
            dy={chartStyles.axisLabel.downShift}
            // @ts-ignore: Victory's own types don't specify that there's a function argument
            textAnchor={axisTickFn('start', 'end')}
          />
        }
      />
    </Chart>
  );

  /** ====================== Helpers ======================================= */
  /**
   * Creates and returns a function for use with the axis's tickLabelComponent. If the tick is the
   * one on the left, returns leftVal; otherwise returns rightVal.
   *
   * @param {any} leftVal: the value to assign for the left (start) tick
   * @param {any} rightVal: the value to assign for the right (end) tick
   */
  function axisTickFn(leftVal: string, rightVal: string): VictoryStringCallback;
  function axisTickFn(leftVal: number, rightVal: number): VictoryNumberCallback;
  function axisTickFn(leftVal: any, rightVal: any): VictoryStringOrNumberCallback {
    return ({ datum }: VictoryCallbackArg<DateTime>) =>
      datum ? (domain[0] > datum.minus({ days: 35 }) ? leftVal : rightVal) : undefined;
  }
}

class DateRangesWrapper<T extends DateRange> extends DateRanges<T> {
  /**
   * Formats the data into a form Victory can render. The x value is totally arbitrary. The y value
   * represents the span of time from the start date to end date; it is converted into a Date
   * because this is the only way Victory can figure out how to handle the stacked offset. The
   * y0 value is the range's start date.
   */
  formatData(colorFn: ColorFunction<T>): Array<RangeDatum> {
    return this.ranges.map((range) => ({
      color: colorFn(range),
      label: range.formatDateRange(formatters.date.monthDayNumbers),
      range: range,
      x: 1,
      y: new Date(+range.duration),
      y0: range.start_date.toJSDate(),
    }));
  }
}

function PeriodDialog(props: PeriodDialogProps) {
  const {
    open,
    onClose,
    title,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    submit,
    onDelete,
  } = props;
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <Dialog.Title>{title}</Dialog.Title>
      <Dialog.Content>
        <Grid>
          <Grid.Item span={6}>
            <TextField
              type="date"
              label="Start Date"
              value={startDate?.toISODate()}
              onChange={(val) => setStartDate(DateTime.fromISO(val))}
            />
          </Grid.Item>
          <Grid.Item span={6}>
            <TextField
              type="date"
              label="End Date"
              value={endDate?.toISODate()}
              onChange={(val) => setEndDate(DateTime.fromISO(val))}
            />
          </Grid.Item>
          {onDelete && (
            <Grid.Item>
              <Button icon="trash" onClick={onDelete}>
                Delete
              </Button>
            </Grid.Item>
          )}
        </Grid>
      </Dialog.Content>
      <Dialog.Actions>
        <Button color="secondary" _variant="text" onClick={submit}>
          Submit
        </Button>
        <Button color="primary" _variant="text" onClick={onClose}>
          Cancel
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}

function PeriodStackWithData<T extends DateRange>(props: PeriodStackProps<T>) {
  const {
    colorFn,
    data,
    onClick,
    selected,
    bounds,
    setBounds,
    editingBounds,
    editingPeriods,
    editPeriod,
    createPeriod,
    deletePeriod,
    legendData,
    showLabels = true,
    showToolTips = true,
  } = props;
  const classes = useStyles(props);
  const [editDialogOpen, openEditDialog, closeEditDialog] = useDialogState();
  const [createDialogOpen, openCreateDialog, closeCreateDialog] = useDialogState();

  // Sorts the date ranges
  let ranges = React.useMemo(() => new DateRangesWrapper<T>(data), [data]);

  // Get the selected range from the data. The PeriodSelector receives its `selected` period as a
  // prop and then gets the periods for the BarStack anew from the ServiceAnalysis object; in that
  // process object identity is lost between the selected period and the period array, so comparing
  // `datum.range === selected` will fail.
  const selectedFromData = React.useMemo(() => ranges.find(selected), [ranges, selected]);
  const [startDate, setStartDate] = React.useState(DateTime.fromJSDate(new Date()));
  const [endDate, setEndDate] = React.useState(DateTime.fromJSDate(new Date()));

  React.useEffect(() => {
    if (selectedFromData) {
      setStartDate(selectedFromData.start_date);
      setEndDate(selectedFromData.end_date);
    }
  }, [selectedFromData, setStartDate, setEndDate]);

  const dataDomain = ranges.ranges.length ? ranges.domain.dateRange : bounds!;
  const start = bounds ? _.min([dataDomain[0], bounds[0]]) : dataDomain[0];
  const end = bounds ? _.max([dataDomain[1], bounds[1]]) : dataDomain[1];
  let domain = start && end ? [start, end] : dataDomain;
  domain = [_.min([domain[0], dataDomain[0]])!, _.max([domain[1], dataDomain[1]])!];
  const dateDomain = _.invokeMap(domain, 'toJSDate') as Tuple<Date>;
  const shiftedTick = <CustomTick dx={axisTickFn(-chartStyles.tickShift, chartStyles.tickShift)} />;

  const container = editingBounds ? (
    <VictoryBrushContainer
      brushDimension="y"
      allowResize={true}
      allowDrag={false}
      brushDomain={{ y: [bounds![0].toJSDate(), bounds![1].toJSDate()] }}
      brushStyle={{ fill: gridColor, fillOpacity: 0.7 }}
      onBrushDomainChangeEnd={(evt) => {
        setBounds([DateTime.fromJSDate(evt.y[0] as Date), DateTime.fromJSDate(evt.y[1] as Date)]);
      }}
    />
  ) : (
    <VictoryContainer />
  );

  return (
    <Box padding={2}>
      {editingPeriods && (
        <Box paddingBottom={2}>
          <Grid justify="space-around">
            <Grid.Item>
              <Typography>Select a period to edit, or </Typography>
              <Button onClick={openCreateDialog} size="small" color="primary" icon="plus">
                Add new period
              </Button>
            </Grid.Item>
          </Grid>
        </Box>
      )}
      <Chart
        className={classes.chart}
        domain={{ y: dateDomain }}
        height={chartStyles.height}
        padding={chartStyles.padding}
        scale={{ y: 'time' }}
        containerComponent={container}
      >
        {legendData && <VictoryLegend x={250} data={legendData} />}
        <VictoryStack horizontal>
          {ranges.formatData(colorFn).map((datum, i) => (
            <VictoryBar
              barWidth={chartStyles.barWidth}
              style={chartStyles.barStyle}
              data={[datum]}
              dataComponent={
                <Bar
                  className={classNames('bar', datum.color.color, {
                    selected: datum.range === selectedFromData,
                  })}
                />
              }
              events={[
                {
                  target: 'data',
                  eventHandlers: {
                    onClick: () => {
                      if (onClick) onClick(datum.range as T);
                      if (editingPeriods) openEditDialog();
                      return [];
                    },
                  },
                },
              ]}
              key={i}
              labelComponent={
                <CustomLabel
                  rangeDatum={datum}
                  showToolTip={showToolTips}
                  showLabels={showLabels}
                />
              }
            />
          ))}
        </VictoryStack>
        <VictoryAxis
          dependentAxis
          orientation="top"
          gridComponent={shiftedTick}
          style={chartStyles.axis}
          tickFormat={(tick: typeof domain[0]) => formatters.date.standard(tick)}
          tickValues={bounds ? bounds : dataDomain}
          tickComponent={shiftedTick}
          tickLabelComponent={
            <VictoryLabel
              dx={axisTickFn(chartStyles.axisLabel.inwardShift, -chartStyles.axisLabel.inwardShift)}
              dy={chartStyles.axisLabel.downShift}
              // @ts-ignore: Victory's own types don't specify that there's a function argument
              textAnchor={axisTickFn('start', 'end')}
            />
          }
        />
      </Chart>
      <PeriodDialog
        open={editDialogOpen}
        onClose={closeEditDialog}
        title="Modify Period"
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        submit={submitPeriodEdit}
        onDelete={onDeletePeriod}
      />
      <PeriodDialog
        open={createDialogOpen}
        onClose={closeCreateDialog}
        title="Create New Period"
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        submit={submitPeriodCreate}
      />
    </Box>
  );

  /** ====================== Helpers ======================================= */
  /**
   * Creates and returns a function for use with the axis's tickLabelComponent. If the tick is the
   * one on the left, returns leftVal; otherwise returns rightVal.
   *
   * @param {any} leftVal: the value to assign for the left (start) tick
   * @param {any} rightVal: the value to assign for the right (end) tick
   */
  function axisTickFn(leftVal: string, rightVal: string): VictoryStringCallback;
  function axisTickFn(leftVal: number, rightVal: number): VictoryNumberCallback;
  function axisTickFn(leftVal: any, rightVal: any): VictoryStringOrNumberCallback {
    return ({ datum }: VictoryCallbackArg<DateTime>) =>
      datum ? (domain[0] > datum.minus({ days: 35 }) ? leftVal : rightVal) : undefined;
  }
  function submitPeriodEdit() {
    editPeriod(selectedFromData!, startDate, endDate);
    closeEditDialog();
  }
  function submitPeriodCreate() {
    createPeriod(startDate, endDate);
    closeCreateDialog();
  }
  function onDeletePeriod() {
    deletePeriod(selectedFromData!);
    closeEditDialog();
  }
}

/**
 * Wraps BarStackWithData and guarantees that the data array isn't empty
 */
export function BarStack<T extends DateRange>(props: BarStackProps<T>) {
  const { data } = props;
  if (data.length === 0) return null;
  return <BarStackWithData {...props} />;
}

/**
 * Wraps PeriodStackWithData and guarantees that the data array isn't empty
 */
export function PeriodStack<T extends DateRange>(props: PeriodStackProps<T>) {
  return <PeriodStackWithData {...props} />;
}
