import _ from 'lodash';
import { useState } from 'react';

import { User } from 'onescreen/models';
import { Loader, Maybe } from 'onescreen/types';

import { useAsync } from './useAsync';

var initUser: Maybe<User>;
let getUser = (): Maybe<User> => {
  throw new Error('you must useLoggedInUser before setting its state');
};

/**
 * Retrieves the current logged-in user.
 */
export function useUser(): Loader<User, 'user'> {
  const [user, setUser] = useState(initUser);

  getUser = () => {
    return user;
  };
  const loading = useAsync(
    async () => {
      if (User.isAuthenticated() && !user) return User.api.retrieve();
    },
    [User.isAuthenticated()],
    (response: User.API.RetrieveResult) => {
      setUser(response.user);
    }
  );
  return {
    loading,
    user: user,
  };
}

/**
 * Retrieves a user and returns whether the user has the required permission
 * @param {User.groups} required list of permission groups
 */
export function HasUserPermissions(required: User['groups']): boolean {
  const user = getUser();
  return _.intersection(user?.groups || [], required).length > 0;
}
