import classNames from 'classnames';
import _ from 'lodash';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { zip } from 'onescreen/data';
import { formatters } from 'onescreen/utils';
import { TextField } from './TextField';
import { Button } from './Button';
import { Typography } from '@material-ui/core';
import { Grid } from './Grid';
import { DateTime } from 'luxon';
import { SetURLSearchParams, useSearchParams } from 'react-router-dom';

/** ======================== Types ========================================= */
type StringKeyOf<T> = Extract<keyof T, string>;
type SortDir = 'ASC' | 'DESC';
type SortElement<T> = { key: StringKeyOf<T>; dir: SortDir };
type Filters<T> = Partial<{ [k in StringKeyOf<T> | string]: string }>;
export type ColumnConfig<T> = {
  header: string;
  key: StringKeyOf<T> | string;
  noFilter?: boolean;
  render?: (datum: T) => React.ReactNode;
  value?: (datum: T) => any;
  sortValue?: (datum: T) => any;
  width?: string | number;
  optionalHeaderElement?: ReactElement;
};

type ColumnSettingsProps<T> = { column: ColumnConfig<T> };
type ColumnGroupSettingsProps<T> = { columns: ColumnConfig<T>[] };
type SortFilterHeaderCellProps<T> = {
  column: ColumnConfig<T>;
  sorting: Array<SortElement<T>>;
  filters: Filters<T>;
  onClickSortHeading: (evt: React.MouseEvent) => void;
  setFilters: (newFilters: Filters<T>) => any;
};

type SortFilterHeaderProps<T> = {
  columns: ColumnConfig<T>[];
  setFilters: (newFilters: Filters<T>) => any;
  setSorting: (newSorting: React.SetStateAction<Array<SortElement<T>>>) => any;
  filters: Filters<T>;
  sorting: Array<SortElement<T>>;
  setSearchParams?: SetURLSearchParams;
};

export type SortingTableProps<T> = {
  columns: ColumnConfig<T>[];
  data: T[];
  loading?: boolean;
  total: number;
  name: string;
  downloadable?: boolean;
  allowSearchParams?: boolean;
};

/** ======================== Components ==================================== */
function ColumnSettings<T>({ column }: ColumnSettingsProps<T>) {
  return column.width ? <col key={column.key} width={column.width} /> : <col key={column.key} />;
}

function ColumnGroupSettings<T>({ columns }: ColumnGroupSettingsProps<T>) {
  let htmlColSettings = [];
  for (let column of columns) {
    htmlColSettings.push(<ColumnSettings key={column.key} column={column} />);
  }
  return <colgroup>{htmlColSettings}</colgroup>;
}

function SortFilterHeaderCell<T>(props: SortFilterHeaderCellProps<T>) {
  const { column, sorting, filters, onClickSortHeading, setFilters } = props;
  // Get sort icon
  const icon = _.iife(() => {
    const sortCol = _.find(sorting, ['key', column.key]);
    if (!sortCol) return null;

    const index = sorting.indexOf(sortCol);
    const iconType = sortCol.dir === 'ASC' ? 'arrow_drop_up' : 'arrow_drop_down';
    const className = classNames('material-icons', {
      'grey-text': [1, 2].includes(index),
      'text-lighten-2': index === 2,
    });

    return <i className={className}>{iconType}</i>;
  });

  return (
    <th key={column.key} style={{ verticalAlign: 'bottom' }}>
      <div>
        {!column.noFilter ? (
          <TextField
            icon="filterList"
            className="fit-content"
            placeholder="Filter"
            value={filters[column.key] || ''}
            onChange={updateFilter}
            InputProps={
              column.key in filters
                ? {
                    endAdornment: (
                      <TextField.Adornment position="end">
                        <Button
                          size="small"
                          _variant="text"
                          icon="close"
                          onClick={removeFilter}
                        ></Button>
                      </TextField.Adornment>
                    ),
                  }
                : {}
            }
          />
        ) : column.optionalHeaderElement ? (
          column.optionalHeaderElement
        ) : (
          ''
        )}
        <Typography variant="button">
          <a
            href="#!"
            id={column.key}
            className="black-text valign-wrapper"
            onClick={onClickSortHeading}
          >
            {column.header}
            {icon}
          </a>
        </Typography>
      </div>
    </th>
  );

  /** ====================== Callbacks ===================================== */
  function removeFilter() {
    // _.omit(filters, column.key) should work here, but the type generics complicate things...
    const goodKeys = _.without(_.keys(filters), column.key);
    setFilters(_.pick(filters, goodKeys));
  }

  function updateFilter(value: string) {
    if (value.length === 0) removeFilter();
    else setFilters({ ...filters, [column.key]: value });
  }
}

function SortFilterHeader<T>({
  columns,
  setFilters,
  setSorting,
  sorting,
  filters,
  setSearchParams,
}: SortFilterHeaderProps<T>) {
  return (
    <thead>
      <tr>
        {columns.map((column) => (
          <SortFilterHeaderCell
            key={column.key}
            column={column}
            sorting={sorting}
            filters={filters}
            setFilters={updateFilters}
            onClickSortHeading={onClickSortHeading}
          />
        ))}
      </tr>
    </thead>
  );

  /** ====================== Callbacks ===================================== */
  function onClickSortHeading(event: React.MouseEvent) {
    const key = event.currentTarget.id as StringKeyOf<T>;
    let sortElements: Array<SortElement<T>> = [];

    // Calculate new sorting array
    // This is being computed outside the hook callback
    // so that there are no dependency issues on the
    // usage of sortElements inside later hooks(like setSearchParams).
    sortElements = _.iife(
      (): Array<SortElement<T>> => {
        let sortKeyIndex = sorting.findIndex((value, index, _) => value.key === key);
        if (!sorting.length || sortKeyIndex === -1) {
          return [{ key, dir: 'ASC' }, ...sorting.slice(0, 2)];
        } else {
          // Remove the column from sorting and move it to
          // front of the array if the sort direction for
          // that column is not already 'DESC'. This tri-state
          // logic for the sort column allows for the removal
          // of a sort column that was previously selected.
          let newSortElements = [
            ...sorting.slice(0, sortKeyIndex),
            ...sorting.slice(sortKeyIndex + 1, 3),
          ];
          if (sorting[sortKeyIndex].dir !== 'DESC') {
            newSortElements = [{ key, dir: 'DESC' }, ...newSortElements];
          }
          return newSortElements;
        }
      }
    );

    setSorting(sortElements);

    setSearchParams?.(
      (params) => {
        // Update sort params in the URL.
        let sortParams: string[] = [];
        sortElements.forEach((value, index, _) => {
          // DESC is displayed with a '-' prefix in the URL.
          const dir = value.dir === 'DESC' ? '-' : '';
          sortParams.push(`${dir}${value.key}`);
        });

        if (!sortParams.length) {
          // All the sort columns got removed.
          params.delete('sort');
        } else {
          params.set('sort', sortParams.join(','));
        }
        return params;
      },
      { replace: true }
    );
  }

  /**
   * Called when the filters change
   */
  function updateFilters(newFilters: Filters<T>) {
    setFilters(newFilters);
    // Update the filters part in the URL.
    setSearchParams?.(
      (params) => {
        // Add the new filters to the search params in the URL.
        Object.entries(newFilters).map(([key, val]) => params.set(key, val!));
        let newFilterKeys = new Set(Object.keys(newFilters));
        [...params.keys()].forEach((key, index, _) => {
          // Remove any deleted filters from the URL.
          // Avoid the 'sort' param, as it is not tracked
          // by the filter state.
          if (key !== 'sort' && !newFilterKeys.has(key)) {
            params.delete(key);
          }
        });
        return params;
      },
      { replace: true }
    );
  }
}

export function SortingTable<T extends { id: any }>(props: SortingTableProps<T>) {
  const { columns } = props;
  const [data, setData] = useState(props.data);
  // Load up the search params from the URL and
  // set the filters and sorting related state from that.
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<Filters<T>>(
    props.allowSearchParams ? getFilterParams<T>(searchParams) : {}
  );
  const [sorting, setSorting] = useState<Array<SortElement<T>>>(
    props.allowSearchParams ? getSortParams<T>(searchParams) : []
  );

  const getFilteredSortedData = useCallback(async (): Promise<T[]> => {
    // Filter the Data
    let filteredData = props.data;
    if (Object.keys(filters).length) {
      const keys = Object.keys(_.truthy(filters)) as StringKeyOf<T>[];
      filteredData = keys.reduce((data, key) => {
        // The column should always be present in `columns`, but for the sake of type-checking we
        // include the if statement
        const column = _.find(columns, ['key', key]);
        if (!column) return data;

        return data.filter((datum) => {
          const value = column.value ? column.value(datum) : datum[key];
          return value.toLowerCase().includes(filters[key]?.toLowerCase());
        });
      }, props.data);
    }
    let sortedData = filteredData;

    if (sorting.length) {
      // Sort the data
      sortedData = [...filteredData].sort((a, b) => {
        const recurseSort = (sidx: number): -1 | 0 | 1 => {
          if (sidx >= sorting.length) return 0;
          const sortingKey = sorting[sidx].key;
          const sortingDir = sorting[sidx].dir;
          let aValue = a[sortingKey];
          let bValue = b[sortingKey];
          const column = zip(columns, null, 'key')[sortingKey];
          if (column.sortValue) {
            aValue = column.sortValue(a);
            bValue = column.sortValue(b);
          } else if (column.value) {
            aValue = column.value(a);
            bValue = column.value(b);
          }
          if (aValue > bValue) {
            return sortingDir === 'ASC' ? 1 : -1;
          } else if (aValue < bValue) {
            return sortingDir === 'ASC' ? -1 : 1;
          } else {
            return recurseSort(sidx + 1);
          }
        };
        return recurseSort(0);
      });
    }
    return sortedData;
  }, [columns, filters, sorting, props.data]);

  useEffect(() => {
    let isMounted = true;
    getFilteredSortedData().then((values) => {
      // Invoke the hook only if this component
      // is still mounted. With the persistent
      // filter & sort, this check is needed to
      // avoid running into exception.
      if (isMounted) setData(values);
    });
    return () => {
      isMounted = false;
    };
  }, [getFilteredSortedData, setData]);

  const rows = Object.values(data).map((datum) => (
    <tr key={datum.id}>
      {props.columns.map((column) => {
        let val: React.ReactNode;
        if (column.render) {
          val = column.render(datum);
        } else if (column.value) {
          val = column.value(datum);
        } else {
          val = datum[column.key as StringKeyOf<T>];
        }
        return <td key={column.key}>{val}</td>;
      })}
    </tr>
  ));

  const downloadFile = ({
    data,
    fileName,
    fileType,
  }: {
    data: string;
    fileName: string;
    fileType: string;
  }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    // Headers for each column
    let header = columns
      .map((column) => {
        return column.header;
      })
      .join(',');

    // Create body from data
    let body = data.reduce((acc: string[], datum) => {
      acc.push(
        columns
          .map((column) => {
            const key = column.key as StringKeyOf<T>;
            return column.value ? column.value(datum) : datum[key];
          })
          .join(',')
      );
      return acc;
    }, []);

    downloadFile({
      data: [header, ...body].join('\n'),
      fileName: `${props.name}-${DateTime.fromMillis(Date.now()).toFormat('yyyyMMddHHmm')}.csv`,
      fileType: 'text/csv',
    });
  };

  return (
    <div>
      {props.downloadable ? (
        <Grid justify="center">
          <Button color="secondary" onClick={exportToCsv} icon="download">
            Download
          </Button>
        </Grid>
      ) : null}
      <span className="grey-text right">
        Showing {data.length} / {props.total ? props.total : props.data.length} Items
      </span>
      <table>
        <ColumnGroupSettings columns={columns} />
        <SortFilterHeader
          columns={columns}
          setFilters={setFilters}
          setSorting={setSorting}
          sorting={sorting}
          filters={filters}
          setSearchParams={props.allowSearchParams ? setSearchParams : undefined}
        />
        <tbody>
          {props.data.length < props.total ? (
            <tr>
              <td className="center" colSpan={columns.length}>
                <div className="progress">
                  <div
                    className="determinate"
                    style={{
                      width: formatters.percentage(props.data.length, props.total),
                    }}
                  />
                </div>
              </td>
            </tr>
          ) : null}
          {rows.length || props.loading ? (
            rows
          ) : (
            <tr>
              <td className="center" colSpan={columns.length}>
                No Items
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  function getSortParams<T>(searchParams: URLSearchParams): Array<SortElement<T>> {
    let sortParams: Array<SortElement<T>> = [];
    let sortListStr = searchParams.get('sort');

    sortListStr?.split(',').forEach((value, index, _) => {
      var sortDir: SortDir = 'ASC';
      // Columns to be sorted desc are
      // prefixed with a '-';
      if (value[0] === '-') {
        sortDir = 'DESC';
        value = value.slice(1);
      }
      sortParams.push({ key: value as StringKeyOf<T>, dir: sortDir });
    });
    return sortParams;
  }

  function getFilterParams<T>(searchParams: URLSearchParams): Filters<T> {
    let filterParams: Filters<T> = {};
    let paramMap = new Map(searchParams);

    paramMap.forEach((value, key, _) => {
      // Column filters are tracked by params other than
      // the 'sort' param.
      if (key !== 'sort') {
        filterParams = { ...filterParams, [key]: value };
      }
    });
    return filterParams;
  }

  /** ====================== Callbacks ===================================== */
}
