import _ from 'lodash';

import { slices, store } from 'onescreen/store';
import { Maybe, ObjectWithoutType, ObjectWithType } from 'onescreen/types';
import { BaseAPI } from '../base';

import { ServiceSimulation } from './service_simulation';
import { DateRange } from '../dates';
import { ServiceAnalysis } from './service_analysis';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'ServicePeriod'> & {
  id: number;
  exclude: boolean;
  service_analysis: ServiceAnalysis['id'];
  service_simulations: Array<ServiceSimulation['id']>;
};

export declare namespace ServicePeriod {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs, DateRange.Raw {}

  export namespace API {
    export type UpdateParams = Pick<Raw, 'start_date' | 'end_date'>;
    export type UpdateResponse = { service_period: ServicePeriod.Raw };
    export type UpdateResult = { servicePeriod: ServicePeriod };

    export type CreateParams = UpdateParams & Pick<Raw, 'service_analysis'>;
    export type CreateResponse = UpdateResponse;
    export type CreateResult = UpdateResult;
  }
}

/** ======================== API =========================================== */
class ServicePeriodAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.servicePeriod;

  static async create(
    params: ServicePeriod.API.CreateParams
  ): Promise<ServicePeriod.API.CreateResult> {
    const { json } = await this.post<ServicePeriod.API.CreateResponse>(this.route, params);

    // Parse response and add to store
    const servicePeriod = new ServicePeriod(json.service_period);
    store.dispatch(slices.data.updateModels(servicePeriod));

    return { servicePeriod };
  }

  static async update(
    id: ServicePeriod['id'],
    params: ServicePeriod.API.UpdateParams
  ): Promise<ServicePeriod.API.UpdateResult> {
    const { json } = await this.patch<ServicePeriod.API.UpdateResponse>(this.route(id), params);

    // Parse response and add to store
    const servicePeriod = new ServicePeriod(json.service_period);
    store.dispatch(slices.data.updateModels(servicePeriod));

    return { servicePeriod };
  }

  static async destroy(id: ServicePeriod['id']): Promise<void> {
    await this.delete(this.route(id));
    store.dispatch(slices.data.removeModel(ServicePeriod.fromStore(id)!));
  }
}

/** ======================== Model ========================================= */
export interface ServicePeriod extends CommonAttrs {}
export class ServicePeriod extends DateRange {
  /** ====================== Static fields ================================= */
  static api = ServicePeriodAPI;
  static readonly rawFields = ['exclude', 'id', 'service_analysis', 'service_simulations'] as const;

  /**
   * Parses an array of raw ServicePeriod objects by passing each in turn to
   * ServicePeriod.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {ServicePeriod.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: ServicePeriod.Raw[]): ServicePeriod[];
  static fromObjects(raw: Maybe<ServicePeriod.Raw[]>): Maybe<ServicePeriod[]>;
  static fromObjects(raw: Maybe<ServicePeriod.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: ServicePeriod.Raw): ServicePeriod;
  static fromObject(raw: Maybe<ServicePeriod.Raw>): Maybe<ServicePeriod>;
  static fromObject(raw: Maybe<ServicePeriod.Raw>) {
    return raw ? new ServicePeriod(raw) : undefined;
  }

  static fromStore(): ServicePeriod[];
  static fromStore(id: Maybe<ServicePeriod['id']>): Maybe<ServicePeriod>;
  static fromStore(id?: ServicePeriod['id']) {
    const { servicePeriods } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(servicePeriods)).map(ServicePeriod.fromObject);
    } else {
      return id ? ServicePeriod.fromObject(servicePeriods[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'ServicePeriod';
  constructor(raw: ServicePeriod.Raw) {
    super(raw);
    Object.assign(this, _.pick(raw, ServicePeriod.rawFields));
  }

  get ServiceAnalysis() {
    return ServiceAnalysis.fromStore(this.service_analysis);
  }

  serialize(): ServicePeriod.Serialized {
    return {
      ...this.serializedDateRange,
      ..._.pick(this, ServicePeriod.rawFields),
      object_type: this.object_type,
    };
  }
}
