import _ from 'lodash';

import { store } from 'onescreen/store';
import { IdType, Maybe, ObjectWithoutType, ObjectWithType } from 'onescreen/types';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'Season'> & {
  name: string;
  id: IdType;
};

export declare namespace Season {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}
}

/** ======================== Model ========================================= */
export interface Season extends CommonAttrs {}
export class Season {
  /** ====================== Static fields ================================= */
  static readonly rawFields = ['name', 'id'] as const;

  /**
   * Parses an array of raw Season objects by passing each in turn to
   * Season.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {Season.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: Season.Raw[]): Season[];
  static fromObjects(raw: Maybe<Season.Raw[]>): Maybe<Season[]>;
  static fromObjects(raw: Maybe<Season.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: Season.Raw): Season;
  static fromObject(raw: Maybe<Season.Raw>): Maybe<Season>;
  static fromObject(raw: Maybe<Season.Raw>) {
    return raw ? new Season(raw) : undefined;
  }

  static fromStore(): Season[];
  static fromStore(id: Maybe<Season['id']>): Maybe<Season>;
  static fromStore(id?: Season['id']) {
    const { seasons } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(seasons)).map(Season.fromObject);
    } else {
      return id ? Season.fromObject(seasons[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'Season';
  constructor(raw: Season.Raw) {
    Object.assign(this, _.pick(raw, Season.rawFields));
  }

  serialize(): Season.Serialized {
    return { ..._.pick(this, Season.rawFields), object_type: this.object_type };
  }
}
