import * as React from 'react';
import MuiTable, { TableProps } from '@material-ui/core/Table';
import MuiTableContainer, { TableContainerProps } from '@material-ui/core/TableContainer';
import MuiTableBody, { TableBodyProps } from '@material-ui/core/TableBody';
import MuiTableRow, { TableRowProps } from '@material-ui/core/TableRow';
import MuiTableCell, { TableCellProps } from '@material-ui/core/TableCell';
import MuiTableHead, { TableHeadProps } from '@material-ui/core/TableHead';

/** ======================== Components ==================================== */
const TableContainer: React.FC<TableContainerProps> = (props) => <MuiTableContainer {...props} />;
const TableComponent: React.FC<TableProps> = (props) => <MuiTable {...props} />;
const TableRow: React.FC<TableRowProps> = (props) => <MuiTableRow {...props} />;
const TableBody: React.FC<TableBodyProps> = (props) => <MuiTableBody {...props} />;
const TableCell: React.FC<TableCellProps> = (props) => <MuiTableCell {...props} />;
const TableHead: React.FC<TableHeadProps> = (props) => <MuiTableHead {...props} />;

export const Table = Object.assign(TableComponent, {
  Container: TableContainer,
  Row: TableRow,
  Body: TableBody,
  Cell: TableCell,
  Head: TableHead,
});
