import _ from 'lodash';
import * as React from 'react';
import MuiMenu from '@material-ui/core/Menu';

import { useRandomString } from 'onescreen/hooks';
import { Logger } from 'onescreen/utils';

import { Button } from './Button';
import { List } from './List';
import { PopoverOrigin } from './Popover';
import { ValidIcon } from './Icon';

/** ============================ Types ===================================== */
type MenuProps = {
  anchorOrigin?: PopoverOrigin;
  fabIcon?: ValidIcon;
  icon?: ValidIcon;
  label?: string;
  transformOrigin?: PopoverOrigin;
};

type MenuExport = React.FC<MenuProps> & {
  Item: typeof List.Item;
};

/** ============================ Components ================================ */
export const Menu: MenuExport = (props) => {
  const {
    fabIcon,
    icon,
    label,
    anchorOrigin = { vertical: 'bottom', horizontal: 'center' } as PopoverOrigin,
    transformOrigin = { vertical: 'top', horizontal: 'center' } as PopoverOrigin,
    ...rest
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuId = useRandomString();

  // Only one of the `icon` and `label` props should be provided
  const numExclusiveProps = _.sum([!!label, !!icon, !!fabIcon]);
  if (numExclusiveProps !== 1) {
    Logger.warn('`Menu` component expects exactly one of `fabIcon`, `icon` or `label` props');
  }

  const menuOpenerProps = {
    'aria-controls': menuId,
    'aria-haspopup': 'true' as React.AriaAttributes['aria-haspopup'],
    'onClick': handleClick,
  };

  const MenuOpener = icon ? (
    <Button {...menuOpenerProps} size="small" icon={icon} />
  ) : fabIcon ? (
    <Button.Fab {...menuOpenerProps} name={fabIcon} size="small" />
  ) : (
    <Button {...menuOpenerProps}>{label}</Button>
  );

  return (
    <>
      {MenuOpener}
      <MuiMenu
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        getContentAnchorEl={null}
        id={menuId}
        open={!!anchorEl}
        onClose={handleClose}
        transformOrigin={transformOrigin}
        {...rest}
      />
    </>
  );

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
};

Menu.Item = List.Item;
