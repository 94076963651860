import { Query } from 'onescreen/types';

/** ============================ Clause builders =========================== */
const in_ = (values?: Array<Query.Primitive>) =>
  ({
    operation: 'in',
    value: values,
  } as Query.FilterClauseIn);

const equals_ = (value: Query.Primitive) =>
  ({
    operation: 'equals',
    value,
  } as Query.FilterClauseEquals);

export const filterClause = {
  in: in_,
  equals: equals_,
};
