import _ from 'lodash';
import { useState } from 'react';

import { Meter } from 'onescreen/models';
import { Loader, Maybe, OnFailCallback } from 'onescreen/types';

import { useAsync } from './useAsync';
import { slices } from '../store';
import { useSelector } from 'react-redux';

/**
 * Retrieves all Meter objects
 *
 * @param {Meter.API.ListParams} params: query parameters
 * @param {OnFailCallback} onFail: callback for when the request fails
 */
export function useMeters(
  params?: Meter.API.ListParams,
  onFail?: OnFailCallback,
  shouldUpdate: boolean = false
): Loader<Meter.API.ListResult, 'meters'> {
  const [response, setResponse] = useState<Meter.API.ListResult>();
  const loading = useAsync(async () => Meter.api.list(params), [shouldUpdate], setResponse, onFail);
  return { loading, meters: response };
}

/**
 * Retrieves one Meter object
 *
 * @param {Meter.API.RetrieveParams} params: query parameters
 * @param {OnFailCallback} onFail: callback for when the request fails
 */
export function useMeter(
  id?: Meter['id'],
  params?: Meter.API.RetrieveParams,
  shouldUpdate: boolean = true,
  onFail?: OnFailCallback
): Loader<Maybe<Meter>, 'meter'> {
  const meter = useSelector(slices.data.selectMeter(id));
  const loading = useAsync(
    async () => {
      if (!id || !shouldUpdate) return;
      return Meter.api.retrieve(id, params);
    },
    [id],
    () => _.noop(),
    onFail
  );

  return {
    loading,
    meter: Meter.fromObject(meter),
  };
}
