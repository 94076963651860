import React from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { makeStylesHook } from 'onescreen/styles';

import { Icon } from './Icon';
import { Typography } from './Typography';

/** ======================== Types ========================================= */
type AccordionProps = {
  disabled?: boolean;
  heading: React.ReactNode;
};

/** ======================== Styles ======================================== */
const useStyles = makeStylesHook(
  (theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    detailsRoot: {
      display: 'block',
    },
  }),
  'OneScreenAccordion'
);

/** ======================== Components ==================================== */
export const Accordion: React.FC<AccordionProps> = ({ children, disabled, heading }) => {
  const classes = useStyles();
  return (
    <MuiAccordion disabled={disabled}>
      <AccordionSummary expandIcon={<Icon name="expand" />}>
        <Typography className={classes.heading}>{heading}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.detailsRoot }}>{children}</AccordionDetails>
    </MuiAccordion>
  );
};
