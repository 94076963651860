import _ from 'lodash';
import { DateTime } from 'luxon';

import { slices, store } from 'onescreen/store';
import {
  Maybe,
  Nullable,
  ObjectWithoutType,
  ObjectWithType,
  Pagination,
  Query,
  StringTuple,
} from 'onescreen/types';
import { BaseAPI } from '../base';
import { DateRange, DateRanges, DateUtils } from '../dates';

/** ======================== Types ========================================= */

export const AggregateOptions = ['sum', 'min', 'max', 'mean'] as const;
export const FrequencyOptions = ['15Min', '1H', '1D', '1M'] as const;
export type IntervalAggregate = typeof AggregateOptions[number];
export type IntervalFrequency = typeof FrequencyOptions[number];
type IntervalDataKey = `${IntervalAggregate}*${IntervalFrequency}`;
export type RawIntervalStream = {
  [timestamp: number]: number;
};
type KeyedIntervalData = {
  key: IntervalDataKey;
};
export type IntervalStream = KeyedIntervalData & {
  value: RawIntervalStream;
};
type IntervalCSV = KeyedIntervalData & {
  url: string;
};
export type IntervalData = {
  streams: IntervalStream[];
};
export type IntervalCSVFiles = {
  files: IntervalCSV[];
};

type CommonAttrs = ObjectWithType<'IntervalFile'> & {
  id: number;
  min_value: number;
  max_value: number;
  average_value: number;
  intervals?: IntervalData;
  interval_csvs?: IntervalCSVFiles;
};

export declare namespace IntervalFile {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {
    gaps?: DataSpans.Raw;
    estimates?: DataSpans.Raw;
    upsamples?: DataSpans.Raw;
    unexpected_zeros?: DataSpans.Raw;
    negative_values?: DataSpans.Raw;
    impossible_high_values?: DataSpans.Raw;
    delayed_reporting?: DataSpans.Raw;
    far_above_baselines?: DataSpans.Raw;
    bill_kwh_mismatch?: DataSpans.Raw;
    starts_at: string | DateTime;
    ends_at: string | DateTime;
  }

  export namespace Date {
    export type Raw = string;
    export type Parsed = DateTime;
  }

  export namespace DataSpans {
    export type Raw = Nullable<Array<StringTuple>>;
    export type Parsed = Nullable<DateRanges>;
  }

  // Network interface
  export namespace API {
    export type ListParams = RetrieveParams & Query.PaginationParams;
    export type ListResponse = Pagination.Response.Raw<{ interval_files: IntervalFile.Raw[] }>;
    export type ListResult = Pagination.Response.Parsed<IntervalFile>;

    export type RetrieveResponse = { interval_file: IntervalFile.Raw };
    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResult = {
      intervalFile: IntervalFile;
    };

    export type RetrieveIntervalsParams = {
      start?: string;
      end?: string;
      agg: IntervalAggregate;
      freq: IntervalFrequency;
    };
    export type RetrieveIntervalsResponse = {
      value: RawIntervalStream;
    };
    export type RetrieveIntervalsResult = {
      stream: IntervalStream;
    };
    export type RetrieveCSVResponse = {
      url: string;
    };
    export type RetrieveCSVResult = {
      file: IntervalCSV;
    };
  }
}

/** ======================== API =========================================== */
class IntervalFileAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.intervalFile;

  static async list(params?: IntervalFile.API.ListParams): Promise<IntervalFile.API.ListResult> {
    const { json } = await this.get<IntervalFile.API.ListResponse>(this.route, params);

    // Parse response
    const intervalFiles = this.parsePaginationSet(json, ({ interval_files }) =>
      IntervalFile.fromObjects(interval_files)
    );

    store.dispatch(slices.data.updateModels(intervalFiles.results));
    return intervalFiles;
  }

  static async retrieve(
    id: IntervalFile['id'],
    params?: IntervalFile.API.RetrieveParams
  ): Promise<IntervalFile.API.RetrieveResult> {
    const { json } = await this.get<IntervalFile.API.RetrieveResponse>(this.route(id), params);
    const { interval_file } = json;

    // Parse response
    const intervalFile = new IntervalFile(interval_file);
    store.dispatch(slices.data.updateModels(intervalFile));

    return {
      intervalFile,
    };
  }

  static async intervals(
    id: IntervalFile['id'],
    params?: IntervalFile.API.RetrieveIntervalsParams
  ): Promise<IntervalFile.API.RetrieveIntervalsResult> {
    const { json } = await this.get<IntervalFile.API.RetrieveIntervalsResponse>(
      this.route(id) + 'intervals/',
      params
    );
    const { value } = json;
    const { agg = 'sum', freq = '1D' } = params || {};

    // Parse response
    const intervalFile = IntervalFile.fromStore(id);
    let streams = intervalFile?.intervals?.streams || [];
    const newStream = { value, key: `${agg}*${freq}` as IntervalDataKey };
    streams.push(newStream);
    if (intervalFile) intervalFile.intervals = { streams };
    store.dispatch(slices.data.updateModels(intervalFile));

    return { stream: newStream };
  }

  static async intervals_csv(
    id: IntervalFile['id'],
    params?: IntervalFile.API.RetrieveIntervalsParams
  ): Promise<IntervalFile.API.RetrieveCSVResult> {
    const { json } = await this.get<IntervalFile.API.RetrieveCSVResponse>(
      this.route(id) + 'intervals-csv/',
      params || {}
    );
    const { url } = json;
    const { agg = 'sum', freq = '1D' } = params || {};

    // Parse response
    const intervalFile = IntervalFile.fromStore(id);
    let files = intervalFile?.interval_csvs?.files || [];
    const newUrl = { url, key: `${agg}*${freq}` as IntervalDataKey };
    files.push(newUrl);
    if (intervalFile) intervalFile.interval_csvs = { files };
    store.dispatch(slices.data.updateModels(intervalFile));

    return { file: newUrl };
  }
}

/** ======================== Model ========================================= */
export interface IntervalFile extends CommonAttrs {}
export class IntervalFile {
  /** ====================== Static fields ================================= */
  static api = IntervalFileAPI;
  static readonly rawFields = ['id', 'min_value', 'max_value', 'average_value'] as const;

  /**
   * Parses an array of raw IntervalFile objects by passing each in turn to
   * IntervalFile.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {IntervalFile.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: IntervalFile.Raw[]): IntervalFile[];
  static fromObjects(raw: Maybe<IntervalFile.Raw[]>): Maybe<IntervalFile[]>;
  static fromObjects(raw: Maybe<IntervalFile.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: IntervalFile.Raw): IntervalFile;
  static fromObject(raw: Maybe<IntervalFile.Raw>): Maybe<IntervalFile>;
  static fromObject(raw: Maybe<IntervalFile.Raw>) {
    return raw ? new IntervalFile(raw) : undefined;
  }

  static fromStore(): IntervalFile[];
  static fromStore(id: Maybe<IntervalFile['id']>): Maybe<IntervalFile>;
  static fromStore(id?: IntervalFile['id']) {
    const { intervalFiles } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(intervalFiles)).map(IntervalFile.fromObject);
    } else {
      return id ? IntervalFile.fromObject(intervalFiles[id]) : undefined;
    }
  }

  /**
   * Parser the raw data_gaps field, if provided
   *
   * @param {DataSpans.Raw} raw: the raw data gaps
   */
  static parseDataSpan(
    raw: Maybe<IntervalFile.DataSpans.Raw>
  ): Maybe<IntervalFile.DataSpans.Parsed> {
    if (!raw) return;
    return DateRanges.parseRanges(raw);
  }

  /**
   * Serializes the data_gaps field, if provided
   *
   * @param {DataSpans.Parsed} gaps: the parsed data gaps
   */
  static serializeDataSpan(
    spans: Maybe<IntervalFile.DataSpans.Parsed>
  ): Maybe<IntervalFile.DataSpans.Raw> {
    if (!spans) return;

    const serializeSpanArray = (spans: IntervalFile.DataSpans.Parsed) =>
      spans ? spans.serialize() : null;

    return serializeSpanArray(spans);
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'IntervalFile';
  gaps?: IntervalFile.DataSpans.Parsed;
  estimates?: IntervalFile.DataSpans.Parsed;
  upsamples?: IntervalFile.DataSpans.Parsed;
  unexpected_zeros?: IntervalFile.DataSpans.Parsed;
  negative_values?: IntervalFile.DataSpans.Parsed;
  impossible_high_values?: IntervalFile.DataSpans.Parsed;
  delayed_reporting?: IntervalFile.DataSpans.Parsed;
  far_above_baselines?: IntervalFile.DataSpans.Parsed;
  bill_kwh_mismatch?: IntervalFile.DataSpans.Parsed;
  starts_at: DateTime;
  ends_at: DateTime;

  constructor(raw: IntervalFile.Raw) {
    Object.assign(this, _.pick(raw, IntervalFile.rawFields));
    this.gaps = IntervalFile.parseDataSpan(raw.gaps);
    this.estimates = IntervalFile.parseDataSpan(raw.estimates);
    this.upsamples = IntervalFile.parseDataSpan(raw.upsamples);
    this.unexpected_zeros = IntervalFile.parseDataSpan(raw.unexpected_zeros);
    this.negative_values = IntervalFile.parseDataSpan(raw.negative_values);
    this.impossible_high_values = IntervalFile.parseDataSpan(raw.impossible_high_values);
    this.delayed_reporting = IntervalFile.parseDataSpan(raw.delayed_reporting);
    this.far_above_baselines = IntervalFile.parseDataSpan(raw.far_above_baselines);
    this.bill_kwh_mismatch = IntervalFile.parseDataSpan(raw.bill_kwh_mismatch);
    this.starts_at = raw.starts_at ? DateUtils.parseDate(raw.starts_at) : DateTime.fromSeconds(0);
    this.ends_at = raw.ends_at ? DateUtils.parseDate(raw.ends_at) : DateTime.fromSeconds(0);
  }

  get dateRange(): DateRange {
    return new DateRange({ start_date: this.starts_at, end_date: this.ends_at });
  }

  get anomalies(): DateRanges {
    return new DateRanges([
      ...(this.gaps as DateRanges).ranges,
      ...(this.unexpected_zeros as DateRanges).ranges,
      ...(this.negative_values as DateRanges).ranges,
      ...(this.impossible_high_values as DateRanges).ranges,
      ...(this.delayed_reporting as DateRanges).ranges,
      ...(this.far_above_baselines as DateRanges).ranges,
      ...(this.bill_kwh_mismatch as DateRanges).ranges,
    ])
  }

  serialize(): IntervalFile.Serialized {
    return {
      ..._.pick(this, IntervalFile.rawFields),
      gaps: IntervalFile.serializeDataSpan(this.gaps),
      estimates: IntervalFile.serializeDataSpan(this.estimates),
      upsamples: IntervalFile.serializeDataSpan(this.upsamples),
      unexpected_zeros: IntervalFile.serializeDataSpan(this.unexpected_zeros),
      negative_values: IntervalFile.serializeDataSpan(this.negative_values),
      impossible_high_values: IntervalFile.serializeDataSpan(this.impossible_high_values),
      delayed_reporting: IntervalFile.serializeDataSpan(this.delayed_reporting),
      far_above_baselines: IntervalFile.serializeDataSpan(this.far_above_baselines),
      bill_kwh_mismatch: IntervalFile.serializeDataSpan(this.bill_kwh_mismatch),
      starts_at: DateUtils.serializeDate(this.starts_at),
      ends_at: DateUtils.serializeDate(this.ends_at),
      object_type: this.object_type,
    };
  }
}
