import _ from 'lodash';
import { DateTime, Interval } from 'luxon';

import { DateTuple, Maybe, Nullable, StringTuple } from 'onescreen/types';
import { formatters, Logger } from 'onescreen/utils';

/** ======================== Types ========================================= */
export declare namespace DateRange {
  export interface Raw {
    start_date: string | DateTime;
    end_date: string | DateTime;
  }

  export type Input = Raw | DateTuple;
}

export declare namespace DateUtils {
  export type RawDate = DateTime | string;
}

export declare namespace TimeStamp {
  export type Raw = {
    created_at: string;
    updated_at: string;
  };

  export type Parsed = {
    created_at: DateTime;
    updated_at: DateTime;
  };
}

/** ======================== DateUtils class =============================== */
export class DateUtils {
  /**
   * Parses a date string into a luxon DateTime object. Note that using the `Date` constructor is
   * unreliable across browsers: ambiguous date strings that omit timezone info will be interpreted
   * differently by different browsers. Hence we use luxon. Also accepts a DateTime object for
   * convenience and returns it unchanged.
   *
   * @param {string|null|undefined} d: the string to parse.
   */
  static parseDate(d: DateUtils.RawDate): DateTime;
  static parseDate(d: DateUtils.RawDate | null | undefined): Maybe<DateTime>;
  static parseDate(d: DateUtils.RawDate | null | undefined): Maybe<DateTime> {
    if (_.isNull(d) || _.isUndefined(d)) return undefined;
    return d instanceof DateTime ? d : DateTime.fromISO(d, { setZone: true });
  }

  /**
   * Serializes a luxon DateTime object into a string
   *
   * @param {DateTime|null|undefined} date: the luxon DateTime object to serialize
   */
  static serializeDate(date: DateTime): string;
  static serializeDate(date: DateTime | null | undefined): Maybe<string>;
  static serializeDate(date: DateTime | null | undefined): Maybe<string> {
    return date?.toISO();
  }
}

/** ======================== DateRange class =============================== */
export class DateRange {
  end_date: DateTime;
  start_date: DateTime;

  /** ====================== Static fields ================================= */
  static fromObject(range: DateTuple | DateRange.Raw) {
    return new DateRange(range);
  }

  /** ====================== Instance fields =============================== */
  constructor(input: DateRange.Input) {
    if (_.isArray(input)) {
      this.start_date = input[0];
      this.end_date = input[1];
    } else {
      this.end_date = DateUtils.parseDate(input.end_date);
      this.start_date = DateUtils.parseDate(input.start_date);
    }
  }

  get dateRange(): DateTuple {
    return [this.start_date, this.end_date];
  }

  /**
   * Returns the duration of the day span
   */
  get duration() {
    return this.end_date.diff(this.start_date);
  }

  /**
   * Returns the date range as a string using the provided formatting function. Formatting function
   * defaults to formatters.date.monthDay
   */
  formatDateRange(formatter: formatters.DateFormatterFunction = formatters.date.monthDay) {
    return formatters.date.range(this.dateRange, formatter);
  }

  contains(range: DateRange) {
    return this.start_date < range.start_date && this.end_date > range.end_date;
  }

  get interval() {
    return Interval.fromDateTimes(this.start_date, this.end_date);
  }

  /**
   * Returns the midpoint between the start date and end date
   */
  get midpoint() {
    const { end_date, start_date } = this;
    const diff = end_date.diff(start_date);
    return end_date.minus(+diff / 2);
  }

  get serializedDateRange() {
    return {
      end_date: DateUtils.serializeDate(this.end_date),
      start_date: DateUtils.serializeDate(this.start_date),
    };
  }
}

/** ======================== DateRanges class =============================== */
export class DateRanges<T extends DateRange = DateRange> {
  /** ====================== Static fields ================================== */
  static parseRanges(rangesRaw: Nullable<Array<StringTuple>>) {
    if (!rangesRaw) return null;
    return new DateRanges(
      rangesRaw.map(([start, end]) =>
        DateRange.fromObject([DateUtils.parseDate(start), DateUtils.parseDate(end)])
      )
    );
  }

  /** ====================== Instance fields ================================ */
  ranges: T[];

  constructor(ranges: T[]) {
    this.ranges = _.sortBy(ranges, (range) => +range.start_date.toJSDate());
  }

  get domain() {
    return new DateRange([_.first(this.ranges)!.start_date, _.last(this.ranges)!.end_date]);
  }

  /**
   * Given the bounds of a new date range, this method returns ranges of dates that are not included
   * in these ranges. The returned ranges will occur in consecutive, ascending order.
   *
   * @param {DateRange} bounds: the complete range for the inversions to cover
   */
  getInverse(bounds: DateRange): DateRanges<DateRange> {
    const { ranges } = this;

    // We start with a range-set of a single range covering the entire boundary interval...
    const inversions = new DateRanges([bounds]);

    // ...next we find the subset of ranges in this set that overlap the boundary interval...
    const rangesWithOverlap = ranges.filter((range) => range.interval.overlaps(bounds.interval));

    // ...if there are no such ranges, there's nothing more to be done...
    if (rangesWithOverlap.length === 0) return inversions;

    // ...but if there are, iterate through them...
    return rangesWithOverlap.reduce((inversions, range) => {
      // ...find the inversion it overlaps with. There should really only be one...
      const overlaps = inversions.filterByInterval(range);
      if (overlaps.length > 1)
        Logger.warn(`getInverse method found multiple (${overlaps.length}) overlapping ranges!`);
      const overlap = _.first(overlaps);

      // ...if there isn't one, return the inversions unchanged...
      if (!overlap) return inversions;

      // ...but if there is, split up that inversion into its sub-intervals not overlapping the
      // range. There are 4 possible configurations:
      //   (1) the overlap's start but not its end is covered by the range
      //   (2) the overlap's end but not its start is covered by the range
      //   (3) the overlap's middle but not its start nor its end is covered by the range
      //   (4) the overlap is completely covered by the range
      const subIntervals: DateRange[] = _.iife((): DateTuple[] => {
        if (range.start_date <= overlap.start_date && range.end_date < overlap.end_date)
          return [[range.end_date, overlap.end_date]];
        else if (range.start_date > overlap.start_date && range.end_date >= overlap.end_date)
          return [[overlap.start_date, range.start_date]];
        else if (range.start_date > overlap.start_date && range.end_date < overlap.end_date)
          return [
            [overlap.start_date, range.start_date],
            [range.end_date, overlap.end_date],
          ];
        else return [];
      }).map(DateRange.fromObject);

      // Finally, create a new DateRanges collection substituting the sub-intervals for the overlap
      return new DateRanges<DateRange>([..._.without(inversions.ranges, overlap), ...subIntervals]);
    }, inversions);
  }

  /**
   * Finds the given range from the date ranges, comparing the start date and end date
   *
   * @param {DateRange|undefined} range: the date range object or undefined
   */
  find(range: Maybe<T>) {
    return _.find(this.ranges, {
      start_date: range?.start_date,
      end_date: range?.end_date,
    }) as Maybe<T>;
  }

  /**
   * Finds a DateRange from within this set that overlaps with the provided DateRange
   *
   * @param {DateRange} overlap: the range to search for overlaps with
   */
  filterByInterval(overlap: DateRange): T[] {
    return this.ranges.filter((range) => range.interval.overlaps(overlap.interval));
  }

  serialize() {
    return this.ranges.map(
      (range) =>
        [
          DateUtils.serializeDate(range.start_date),
          DateUtils.serializeDate(range.end_date),
        ] as StringTuple
    );
  }
}
