import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { BaseCSSProperties, CreateCSSProperties } from '@material-ui/core/styles/withStyles';

import { materialColors, primaryColor, secondaryColor } from './colors';

type BorderArgs = Partial<{
  width: number;
  color: string;
  radius: number;
}>;

declare module '@material-ui/core/styles/createMixins' {
  type TransitionBounds = [any, any];
  interface Mixins {
    border: (args: BorderArgs) => CreateCSSProperties;

    transition: (
      property: string,
      activated: boolean,
      bounds: TransitionBounds
    ) => BaseCSSProperties;
  }
}

/** ======================== Theme ========================================= */
export const theme = createMuiTheme({
  mixins: {
    border: ({ color, radius = 0, width = 1 }) => ({
      borderWidth: width,
      borderStyle: 'solid',
      borderColor: color,
      borderRadius: radius,
    }),
    flex: ({ direction, wrap, justify, align }) => ({
      alignItems: align,
      display: 'flex',
      flexDirection: direction,
      flexWrap: wrap,
      justifyContent: justify,
    }),
    transition: (property, activated, bounds) => ({
      transition: `${property} 0.25s`,
      [property]: activated ? bounds[0] : bounds[1],
    }),
  },
  palette: {
    action: {
      selected: materialColors.green[200],
    },
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  typography: {
    fontSize: 16,

    button: materializeOverride({
      '&:focus': {
        backgroundColor: materialColors.grey[300],
      },
    }),
  },
});

export type Theme = typeof theme;

/**
 * This is an identity function whose sole purpose is to identify instances of CSS in the
 * application that exist solely to override materialize styling. When (if) materialize is
 * eventually removed from the app, this function can and should be deleted, along with all of its
 * usages. Generally speaking this function should be used only in instances where Material UI's
 * default behavior needs to be explicitly declared so as to override materialize overriding MUI
 */
export function materializeOverride(thing: any) {
  return thing;
}
