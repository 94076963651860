import _ from 'lodash';

import { Maybe } from 'onescreen/types';

/** ======================== Types ========================================= */
type FakeLocalStorage = { [key in StorageKey]?: number };
type StorageKey = 'activeCostSavings' | 'activePortfolio' | 'activeServiceAnalysis';

export declare namespace LocalStorage {
  export type Key = StorageKey;
}

/** ======================== Storage ======================================= */
/**
 * Wraps the native `localStorage` if it exists, otherwise this provides a simple data dump. Not all
 * browsers have `localStorage` (notably, Safari's private mode does not). We still want the app to
 * work in these browsers, however, so this method protects against using `localStorage` where it
 * isn't available. The fallback is very naive, making use of a simple JS object for storage and
 * lookup
 */
export class LocalStorage {
  private static _hasLocalStorage: Maybe<boolean>;
  private static storage: FakeLocalStorage = {};

  /**
   * Checks if the user's browser has access to local storage. Very old browsers do not, nor does
   * Safari in private mode.
   */
  private static get hasLocalStorage(): boolean {
    // If it's been computed, return
    if (typeof this._hasLocalStorage === 'boolean') {
      return this._hasLocalStorage;
    }

    // Detect if it has been disabled. Even accessing `window.localStorage` throws an error if
    // the user has disabled it.
    let localStorage;
    try {
      localStorage = window.localStorage;
    } catch (e) {
      // Access denied :-(
      return (this._hasLocalStorage = false);
    }

    const date = new Date();
    try {
      localStorage.setItem(date.toString(), date.toString());
      localStorage.removeItem(date.toString());
      return (this._hasLocalStorage = true);
    } catch (e) {
      return (this._hasLocalStorage = false);
    }
  }

  /**
   * Returns the current value associated with the given key. If the key is not present in storage,
   * returns null
   *
   * @param {StorageKey} key: the key to look up in storage
   */
  static get(key: StorageKey): Maybe<number>;
  /**
   * Returns the current value associated with the given key. If the key is not present in storage,
   * returns the default value
   *
   * @param {StorageKey} key: the key to look up in storage
   * @param {number|string} defaultValue: a default value to return if the key is not found
   */
  static get(key: StorageKey, defaultValue: number): number;
  static get(key: StorageKey, defaultValue?: any) {
    // If it's not present, return default value or null
    if (!this.has(key)) {
      return _.isUndefined(defaultValue) ? undefined : defaultValue;
    }

    // If local storage isn't enabled, return value unparsed from storage object
    if (!this.hasLocalStorage) return this.storage[key];

    // Parse the value
    const valueStr = localStorage.getItem(key);
    if (
      key === 'activeCostSavings' ||
      key === 'activePortfolio' ||
      key === 'activeServiceAnalysis'
    ) {
      return Number(valueStr);
    } else {
      return valueStr ?? undefined;
    }
  }

  /**
   * Sets the value of the key to a new value. The value will be converted to a string prior to
   * setting it in local storage.
   *
   * @param {string} key: the key to set in storage
   * @param {any} value: the value to associate with the key, after converting to string
   */
  static set(key: StorageKey, value: Maybe<number>): void;
  static set(key: StorageKey, value: Maybe<any>): void {
    if (_.isUndefined(value)) return this.remove(key);
    this.hasLocalStorage ? localStorage.setItem(key, String(value)) : (this.storage[key] = value);
  }

  /**
   * Removes a key-value pair from storage.
   *
   * @param {string} key: the key to remove from storage
   */
  static remove(key: StorageKey): void {
    this.hasLocalStorage ? localStorage.removeItem(key) : delete this.storage[key];
  }

  /**
   * Returns true or false if a key is present or absent (respectively) in storage
   *
   * @param {string} key: the key to look for in storage
   */
  static has(key: StorageKey): boolean {
    return this.hasLocalStorage ? key in localStorage : key in this.storage;
  }
}
