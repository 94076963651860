import _ from 'lodash';

import { slices, store } from 'onescreen/store';
import { Maybe, ObjectWithoutType, Pagination, Query } from 'onescreen/types';

import { AsyncTask, BaseAPI } from '../base';
import { IntervalFile } from '../interval';
import { ServiceDrop } from '../orgs';
import { Authorization } from './authorization';

/** ======================== Types ========================================= */
type CommonAttrs = {
  id: number;
  object_type: 'Meter';
  utilityapi_id: string;
  meter_numbers: Array<string>;
  billing_account: string;
  billing_contact: string;
  billing_address: string;
  service_identifier: string;
  service_address: string;
  utilityapi_utility_id: string;
  service_tariff: string;
  service_class: string;
  status: string;
  status_ts: string;
  is_activated: boolean;
  is_primary: boolean;
  bill_count: number;
  authorization?: Authorization['id'];
  service_drop?: ServiceDrop['id'];
  demo_service_drop?: ServiceDrop['id'];
  interval_count: number;
  interval_file?: IntervalFile['id'];

  api_bills: null;
};

export declare namespace Meter {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}

  // Network interface
  export namespace API {
    export type SideloadedResponses = {
      service_drops?: ServiceDrop.Raw[],
      demo_service_drops?: ServiceDrop.Raw[],
     };

    export type ListParams = RetrieveParams & Query.PaginationParams;
    export type ListResult = Pagination.Response.Parsed<Meter>;
    export type ListResponse = Pagination.Response.Raw<
      { meters: Meter.Raw[] } & SideloadedResponses
    >;

    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResponse = { meter: Meter.Raw } & SideloadedResponses;
    export type RetrieveResult = { meter: Meter };

    export type UpdateParams = {
      service_drop?: Meter.Raw['service_drop'] | null;
      demo_service_drop?: Meter.Raw['demo_service_drop'] | null;
    };

    export type CollectIntervalsResult = { interval_file_id: IntervalFile['id'] };
    export type CollectIntervalsResponse = {
      task_id: AsyncTask<CollectIntervalsResult>['task_id'];
    };
  }
}

/** ======================== API =========================================== */
class MeterAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.meter;

  static async list(params?: Meter.API.ListParams): Promise<Meter.API.ListResult> {
    const { json } = await this.get<Meter.API.ListResponse>(this.route, params);

    // Parse response
    const { service_drops, demo_service_drops } = this.parseSideloadedObjects(json.results);
    const meters = this.parsePaginationSet(json, ({ meters }) => Meter.fromObjects(meters));

    store.dispatch(slices.data.updateModels(meters.results, service_drops, demo_service_drops));
    return meters;
  }

  static async retrieve(
    id: Meter.Raw['id'],
    params?: Meter.API.RetrieveParams
  ): Promise<Meter.API.RetrieveResult> {
    const { json } = await this.get<Meter.API.RetrieveResponse>(this.route(id), params);

    // Parse response
    const { service_drops, demo_service_drops } = this.parseSideloadedObjects(json);
    const meter = new Meter(json.meter);
    store.dispatch(slices.data.updateModels(meter, service_drops, demo_service_drops));
    return { meter };
  }

  static async update(
    id: Meter.Raw['id'],
    body: Meter.API.UpdateParams,
    params?: Meter.API.RetrieveParams
  ): Promise<Meter.API.RetrieveResult> {
    const { json } = await this.patch(this.route(id), body, params);
    const { meter } = json;
    store.dispatch(slices.data.updateModels(Meter.fromObject(meter)));
    return meter;
  }

  static async collect_intervals(id: Meter['id']): Promise<Meter.API.CollectIntervalsResult> {
    const { json } = await this.get<Meter.API.CollectIntervalsResponse>(
      this.route(id) + 'collect-intervals/'
    );
    const task = new AsyncTask<Meter.API.CollectIntervalsResult>(json.task_id);
    const result = await task.getResult();
    return result;
  }

  static async collect_bills(id: Meter['id']): Promise<Meter.API.CollectIntervalsResult> {
    const { json } = await this.get<Meter.API.CollectIntervalsResponse>(
      this.route(id) + 'collect-bills/'
    );
    const task = new AsyncTask<Meter.API.CollectIntervalsResult>(json.task_id);
    const result = await task.getResult();
    return result;
  }

  static parseSideloadedObjects(json: Meter.API.SideloadedResponses) {
    return {
      service_drops: ServiceDrop.fromObjects(json.service_drops),
      demo_service_drops: ServiceDrop.fromObjects(json.demo_service_drops),
     };
  }
}

/** ======================== Model ========================================= */
export interface Meter extends CommonAttrs {}
export class Meter {
  /** ====================== Static fields ================================= */
  static api = MeterAPI;
  static readonly rawFields = [
    'id',
    'utilityapi_id',
    'meter_numbers',
    'billing_account',
    'billing_contact',
    'billing_address',
    'service_identifier',
    'service_address',
    'utilityapi_utility_id',
    'service_tariff',
    'service_class',
    'status',
    'status_ts',
    'is_activated',
    'is_primary',
    'bill_count',
    'authorization',
    'service_drop',
    'demo_service_drop',
    'interval_count',
    'interval_file',
    'api_bills',
  ] as const;

  /**
   * Parses an array of raw Meter objects by passing each in turn to
   * Meter.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {Meter.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: Meter.Raw[]): Meter[];
  static fromObjects(raw: Maybe<Meter.Raw[]>): Maybe<Meter[]>;
  static fromObjects(raw: Maybe<Meter.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: Meter.Raw): Meter;
  static fromObject(raw: Maybe<Meter.Raw>): Maybe<Meter>;
  static fromObject(raw: Maybe<Meter.Raw>) {
    return raw ? new Meter(raw) : undefined;
  }

  static fromStore(): Meter[];
  static fromStore(id: Maybe<Meter['id']>): Maybe<Meter>;
  static fromStore(id?: Meter['id']) {
    const { meters } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(meters)).map(Meter.fromObject);
    } else {
      return id ? Meter.fromObject(meters[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'Meter';
  constructor(raw: Meter.Raw) {
    Object.assign(this, _.pick(raw, Meter.rawFields));
  }

  get ServiceDrop() {
    return ServiceDrop.fromStore(this.service_drop);
  }

  get DemoServiceDrop() {
    return ServiceDrop.fromStore(this.demo_service_drop);
  }

  get Authorization() {
    return Authorization.fromStore(this.authorization!);
  }

  serialize(): Meter.Serialized {
    return { ..._.pick(this, Meter.rawFields), object_type: this.object_type };
  }
}
