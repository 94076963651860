import React, { useEffect, useState } from 'react';

import {
  Button,
  Grid,
  MultiSelect,
  Typography,
  Checkbox,
  LoadingCircle,
} from 'onescreen/components';
import { Site } from '../../common/models';
import { ErrorMessage } from 'onescreen/types';

/** ================================= Types ================================ */
type DownloadURL = { url: string; error?: ErrorMessage };
type DownlaodModalFooterProps = {
  disabled: boolean;
  getDownloadURL: () => Promise<DownloadURL>;
  onClose: () => void;
};

type SiteMultiSelectProps = {
  siteList: Site[];
  sitesChanged: (sites: Array<Site>) => void;
};

/** ============================= Components =============================== */

export const SiteMultiSelect: React.FC<SiteMultiSelectProps> = ({ siteList, sitesChanged }) => {
  const [sites, setSites] = useState<Site[]>(siteList);

  useEffect(() => {
    sitesChanged(sites);
  }, [sites, sitesChanged]);

  return (
    <Grid noMargin>
      <Grid.Item span={3}>
        <Checkbox
          color="primary"
          label={`${sites.length} of ${siteList.length} selected`}
          checked={siteList.length === sites.length}
          indeterminate={sites.length > 0 && sites.length < siteList.length}
          onChange={() => {
            if (sites.length) {
              setSites([]);
            } else {
              setSites(siteList);
            }
          }}
        ></Checkbox>
      </Grid.Item>
      <Grid.Item span={9}>
        <MultiSelect
          label="Sites"
          sorted={true}
          options={siteList}
          value={sites}
          valueOption="id"
          onChange={setSites}
          renderOption="name"
        />
      </Grid.Item>
    </Grid>
  );
};

export const DownloadModalFooter: React.FC<DownlaodModalFooterProps> = ({
  disabled,
  getDownloadURL,
  onClose,
}) => {
  const [downloadURL, setDownloadURL] = useState<DownloadURL>({ url: '' });
  const [downloading, setDownloading] = useState<boolean>(false);

  useEffect(() => {
    if (downloadURL.url.length) {
      const elem = document.querySelector('#org-interval-download-id') as HTMLElement;
      elem.click();
    }
  }, [downloadURL]);

  return (
    <>
      <a
        href={downloadURL?.url}
        hidden={downloadURL?.url.length <= 3}
        download={true}
        id="org-interval-download-id"
      >
        Click here if download did not start automatically.
      </a>
      <Typography color="error" hidden={!downloadURL.error}>
        {downloadURL.error?.exc_message}
      </Typography>
      <Button color="primary" disabled={disabled || downloading} onClick={performDownload}>
        {downloading ? <LoadingCircle /> : 'Download'}
      </Button>
      <Button onClick={onClose}>Close</Button>
    </>
  );
  /** ======================= Callbacks ==================================== */
  async function performDownload() {
    setDownloadURL({ url: '' });
    setDownloading(true);
    const { url, error } = await getDownloadURL();
    setDownloadURL({ url, error });
    setDownloading(false);
  }
};
