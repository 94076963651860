import _ from 'lodash';
import * as React from 'react';

import { Accordion, Box, Card, Grid, Typography } from 'onescreen/components';
import { RatePlanVariation, RateTypeVariation } from 'onescreen/models';
import { Maybe } from 'onescreen/types';

/** ======================== Types ========================================= */
type VariationListProps = { variations: RateTypeVariation[] };
type RatePlanVariationsProps = { variations: Maybe<RatePlanVariation[]> };

/** ======================== Components ==================================== */
const VariationList: React.FC<VariationListProps> = ({ variations }) => (
  <div>
    {variations.map((rateTypeVariation) => (
      <div key={rateTypeVariation.id}>
        <Box component="span" display="inline-block" textAlign="center" width={25}>
          <Typography color={rateTypeVariation.action ? 'success' : 'error'} variant="body1">
            {rateTypeVariation.action ? '+' : '-'}
          </Typography>
        </Box>
        <Typography>{rateTypeVariation.RateType?.description}</Typography>
      </div>
    ))}
  </div>
);

export const RatePlanVariations: React.FC<RatePlanVariationsProps> = ({ variations }) => {
  if (!variations || variations.length === 0) {
    return (
      <Card padding={10} raised={false}>
        <Typography>No variations</Typography>
      </Card>
    );
  }

  return (
    <>
      {variations.map(({ id, name, RateTypeVariations }) => {
        // Split variations into additions and removals
        const [additions, removals] = _.partition(RateTypeVariations, 'action');
        const noVariations = _.isEmpty(RateTypeVariations);
        return (
          <Accordion heading={`${id}: ${name}`} key={id}>
            {noVariations ? (
              <Typography>No variations</Typography>
            ) : (
              <Grid>
                {!_.isEmpty(additions) && (
                  <Grid.Item span={6}>
                    <VariationList variations={additions} />
                  </Grid.Item>
                )}

                {!_.isEmpty(removals) && (
                  <Grid.Item span={6}>
                    <VariationList variations={removals} />
                  </Grid.Item>
                )}
              </Grid>
            )}
          </Accordion>
        );
      })}
    </>
  );
};
