import _ from 'lodash';

import { store } from 'onescreen/store';
import { IdType, Maybe, ObjectWithoutType } from 'onescreen/types';
import { RateType } from '.';

/** ======================== Types ========================================= */
type CommonAttrs = {
  action: boolean;
  id: IdType;
  object_type: 'RateTypeVariation';
  rate_type: RateType['id'];
};

export declare namespace RateTypeVariation {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}
}

/** ======================== Model ========================================= */
export interface RateTypeVariation extends CommonAttrs {}
export class RateTypeVariation {
  /** ====================== Static fields ================================= */
  static readonly rawFields = ['action', 'id', 'rate_type'] as const;

  /**
   * Parses an array of raw RateTypeVariation objects by passing each in turn to
   * RateTypeVariation.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {RateTypeVariation.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: RateTypeVariation.Raw[]): RateTypeVariation[];
  static fromObjects(raw: Maybe<RateTypeVariation.Raw[]>): Maybe<RateTypeVariation[]>;
  static fromObjects(raw: Maybe<RateTypeVariation.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: RateTypeVariation.Raw): RateTypeVariation;
  static fromObject(raw: Maybe<RateTypeVariation.Raw>): Maybe<RateTypeVariation>;
  static fromObject(raw: Maybe<RateTypeVariation.Raw>) {
    return raw ? new RateTypeVariation(raw) : undefined;
  }

  static fromStore(): RateTypeVariation[];
  static fromStore(id: Maybe<RateTypeVariation['id']>): Maybe<RateTypeVariation>;
  static fromStore(id?: RateTypeVariation['id']) {
    const { rateTypeVariations } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(rateTypeVariations)).map(RateTypeVariation.fromObject);
    } else {
      return id ? RateTypeVariation.fromObject(rateTypeVariations[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'RateTypeVariation';
  constructor(raw: RateTypeVariation.Raw) {
    Object.assign(this, _.pick(raw, RateTypeVariation.rawFields));
  }

  get RateType() {
    return RateType.fromStore(this.rate_type);
  }

  serialize(): RateTypeVariation.Serialized {
    return { ..._.pick(this, RateTypeVariation.rawFields), object_type: this.object_type };
  }
}
