import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import {
  VictoryChart,
  VictoryLine,
  VictoryLegend,
  VictoryAxis,
  VictoryTheme,
  VictoryLabel,
  VictoryZoomContainer,
} from 'victory';
import { Button, Grid } from '..';
import { useIntervalFileIntervals } from '../../hooks/interval';
import { IntervalFile, IntervalStream } from '../../models';
import { DateTuple, Maybe } from '../../types';
import { formatters } from '../../utils';

/**================================= Types ================================= */
export type IntervalDatum = {
  x: number;
  y: number;
};
type IntervalDataStream = {
  name: string;
  color: string;
  key: number;
  dataFn?: (values: IntervalStream) => IntervalStream | undefined;
  stream?: IntervalStream;
};
type IntervalGraphProps = {
  title: string;
  id: IntervalFile['id'];
  bounds?: DateTuple;
  streams: IntervalDataStream[];
  defaultKey?: IntervalStream['key'];
  override?: boolean;
  paramsUpdated?: (params: IntervalFile.API.RetrieveIntervalsParams) => void;
};
type AggType = IntervalFile.API.RetrieveIntervalsParams['agg'];
type FreqType = IntervalFile.API.RetrieveIntervalsParams['freq'];
type FreqOptions = { key: FreqType; title: string }[];
type AggOptions = { key: AggType; title: string }[];

/** ============================== Constants =============================== */
const oneHour = 3600000;
const freqOptions: FreqOptions = [
  { key: '1M', title: '1 Month' },
  { key: '1D', title: '1 Day' },
  { key: '1H', title: '1 Hour' },
  { key: '15Min', title: '15 Minutes' },
];

const aggOptions: AggOptions = [
  { key: 'mean', title: 'Average' },
  { key: 'sum', title: 'Sum' },
  { key: 'max', title: 'Maximum' },
  { key: 'min', title: 'Minimum' },
];

/**=============================== Functions =============================== */
function convertToGraphData(streamValue?: IntervalStream['value']): IntervalDatum[] {
  return streamValue
    ? Object.keys(streamValue || {}).map((key) => {
        const numKey = Number(key);
        return { x: numKey, y: streamValue[numKey]! };
      })
    : [];
}

/**============================== Components =============================== */
export const IntervalGraph: React.FC<IntervalGraphProps> = (props) => {
  const {
    id,
    title,
    streams,
    bounds,
    defaultKey = 'mean*1M',
    override = false,
    paramsUpdated,
  } = props;

  const [dataStreams, setDataStreams] = useState<{ [key: number]: IntervalDatum[] }>({});
  const [window, setWindow] = useState<Maybe<DateTuple>>(bounds);
  const [boundary, setBoundary] = useState<Maybe<DateTuple>>(bounds);
  const [agg, setAgg] = useState<AggType>(defaultKey.split('*')[0] as AggType);
  const [freq, setFreq] = useState<FreqType>(defaultKey.split('*')[1] as FreqType);
  const [range, setRange] = useState<[number, number]>([0, 1]);
  const [fetched, setFetched] = useState(false);

  const updateDataStreams = useCallback(
    (response?: IntervalStream) => {
      let newDataStreams = {};
      streams.forEach((streamObj) => {
        let altStream = streamObj.stream;
        if (streamObj.dataFn && response) {
          altStream = streamObj.dataFn(response);
        }
        if (!altStream) {
          return;
        }
        const streamData = altStream.value;
        newDataStreams = {
          ...newDataStreams,
          [streamObj.key]: streamData ? convertToGraphData(streamData) : [],
        };
      });
      setDataStreams(newDataStreams);
      setFetched(true);
    },
    [streams]
  );

  useIntervalFileIntervals(
    id,
    {
      start: window ? window[0].toISODate() : undefined,
      end: window ? window[1].toISODate() : undefined,
      agg,
      freq,
    },
    !override && !fetched,
    [fetched],
    (response) => {
      if (!response) return;
      updateDataStreams(response);
    }
  );

  useEffect(() => {
    if (override) updateDataStreams();
  }, [fetched, override, updateDataStreams]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      !!paramsUpdated &&
        paramsUpdated({
          start: window ? window[0].toISODate() : undefined,
          end: window ? window[1].toISODate() : undefined,
          agg,
          freq,
        });
      setFetched(false);
    }, 250);
    return () => {
      clearTimeout(timeout);
    };
  }, [window, agg, freq, override, paramsUpdated]);

  useEffect(() => {
    setBoundary((curr) => {
      if (!curr) return window;
      return curr;
    });
  }, [window, setBoundary]);

  useEffect(() => {
    let maxs: number[] = [];
    let mins: number[] = [0];
    Object.keys(dataStreams).forEach((streamKey) => {
      const valueStream = dataStreams[+streamKey];
      const values = valueStream.map(({ y }) => y);
      if (!!values && values.length) {
        maxs.push(_.max(values)!);
        mins.push(_.min(values)!);
      }
    });
    if (mins.length && maxs.length) {
      setRange([_.min(mins)!, _.max(maxs)!]);
    }
  }, [dataStreams]);

  return (
    <Grid justify="center">
      <Grid.Item>
        <Button.Group>
          {freqOptions.map(({ key, title }) => (
            <Button
              key={key}
              onClick={() => setFreq(key)}
              color={freq === key ? 'primary' : undefined}
            >
              {title}
            </Button>
          ))}
        </Button.Group>
      </Grid.Item>
      <Grid.Item>
        <Button.Group>
          {aggOptions.map(({ key, title }) => (
            <Button
              key={key}
              onClick={() => setAgg(key)}
              color={agg === key ? 'primary' : undefined}
            >
              {title}
            </Button>
          ))}
        </Button.Group>
      </Grid.Item>

      <Grid.Item span={12}>
        <VictoryChart
          padding={{ left: 80, bottom: 50, top: 50 }}
          scale={{ x: 'time' }}
          width={1600}
          theme={VictoryTheme.material}
          domain={{
            x: boundary ? [boundary[0].toMillis(), boundary[1].toMillis()] : undefined,
            y: range,
          }}
          containerComponent={
            <VictoryZoomContainer
              zoomDimension="x"
              onZoomDomainChange={onZoom}
              zoomDomain={{
                y: range,
              }}
              minimumZoom={{ x: oneHour * 12 }}
            />
          }
          domainPadding={{ x: [30, 0], y: 10 }}
        >
          {Object.values(streams).map((stream) => {
            if (!_.keys(dataStreams).includes(stream.key.toString())) return null;
            return (
              <VictoryLine
                key={stream.key}
                data={dataStreams[stream.key]}
                style={{ data: { stroke: stream.color } }}
              />
            );
          })}
          <VictoryLegend
            title={title}
            centerTitle
            x={80}
            orientation="horizontal"
            data={Object.values(streams).map((stream) => ({
              name: stream.name,
              symbol: { fill: stream.color },
            }))}
            labelComponent={<VictoryLabel />}
          />
          <VictoryAxis crossAxis tickCount={12} />
          <VictoryAxis
            crossAxis
            tickCount={3}
            tickLabelComponent={<VictoryLabel dy={25} />}
            tickFormat={(d) => {
              const datetime = DateTime.fromJSDate(new Date(d));
              return formatters.date.monthDayYear(datetime);
            }}
          />
          <VictoryAxis dependentAxis label="kWh" axisLabelComponent={<VictoryLabel dy={-50} />} />
        </VictoryChart>
      </Grid.Item>
    </Grid>
  );
  /** ================================= Callbacks ========================== */

  function onZoom(domain: any, props: any) {
    const [firstKey, lastKey] = domain.x;
    const firstDate = DateTime.fromMillis(firstKey);
    const lastDate = DateTime.fromMillis(lastKey);
    setWindow([firstDate, lastDate]);
  }
};
