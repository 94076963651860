import _ from 'lodash';

import { slices, store } from 'onescreen/store';
import { Maybe, ObjectWithoutType, ObjectWithType, Query } from 'onescreen/types';
import { ServiceAgreement } from '../assets';

import { BaseAPI } from '../base';
import { DateRange } from '../dates';
import { ServiceAnalysis } from './service_analysis';
/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'AnalysisPeriod'> & {
  id: number;
  service_agreement: ServiceAgreement['id'];
};

export declare namespace AnalysisPeriod {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs, DateRange.Raw {}

  // Network interface
  export namespace API {
    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResponse = { analysis_period: AnalysisPeriod };
    export type RetrieveResult = { analysisPeriod: AnalysisPeriod.Raw };

    export type CreateParams = {
      serviceAgreement: ServiceAgreement;
      serviceAnalysis: ServiceAnalysis;
      start_date?: string;
      end_date?: string;
    };
    export type CreateResponse = RetrieveResponse;
    export type CreateResult = RetrieveResult;

    export type UpdateParams = {
      end_date: string;
      start_date: string;
    };
    export type UpdateResponse = RetrieveResponse;
    export type UpdateResult = RetrieveResult;
  }
}

/** ======================== API =========================================== */
class AnalysisPeriodAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.analysisPeriod;

  static async update(
    id: AnalysisPeriod['id'],
    params: AnalysisPeriod.API.UpdateParams
  ): Promise<AnalysisPeriod.API.UpdateResult> {
    const { json } = await this.patch<AnalysisPeriod.API.UpdateResponse>(this.route(id), params);

    // Parse response and add to store
    const analysisPeriod = new AnalysisPeriod(json.analysis_period);
    store.dispatch(slices.data.updateModels(analysisPeriod));

    return { analysisPeriod };
  }

  static async create(
    params: AnalysisPeriod.API.CreateParams
  ): Promise<AnalysisPeriod.API.CreateResult> {
    const { serviceAgreement, serviceAnalysis, ...rest } = params;
    const { json } = await this.post<AnalysisPeriod.API.CreateResponse>(this.route, {
      service_agreement: serviceAgreement.id,
      service_analysis: serviceAnalysis.id,
      ...rest,
    });

    // Parse response and add to store
    const analysisPeriod = new AnalysisPeriod(json.analysis_period);
    store.dispatch(slices.data.updateModels(analysisPeriod));

    return { analysisPeriod };
  }

  static async destroy(id: AnalysisPeriod['id']): Promise<void> {
    await this.delete(this.route(id));
  }

  static async retrieve(
    id: AnalysisPeriod['id'],
    params?: AnalysisPeriod.API.RetrieveParams
  ): Promise<AnalysisPeriod.API.RetrieveResult> {
    const { json } = await this.get<AnalysisPeriod.API.RetrieveResponse>(this.route(id), params);

    // Parse response and add to store
    const analysisPeriod = new AnalysisPeriod(json.analysis_period);
    store.dispatch(slices.data.updateModels(analysisPeriod));

    return { analysisPeriod };
  }
}

/** ======================== Model ========================================= */
export interface AnalysisPeriod extends CommonAttrs {}
export class AnalysisPeriod extends DateRange {
  /** ====================== Static fields ================================= */
  static api = AnalysisPeriodAPI;
  static readonly rawFields = ['id', 'service_agreement'] as const;

  /**
   * Parses an array of raw AnalysisPeriod objects by passing each in turn to
   * AnalysisPeriod.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {AnalysisPeriod.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: AnalysisPeriod.Raw[]): AnalysisPeriod[];
  static fromObjects(raw: Maybe<AnalysisPeriod.Raw[]>): Maybe<AnalysisPeriod[]>;
  static fromObjects(raw: Maybe<AnalysisPeriod.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: AnalysisPeriod.Raw): AnalysisPeriod;
  static fromObject(raw: Maybe<AnalysisPeriod.Raw>): Maybe<AnalysisPeriod>;
  static fromObject(raw: Maybe<AnalysisPeriod.Raw>) {
    return raw ? new AnalysisPeriod(raw) : undefined;
  }

  static fromStore(): AnalysisPeriod[];
  static fromStore(id: Maybe<AnalysisPeriod['id']>): Maybe<AnalysisPeriod>;
  static fromStore(id?: AnalysisPeriod['id']) {
    const { analysisPeriod } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(analysisPeriod)).map(AnalysisPeriod.fromObject);
    } else {
      return id ? AnalysisPeriod.fromObject(analysisPeriod[id]) : undefined;
    }
  }

  /** ====================== Static fields ================================= */
  readonly object_type = 'AnalysisPeriod';
  constructor(raw: AnalysisPeriod.Raw) {
    super(raw);
    Object.assign(this, _.pick(raw, AnalysisPeriod.rawFields));
  }

  get ServiceAgreement() {
    return ServiceAgreement.fromStore(this.service_agreement);
  }

  serialize(): AnalysisPeriod.Serialized {
    return {
      ...this.serializedDateRange,
      ..._.pick(this, AnalysisPeriod.rawFields),
      object_type: this.object_type,
    };
  }
}
