import _ from 'lodash';
import React, { useState } from 'react';

import {
  Button,
  Card,
  CardCornerMenu,
  Grid,
  Loading,
  Select,
  Typography,
} from 'onescreen/components';
import { IntervalFileBar } from 'onescreen/components/Interval';
import { Meter, ServiceDrop, Site, IntervalFile } from '../../common/models';
import { useMeter, useMeters } from '../../common/hooks/meter';
import { HasUserPermissions } from 'onescreen/hooks/user';

/** ================================ Types ================================= */
type MeterItemProps = {
  meterId: Meter['id'];
  serviceDrop: ServiceDrop;
};

type MeterAttachFormProps = {
  site: Site;
  serviceDrop: ServiceDrop;
  visible: boolean;
  onCancel: () => void;
  onAddMeter: () => void;
};

/** ============================= Components =============================== */

export const MeterItem: React.FC<MeterItemProps> = ({ meterId, serviceDrop }) => {
  const [fetching, setFetching] = useState(false);
  const { meter } = useMeter(meterId, undefined, false);
  const hasPermission = HasUserPermissions(['OrgEditors', 'OrgFullAccessEditors']);
  const demo_org = serviceDrop.Site?.Org?.is_test

  if (!meter) return <Loading />;
  const meterInfo = (
    <div>
      <span className="grey-text right truncate" style={{ maxWidth: '20%' }}>
        {meter.utilityapi_utility_id}
      </span>
      <span className="card-title truncate">{meter.service_tariff}</span>
      <span className="grey-text">{meter.service_identifier}</span>
    </div>
  );
  const ifileInfo = (
    <IntervalFileBar
      id={meter.interval_file}
      title={`Meter Usage data ${meter.service_identifier}`}
      fetching={fetching}
    />
  );

  const menu = {
    collect_intervals: {
      label: 'COLLECT INTERVALS',
      callback: async (meterId: Meter['id']) => {
        setFetching(true);
        const { interval_file_id } = await Meter.api.collect_intervals(meterId);
        await Meter.api.retrieve(meterId, { include: ['interval_file'] });
        await IntervalFile.api.retrieve(interval_file_id);
        setFetching(false);
      },
    },
    collect_bills: {
      label: 'COLLECT BILLS',
      callback: async (meterId: Meter['id']) => {
        await Meter.api.collect_bills(meterId);
        ServiceDrop.api.retrieve(serviceDrop.id, {
          include:
            [!demo_org ? 'meters.api_bills.*' : 'demo_meters.api_bills.*']
        });
      },
    },
    delete: {
      label: 'DETACH',
      callback: async (meterId: Meter['id']) => {
        await Meter.api.update(meterId,
          !demo_org ? { service_drop: null } : {demo_service_drop: null});
        ServiceDrop.api.retrieve(serviceDrop.id, {
          include:
            [!demo_org ? 'meters.*' : 'demo_meters.*']
        });
      },
      hide: !hasPermission,
    },
  };

  return (
    <Card>
      {meter.is_primary ? (
        <i
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            padding: '3px',
          }}
          className="material-icons"
        >
          star
        </i>
      ) : (
        ''
      )}
      <Grid justify="flex-end">
        <Grid.Item>
          <CardCornerMenu objId={meter.id} menu={menu} />
        </Grid.Item>
      </Grid>
      <Card.Content>
        {meterInfo}
        {ifileInfo}
      </Card.Content>
    </Card>
  );
};

export const MeterAttachForm: React.FC<MeterAttachFormProps> = ({
  site,
  serviceDrop,
  onCancel,
  visible,
  onAddMeter,
}) => {
  let [meter, setMeter] = useState<Meter>();
  const { meters } = useMeters(
    {
      limit: 1000,
      offset: 0,
      filter: !site.Org?.is_test ? {
        'authorization.org': { operation: 'equals', value: site.org! },
        'service_drop.isnull': { operation: 'equals', value: true },
      } : {
        'authorization.demo_org': { operation: 'equals', value: site.org! },
        'demo_service_drop.isnull': { operation: 'equals', value: true },
      },
    },
    undefined,
    visible
  );

  return (
    <>
      {visible && (
        <Card hidden={!visible}>
          <Card.Content>
            <Grid justify="flex-end">
              <Typography variant="h5">Attach a Meter</Typography>
              <Grid.Item span={12}>
                <Typography>(SAID) Meter Address</Typography>
                <Select
                  fullWidth
                  onChange={setMeter}
                  options={_.sortBy(meters?.results, 'service_identifier')}
                  renderOption={(m) => `(${m.service_identifier}) ${m.service_address}`}
                  value={meter}
                  valueOption="id"
                />
              </Grid.Item>
              <Grid.Item>
                <Button onClick={attachMeter} color="primary">
                  Attach
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button onClick={onCancel}>cancel</Button>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      )}
    </>
  );

  /** ============================ Callbacks =============================== */
  async function attachMeter() {
    await Meter.api.update(meter?.id!,
      !site.Org?.is_test ?
        { service_drop: serviceDrop.id } :
        { demo_service_drop: serviceDrop.id }).then(onAddMeter);
    ServiceDrop.api.retrieve(serviceDrop.id, {
      include: !site.Org?.is_test ? ['meters.*'] : ['demo_meters.*']
    });
  }
};
