/**
 * This is a wrapper around the handy iframe-resizer library. Inspired by the
 * react-iframe-resizer-super package, which is no longer supported but is nevertheless easier
 * to use than the "official" iframe-resizer-react package.
 */

import _ from 'lodash';
import { iframeResizer, IFrameOptions } from 'iframe-resizer';
import * as React from 'react';
import ReactDOM from 'react-dom';

import { Logger } from 'onescreen/utils';

import { ErrorBoundary } from './ErrorBoundary';

/** ======================== Constants ===================================== */
const RESIZER_CONTENT_WINDOW_URL = '/js/iframeResizer.contentWindow.min.js';

/** ======================== Types ========================================= */
type IFrameProps = React.PropsWithChildren<{
  content: string | React.ReactElement;
  src?: string;
  iframeResizerOptions?: IFrameOptions;
  id: string;
  frameBorder?: number;
  className?: string;
  title: string;
}>;

/** ======================== Components ==================================== */
export class IFrame extends React.Component<IFrameProps> {
  iFrame: React.RefObject<HTMLIFrameElement>;

  constructor(props: IFrameProps) {
    super(props);
    this.iFrame = React.createRef();
  }

  componentDidMount() {
    this.updateIframe(this.props);
    this.resizeIframe(this.props);
  }

  componentWillUnmount() {
    // React will remove the iframe, however we need to manually
    // call iframe-resizer to stop its listeners
    // @ts-ignore
    const iframeResizer = this.iFrame.iFrameResizer;
    iframeResizer && iframeResizer.removeListeners();
  }

  /**
   * Quick access to the iframe's contentDocument attribute. Note that the contentDocument of an
   * iframe on a different domain will not be accessible, and attempting to access it will throw
   * a DOMException error. This can be addressed by passing the frame contents using the `content`
   * prop or as component children instead of using the `src` prop.
   */
  get contentDocument() {
    return this.iFrame.current?.contentDocument;
  }

  updateIframe = (props: IFrameProps) => {
    // has src - no injected content
    if (props.src) return;

    // do we have content to inject (content or children)
    const content = props.content || props.children;
    if (!content) return;

    const doc = this.iFrame.current?.contentDocument;
    if (!doc) return;

    // Replace iframe document content
    if (props.content) {
      if (typeof props.content === 'string') {
        this.renderString(props.content);
      } else {
        this.renderElement(props.content);
      }
    } else if (props.children) {
      this.renderElement(React.createElement('div', null, props.children));
    }
  };

  // inject the iframe resizer "content window" script
  injectIframeResizerUrl = () => {
    const doc = this.contentDocument;
    if (!doc) return;

    const possibleTagNames = ['head', 'HEAD', 'body', 'BODY', 'div', 'DIV'];
    const injectTargetTagName = _.find(possibleTagNames, (tagName) => {
      return doc.getElementsByTagName(tagName).length > 0;
    });

    if (!injectTargetTagName) {
      Logger.error('Unable to inject iframe resizer script');
      return;
    }

    const injectTarget = _.head(doc.getElementsByTagName(injectTargetTagName));
    const resizerScriptElement = document.createElement('script');
    resizerScriptElement.type = 'text/javascript';
    resizerScriptElement.src = RESIZER_CONTENT_WINDOW_URL;
    injectTarget!.appendChild(resizerScriptElement);
  };

  onLoad = () => {
    this.injectIframeResizerUrl();
  };

  resizeIframe = (props: IFrameProps) => {
    const frame = this.iFrame.current;
    if (!frame) return;
    iframeResizer({ checkOrigin: false, ...props.iframeResizerOptions }, frame);
  };

  renderString(str: string) {
    const doc = this.contentDocument;
    if (!doc) return;

    doc.open();
    doc.write(decodeURIComponent(str.slice(29)));
    doc.close();
  }

  renderElement(element: React.ReactElement) {
    const doc = this.contentDocument;
    if (!doc) return;

    doc.open();
    doc.write('<div id="iframe-root"></div>');
    doc.close();
    ReactDOM.render(element, doc.getElementById('iframe-root'));
  }

  render() {
    const { src, id, frameBorder = 0, className, title } = this.props;
    return (
      <ErrorBoundary>
        <iframe
          ref={this.iFrame}
          src={src}
          id={id}
          frameBorder={frameBorder}
          className={className}
          onLoad={this.onLoad}
          style={{ width: '100%' }}
          title={title}
        />
      </ErrorBoundary>
    );
  }
}
