import * as React from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';

import { Icon, ValidIcon, IconProps } from './Icon';

/** ============================ Types ===================================== */
export type CheckboxProps = {
  className?: string;
  color?: 'primary' | 'secondary' | 'default';
  disabled?: boolean;
  icon?: ValidIcon;
  label: string | React.ReactNode;
  checked: boolean;
  onChange?: (event: React.ChangeEvent<HTMLDivElement>) => void;
  iconColor?: IconProps['color'];
  size?: 'small' | 'medium';
  indeterminate?: boolean;
};

/** ============================ Components ================================ */
export const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(
  function OneScreenCheckbox(props, ref) {
    const { color = 'default', disabled = false, icon, onChange, label, ...rest } = props;
    const checkboxProps = {
      color,
      disabled,
      icon: icon ? <Icon name={icon} /> : undefined,
      onChange: disabled ? undefined : onChange,
      ref,
      ...rest,
    };

    return <MuiFormControlLabel control={<MuiCheckbox {...checkboxProps} />} label={label} />;
  }
);
