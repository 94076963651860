/**
 * This is a reusable component so that it can be used both during App initialization and in tests
 * that rely on having access to the `theme` context.
 */
import * as React from 'react';
import MuiThemeProvider from '@material-ui/styles/ThemeProvider';

import { theme } from 'onescreen/styles';

/** ======================== Components ==================================== */
export const ThemeProvider: React.FC = (props) => (
  <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
);
