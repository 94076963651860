import * as React from 'react';
import { DateTime } from 'luxon';

import { Nullable, Tuple } from 'onescreen/types';
import { formatters } from 'onescreen/utils';

import { Typography } from './Typography';
import { Grid } from './Grid';
import { TextField } from './TextField';

/** ======================== Types ========================================= */
type StandardDateProps = { date?: Nullable<DateTime | string> };
type StandardDateRangeProps = { range?: Nullable<Tuple<DateTime>> };

export type DateSpanPickerProps = {
  disabled: boolean;
  datesDidChange: (startDate: DateTime, endDate: DateTime) => void;
  defaultStartDate: DateTime;
  defaultEndDate: DateTime;
  options?: { maxDate?: Date; minDate?: Date };
};

/** ======================== Components ==================================== */
const DateRange: React.FC<StandardDateRangeProps> = ({ range }) => (
  <Typography variant="body2">
    {range ? formatters.date.range(range, formatters.date.standard) : '-'}
  </Typography>
);

export const StandardDate = Object.assign(
  function StandardDate({ date }: StandardDateProps) {
    if (!date) return null;
    return (
      <Typography noWrap variant="body2">
        {formatters.date.standard(date)}
      </Typography>
    );
  },
  { Range: DateRange }
);

export const DateSpanPicker: React.FC<DateSpanPickerProps> = ({
  disabled = false,
  datesDidChange,
  defaultStartDate,
  defaultEndDate,
  options = {},
}) => {
  const [startDate, setStart] = React.useState<string>(defaultStartDate.toISODate());
  const [endDate, setEnd] = React.useState<string>(defaultEndDate.toISODate());
  const [startError, setStartError] = React.useState(false);
  const [endError, setEndError] = React.useState(false);

  React.useEffect(() => {
    const start = DateTime.fromISO(startDate);
    const end = DateTime.fromISO(endDate);
    if (start && end) {
      datesDidChange(start, end);
    }
    setStartError(!start.isValid);
    setEndError(!end.isValid);
  }, [startDate, endDate, datesDidChange]);

  return (
    <Grid noMargin>
      <Grid.Item span={6}>
        <Typography
          color={startError ? 'error' : 'textPrimary'}
          emphasis={disabled ? 'disabled' : undefined}
        >
          Start Date
        </Typography>
        <TextField
          error={startError}
          disabled={disabled}
          type="date"
          value={startDate}
          onChange={setStart}
        />
      </Grid.Item>
      <Grid.Item span={6}>
        <Typography
          color={endError ? 'error' : 'textPrimary'}
          emphasis={disabled ? 'disabled' : undefined}
        >
          End Date
        </Typography>
        <TextField
          error={endError}
          disabled={disabled}
          type="date"
          value={endDate}
          onChange={setEnd}
        />
      </Grid.Item>
    </Grid>
  );
};
