import _ from 'lodash';

import { slices, store } from 'onescreen/store';
import { Maybe, ObjectWithoutType, Pagination, Query } from 'onescreen/types';
import { ApiBill, Bill, BillValidation, ValidationCase } from '..';
import { BaseAPI } from '../base';

import {
  BundledRatePlan,
  CCARatePlan,
  ConnectionLevel,
  RatePlanVariation,
  RateTypeVariation,
} from '../rates';

/** ======================== Types ========================================= */
type CommonAttrs = {
  bundled_rate_plan: BundledRatePlan['id'];
  bundled_variations: Array<RatePlanVariation>;
  cca_vintage?: number;
  connection_level: ConnectionLevel['id'];
  id: number;
  name: string;
  object_type: 'ServiceAgreement';
  standby_reserve?: string;
  generation_rate_plan?: CCARatePlan['id'];
  validation_cases?: Array<ValidationCase['id']>;
};

export declare namespace ServiceAgreement {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {
    power_factor: string;
  }
  export namespace API {
    export type SideloadedResponses = {
      bundled_rate_plans?: BundledRatePlan.Raw[];
      rate_type_variations?: RateTypeVariation.Raw[];
      cca_rate_plans?: CCARatePlan.Raw[];
      connection_levels?: ConnectionLevel.Raw[];
      validation_cases?: ValidationCase.Raw[];
      bill_validations?: BillValidation.Raw[];
      bills?: Bill.Raw[];
      api_bills?: ApiBill.Raw[];
    };

    export type CreateParams = Pick<
      Raw,
      'bundled_rate_plan' | 'cca_vintage' | 'connection_level' | 'name' | 'generation_rate_plan'
    > & {
      power_factor: number;
      bundled_variations: Array<RatePlanVariation['id']>;
    };
    export type CreateResponse = { service_agreement: ServiceAgreement.Raw };
    export type CreateResult = { serviceAgreement: ServiceAgreement };

    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResponse = {
      service_agreement: ServiceAgreement.Raw;
    } & SideloadedResponses;
    export type RetrieveResult = { service_agreement: ServiceAgreement };

    export type ListParams = Query.DynamicRestParams;
    export type ListResponse = Pagination.Response.Raw<
      {
        service_agreements: ServiceAgreement.Raw[];
      } & SideloadedResponses
    >;
    export type ListResult = Pagination.Response.Parsed<ServiceAgreement>;
  }
}

/** ======================== API =========================================== */
class ServiceAgreementAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.serviceAgreement;

  static async create(
    params: ServiceAgreement.API.CreateParams
  ): Promise<ServiceAgreement.API.CreateResult> {
    const { json } = await this.post<ServiceAgreement.API.CreateResponse>(this.route, params);

    // Parse response and add to store
    const serviceAgreement = new ServiceAgreement(json.service_agreement);
    store.dispatch(slices.data.updateModels(serviceAgreement));

    return { serviceAgreement };
  }

  static async retrieve(
    id: ServiceAgreement['id'],
    params?: ServiceAgreement.API.RetrieveParams
  ): Promise<ServiceAgreement.API.RetrieveResult> {
    const { json } = await this.get<ServiceAgreement.API.RetrieveResponse>(this.route(id), params);

    // Parse response
    const {
      bundled_rate_plans,
      cca_rate_plans,
      connection_levels,
      rate_type_variations,
      validation_cases,
      bill_validations,
      bills,
      api_bills,
    } = this.parseSideloadedObjects(json);
    const service_agreement = ServiceAgreement.fromObject(json.service_agreement);

    store.dispatch(
      slices.data.updateModels(
        service_agreement,
        bundled_rate_plans,
        rate_type_variations,
        cca_rate_plans,
        connection_levels,
        validation_cases,
        bill_validations,
        bills,
        api_bills
      )
    );
    return { service_agreement };
  }

  static async list(
    params?: ServiceAgreement.API.ListParams
  ): Promise<ServiceAgreement.API.ListResult> {
    const { json } = await this.get<ServiceAgreement.API.ListResponse>(this.route, params);

    // Parse response
    const { bundled_rate_plans, cca_rate_plans, connection_levels } = this.parseSideloadedObjects(
      json.results
    );
    const service_agreements = this.parsePaginationSet(json, ({ service_agreements }) =>
      ServiceAgreement.fromObjects(service_agreements)
    );

    store.dispatch(
      slices.data.updateModels(
        service_agreements.results,
        bundled_rate_plans,
        cca_rate_plans,
        connection_levels
      )
    );
    return service_agreements;
  }

  static parseSideloadedObjects(json: ServiceAgreement.API.SideloadedResponses) {
    return {
      bundled_rate_plans: BundledRatePlan.fromObjects(json.bundled_rate_plans),
      rate_type_variations: RateTypeVariation.fromObjects(json.rate_type_variations),
      cca_rate_plans: CCARatePlan.fromObjects(json.cca_rate_plans),
      connection_levels: ConnectionLevel.fromObjects(json.connection_levels),
      validation_cases: ValidationCase.fromObjects(json.validation_cases),
      bill_validations: BillValidation.fromObjects(json.bill_validations),
      bills: Bill.fromObjects(json.bills),
      api_bills: ApiBill.fromObjects(json.api_bills),
    };
  }
}

/** ======================== Model ========================================= */
export interface ServiceAgreement extends CommonAttrs {}
export class ServiceAgreement {
  /** ====================== Static fields ================================= */
  static api = ServiceAgreementAPI;
  static readonly rawFields = [
    'bundled_rate_plan',
    'cca_vintage',
    'connection_level',
    'id',
    'name',
    'standby_reserve',
    'bundled_variations',
    'generation_rate_plan',
    'validation_cases',
  ] as const;

  /**
   * Parses an array of raw ServiceAgreement objects by passing each in turn to
   * ServiceAgreement.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {ServiceAgreement.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: ServiceAgreement.Raw[]): ServiceAgreement[];
  static fromObjects(raw: Maybe<ServiceAgreement.Raw[]>): Maybe<ServiceAgreement[]>;
  static fromObjects(raw: Maybe<ServiceAgreement.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: ServiceAgreement.Raw): ServiceAgreement;
  static fromObject(raw: Maybe<ServiceAgreement.Raw>): Maybe<ServiceAgreement>;
  static fromObject(raw: Maybe<ServiceAgreement.Raw>) {
    return raw ? new ServiceAgreement(raw) : undefined;
  }

  static fromStore(): ServiceAgreement[];
  static fromStore(id: Maybe<ServiceAgreement['id']>): Maybe<ServiceAgreement>;
  static fromStore(id?: ServiceAgreement['id']) {
    const { serviceAgreements } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(serviceAgreements)).map(ServiceAgreement.fromObject);
    } else {
      return id ? ServiceAgreement.fromObject(serviceAgreements[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'ServiceAgreement';
  power_factor: number;
  private _bundledRatePlan?: BundledRatePlan;
  private _generationRatePlan?: CCARatePlan;
  private _bundledVariations?: RatePlanVariation[];
  private _connectionLevel?: ConnectionLevel;

  constructor(raw: ServiceAgreement.Raw) {
    Object.assign(this, _.pick(raw, ServiceAgreement.rawFields));
    this.power_factor = parseFloat(raw.power_factor);
  }

  serialize(): ServiceAgreement.Serialized {
    return {
      ..._.pick(this, ServiceAgreement.rawFields),
      object_type: this.object_type,
      power_factor: this.power_factor.toString(),
    };
  }

  get BundledRatePlan() {
    if (this._bundledRatePlan) return this._bundledRatePlan;
    return (this._bundledRatePlan = BundledRatePlan.fromStore(this.bundled_rate_plan));
  }

  get GenerationRatePlan() {
    if (this._generationRatePlan) return this._generationRatePlan;
    return (this._generationRatePlan = CCARatePlan.fromStore(this.generation_rate_plan));
  }

  get BundledVariations() {
    if (this._bundledVariations) return this._bundledVariations;
    return (this._bundledVariations = _.truthy(
      this.bundled_variations.map(RatePlanVariation.fromObject)
    ));
  }

  get ValidationCases() {
    return this.validation_cases?.map(ValidationCase.fromStore) || [];
  }

  get ConnectionLevel() {
    if (this._connectionLevel) return this._connectionLevel;
    return (this._connectionLevel = ConnectionLevel.fromStore(this.connection_level));
  }
}
