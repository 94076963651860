import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Card, Grid, TabDatum, Box, Loading, List } from 'onescreen/components';
import { Typography } from '@material-ui/core';
import { ServiceAgreement, ValidationCase } from 'onescreen/models';
import _ from 'lodash';
import { RatePlanVariations } from './ratePlanVariations';
import { useServiceAgreement } from 'onescreen/hooks';

/** ================================ Types ================================= */
type ServiceAgreementCardProps = {
  serviceAgreementId: ServiceAgreement['id'];
  clickValidationCase: (vc: ValidationCase) => void;
};
/** ============================== Component =============================== */
export const ServiceAgreementCard: React.FC<ServiceAgreementCardProps> = ({
  serviceAgreementId,
  clickValidationCase,
}) => {
  const [chosenVc, setChosenVc] = useState<ValidationCase>();
  const { serviceAgreement, loading } = useServiceAgreement(serviceAgreementId, {
    include: [
      'bundled_variations.rate_type_variations.*',
      'validation_cases.bill_validations.bill.*',
      'validation_cases.bill_validations.api_bill.*',
    ],
  });
  if (loading || !serviceAgreement) return <Loading />;

  return (
    <Grid>
      <Grid.Item span={12}>
        <Card>
          <Typography variant="h6">
            {serviceAgreement.id}: <strong>{serviceAgreement.name}</strong>
          </Typography>
          <TabDatum field="Power Factor" value={`${serviceAgreement.power_factor * 100}%`} />
          {serviceAgreement.generation_rate_plan && (
            <>
              <TabDatum field="CCA Vintage" value={serviceAgreement.cca_vintage} />
              <TabDatum
                field="Generation Rate Plan"
                value={
                  <Link to={'/rates/cca/' + serviceAgreement.generation_rate_plan}>
                    {serviceAgreement.GenerationRatePlan?.friendly_name}
                  </Link>
                }
              />
            </>
          )}
          {serviceAgreement.bundled_rate_plan && (
            <TabDatum
              field="Bundled Rate Plan"
              value={
                <Link to={'/rates/' + serviceAgreement.bundled_rate_plan}>
                  {serviceAgreement.BundledRatePlan?.friendly_name}
                </Link>
              }
            />
          )}
          <TabDatum
            field="Bundled Variations"
            value={
              <Box paddingLeft={1} boxShadow={0}>
                <RatePlanVariations variations={serviceAgreement.BundledVariations} />
              </Box>
            }
          />

          <Typography>Validation Cases:</Typography>
          <List>
            {_.truthy(serviceAgreement.ValidationCases).map((vc) => (
              <List.Item
                selected={chosenVc?.id === vc.id}
                key={vc.id}
                onClick={() => {
                  clickValidationCase(vc);
                  setChosenVc(vc);
                }}
              >
                {vc.id}: {vc.name}
              </List.Item>
            ))}
          </List>
        </Card>
      </Grid.Item>
    </Grid>
  );
};
