/**
 * This file defines some methods that will be mixed into lodash and, thereafter, be available via
 * lodash's default export `_`. It's important that this file be imported prior to any code that
 * uses the mixins defined here, and for that reason this file should be very sparse with its own
 * imports.
 */

import _ from 'lodash';

/** ======================== Types ========================================= */
// This is meant to capture all values in JS that evaluate to `false` when passed through the
// Boolean constructor. This is incomplete, and perhaps impossible to do with TypeScript because
// there are some language values which types can't capture. For example, the type of `NaN` is
// `number`, yet `Boolean(NaN) === false`.
type Falsey = false | 0 | '' | null | undefined;
type PartialArray<T> = { [P in keyof T]?: Array<T[P]> };

/** ======================== Filter in ===================================== */
/**
 * Similar to _.filter, in which the object's values are arrays.
 *
 *   Ex:
 *   const users = [{ id: 1, name: "Milo" }, { id: 2, name: "Dylan" }, { id: 3, name: "Babak" }]
 *   _.filterIn(users, { id: [1] }) --> [{ id: 1, name: "Milo" }]
 *   _.filterIn(users, { name: ["Dylan", "Babak" ]}) --> [{ id: 2, name: "Dylan" }, { id: 3, name: "Babak" }]
 *
 * @param {array} collection: collection to filter
 * @param {PartialArray} predicate: the filtering constraints
 */
function filterIn<T>(collection: T[] | null | undefined, predicate: PartialArray<T>): T[] {
  if (!collection) return [];

  const predicateComponents = _.entries(predicate);
  return collection.filter((item) =>
    predicateComponents.every(([key, values]) =>
      (values as Array<unknown>).includes(item[key as keyof T])
    )
  );
}

/** ======================== IIFE ========================================== */
function iife<T>(func: () => T) {
  return func();
}

/** ======================== Product ======================================= */
/**
 * Returns the cartesian product of two arrays. The returned arrays are ordered first by
 * first-array element-order and then by second-array element-order.
 *
 *   Ex:
 *   const numbers = [1, 2]
 *   const letters = ['a', 'b']
 *   _.product(numbers, letters) --> [[1, 'a'], [1, 'b'], [2, 'a'], [2, 'b']]
 *
 * @param {any[]} arr1: the first array
 * @param {any[]} arr2: the second array
 */
function product<T1, T2>(arr1: Array<T1>, arr2: Array<T2>): Array<[T1, T2]> {
  return _.flatMap(arr1, (arr1Elem) => _.map(arr2, (arr2Elem) => [arr1Elem, arr2Elem] as [T1, T2]));
}

/** ======================== Truthy ======================================== */
/**
 * Filters an array of values to non-falsey values
 *
 * @param {object} arr: the array to filter falsey values from
 */
export function truthy<T>(arr: Array<T | Falsey>): Array<T>;

/**
 * Takes an object of values and returns all of the key-value pairs in that object that aren't
 * falsey
 *
 * @param {object} obj: the object to filter falsey values from
 */
export function truthy<T>(obj: Record<string, T | Falsey>): Record<string, T>;

export function truthy(arrayOrObject: any) {
  return Array.isArray(arrayOrObject)
    ? arrayOrObject.filter(isTruthy)
    : _.pickBy(arrayOrObject, isTruthy);
}

/**
 * Typeguard for truthy values
 */
function isTruthy<T>(x: T | Falsey): x is T {
  return Boolean(x);
}

/** ======================== Mixin ========================================= */
declare module 'lodash' {
  interface LoDashStatic {
    filterIn: typeof filterIn;
    iife: typeof iife;
    product: typeof product;
    truthy: typeof truthy;
  }
}

_.mixin({ filterIn, iife, product, truthy });
