import { useSelector } from 'react-redux';

import { ServiceAgreement } from 'onescreen/models';
import { slices } from 'onescreen/store';
import { Loader, Maybe } from 'onescreen/types';

import { useAsync } from './useAsync';

/**
 * Retrieves a page of ServiceAgreement objects
 *
 * @param {ServiceAgreement.API.RetrieveParams} params: query parameters
 */
export function useServiceAgreements(
  params?: ServiceAgreement.API.ListParams
): Loader<Record<ServiceAgreement['id'], Maybe<ServiceAgreement.Serialized>>, 'serviceAgreements'> {
  const serviceAgreements = useSelector(slices.data.selectServiceAgreements());

  const loading = useAsync(() => ServiceAgreement.api.list(params), []);
  return {
    loading,
    serviceAgreements,
  };
}

/**
 * Retrieves a page of ServiceAgreement objects
 *
 * @param {ServiceAgreement.API.RetrieveParams} params: query parameters
 */
export function useServiceAgreement(
  id: ServiceAgreement['id'],
  params?: ServiceAgreement.API.RetrieveParams
): Loader<ServiceAgreement, 'serviceAgreement'> {
  const serviceAgreement = useSelector(slices.data.selectServiceAgreement(id));

  const loading = useAsync(() => ServiceAgreement.api.retrieve(id, params), []);
  return {
    loading,
    serviceAgreement: ServiceAgreement.fromObject(serviceAgreement),
  };
}
