/**
 * Centralized location for managing the site's cookies.
 */
import Cookies from 'js-cookie';

import { Tuple } from 'onescreen/types';

/** ======================== Types ========================================= */
type CookieType = 'authToken' | 'csrftoken';
type CookieValue = string | undefined;

/** ======================== Cookie Manager ================================ */
class CookieManager {
  /* ======================= Private methods =============================== */
  /**
   * Retrieves a cookie by name. Returns `undefined` if not found
   *
   * @param {CookieType} name: the name of the cookie to get
   */
  private static getCookie(name: CookieType) {
    return Cookies.get(name);
  }

  /**
   * Sets a cookie. If the provided value is `undefined` the cookie will be removed
   *
   * @param {CookieType} name: the name of the cookie to set
   * @param {CookieValue} value: the value of the cookie. If undefined the cookie is removed
   */
  private static setCookie(name: CookieType, value: CookieValue) {
    if (value === undefined) {
      CookieManager.removeCookie(name);
      return;
    }

    Cookies.set(name, value);
  }

  /**
   * Removes a cookie by name
   *
   * @param {CookieType} name: the name of the cookie to get
   */
  private static removeCookie(name: CookieType) {
    Cookies.remove(name);
  }

  /* ======================= Cookie accessors/modifiers ==================== */
  get authToken(): Tuple<CookieValue> {
    const cookieValue = CookieManager.getCookie('authToken');
    if (!cookieValue || !cookieValue.includes('--')) {
      return [undefined, undefined];
    } else {
      const [username, token] = cookieValue.split('--');
      return [username, token];
    }
  }

  set authToken(authToken: Tuple<CookieValue>) {
    const [username, token] = authToken;
    CookieManager.setCookie('authToken', `${username}--${token}`);
  }

  get csrfToken() {
    return CookieManager.getCookie('csrftoken');
  }

  set csrfToken(value: CookieValue) {
    CookieManager.setCookie('csrftoken', value);
  }

  remove = {
    authToken: () => CookieManager.removeCookie('authToken'),
    csrfToken: () => CookieManager.removeCookie('csrftoken'),
  };
}

export const cookieManager = new CookieManager();
