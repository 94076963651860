import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';

import {
  Button,
  Card,
  Dialog,
  FilterList,
  GenericForm,
  Grid,
  Loading,
  Box,
  Typography,
  Link,
} from 'onescreen/components';

import OrgReport from './orgReport';
import SiteReport from './siteReport';
import ServiceDropReport from './serviceDropReport';
import { useDialogState, useOrgs } from '../../common/hooks';
import { Org } from '../../common/models';
import { useDispatch, useSelector } from 'react-redux';
import { slices } from '../../common/store';
import { makeStylesHook } from 'onescreen/styles';
import { HasUserPermissions } from 'onescreen/hooks/user';

/** ================================= Types ================================ */
type BreadcrumbWrapperProps = {
  children: React.ReactElement | React.ReactElement[];
};
type URLParams = {
  sdId?: string;
  siteId?: string;
  orgId?: string;
};

/** ================================= Styles =============================== */
const useBreadcrumbsStyles = makeStylesHook((theme) => {
  return {
    containerBox: { backgroundColor: theme.palette.background.default, borderRadius: 8 },
  };
}, 'Breadcrumbs');

/** ================================= Components =========================== */
const BreadcrumbWrapper: React.FC<BreadcrumbWrapperProps> = ({ children }) => {
  const params = useParams<URLParams>();
  const { pathname } = useLocation();
  const classes = useBreadcrumbsStyles();

  const org = useSelector(slices.data.selectOrg(+params.orgId!));
  const site = useSelector(slices.data.selectSite(+params.siteId!));
  const sd = useSelector(slices.data.selectServiceDrop(+params.sdId!));

  let pages = [{ name: 'Organizations', link: '/orgs' }];
  if (org) {
    pages.push({ name: org.name, link: `/orgs/${org.id}` });
    if (site) {
      pages.push({ name: site.name, link: `/orgs/${org?.id}/site/${site.id}` });
      if (sd) {
        pages.push({
          name: sd.name,
          link: `/orgs/${org?.id}/site/${site.id}/service-drop/${sd.id}`,
        });
      }
    }
  }

  return (
    <Grid.Item>
      <Box paddingTop={2} className={classes.containerBox}>
        <Grid justify="center">
          <Grid.Item span={11}>
            {pages.map((page) =>
              pathname === page.link ? (
                <Typography variant="h6" emphasis="bold" key={page.link}>
                  {page.name}
                </Typography>
              ) : (
                <span key={page.link}>
                  <Link to={page.link}>
                    <Typography variant="h6">{page.name}</Typography>
                  </Link>
                  <Typography variant="h6"> / </Typography>
                </span>
              )
            )}
          </Grid.Item>
          {children}
        </Grid>
      </Box>
    </Grid.Item>
  );
};

const OrgList: React.FC = () => {
  const [modalOpen, openModal, closeModal] = useDialogState();
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const { orgs, loading } = useOrgs({
    limit: 1000,
    offset: 0,
    include: ['id', 'sites', 'name'],
  });

  const hasPermission = HasUserPermissions(['OrgEditors', 'OrgFullAccessEditors']);

  if (!orgs || loading) {
    return <Loading />;
  }
  return (
    <>
      <Grid.Item span={10}>
        <Card>
          <Card.Content>
            <FilterList
              url={pathname}
              data={orgs.results}
              onClickAdd={hasPermission ? openModal : undefined}
              secondaryFunc={(item) => (
                <Typography emphasis="disabled">{item.sites.length} Sites</Typography>
              )}
            />
          </Card.Content>
        </Card>
      </Grid.Item>
      <Dialog open={modalOpen} onClose={closeModal}>
        <Dialog.Content>
          <GenericForm
            title="Create a new Organization"
            fields={[
              {
                label: 'Name',
                key: 'name',
                placeholder: 'Los Angeles County',
              },
            ]}
            callback={createOrganization}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Button onClick={closeModal}>Cancel</Button>
        </Dialog.Actions>
      </Dialog>
    </>
  );
  /** ============================= Callbacks ============================== */
  async function createOrganization(payload: any) {
    const { org } = await Org.api.create(payload);
    dispatch(slices.ui.setMessage({ msg: `Successfully created ${org.name}`, type: 'success' }));
  }
};

export default function Orgs() {
  return (
    <Grid justify="center">
      <Routes>
        <Route
          index
          element={
            <BreadcrumbWrapper>
              <OrgList />
            </BreadcrumbWrapper>
          }
        />
        <Route
          path={':orgId'}
          element={
            <BreadcrumbWrapper>
              <OrgReport />
            </BreadcrumbWrapper>
          }
        />
        <Route
          path={':orgId/site/:siteId'}
          element={
            <BreadcrumbWrapper>
              <SiteReport />
            </BreadcrumbWrapper>
          }
        />
        <Route
          path={':orgId/site/:siteId/service-drop/:sdId'}
          element={
            <BreadcrumbWrapper>
              <ServiceDropReport />
            </BreadcrumbWrapper>
          }
        />
      </Routes>
    </Grid>
  );
}
