import _ from 'lodash';

import { store } from 'onescreen/store';
import { IdType, Maybe, ObjectWithoutType, ObjectWithType } from 'onescreen/types';
import { ConnectionLevel, RateType } from '.';
import { Source } from '..';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'Rate'> & {
  id: IdType;
  sources: Array<Source['id']>;
  rate_type: RateType['id'];
  rate: number;
  connection_level: ConnectionLevel['id'];
};

export declare namespace Rate {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}
}

/** ======================== Model ========================================= */
export interface Rate extends CommonAttrs {}
export class Rate {
  /** ====================== Static fields ================================= */
  static readonly rawFields = ['id', 'sources', 'rate_type', 'rate', 'connection_level'] as const;

  /**
   * Parses an array of raw Rate objects by passing each in turn to
   * Rate.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {Rate.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: Rate.Raw[]): Rate[];
  static fromObjects(raw: Maybe<Rate.Raw[]>): Maybe<Rate[]>;
  static fromObjects(raw: Maybe<Rate.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: Rate.Raw): Rate;
  static fromObject(raw: Maybe<Rate.Raw>): Maybe<Rate>;
  static fromObject(raw: Maybe<Rate.Raw>) {
    return raw ? new Rate(raw) : undefined;
  }

  static fromStore(): Rate[];
  static fromStore(id: Maybe<Rate['id']>): Maybe<Rate>;
  static fromStore(id?: Rate['id']) {
    const { rates } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(rates)).map(Rate.fromObject);
    } else {
      return id ? Rate.fromObject(rates[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'Rate';
  constructor(raw: Rate.Raw) {
    Object.assign(this, _.pick(raw, Rate.rawFields));
  }

  get Sources() {
    return this.sources.map(Source.fromStore);
  }

  get ConnectionLevel() {
    return ConnectionLevel.fromStore(this.connection_level);
  }

  get RateType() {
    return RateType.fromStore(this.rate_type);
  }

  serialize(): Rate.Serialized {
    return { ..._.pick(this, Rate.rawFields), object_type: this.object_type };
  }
}
