import _ from 'lodash';

import { slices, store } from 'onescreen/store';
import {
  DateTuple,
  ErrorMessage,
  Maybe,
  ObjectWithoutType,
  Pagination,
  Query,
} from 'onescreen/types';
import { Org } from '.';
import { DateUtils, IntervalFile } from '..';
import { Meter } from '../apis';
import { SolarGenerator, Storage } from '../assets';

import { AsyncTask, BaseAPI } from '../base';
import { MonitoringPlatform, MonitoringPlatformAccountType } from '../monitoring';
import { BaselineMonth } from '../util';
import { Site } from './site';

/** ======================== Types ========================================= */
type CommonAttrs = {
  id: number;
  name: string;
  address: string;
  generation_benefit?: 'direct' | 'NEM-A' | 'RES-BCT' | '';
  generation_benefit_choices?: string[];
  object_type: 'ServiceDrop';
  site?: number;
  meters?: Array<Meter['id']>;
  demo_meters?: Array<Meter['id']>;
  interval_file?: IntervalFile['id'];
  solar_generator?: SolarGenerator['id'];
  storage?: Storage['id'];
};

export declare namespace ServiceDrop {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {
    meter_date_range?: [string, string];
    solar_date_range?: [string, string];
    storage_date_range?: [string, string];
  }

  // Network interface
  export namespace API {
    export type SideloadedResponses = {
      orgs?: Org.Raw[];
      sites?: Site.Raw[];
      meters?: Meter.Raw[];
      demo_meters?: Meter.Raw[];
      solar_generators?: SolarGenerator.Raw[];
      storages?: Storage.Raw[];
      monitoring_platforms?: MonitoringPlatform.Raw[];
      baseline_months?: BaselineMonth.Raw[];
      monitoring_platform_account_types?: MonitoringPlatformAccountType.Raw[];
    };

    export type ListParams = RetrieveParams & Query.PaginationParams;
    export type ListResult = Pagination.Response.Parsed<ServiceDrop>;
    export type ListResponse = Pagination.Response.Raw<
      { service_drops: ServiceDrop.Raw[] } & SideloadedResponses
    >;

    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResponse = { service_drop: ServiceDrop.Raw } & SideloadedResponses;
    export type RetrieveResult = { serviceDrop: ServiceDrop };

    export type CreateParams = Pick<Raw, 'name' | 'address' | 'site'>;

    export type UpdateParams = Pick<Raw, 'name' | 'address' | 'generation_benefit'> & {
      solar_generator?: ServiceDrop.Raw['solar_generator'] | null;
      storage?: ServiceDrop.Raw['storage'] | null;
    };

    export type DownloadIntervalParams = {
      start_date?: string;
      end_date?: string;
      interpolate: boolean;
      solar_params?: { unit: 'kW' | 'kWh'; preferred_monitor: MonitoringPlatform['id'] };
      storage_params?: { unit: 'kW' | 'kWh'; preferred_monitor: MonitoringPlatform['id'] };
      utility_params?: { unit: 'kW' | 'kWh' };
    };
    export type DownloadIntervalResponse = {
      task_id: AsyncTask['task_id'];
    };
    export type DownloadIntervalResult = {
      url: string;
      error?: ErrorMessage;
    };

    export type CollectIntervalOrBillResult = Org.API.CollectIntervalOrBillResult;
    export type CollectIntrevalsAndBillsResponse = Org.API.CollectIntrevalsAndBillsResponse;
  }
}

/** ======================== API =========================================== */
class ServiceDropAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.serviceDrop;

  static async list(params?: ServiceDrop.API.ListParams): Promise<ServiceDrop.API.ListResult> {
    const { json } = await this.get<ServiceDrop.API.ListResponse>(this.route, params);

    // Parse response
    const { sites, meters, demo_meters, solar_generators, storages } = this.parseSideloadedObjects(json.results);
    const serviceDrops = this.parsePaginationSet(json, ({ service_drops }) =>
      ServiceDrop.fromObjects(service_drops)
    );

    store.dispatch(
      slices.data.updateModels(serviceDrops.results, sites, meters, demo_meters, solar_generators, storages)
    );
    return serviceDrops;
  }

  static async update(
    id: ServiceDrop.Raw['id'],
    body: ServiceDrop.API.UpdateParams,
    params?: ServiceDrop.API.RetrieveParams
  ): Promise<ServiceDrop.API.RetrieveResult> {
    const { json } = await this.patch(this.route(id), body, params);
    const { service_drop } = json;
    store.dispatch(slices.data.updateModels(ServiceDrop.fromObject(service_drop)));
    return service_drop;
  }

  static async create(body: ServiceDrop.API.CreateParams): Promise<ServiceDrop.API.RetrieveResult> {
    const { json } = await this.post(this.route, body);
    const { service_drop } = json;
    const serviceDrop = ServiceDrop.fromObject(service_drop);
    store.dispatch(slices.data.updateModels(serviceDrop));
    serviceDrop.Site?.addServiceDrop(service_drop.id);
    return service_drop;
  }

  static async retrieve(
    id: ServiceDrop.Raw['id'],
    params?: ServiceDrop.API.RetrieveParams
  ): Promise<ServiceDrop.API.RetrieveResult> {
    const { json } = await this.get<ServiceDrop.API.RetrieveResponse>(this.route(id), params);

    // Parse response
    const {
      orgs,
      sites,
      meters,
      demo_meters,
      solar_generators,
      storages,
      monitoring_platforms,
      baseline_months,
      monitoring_platform_account_types,
    } = this.parseSideloadedObjects(json);
    const serviceDrop = new ServiceDrop(json.service_drop);
    store.dispatch(
      slices.data.updateModels(
        serviceDrop,
        orgs,
        sites,
        meters,
        demo_meters,
        solar_generators,
        storages,
        monitoring_platforms,
        baseline_months,
        monitoring_platform_account_types
      )
    );
    return { serviceDrop };
  }

  static async downloadIntervals(
    id: ServiceDrop['id'],
    params: ServiceDrop.API.DownloadIntervalParams
  ): Promise<ServiceDrop.API.DownloadIntervalResult> {
    const { json } = await this.post<ServiceDrop.API.DownloadIntervalResponse>(
      this.route(id) + 'interval-download/',
      params
    );
    const task = new AsyncTask<string | ErrorMessage>(json.task_id);
    const resp = await task.getResult();
    const url = resp as string;
    const error = resp as ErrorMessage;
    if (error.exc_message) {
      return { url: '', error };
    }
    return { url };
  }

  static async collectIntervalsAndBills(
    id: ServiceDrop['id']
  ): Promise<boolean> {
    const { json } = await this.get<ServiceDrop.API.CollectIntrevalsAndBillsResponse>(
      this.route(id) + 'collect-intervals-bills/',
    );

    await Promise.all(json.task_ids.map(async (task_id) => {
      const task = new AsyncTask<ServiceDrop.API.CollectIntervalOrBillResult>(task_id);
      await task.getResult();
    }));
    return true;
  }

  static parseSideloadedObjects(json: ServiceDrop.API.SideloadedResponses) {
    return {
      orgs: Org.fromObjects(json.orgs),
      sites: Site.fromObjects(json.sites),
      meters: Meter.fromObjects(json.meters),
      demo_meters: Meter.fromObjects(json.demo_meters),
      solar_generators: SolarGenerator.fromObjects(json.solar_generators),
      storages: Storage.fromObjects(json.storages),
      monitoring_platforms: MonitoringPlatform.fromObjects(json.monitoring_platforms),
      baseline_months: BaselineMonth.fromObjects(json.baseline_months),
      monitoring_platform_account_types: MonitoringPlatformAccountType.fromObjects(
        json.monitoring_platform_account_types
      ),
    };
  }
}

/** ======================== Model ========================================= */
export interface ServiceDrop extends CommonAttrs {
  meter_date_range?: DateTuple;
  solar_date_range?: DateTuple;
  storage_date_range?: DateTuple;
}
export class ServiceDrop {
  /** ====================== Static fields ================================= */
  static api = ServiceDropAPI;
  static readonly rawFields = [
    'id',
    'name',
    'address',
    'generation_benefit',
    'generation_benefit_choices',
    'site',
    'meters',
    'demo_meters',
    'interval_file',
    'solar_generator',
    'storage',
  ] as const;

  /**
   * Parses an array of raw ServiceDrop objects by passing each in turn to
   * ServiceDrop.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {ServiceDrop.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: ServiceDrop.Raw[]): ServiceDrop[];
  static fromObjects(raw: Maybe<ServiceDrop.Raw[]>): Maybe<ServiceDrop[]>;
  static fromObjects(raw: Maybe<ServiceDrop.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: ServiceDrop.Raw): ServiceDrop;
  static fromObject(raw: Maybe<ServiceDrop.Raw>): Maybe<ServiceDrop>;
  static fromObject(raw: Maybe<ServiceDrop.Raw>) {
    return raw ? new ServiceDrop(raw) : undefined;
  }

  static fromStore(): ServiceDrop[];
  static fromStore(id: Maybe<ServiceDrop['id']>): Maybe<ServiceDrop>;
  static fromStore(id?: ServiceDrop['id']) {
    const { serviceDrops } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(serviceDrops)).map(ServiceDrop.fromObject);
    } else {
      return id ? ServiceDrop.fromObject(serviceDrops[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'ServiceDrop';
  constructor(raw: ServiceDrop.Raw) {
    Object.assign(this, _.pick(raw, ServiceDrop.rawFields));
    this.meter_date_range = raw.meter_date_range
      ? [
          DateUtils.parseDate(raw.meter_date_range![0]),
          DateUtils.parseDate(raw.meter_date_range![1]),
        ]
      : undefined;
    this.solar_date_range = raw.solar_date_range
      ? [
          DateUtils.parseDate(raw.solar_date_range![0]),
          DateUtils.parseDate(raw.solar_date_range![1]),
        ]
      : undefined;
    this.storage_date_range = raw.storage_date_range
      ? [
          DateUtils.parseDate(raw.storage_date_range![0]),
          DateUtils.parseDate(raw.storage_date_range![1]),
        ]
      : undefined;
  }

  get Site() {
    return Site.fromStore(this.site);
  }

  get Meters() {
    const meters = !this.Site?.Org?.is_test ? this.meters : this.demo_meters;
    return meters?.map(Meter.fromStore);
  }

  get Meter() {
    return _.find(this.Meters, { is_primary: true });
  }

  get SolarGenerator() {
    return SolarGenerator.fromStore(this.solar_generator);
  }

  get Storage() {
    return Storage.fromStore(this.storage);
  }

  serialize(): ServiceDrop.Serialized {
    return {
      ..._.pick(this, ServiceDrop.rawFields),
      object_type: this.object_type,
      meter_date_range: this.meter_date_range
        ? [
            DateUtils.serializeDate(this.meter_date_range![0]),
            DateUtils.serializeDate(this.meter_date_range![1]),
          ]
        : undefined,
      solar_date_range: this.solar_date_range
        ? [
            DateUtils.serializeDate(this.solar_date_range![0]),
            DateUtils.serializeDate(this.solar_date_range![1]),
          ]
        : undefined,
      storage_date_range: this.storage_date_range
        ? [
            DateUtils.serializeDate(this.storage_date_range![0]),
            DateUtils.serializeDate(this.storage_date_range![1]),
          ]
        : undefined,
    };
  }
}
