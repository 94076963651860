import _ from 'lodash';

import { useLocation } from 'react-router';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  FilterList,
  InfoCardForm,
  GenericForm,
  Loading,
  Grid,
  Card,
  Typography,
  Icon,
  InfoCardFormProps,
  Dialog,
  Button,
  LoadingCircle,
  Box,
  Progress,
} from 'onescreen/components';

import { IntervalUploadModal } from './intervalUploadModal';
import { useAuthorizations, useDialogState, useOrg } from '../../common/hooks';
import { Site, Org, User } from '../../common/models';
import { formatters } from '../../common/utils';
import { OrgIntervalDownloadModal } from './intervalDownloadModal';
import { Divider } from '@material-ui/core';
import { OrgBillDownloadModal } from './billDownloadModal';
import { HasUserPermissions } from 'onescreen/hooks/user';
import { OrgParam } from 'onescreen/types';

export default function OrgReport() {
  const { orgId } = useParams<OrgParam>() as OrgParam;
  const [intervalUploadOpen, openIntervalUpload, closeIntervalUpload] = useDialogState();
  const [intervalDownloadOpen, openIntervalDownload, closeIntervalDownload] = useDialogState();
  const [billDownloadOpen, openBillDownload, closeBillDownload] = useDialogState();
  const [createSiteOpen, openCreateSite, closeCreateSite] = useDialogState();
  const [waiting, setWaiting] = React.useState<boolean>(false);
  const [
    collectOrgDataComplete,
    setCollectOrgDataComplete,
  ] = React.useState<boolean>();

  const { pathname } = useLocation();
  const [refreshing, setRefreshing] = useState(false);
  const today = new Date().toISOString();

  const { org } = useOrg(+orgId, {
    exclude: ['sites.*', 'sites.service_drops.*'],
    include: [
      'sites.id',
      'sites.name',
      'sites.service_drops.id',
      'sites.service_drops.solar_generator',
      'sites.service_drops.storage',
      'sites.service_drops.meters',
      'authorizations.*',
      'sites.service_drops.demo_meters',
      'demo_authorizations.*',
      'eligible_owners.*',
    ],
    filter: {
      'authorizations|status': { operation: 'equals', value: 'updated' },
      'authorizations|expires.gt': { operation: 'equals', value: today },
      'demo_authorizations|status': { operation: 'equals', value: 'updated' },
      'demo_authorizations|expires.gt': { operation: 'equals', value: today },
    },
  });

  const hasPermission = HasUserPermissions(['OrgEditors', 'OrgFullAccessEditors']);

  const org_filter_key = !org?.is_test ? 'org.isnull' : 'demo_org.isnull';
  const { authorizations } = useAuthorizations({
    limit: 1000,
    offset: 0,
    include: ['expires'],
    filter: {
      [org_filter_key]: { operation: 'equals', value: true },
      'status': { operation: 'equals', value: 'updated' },
      'expires.gt': { operation: 'equals', value: today },
    },
  });

  if (!org) return <Loading />;

  const primaryAuth = _.sortBy(org.Authorizations, 'status_ts').reverse()[0];

  const sitesSecondaryFunc = (site: Site) => {
    if (site.service_drops.length === 0 || site.service_drops.length > 1) {
      return <Typography emphasis="disabled">{site.service_drops.length} Service Drops</Typography>;
    } else if (site.ServiceDrops) {
      const serviceDrop = site.ServiceDrops[0];
      const hasMeters = !!(!site.Org?.is_test ?
        serviceDrop?.meters?.length :
        serviceDrop?.demo_meters?.length);
      const hasSolar = !!serviceDrop?.solar_generator;
      const hasStorage = !!serviceDrop?.storage;
      return (
        <>
          <Typography emphasis={hasMeters ? 'normal' : 'disabled'}>
            <Icon name="plug" />
          </Typography>
          <Typography emphasis={hasSolar ? 'normal' : 'disabled'}>
            <Icon name="sun" />
          </Typography>
          <Typography emphasis={hasStorage ? 'normal' : 'disabled'}>
            <Icon name="battery" />
          </Typography>
        </>
      );
    }
  };

  const collectOrgData = async () => {
    setWaiting(true);
    setCollectOrgDataComplete(undefined);
    const result = await Org.api.collectIntervalsAndBills(org.id);
    setCollectOrgDataComplete(result);

    setWaiting(false);
  };

  const since = org.created_at;
  const owners = User.fromStore();
  const infoCardItems: InfoCardFormProps['items'] = [
    {
      value: org.name,
      label: 'Organization Name',
      key: 'name',
      largerText: true,
    },
    { value: org.id, label: 'ID', readOnly: true, key: 'id' },
    {
      value: since ? formatters.date.standard(since) : '',
      label: 'Member Since',
      readOnly: true,
      key: 'created_at',
    },
    {
      value: !org.is_test ? org.authorizations : org.demo_authorizations,
      label: 'Active UtilityAPI Authorizations',
      key: 'authorizations',
      type: 'select',
      selectOptions: {
        choices: _.sortedUniqBy(
          _.sortBy(authorizations?.results.concat(org.Authorizations), ({ utilityapi_id }) =>
            parseInt(utilityapi_id)
          ),
          'id'
        ).map((auth) => ({
          value: auth?.id,
          // Anonymize the auth's customer-email for demo org.
          label: `${primaryAuth && auth.id === primaryAuth.id ? '*' : ''}${auth.utilityapi_id} ${
            !org.is_test ? '<' + auth.customer_email + '>' : ''
          }`,
        })),
        multi: true,
      },
      secondaryAction: hasPermission ? (
        <>
          {!refreshing && <Button size="small" icon="refresh" onClick={refreshAuthorizations} />}
          {refreshing && <LoadingCircle />}
        </>
      ) : undefined,
    },
    {
      value: org.account_owner,
      label: 'Account Owner',
      key: 'account_owner',
      type: 'select',
      selectOptions: {
        allowBlank: true,
        blankValue: null,
        choices: owners.map((user) => ({
          value: user.id,
          label: `${org.account_owner && org.account_owner === user.id ? '*' : ''}${
            user.first_name
          } ${user.last_name} <${user.email}>`,
        })),
      },
    },
    {
      value: org.tve_customer_id || undefined,
      label: 'TerraVerde Customer ID',
      key: 'tve_customer_id',
      type: 'number',
    },
    {
      value: org.is_ams_customer,
      label: 'Is AMS Customer',
      key: 'is_ams_customer',
      type: 'checkbox',
    },
    {
      value: org.report_cost_savings,
      label: 'Should report cost savings',
      key: 'report_cost_savings',
      type: 'checkbox',
    },
  ];
  const formFields = [
    {
      label: 'Name',
      key: 'name',
      placeholder: 'Lincoln Elementary School',
    },
    { label: 'Address', key: 'address', placeholder: '123 Jane St.' },
    { label: 'Address 2', key: 'address_2' },
    { label: 'City', key: 'city' },
    { label: 'State', key: 'state' },
    { label: 'Zip', key: 'zipcode', type: 'number' },
  ];
  return (
    <>
      <Grid.Item span={10}>
        <Card>
          <Card.Content>
            <Grid justify="flex-end">
              {hasPermission && (
                <>
                  <Grid.Item>
                    <Button disabled={waiting} color="primary" onClick={collectOrgData} icon="sync">
                      Org Data
                    </Button>
                  </Grid.Item>
                  <Grid.Item>
                    <Divider orientation="vertical" />
                  </Grid.Item>
                  <Grid.Item>
                    <Button color="primary" onClick={openIntervalUpload} icon="upload">
                      Cleaned intervals
                    </Button>
                  </Grid.Item>
                  <Grid.Item>
                    <Divider orientation="vertical" />
                  </Grid.Item>
                </>
              )}
              <Grid.Item>
                <Button color="secondary" onClick={openIntervalDownload} icon="download">
                  Intervals
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button color="secondary" onClick={openBillDownload} icon="download">
                  Bills
                </Button>
              </Grid.Item>
            </Grid>
            <Box padding={1}></Box>
            <Grid.Item>
              {waiting && <Progress />}
              {collectOrgDataComplete && (
                <Card>
                  <Grid justify="flex-end">
                    <Grid.Item>
                      <Button
                        icon="close"
                        onClick={() => setCollectOrgDataComplete(undefined)}
                      />
                    </Grid.Item>
                  </Grid>
                  <Grid justify="flex-start" key={org.name}>
                    <Grid.Item span={8}>
                      <Typography color='success'>
                        Collected intervals and bills for {org.name}
                      </Typography>
                    </Grid.Item>
                  </Grid>
                </Card>
              )}
            </Grid.Item>
            <Grid justify="center">
              <Grid.Item span={12}>
                <InfoCardForm
                  items={infoCardItems}
                  callback={hasPermission ? updateOrg : undefined}
                />
              </Grid.Item>
              <Grid.Item span={9}>
                <FilterList
                  title="Sites"
                  dense={true}
                  url={`${pathname}/site`}
                  data={org.Sites}
                  onClickAdd={hasPermission ? openCreateSite : undefined}
                  secondaryFunc={sitesSecondaryFunc}
                />
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </Grid.Item>
      <Dialog open={createSiteOpen} onClose={closeCreateSite}>
        <Dialog.Content>
          <GenericForm title="Create a new Site" fields={formFields} callback={createSite} />
        </Dialog.Content>
        <Dialog.Actions>
          <Button onClick={closeCreateSite}>Cancel</Button>
        </Dialog.Actions>
      </Dialog>
      <OrgIntervalDownloadModal
        orgId={org.id}
        isOpen={intervalDownloadOpen}
        onClose={closeIntervalDownload}
      />
      <OrgBillDownloadModal orgId={org.id} open={billDownloadOpen} onClose={closeBillDownload} />
      <IntervalUploadModal
        orgId={org.id}
        modalOpen={intervalUploadOpen}
        closeModal={closeIntervalUpload}
      />
    </>
  );
  /** ============================ Callbacks =============================== */
  async function createSite(payload: Site.API.CreateParams) {
    let p = _.clone(payload);
    p['org'] = +orgId;
    await Site.api.create(p);
    closeCreateSite();
  }

  async function updateOrg(payload: Org.API.UpdateParams) {
    Org.api.update(+orgId, payload);
  }

  async function refreshAuthorizations() {
    setRefreshing(true);
    await Org.api.refreshAuthorizations(+orgId);
    setRefreshing(false);
  }
}
