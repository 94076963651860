import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Grid,
  Loading,
  Slider,
  SortingTable,
  SortingTableProps,
  Tabs,
  Tooltip,
  Typography,
} from 'onescreen/components';

import { useBundledRatePlan, useCCARatePlan } from 'onescreen/hooks/rates';
import { ConnectionLevel, Rate, RateType, Source } from 'onescreen/models';
import _ from 'lodash';
import { RatePlanParam } from 'onescreen/types';
import { formatters } from 'onescreen/utils';
import { BundledRateValidation, CCARateValidation } from './rateValidation';

/* ============================= Constants ================================= */
const scopeMap = {
  BASE: { order: 1, code: 'B' },
  CUSTOMER_CLASS: { order: 2, code: 'C' },
  UTILITY: { order: 3, code: 'U' },
  REGULATOR: { order: 4, code: 'R' },
};

const baseRateRequestIncludes = [
  'rates.sources.*',
  'rates.rate_type.bucket.*',
  'rates.rate_type.season.*',
  'rates.rate_type.unit.*',
  'rates.connection_level.*',
];
const bundledRateRequestIncludes = [
  ...baseRateRequestIncludes,
  'customer_class_rate_plan.rates.sources.*',
  'customer_class_rate_plan.rates.rate_type.bucket.*',
  'customer_class_rate_plan.rates.rate_type.season.*',
  'customer_class_rate_plan.rates.rate_type.unit.*',
  'customer_class_rate_plan.rates.connection_level.*',
  'customer_class_rate_plan.utility_rate_plan.rates.sources.*',
  'customer_class_rate_plan.utility_rate_plan.rates.rate_type.season.*',
  'customer_class_rate_plan.utility_rate_plan.rates.rate_type.bucket.*',
  'customer_class_rate_plan.utility_rate_plan.rates.rate_type.unit.*',
  'customer_class_rate_plan.utility_rate_plan.rates.connection_level.*',
  'customer_class_rate_plan.utility_rate_plan.regulator_rate_plan.rates.sources.*',
  'customer_class_rate_plan.utility_rate_plan.regulator_rate_plan.rates.rate_type.season.*',
  'customer_class_rate_plan.utility_rate_plan.regulator_rate_plan.rates.rate_type.bucket.*',
  'customer_class_rate_plan.utility_rate_plan.regulator_rate_plan.rates.rate_type.unit.*',
  'customer_class_rate_plan.utility_rate_plan.regulator_rate_plan.rates.connection_level.*',
];
/* ============================= Types ===================================== */
type ValueLabelComponentProps = {
  children: React.ReactElement;
  value: number;
};

type RateExplorerProps = {
  sources: Array<Source>;
  rates: Array<Rate>;
  name: string;
};

/* =========================== Components ================================== */
const ValueLabelComponent: React.FC<ValueLabelComponentProps> = (props) => {
  const { children, value } = props;

  return (
    <Tooltip placement="top" title={value}>
      {children}
    </Tooltip>
  );
};

const RateExplorer: React.FC<RateExplorerProps> = ({ rates, sources, name }) => {
  const [sourceIndex, setSourceIndex] = useState(0);

  if (!sources || !sources.length) return <Loading />;

  const rateTypes = _.truthy(_.uniqBy(_.map(rates, 'RateType'), 'id'));
  const connectionLevels = _.truthy(_.uniqBy(_.map(rates, 'ConnectionLevel'), 'id'));

  const columns: SortingTableProps<RateType>['columns'] = [
    {
      header: 'R',
      key: '_rate_scope',
      value: (rateType) => scopeMap[rateType._rate_scope]['code'],
      sortValue: (rateType) => scopeMap[rateType._rate_scope]['order'],
    },
    {
      header: 'RT',
      key: '_rate_type_scopes',
      value: (rateType) => scopeMap[rateType._rate_type_scope]['code'],
      sortValue: (rateType) => scopeMap[rateType._rate_type_scope]['order'],
    },
    {
      header: 'Rate Name',
      key: 'description',
      width: '30%',
    },
    {
      header: 'Type',
      key: 'type',
    },
    {
      header: 'Season',
      key: 'season',
      value: (rateType) => {
        const season = rateType.Season;
        return season ? season.name : '.';
      },
    },
    {
      header: 'Bucket',
      key: 'bucket',
      value: (rateType) => {
        const bucket = rateType.Bucket;
        return bucket ? bucket.name : '.';
      },
    },
    {
      header: 'Unit',
      key: 'unit',
      value: (rateType) => {
        const unit = rateType.Unit;
        return unit ? unit.name : '.';
      },
      noFilter: true,
    },
    {
      header: 'Rate',
      key: 'rate',
      value: (rateType) => {
        const rate = getCurrentRate(rateType.id);
        return rate ? rate.rate : 0;
      },
      sortValue: function (rateType) {
        const rate = parseFloat(this.value!(rateType));
        return rate !== 0 ? rate : -9999999;
      },
      render: function (rateType) {
        const rate = getCurrentRate(rateType.id);
        if (!rate) return '';
        return (
          <Typography color={isRateNew(rate) ? 'secondary' : undefined} variant="body2">
            {rate.rate}
          </Typography>
        );
      },
      noFilter: true,
    },
    ..._.sortBy(connectionLevels, 'id').map((cl) => {
      return {
        header: cl.name,
        key: `connection-level-${cl.id}`,
        value: (rateType: RateType) => {
          const rate = getCurrentRate(rateType.id, cl.id);
          return rate ? rate.rate : 0;
        },
        sortValue: function (rateType: RateType) {
          const rate = this.value(rateType);
          return rate !== 0 ? rate : -9999999;
        },
        render: function (rateType: RateType) {
          const rate = getCurrentRate(rateType.id, cl.id);
          if (!rate) return '';
          return (
            <Typography color={isRateNew(rate) ? 'secondary' : undefined} variant="body2">
              {rate.rate}
            </Typography>
          );
        },
        noFilter: true,
      };
    }),
  ];

  const marks = [
    { value: 1, label: formatters.date.standard(sources[sources.length - 1]?.effective!) },
    ..._.range(2, sources.length).map((value) => ({ value })),
    {
      value: sources.length,
      label: formatters.date.standard(sources[0]?.effective!),
    },
  ];

  return (
    <Grid justify="center">
      <Grid.Item>
        <Typography variant="h5">Effective since: </Typography>
        <Typography color="secondary" variant="h5">
          {formatters.date.standard(sources[sourceIndex]?.effective!)}
        </Typography>
      </Grid.Item>
      <Grid.Item span={12}>
        <Grid justify="center">
          <Grid.Item span={6}>
            <Slider
              min={1}
              max={sources.length}
              marks={marks}
              aria-label="Effective Date"
              getAriaValueText={valueText}
              valueLabelFormat={valueText}
              valueLabelDisplay="auto"
              step={1}
              value={sources.length - sourceIndex}
              onChange={(index) => setSourceIndex(sources.length - index)}
              color="secondary"
              ValueLabelComponent={ValueLabelComponent}
            />
          </Grid.Item>
        </Grid>
      </Grid.Item>
      <Grid.Item span={12}>
        <SortingTable<RateType>
          data={rateTypes}
          columns={columns}
          total={rateTypes.length}
          name={name}
          allowSearchParams={true}
        />
      </Grid.Item>
    </Grid>
  );
  /* ============================= Callback ================================ */
  function valueText(value: number) {
    return sources[sources.length - value]?.name || '';
  }

  function getCurrentRate(rtId: RateType['id'], clId: ConnectionLevel['id'] | null = null) {
    const effectiveSources = sources.slice(sourceIndex);
    const relevantRates = rates.filter((rate) => {
      return (
        rate.connection_level === clId &&
        rate.rate_type === rtId &&
        !_.isEmpty(_.intersectionBy(effectiveSources, rate.Sources, 'id'))
      );
    });
    if (!relevantRates.length) return null;
    if (relevantRates.length === 1) return relevantRates[0];
    const last = _.last(
      _.sortBy(relevantRates, (rate) => {
        const latestSource = _.last(
          _.sortBy(_.intersectionBy(effectiveSources, rate.Sources, 'id'), 'effective')
        );
        return latestSource?.effective;
      })
    );
    return last;
  }

  function isRateNew(rate: Rate) {
    const effectiveSources = sources.slice(sourceIndex);
    return (
      _.last(_.sortBy(_.intersectionBy(effectiveSources, rate.Sources, 'id'), 'effective'))?.id ===
      effectiveSources[0]?.id
    );
  }
};

export const BundledRateExplorer: React.FC = () => {
  const { rpId } = useParams<RatePlanParam>() as RatePlanParam;
  const { loading, bundledRatePlan } = useBundledRatePlan(+rpId, {
    include: bundledRateRequestIncludes,
  });
  const customerClassRatePlan = bundledRatePlan?.CustomerClassRatePlan;
  const utilityRatePlan = customerClassRatePlan?.UtilityRatePlan;
  const regulatorRatePlan = utilityRatePlan?.RegulatorRatePlan;

  if (
    loading ||
    !bundledRatePlan ||
    !customerClassRatePlan ||
    !utilityRatePlan ||
    !regulatorRatePlan
  )
    return <Loading />;
  const baseRates = _.truthy([
    ...bundledRatePlan.Rates,
    ...customerClassRatePlan.Rates,
    ...utilityRatePlan.Rates,
  ]);
  const rates = _.truthy([...baseRates, ...regulatorRatePlan.Rates]);
  const sources = _.reverse(
    _.sortBy(_.uniqBy(_.truthy(_.flatMap(baseRates, (rate) => rate?.Sources)), 'id'), 'effective')
  );
  return (
    <Grid>
      <Grid.Item span={12}>
        <Typography variant="h4">{bundledRatePlan.friendly_name}</Typography>
      </Grid.Item>
      <Grid.Item span={12}>
        <Tabs initialTab="Rate Explorer">
          <Tabs.Tab title="Rate Explorer">
            <RateExplorer rates={rates} sources={sources} name={bundledRatePlan.friendly_name} />
          </Tabs.Tab>
          <Tabs.Tab title="Validation">
            <BundledRateValidation />
          </Tabs.Tab>
        </Tabs>
      </Grid.Item>
    </Grid>
  );
};

export const CCARateExplorer: React.FC = () => {
  const { rpId } = useParams<RatePlanParam>() as RatePlanParam;
  const { loading, ccaRatePlan } = useCCARatePlan(+rpId, {
    include: baseRateRequestIncludes,
  });
  if (loading || !ccaRatePlan) return <Loading />;
  const rates = _.truthy(ccaRatePlan.Rates);
  const sources = _.reverse(
    _.sortBy(_.uniqBy(_.truthy(_.flatMap(rates, (rate) => rate?.Sources)), 'id'), 'effective')
  );
  return (
    <Grid>
      <Grid.Item span={12}>
        <Typography variant="h4">{ccaRatePlan?.friendly_name}</Typography>
      </Grid.Item>
      <Grid.Item span={12}>
        <Tabs initialTab="Rate Explorer">
          <Tabs.Tab title="Rate Explorer">
            <RateExplorer rates={rates} sources={sources} name={ccaRatePlan.friendly_name} />
          </Tabs.Tab>
          <Tabs.Tab title="Validation">
            <CCARateValidation />
          </Tabs.Tab>
        </Tabs>
      </Grid.Item>
    </Grid>
  );
};
