import * as React from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@material-ui/core/AppBar';
import MuiToolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';

import { Button } from './Button';
import { makeStylesHook } from '../styles';
import { Container, Menu, MenuItem } from '@material-ui/core';
import { Typography } from './Typography';
import { Box, Tooltip } from '.';

/** ============================ Types ===================================== */
type AppBarProps = MuiAppBarProps & {
  email?: string;
};

/** ============================ Styles ==================================== */
const useStyles = makeStylesHook(
  () => ({
    container: {
      backgroundColor: 'white',
      boxSizing: 'border-box',
      width: '100%',
    },
  }),
  'AppBar'
);

/** ============================ Components ================================ */

export const AppBar: React.FC<AppBarProps> = ({ children, email }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();

  return (
    <MuiAppBar position="fixed" color="transparent" className={classes.container}>
      <Container maxWidth="xl">
        <MuiToolbar disableGutters>
          <Box paddingRight={4}>
            <Link to="/">
              <Typography emphasis="bold" color="primary" variant="h4">
                Solar Shadow
              </Typography>
            </Link>
          </Box>
          <Box flexGrow={1}>{children}</Box>
          <Box hidden={!email}>
            <Tooltip title={email}>
              <Button icon="account" onClick={(evt) => setAnchorEl(evt.currentTarget)} />
            </Tooltip>
            <Menu
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={() => navigate('/logout')}>Logout {email}</MenuItem>
            </Menu>
          </Box>
          <Box>
            <Tooltip title="What's New?">
              <Button icon="new_releases" href={'/changelog'}></Button>
            </Tooltip>
          </Box>
          <Box paddingLeft={2}>
            <a href="https://terraverde.energy/">
              <img src="/logo.gif" alt="TerraVerde Energy" width="auto" height="50px" />
            </a>
          </Box>
        </MuiToolbar>
      </Container>
    </MuiAppBar>
  );
};
