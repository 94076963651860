import { useSelector } from 'react-redux';

import { AMSPortfolio, AnalysisPeriod, CostSavings, ServiceAnalysis } from 'onescreen/models';
import { slices } from 'onescreen/store';
import { Loader, Maybe } from 'onescreen/types';

import { useAsync } from './useAsync';

/**
 * Retrieves a single ServiceAnalysis object given its ID
 *
 * @param {number} id: ServiceAnalysis ID
 * @param {ServiceAnalysis.API.RetrieveParams} params: query parameters
 */
export function useServiceAnalysis(
  id: Maybe<ServiceAnalysis['id']>,
  params?: ServiceAnalysis.API.RetrieveParams
): Loader<ServiceAnalysis, 'serviceAnalysis'> {
  const serviceAnalysis = useSelector(slices.data.selectServiceAnalysis(id));

  const loading = useAsync(async () => {
    if (!id) return;
    return ServiceAnalysis.api.retrieve(id, params);
  }, [id]);

  return { loading, serviceAnalysis: ServiceAnalysis.fromObject(serviceAnalysis) };
}

/**
 * Retrieves a single AnalysisPeriod object given its ID
 *
 * @param {number} id: AnalysisPeriod ID
 * @param {AnalysisPeriod.API.RetrieveParams} params: query parameters
 */
export function useAnalysisPeriod(
  id: Maybe<AnalysisPeriod['id']>,
  params?: AnalysisPeriod.API.RetrieveParams
): Loader<AnalysisPeriod, 'analysisPeriod'> {
  const analysisPeriod = useSelector(slices.data.selectAnalysisPeriod(id));

  const loading = useAsync(async () => {
    if (!id) return;
    return AnalysisPeriod.api.retrieve(id, params);
  }, [id]);

  return { loading, analysisPeriod: AnalysisPeriod.fromObject(analysisPeriod) };
}

/**
 * Retrieves a single CostSavings object given its ID
 *
 * @param {number} id: CostSavings ID
 * @param {CostSavings.API.RetrieveParams} params: query parameters
 */
export function useCostSavings(
  id: Maybe<CostSavings['id']>,
  params?: CostSavings.API.RetrieveParams
): Loader<CostSavings, 'costSavings'> {
  const costSavings = useSelector(slices.data.selectCostSavings(id));

  const loading = useAsync(async () => {
    if (!id) return;
    return CostSavings.api.retrieve(id, params);
  }, [id]);

  return { loading, costSavings: CostSavings.fromObject(costSavings) };
}

/**
 * Retrieves a single AMSPortfolio object given its ID
 *
 * @param {number} id: AMSPortfolio ID
 * @param {AMSPortfolio.API.RetrieveParams} params: query parameters
 */
export function usePortfolio(
  id: Maybe<AMSPortfolio['id']>,
  params?: AMSPortfolio.API.RetrieveParams
): Loader<AMSPortfolio, 'portfolio'> {
  const portfolio = useSelector(slices.data.selectPortfolio(id));

  const loading = useAsync(async () => {
    if (!id) return;
    return AMSPortfolio.api.retrieve(id, params);
  }, [id]);

  return { loading, portfolio: AMSPortfolio.fromObject(portfolio) };
}

/**
 * Retrieves a page of AMSPortfolio objects
 *
 * @param {AMSPortfolio.API.RetrieveParams} params: query parameters
 */
export function usePortfolios(
  params?: AMSPortfolio.API.ListParams
): Loader<Record<AMSPortfolio['id'], Maybe<AMSPortfolio.Serialized>>, 'portfolios'> {
  const portfolios = useSelector(slices.data.selectPortfolios());

  const loading = useAsync(() => AMSPortfolio.api.list(params), []);
  return {
    loading,
    portfolios,
  };
}
