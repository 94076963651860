import _ from 'lodash';

import { store } from 'onescreen/store';
import { Maybe, ObjectWithoutType } from 'onescreen/types';

/** ======================== Types ========================================= */
type CommonAttrs = {
  id: number;
  month: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  baseline: number;
  object_type: 'BaselineMonth';
};

export declare namespace BaselineMonth {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}
}

/** ======================== Model ========================================= */
export interface BaselineMonth extends CommonAttrs {}
export class BaselineMonth {
  /** ====================== Static fields ================================= */
  static readonly rawFields = ['id', 'month', 'baseline'] as const;

  /**
   * Parses an array of raw BaselineMonth objects by passing each in turn to BaselineMonth.fromObject. Note that calling
   * `raw.map(this.fromObject)` does not work, because TypeScript is unable to resolve the correct
   * overload for fromObject when it is passed to the `map` method. This is a TypeScript design
   * limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {BaselineMonth.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: BaselineMonth.Raw[]): BaselineMonth[];
  static fromObjects(raw: Maybe<BaselineMonth.Raw[]>): Maybe<BaselineMonth[]>;
  static fromObjects(raw: Maybe<BaselineMonth.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: BaselineMonth.Raw): BaselineMonth;
  static fromObject(raw: Maybe<BaselineMonth.Raw>): Maybe<BaselineMonth>;
  static fromObject(raw: Maybe<BaselineMonth.Raw>) {
    return raw ? new BaselineMonth(raw) : undefined;
  }

  static fromStore(): BaselineMonth[];
  static fromStore(id: Maybe<BaselineMonth['id']>): Maybe<BaselineMonth>;
  static fromStore(id?: BaselineMonth['id']) {
    const { baselineMonths } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(baselineMonths)).map(BaselineMonth.fromObject);
    } else {
      return id ? BaselineMonth.fromObject(baselineMonths[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'BaselineMonth';
  constructor(raw: BaselineMonth.Raw) {
    Object.assign(this, _.pick(raw, BaselineMonth.rawFields));
  }

  get MonthName(): string {
    const date = new Date(2000, this.month - 1, 1);
    return date.toLocaleString('default', { month: 'long' });
  }

  serialize(): BaselineMonth.Serialized {
    return {
      ..._.pick(this, BaselineMonth.rawFields),
      object_type: this.object_type,
    };
  }
}
