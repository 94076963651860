import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Route, Routes, useLocation } from 'react-router-dom';

import { BundledRateExplorer, CCARateExplorer } from './rateExplorer';
import { Card, Chip, Grid, List, Typography } from 'onescreen/components';
import { useBundledRatePlans, useCCARatePlans } from 'onescreen/hooks/rates';
import { slices } from 'onescreen/store';

export const Rates: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { bundledRatePlans, loading } = useBundledRatePlans({
    limit: 1000,
    offset: 0,
    include: ['customer_class_rate_plan.utility_rate_plan.utility.*'],
  });

  const ccaResponse = useCCARatePlans({ limit: 1000, offset: 0, include: ['cca.*'] });
  const { ccaRatePlans } = ccaResponse;

  const customerClassRatePlans = useSelector(slices.data.selectCustomerClassRatePlans());
  const utilityRatePlans = useSelector(slices.data.selectUtilityRatePlans());
  const utilities = useSelector(slices.data.selectUtilities());
  const ccas = useSelector(slices.data.selectCCAs());

  let utilityList = [];
  if (!loading && bundledRatePlans) {
    for (const urp of _.truthy(Object.values(utilityRatePlans))) {
      utilityList.push(
        <Grid.Item span={4} key={urp.id}>
          <Card
            chipLabel={
              <Chip
                icon="plug"
                label={<Typography variant="h6">{utilities[urp.utility]!.name}</Typography>}
              />
            }
          >
            <List>
              {_.truthy(Object.values(customerClassRatePlans))
                .filter((ccrp) => ccrp.utility_rate_plan === urp.id)
                .map((ccrp) => (
                  <List.Item button={false} key={ccrp.id}>
                    <Grid>
                      <Grid.Item span={12}>
                        <Typography emphasis="secondary" color="textSecondary" variant="h6">
                          {ccrp.friendly_name}
                        </Typography>
                      </Grid.Item>
                      <Grid.Item span={12}>
                        <List>
                          {_.truthy(Object.values(bundledRatePlans))
                            .filter((brp) => brp.customer_class_rate_plan === ccrp.id)
                            .map((brp) => (
                              <List.Item
                                key={brp.id}
                                onClick={() => navigate(`${pathname}/${brp.id}`)}
                              >
                                <Typography variant="button" color="primary">
                                  {brp.friendly_name}
                                </Typography>
                              </List.Item>
                            ))}
                        </List>
                      </Grid.Item>
                    </Grid>
                  </List.Item>
                ))}
            </List>
          </Card>
        </Grid.Item>
      );
    }
  }
  if (!ccaResponse.loading && ccaRatePlans) {
    utilityList.push(
      <Grid.Item span={4} key="cca">
        <Card
          chipLabel={<Chip icon="plug" label={<Typography variant="h6">CCA Rates</Typography>} />}
        >
          <List>
            {_.truthy(Object.values(ccas)).map((cca) => (
              <List.Item key={cca.id} button={false}>
                <Grid>
                  <Grid.Item span={12}>
                    <Typography emphasis="secondary" color="textSecondary" variant="h6">
                      {cca.name}
                    </Typography>
                  </Grid.Item>
                  <Grid.Item span={12}>
                    <List>
                      {_.truthy(Object.values(ccaRatePlans))
                        .filter((ccarp) => ccarp.cca === cca.id)
                        .map((ccarp) => (
                          <List.Item
                            key={ccarp.id}
                            onClick={() => navigate(`${pathname}/cca/${ccarp.id}`)}
                          >
                            <Typography variant="button" color="primary">
                              {ccarp.friendly_name}
                            </Typography>
                          </List.Item>
                        ))}
                    </List>
                  </Grid.Item>
                </Grid>
              </List.Item>
            ))}
          </List>
        </Card>
      </Grid.Item>
    );
  }

  return (
    <Card>
      <Card.Content>
        <Routes>
          <Route
            index
            element={
              <Grid>
                <Grid.Item span={12}>
                  <Grid justify="center" noMargin>
                    <Grid.Item>
                      <Typography variant="h3">All Rate Plans</Typography>
                    </Grid.Item>
                  </Grid>
                </Grid.Item>
                {utilityList}
              </Grid>
            }
          />
          <Route path={':rpId'} element={<BundledRateExplorer />} />
          <Route path={'cca/:rpId'} element={<CCARateExplorer />} />
        </Routes>
      </Card.Content>
    </Card>
  );
};
