import { useSelector } from 'react-redux';

import { BundledRatePlan, CCARatePlan, RatePlanVariation } from 'onescreen/models';
import { slices } from 'onescreen/store';
import { Loader, Maybe, OnFailCallback } from 'onescreen/types';

import { useAsync } from './useAsync';
import _ from 'lodash';

/**
 * Retrieves a page of BundledRatePlan objects
 *
 * @param {BundledRatePlan.API.ListParams} params: query parameters
 */
export function useBundledRatePlans(
  params?: BundledRatePlan.API.ListParams
): Loader<Record<BundledRatePlan['id'], Maybe<BundledRatePlan.Serialized>>, 'bundledRatePlans'> {
  const bundledRatePlans = useSelector(slices.data.selectBundledRatePlans());

  const loading = useAsync(() => BundledRatePlan.api.list(params), []);
  return {
    loading,
    bundledRatePlans,
  };
}

/**
 * Retrieves a single BundledRatePlan object
 *
 * @param {BundledRatePlan.Raw.id} id: The bundledRatePlan id
 * @param {BundledRatePlan.API.RetrieveParams} params: query parameters
 * @param {OnFailCallback} onFail: callback for when the request fails
 */
export function useBundledRatePlan(
  id: BundledRatePlan['id'],
  params?: BundledRatePlan.API.RetrieveParams,
  onFail?: OnFailCallback
): Loader<BundledRatePlan, 'bundledRatePlan'> {
  const bundledRatePlan = useSelector(slices.data.selectBundledRatePlan(id));
  const loading = useAsync(
    () => BundledRatePlan.api.retrieve(id, params),
    [],
    () => _.noop(),
    onFail
  );
  return { loading, bundledRatePlan: BundledRatePlan.fromObject(bundledRatePlan) };
}

/**
 * Retrieves a page of CCARatePlan objects
 *
 * @param {CCARatePlan.API.ListParams} params: query parameters
 */
export function useCCARatePlans(
  params?: CCARatePlan.API.ListParams
): Loader<Record<CCARatePlan['id'], Maybe<CCARatePlan.Serialized>>, 'ccaRatePlans'> {
  const ccaRatePlans = useSelector(slices.data.selectCCARatePlans());

  const loading = useAsync(() => CCARatePlan.api.list(params), []);
  return {
    loading,
    ccaRatePlans,
  };
}

/**
 * Retrieves a single CCARatePlan object
 *
 * @param {CCARatePlan.Raw.id} id: The ccaRatePlan id
 * @param {CCARatePlan.API.RetrieveParams} params: query parameters
 * @param {OnFailCallback} onFail: callback for when the request fails
 */
export function useCCARatePlan(
  id: CCARatePlan['id'],
  params?: CCARatePlan.API.RetrieveParams,
  onFail?: OnFailCallback
): Loader<CCARatePlan, 'ccaRatePlan'> {
  const ccaRatePlan = useSelector(slices.data.selectCCARatePlan(id));
  const loading = useAsync(
    () => CCARatePlan.api.retrieve(id, params),
    [],
    () => _.noop(),
    onFail
  );
  return { loading, ccaRatePlan: CCARatePlan.fromObject(ccaRatePlan) };
}

/**
 * Retrieves a page of RatePlanVariation objects
 *
 * @param {RatePlanVariation.API.ListParams} params: query parameters
 */
export function useRatePlanVariations(
  params?: RatePlanVariation.API.ListParams
): Loader<
  Record<RatePlanVariation['id'], Maybe<RatePlanVariation.Serialized>>,
  'ratePlanVariations'
> {
  const ratePlanVariations = useSelector(slices.data.selectRatePlanVariations());

  const loading = useAsync(() => RatePlanVariation.api.list(params), []);
  return {
    loading,
    ratePlanVariations,
  };
}
