import _ from 'lodash';

import { store } from 'onescreen/store';
import { IdType, Maybe, ObjectWithoutType, ObjectWithType } from 'onescreen/types';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'Bucket'> & {
  name: string;
  id: IdType;
};

export declare namespace Bucket {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}
}

/** ======================== Model ========================================= */
export interface Bucket extends CommonAttrs {}
export class Bucket {
  /** ====================== Static fields ================================= */
  static readonly rawFields = ['name', 'id'] as const;

  /**
   * Parses an array of raw Bucket objects by passing each in turn to
   * Bucket.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {Bucket.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: Bucket.Raw[]): Bucket[];
  static fromObjects(raw: Maybe<Bucket.Raw[]>): Maybe<Bucket[]>;
  static fromObjects(raw: Maybe<Bucket.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: Bucket.Raw): Bucket;
  static fromObject(raw: Maybe<Bucket.Raw>): Maybe<Bucket>;
  static fromObject(raw: Maybe<Bucket.Raw>) {
    return raw ? new Bucket(raw) : undefined;
  }

  static fromStore(): Bucket[];
  static fromStore(id: Maybe<Bucket['id']>): Maybe<Bucket>;
  static fromStore(id?: Bucket['id']) {
    const { buckets } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(buckets)).map(Bucket.fromObject);
    } else {
      return id ? Bucket.fromObject(buckets[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'Bucket';
  constructor(raw: Bucket.Raw) {
    Object.assign(this, _.pick(raw, Bucket.rawFields));
  }

  serialize(): Bucket.Serialized {
    return { ..._.pick(this, Bucket.rawFields), object_type: this.object_type };
  }
}
