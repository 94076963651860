import React from 'react';

import { Grid } from './Grid';
import { TextField, TextFieldProps } from './TextField';
import { Button } from './Button';
import { Typography } from './Typography';
import { Box } from '.';

/** ================================ Types ================================= */

type GenericField = {
  label: string;
  key: string;
  placeholder?: string;
  type?: TextFieldProps['type'];
  value?: string;
};
type GenericFormProps = {
  fields: GenericField[];
  title: string;
  callback: (payload: any) => void;
};

/** ============================= Components =============================== */

export const GenericForm: React.FC<GenericFormProps> = ({ fields, title, callback }) => {
  const p: any = {};
  Object.keys(fields).forEach((key) => {
    p[key] = null;
  });
  const [payload, setPayload] = React.useState(p);

  return (
    <Grid>
      <Grid.Item>
        <Typography variant="h6">{title}</Typography>
      </Grid.Item>
      <Grid.Item span={12}>
        <form onSubmit={handleEnter}>
          {fields.map((field) => (
            <Box key={field.key}>
              <TextField
                type={field.type ? field.type : 'text'}
                name={field.key}
                placeholder={field.placeholder ? field.placeholder : ''}
                id={field.key}
                label={field.label}
                value={field.value!}
                onChange={(value) => setPayload((p: any) => ({ ...p, [field.key]: value }))}
              />
            </Box>
          ))}
          <Box paddingTop={2}>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </Box>
        </form>
      </Grid.Item>
    </Grid>
  );
  function handleEnter(event: React.FormEvent) {
    event.preventDefault();
    callback(payload);
  }
};
