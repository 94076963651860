import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { api_host } from 'onescreen/requests';

function SetPassword(props) {
  const navigate = useNavigate();
  let { uid, token } = useParams();
  return (
    <div className="container">
      <div className="row valign-wrapper card-panel">
        <form
          className="col s12"
          onSubmit={(event) => {
            event.preventDefault();
            let body = {
              uid,
              token,
              new_password1: event.target.new_password1.value,
              new_password2: event.target.new_password2.value,
            };
            fetch(api_host + '/v1/password/reset/confirm/', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
            }).then((resp) => {
              if (resp.ok) navigate('/login');
            });
          }}
        >
          <h4 className="col s12">Set Password</h4>
          <div className="input-field col s12">
            <input placeholder="password" id="new_password1" type="password" className="validate" />
            <label htmlFor="new_password1">New password</label>
          </div>
          <div className="input-field col s12">
            <input placeholder="password" id="new_password2" type="password" className="validate" />
            <label htmlFor="new_password2">Reenter password</label>
          </div>
          <input className="btn col s2 offset-s10" type="submit" />
        </form>
      </div>
    </div>
  );
}

export default SetPassword;
