import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { AlertSnackbar, ThemeProvider } from 'onescreen/components';
import { slices } from 'onescreen/store';

import './App.css';
import { NavBar } from './components/NavBar';
import { ChangeLog } from './components/ChangeLog';
import { Login, Logout, UserPage } from './components/auth';
import { Rates } from './components/rates/rates';
import Orgs from './components/orgs/orgs';
import { Analysis } from './components/analysis';
import Bucketing from './components/bucketing';
import NotFound from './components/notFound';
import { Monitoring } from './components/monitoring';
import { RequireAuth } from 'onescreen/components/RequireAuth';

export const App: React.FC = () => {
  const dispatch = useDispatch();
  const { duration, msg, open, type } = useSelector(slices.ui.selectSnackbar);
  return (
    <ThemeProvider>
      <Router>
        <NavBar />
        <div>
          <div className="row main-body">
            <div className="col m10 offset-m1 s12">
              <Routes>
                <Route path="/" element={<RequireAuth children={<Navigate to="/orgs" />} />} />
                <Route path="/orgs/*" element={<RequireAuth children={<Orgs />} />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<RequireAuth children={<Logout />} />} />
                <Route path="/rates/*" element={<RequireAuth children={<Rates />} />} />
                <Route path="/analysis" element={<RequireAuth children={<Analysis />} />} />
                <Route path="/bucketing" element={<RequireAuth children={<Bucketing />} />} />
                <Route
                  path="/monitoring"
                  element={<RequireAuth children={<Navigate to="/monitoring/solar" />} />}
                />
                <Route
                  path="/monitoring/:view"
                  element={<RequireAuth children={<Monitoring />} />}
                />
                <Route path="/user/*" element={<UserPage />} />
                <Route path="/changelog" element={<ChangeLog />} />
                <Route element={<NotFound />} />
              </Routes>
            </div>
          </div>
        </div>
      </Router>

      <AlertSnackbar
        autoHideDuration={duration}
        onClose={closeSnackbar}
        open={open}
        msg={msg}
        type={type}
      />
    </ThemeProvider>
  );

  /** ====================== Callbacks ===================================== */
  function closeSnackbar() {
    dispatch(slices.ui.closeSnackbar());
  }
};
