import * as React from 'react';

import { User } from 'onescreen/models';

/**
 * When mounted, the user is immediately redirected to the login page. A request will be sent to
 * the server instructing it to end the session.
 */
export function Logout() {
  React.useEffect(() => {
    User.logout();
  });

  return null;
}
