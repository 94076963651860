import 'materialize-css/dist/css/materialize.min.css';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// Must be imported early in the code execution path so the mixins are added to the lodash default
// export
import 'onescreen/utils/lodash_mixins';

import { store } from 'onescreen/store';
import { App } from './App';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
