import _ from 'lodash';

import { store } from 'onescreen/store';
import { IdType, Maybe, ObjectWithoutType, ObjectWithType } from 'onescreen/types';
import { BillValidation } from '.';
import { Meter } from '..';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'ValidationCase'> & {
  id: IdType;
  name: string;
  meter: Meter['id'];
  bill_validations: Array<BillValidation['id']>;
};

export declare namespace ValidationCase {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}
}

/** ======================== Model ========================================= */
export interface ValidationCase extends CommonAttrs {}
export class ValidationCase {
  /** ====================== Static fields ================================= */
  static readonly rawFields = ['id', 'name', 'meter', 'bill_validations'] as const;

  /**
   * Parses an array of raw ValidationCase objects by passing each in turn to
   * ValidationCase.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {ValidationCase.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: ValidationCase.Raw[]): ValidationCase[];
  static fromObjects(raw: Maybe<ValidationCase.Raw[]>): Maybe<ValidationCase[]>;
  static fromObjects(raw: Maybe<ValidationCase.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: ValidationCase.Raw): ValidationCase;
  static fromObject(raw: Maybe<ValidationCase.Raw>): Maybe<ValidationCase>;
  static fromObject(raw: Maybe<ValidationCase.Raw>) {
    return raw ? new ValidationCase(raw) : undefined;
  }

  static fromStore(): ValidationCase[];
  static fromStore(id: Maybe<ValidationCase['id']>): Maybe<ValidationCase>;
  static fromStore(id?: ValidationCase['id']) {
    const { validationCases } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(validationCases)).map(ValidationCase.fromObject);
    } else {
      return id ? ValidationCase.fromObject(validationCases[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'ValidationCase';
  constructor(raw: ValidationCase.Raw) {
    Object.assign(this, _.pick(raw, ValidationCase.rawFields));
  }

  get BillValidations() {
    return this.bill_validations.map(BillValidation.fromStore) || [];
  }

  get Meter() {
    return Meter.fromStore(this.meter);
  }

  serialize(): ValidationCase.Serialized {
    return {
      ..._.pick(this, ValidationCase.rawFields),
      object_type: this.object_type,
    };
  }
}
