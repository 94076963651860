import _ from 'lodash';

import { Query } from 'onescreen/types';

/** ======================== Types ========================================= */
export type URLCreator = {
  [urlComponent: string]: URLCreator;
  [Symbol.toPrimitive](): string;
  (id: Query.Primitive): URLCreator;
};

/** ======================== URL Creator =================================== */
/**
 * Creates a `URLCreator` object which conforms to the interface defined above. This leverages the
 * `Proxy` object, which can intercept and redefine fundamental operations for objects. In our case,
 * we redefine the behavior for getting a property value and for function calls.
 *
 * References:
 *   The Proxy object:
 *     https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy
 *     https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy/Proxy/apply
 *     https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy/Proxy/get
 *
 *   Inspiration:
 *     https://hackernoon.com/creating-callable-objects-in-javascript-d21l3te1
 *
 * Ex:
 *   const creator = new URLCreator()
 *   creator.xyz             --> /xyz/
 *   creator.xyz.abc(123)    --> /xyz/abc/123/
 *   creator("r").javascript --> /r/javascript/
 */
export function makeURLCreator(route: string = ''): URLCreator {
  // The first argument to `Proxy` must be callable in order to support the `apply` handler. If the
  // argument isn't callable then `urlCreator.objects(5)`, intended to return `/objects/5/`, will
  // throw a `TypeError` that "urlCreator.objects is not a function"
  const callable = ((() => {}) as unknown) as URLCreator;
  return new Proxy<URLCreator>(callable, {
    /**
     * When the `URLCreator` instance is called, the first argument is passed to the `extend`
     * method. This is how we support building URLs with IDs.
     *
     * Ex:
     *   creator.xyz                 --> /xyz/
     *   creator.xyz.abc.oneTwoThree --> /xyz/abc/one-two-three/
     *
     * @param {URLCreator} t: the proxy object. Not used.
     * @param {any} thisArg: the `this` argument of the function. Not used.
     * @param {ary[]} args: the arguments passed to the function. Only the first is used to
     *   extend the URL
     */
    apply: (t, thisArg, args): URLCreator => extend(args[0]),

    /**
     * When any key on the `URLCreator` instance is accessed, the key will be used as the next
     * URL component.
     *
     * Ex:
     *   creator(5)        --> /5/
     *   creator.xyz("id") --> /xyz/id/
     *
     * @param {URLCreator} t: the proxy object. Not used.
     * @param {PropertyKey} property: the name of the field to extend the URL with, or a symbol. If
     *   passed a symbol, the URL is returned as assembled.
     */
    get: (t, property) => (_.isSymbol(property) ? () => finish(route) : extend(property)),
  });

  /**
   * Returns a new URLCreator by appending the route with the extension URL component. If
   * `urlComponent` is a string, the interpretation is case sensitive: if any uppercase characters
   * are included, they will be considered a part of a new word, separated in the URL by a hyphen.
   *
   * Ex:
   *   extend("restAuth") --> /rest-auth/
   *   extend(5)          --> /5/
   */
  function extend(urlComponent: any) {
    let extension = urlComponent;

    // Replace camelcase strings with all lower case, adding hyphens between words
    if (_.isString(urlComponent)) {
      extension = urlComponent.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
    }

    return makeURLCreator(route + '/' + extension);
  }

  /**
   * Finishes the route by appending a slash if the last character isn't one already
   *
   * @param {string} route: the finished route
   */
  function finish(route: string) {
    return _.last(route) === '/' ? route : route + '/';
  }
}
