import _ from 'lodash';
import React, { useEffect, useState, useCallback, ReactNode } from 'react';

import {
  Checkbox,
  Dialog,
  Grid,
  Icon,
  Typography,
  Chip,
  Button,
  Select,
  Loading,
  Tooltip,
  DateSpanPicker,
  DateSpanPickerProps,
} from 'onescreen/components';

import { SiteMultiSelect, DownloadModalFooter } from './common';
import { MonitoringPlatformAccountType, Org, ServiceDrop, Site } from '../../common/models';
import { DateTime } from 'luxon';
import { makeStylesHook } from 'onescreen/styles';
import { formatters } from 'onescreen/utils';
import { useOrg, useServiceDrop } from 'onescreen/hooks';

/** ============================== Types =================================== */
type TimeStampRange = { start: DateTime; end: DateTime };
type UnitType = 'kW' | 'kWh';
type LabeledTimeStampRange = TimeStampRange & { label: ReactNode };
type DateRangeBarProps = {
  globalBounds: TimeStampRange;
  relativeData: LabeledTimeStampRange;
};

type DateRangeAvailabilityPickerProps = {
  data: {
    solar?: LabeledTimeStampRange;
    storage?: LabeledTimeStampRange;
    utility?: LabeledTimeStampRange;
  };
  datesDidChange: DateSpanPickerProps['datesDidChange'];
};

type UnitRadioChooserProps = {
  disabled: boolean;
  name: string;
  kW?: boolean;
  onChange: (unit: UnitType) => void;
};

type PreferredMonitorSelectorProps = {
  data: MonitoringPlatformAccountType[];
  onChange: (monitorType: MonitoringPlatformAccountType) => void;
  disabled: boolean;
};

type DownloadIntervalParams = ServiceDrop.API.DownloadIntervalParams;

type BaseDownloadModalProps = {
  data: {
    solar?: LabeledTimeStampRange & { monitor_choices?: MonitoringPlatformAccountType[] };
    storage?: LabeledTimeStampRange & { monitor_choices?: MonitoringPlatformAccountType[] };
    utility?: LabeledTimeStampRange;
  };
  isReady: (ready: boolean) => void;
  setPayload: React.Dispatch<React.SetStateAction<DownloadIntervalParams>>;
};

type OrgIntervalDownloadModalProps = {
  orgId: Org['id'];
  isOpen: boolean;
  onClose: () => void;
};

type ServiceDropDownloadModalProps = {
  serviceDropId: ServiceDrop['id'];
  isOpen: boolean;
  onClose: () => void;
};

/** ============================= Styles =================================== */
const useDateRangeStyles = (left: number, right: number) =>
  makeStylesHook(
    (theme) => ({
      dateRangeBorder: { borderRightStyle: 'solid' },
      dateRangeBar: {
        backgroundColor: theme.palette.secondary.light,
        marginLeft: `${left}%`,
        marginRight: `${right}%`,
        borderRadius: '3px',
      },
      dateLabel: {
        marginLeft: '2%',
        marginRight: '2%',
      },
    }),
    'DateRangeBar'
  )();

/** =========================== Components ================================= */
const DateRangeBar: React.FC<DateRangeBarProps> = ({ globalBounds, relativeData }) => {
  const globalStart = globalBounds.start;
  const globalEnd = globalBounds.end;
  const relativeStart = relativeData.start;
  const relativeEnd = relativeData.end;
  const range = globalEnd.toMillis() - globalStart.toMillis();
  let leftPercentage = ((relativeStart.toMillis() - globalStart.toMillis()) / range) * 100;
  leftPercentage = leftPercentage > 0 ? leftPercentage : 0;
  let rightPercentage = ((relativeEnd.toMillis() - globalStart.toMillis()) / range) * 100;
  rightPercentage = 100 - rightPercentage;
  rightPercentage = rightPercentage > 0 ? rightPercentage : 0;
  const classes = useDateRangeStyles(leftPercentage, rightPercentage);

  let bar = undefined;
  let tooltip = `${formatters.date.standard(relativeStart)} - ${formatters.date.standard(
    relativeEnd
  )}`;
  if (leftPercentage + rightPercentage < 70) {
    bar = (
      <Grid justify="space-between">
        <Grid.Item className={classes.dateLabel}>
          <Typography variant="caption">{formatters.date.standard(relativeStart)}</Typography>
        </Grid.Item>
        <Grid.Item className={classes.dateLabel}>
          <Typography variant="caption">{formatters.date.standard(relativeEnd)}</Typography>
        </Grid.Item>
      </Grid>
    );
  } else {
    bar = (
      <Grid justify="center">
        <Grid.Item className={classes.dateLabel}>
          <Tooltip title={tooltip} placement="top">
            <Typography variant="caption">...</Typography>
          </Tooltip>
        </Grid.Item>
      </Grid>
    );
  }
  return (
    <Grid noMargin>
      <Grid.Item span={2} className={classes.dateRangeBorder}>
        <Grid justify="flex-end">
          <Grid.Item>
            <Typography>{relativeData.label}</Typography>
          </Grid.Item>
        </Grid>
      </Grid.Item>
      <Grid.Item span={10}>
        <div className={classes.dateRangeBar}>{bar}</div>
      </Grid.Item>
    </Grid>
  );
};

const DateRangeAvailabilityPicker: React.FC<DateRangeAvailabilityPickerProps> = ({
  data,
  datesDidChange,
}) => {
  const [disabled, setDisabled] = useState(!_.truthy(Object.values(data)).length);
  const [globalStart, setGlobalStart] = useState<DateTime | undefined>();
  const [globalEnd, setGlobalEnd] = useState<DateTime | undefined>();

  useEffect(() => {
    let starts: number[] = [];
    let ends: number[] = [];

    _.truthy(Object.values(data)).forEach((value) => {
      if (value.start && value.end) {
        starts.push(value.start.toMillis());
        ends.push(value.end.toMillis());
      }
    });

    setDisabled(!_.truthy(Object.values(data)).length);
    setGlobalStart(starts.length ? DateTime.fromMillis(Math.min(...starts)) : undefined);
    setGlobalEnd(ends.length ? DateTime.fromMillis(Math.max(...ends)) : undefined);
  }, [data, setDisabled]);

  return (
    <>
      <Grid>
        <Grid.Item span={12}>
          <Typography emphasis={disabled ? 'disabled' : undefined} variant="h5">
            Select a date range
          </Typography>
        </Grid.Item>
        {Object.entries(data).map(([key, value]) => {
          if (value?.start && value?.end && globalStart && globalEnd) {
            return (
              <DateRangeBar
                key={key}
                globalBounds={{
                  start: globalStart,
                  end: globalEnd,
                }}
                relativeData={value}
              />
            );
          }
          return undefined;
        })}
      </Grid>
      {globalStart && globalEnd && (
        <DateSpanPicker
          disabled={disabled}
          datesDidChange={datesDidChange}
          defaultStartDate={globalStart}
          defaultEndDate={globalEnd}
          options={{
            minDate: globalStart.toJSDate(),
            maxDate: globalEnd.toJSDate(),
          }}
        />
      )}
    </>
  );
};

const UnitRadioChooser: React.FC<UnitRadioChooserProps> = ({
  name,
  disabled,
  onChange,
  kW = false,
}) => {
  const [kWChecked, setkWChecked] = useState(kW);

  useEffect(() => {
    onChange(kWChecked ? 'kW' : 'kWh');
  }, [kWChecked, onChange]);

  return (
    <Grid>
      <Grid.Item span={4}>
        <Typography emphasis={disabled ? 'disabled' : undefined}>Unit:</Typography>
      </Grid.Item>
      <Grid.Item span={8}>
        <Grid>
          <Button
            disabled={disabled}
            _variant="text"
            onClick={() => {
              setkWChecked(false);
            }}
          >
            <Icon name={!kWChecked ? 'radio_button_checked' : 'radio_button_unchecked'} />
            kWh
          </Button>
          <Button
            _variant="text"
            disabled={disabled}
            onClick={() => {
              setkWChecked(true);
            }}
          >
            <Icon name={kWChecked ? 'radio_button_checked' : 'radio_button_unchecked'} />
            kW
          </Button>
        </Grid>
      </Grid.Item>
    </Grid>
  );
};

const PreferredMonitorSelector: React.FC<PreferredMonitorSelectorProps> = ({
  data,
  onChange,
  disabled,
}) => {
  const [selection, setSelection] = useState<MonitoringPlatformAccountType | undefined>(
    data ? data[0] : undefined
  );
  useEffect(() => {
    if (selection) {
      onChange(selection);
    }
  }, [selection, onChange]);
  if (!data) return <></>;
  return (
    <Grid>
      <Grid.Item span={12}>
        <Select
          disabled={disabled}
          options={data}
          renderOption="name"
          valueOption="id"
          value={selection}
          onChange={setSelection}
        />
      </Grid.Item>
    </Grid>
  );
};

const BaseDownloadModal: React.FC<BaseDownloadModalProps> = ({ data, isReady, setPayload }) => {
  const hasSolar = data && data.solar?.start;
  const hasStorage = data && data.storage?.start;
  const hasUtility = data && data.utility?.start;
  const ready = !!hasSolar || !!hasStorage || !!hasUtility;

  const [utilityChecked, setUtilityChecked] = useState<boolean>(false);
  const [solarChecked, setSolarChecked] = useState<boolean>(false);
  const [storageChecked, setStorageChecked] = useState<boolean>(false);
  const [interpolate, setInterpolate] = useState<boolean>(false);

  useEffect(() => {
    isReady(ready && (utilityChecked || solarChecked || storageChecked));
  }, [ready, isReady, utilityChecked, solarChecked, storageChecked]);

  useEffect(() => {
    setPayload((p) => ({ ...p, interpolate }));
  }, [setPayload, interpolate]);

  const datesDidChange = useCallback(
    (start: DateTime, end: DateTime) => {
      setPayload((payload) => ({
        ...payload!,
        start_date: start.toISODate(),
        end_date: end.toISODate(),
      }));
    },
    [setPayload]
  );

  const changeSolarMonitor = useCallback(
    (monitorType) => {
      setPayload((v) => {
        if (v.solar_params) {
          return {
            ...v,
            solar_params: { ...v.solar_params, preferred_monitor: +monitorType.id },
          };
        }
        return v;
      });
    },
    [setPayload]
  );

  const changeStorageMonitor = useCallback(
    (monitorType) => {
      setPayload((v) => {
        if (v.storage_params) {
          return {
            ...v,
            storage_params: { ...v.storage_params, preferred_monitor: +monitorType.id },
          };
        }
        return v;
      });
    },
    [setPayload]
  );

  return (
    <>
      <Grid>
        <Grid.Item span={12}>
          <Typography variant="h5">Configure data sources</Typography>
        </Grid.Item>

        <Grid.Item span={4}>
          <Checkbox
            color="secondary"
            checked={utilityChecked}
            label={<Chip label="Utility" icon="plug" disabled={!utilityChecked} />}
            onChange={() => checkItem(utilityChecked, setUtilityChecked, 'utility_params')}
            disabled={!hasUtility}
          />
          <hr />
          <UnitRadioChooser
            onChange={useCallback(
              (unit) =>
                setPayload((v) =>
                  v.utility_params ? { ...v, utility_params: { ...v.utility_params, unit } } : v
                ),
              [setPayload]
            )}
            name="utility-unit"
            disabled={!utilityChecked}
          />
        </Grid.Item>

        <Grid.Item span={4}>
          <Checkbox
            color="secondary"
            disabled={!hasSolar}
            checked={solarChecked}
            label={<Chip label="Solar" icon="sun" disabled={!solarChecked} />}
            onChange={() => {
              checkItem(
                solarChecked,
                setSolarChecked,
                'solar_params',
                data.solar?.monitor_choices?.length && data.solar?.monitor_choices[0].id
              );
            }}
          />
          <hr />
          <UnitRadioChooser
            onChange={useCallback(
              (unit) =>
                setPayload((v) =>
                  v.solar_params ? { ...v, solar_params: { ...v.solar_params, unit } } : v
                ),
              [setPayload]
            )}
            name="solar-unit"
            disabled={!solarChecked}
          />
          {data && (
            <PreferredMonitorSelector
              data={data.solar?.monitor_choices!}
              onChange={changeSolarMonitor}
              disabled={!solarChecked}
            />
          )}
        </Grid.Item>

        <Grid.Item span={4}>
          <Checkbox
            color="secondary"
            disabled={!hasStorage}
            checked={storageChecked}
            label={<Chip label="Storage" icon="battery" disabled={!storageChecked} />}
            onChange={() =>
              checkItem(
                storageChecked,
                setStorageChecked,
                'storage_params',
                data.storage?.monitor_choices?.length && data.storage?.monitor_choices[0].id
              )
            }
          />
          <hr />
          <UnitRadioChooser
            onChange={useCallback(
              (unit) =>
                setPayload((v) =>
                  v.storage_params ? { ...v, storage_params: { ...v.storage_params, unit } } : v
                ),
              [setPayload]
            )}
            name="storage-unit"
            disabled={!storageChecked}
          />
          {data ? (
            <PreferredMonitorSelector
              data={data.storage?.monitor_choices!}
              onChange={changeStorageMonitor}
              disabled={!storageChecked}
            />
          ) : (
            ''
          )}
        </Grid.Item>
      </Grid>
      <hr />
      <DateRangeAvailabilityPicker
        data={{
          solar: solarChecked ? data.solar : undefined,
          storage: storageChecked ? data.storage : undefined,
          utility: utilityChecked ? data.utility : undefined,
        }}
        datesDidChange={datesDidChange}
      />
      <Grid>
        <Grid.Item span={12}>
          <Checkbox
            color="primary"
            disabled={!ready}
            checked={interpolate}
            label="Interpolate"
            onChange={() => setInterpolate((i) => !i)}
          />
        </Grid.Item>
      </Grid>
    </>
  );
  /** ============================ Callbacks =============================== */
  function checkItem(
    checked: boolean,
    setChecked: React.Dispatch<React.SetStateAction<boolean>>,
    paramName: string,
    preferredMonitor: MonitoringPlatformAccountType['id'] | undefined = undefined,
    defaultUnit: 'kW' | 'kWh' = 'kWh'
  ) {
    setChecked(!checked);
    if (!checked) {
      setPayload((payload) => ({
        ...payload!,
        [paramName]: {
          unit: defaultUnit,
          preferred_monitor: +preferredMonitor!,
        },
      }));
    } else {
      setPayload((payload) => ({ ...payload!, [paramName]: undefined }));
    }
  }
};

export const ServiceDropDownloadModal: React.FC<ServiceDropDownloadModalProps> = ({
  serviceDropId,
  isOpen,
  onClose,
}) => {
  const [downloadDisabled, setDisabled] = useState(true);
  const [payload, setPayload] = useState<DownloadIntervalParams>({
    interpolate: false,
  });

  const baseModalIsReady = useCallback(
    (ready) => {
      setDisabled(!ready);
    },
    [setDisabled]
  );

  const { loading, serviceDrop } = useServiceDrop(serviceDropId, {
    include: [
      'meter_date_range',
      'solar_date_range',
      'storage_date_range',
      'solar_generator.monitor_types.*',
      'storage.monitor_types.*',
    ],
  });

  if (loading || !serviceDrop)
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <Loading />
      </Dialog>
    );
  const data: BaseDownloadModalProps['data'] = {
    solar: serviceDrop.solar_date_range && {
      start: serviceDrop.solar_date_range![0],
      end: serviceDrop.solar_date_range![1],
      monitor_choices: serviceDrop.SolarGenerator?.MonitorTypes,
      label: 'Solar',
    },
    storage: serviceDrop.storage_date_range && {
      start: serviceDrop.storage_date_range![0],
      end: serviceDrop.storage_date_range![1],
      monitor_choices: serviceDrop.Storage?.MonitorTypes,
      label: 'Storage',
    },
    utility: serviceDrop.meter_date_range && {
      start: serviceDrop.meter_date_range![0],
      end: serviceDrop.meter_date_range![1],
      label: 'Utility',
    },
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Content>
        <Typography variant="h4">
          Download intervals for {serviceDrop.Site?.name} {serviceDrop.name}
        </Typography>
        <BaseDownloadModal data={data} isReady={baseModalIsReady} setPayload={setPayload} />
      </Dialog.Content>
      <Dialog.Actions>
        <DownloadModalFooter
          disabled={downloadDisabled}
          getDownloadURL={getDownloadURL}
          onClose={onClose}
        />
      </Dialog.Actions>
    </Dialog>
  );
  /** =============================== Callbacks ============================ */
  async function getDownloadURL() {
    const resp = await ServiceDrop.api.downloadIntervals(serviceDropId, payload);
    return resp;
  }
};

export const OrgIntervalDownloadModal: React.FC<OrgIntervalDownloadModalProps> = ({
  orgId,
  isOpen,
  onClose,
}) => {
  const [selectedSites, setSelectedSites] = useState<Site[]>([]);
  const [downloadDisabled, setDisabled] = useState(true);
  const [payload, setPayload] = useState<DownloadIntervalParams>({
    interpolate: false,
  });

  const baseModalIsReady = useCallback(
    (ready) => {
      setDisabled(!ready || selectedSites.length === 0);
    },
    [setDisabled, selectedSites.length]
  );

  const { loading, org } = useOrg(orgId, {
    include: [
      'meter_date_range',
      'solar_date_range',
      'storage_date_range',
      'solar_monitor_types.*',
      'storage_monitor_types.*',
    ],
  });

  if (loading || !org)
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <Loading />
      </Dialog>
    );
  const data: BaseDownloadModalProps['data'] = {
    solar: org.solar_date_range && {
      start: org.solar_date_range![0],
      end: org.solar_date_range![1],
      monitor_choices: org.SolarMonitorTypes,
      label: 'Solar',
    },
    storage: org.storage_date_range && {
      start: org.storage_date_range![0],
      end: org.storage_date_range![1],
      monitor_choices: org.StorageMonitorTypes,
      label: 'Storage',
    },
    utility: org.meter_date_range && {
      start: org.meter_date_range![0],
      end: org.meter_date_range![1],
      label: 'Utility',
    },
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <Dialog.Content>
        <Typography variant="h4">Download intervals for {org.name}</Typography>
        <SiteMultiSelect siteList={org.Sites} sitesChanged={setSelectedSites} />
        <BaseDownloadModal data={data} isReady={baseModalIsReady} setPayload={setPayload} />
      </Dialog.Content>
      <Dialog.Actions>
        <DownloadModalFooter
          disabled={downloadDisabled}
          getDownloadURL={getDownloadURL}
          onClose={onClose}
        />
      </Dialog.Actions>
    </Dialog>
  );
  /** =============================== Callbacks ============================ */
  async function getDownloadURL() {
    const resp = await Org.api.downloadIntervals(orgId, {
      ...payload!,
      site_list: selectedSites.map((site) => site.id),
    });
    return resp;
  }
};
