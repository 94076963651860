import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  Drawer,
  Grid,
  Typography,
  TextField,
  Select,
  Icon,
} from 'onescreen/components';
import {
  SolarGenerator,
  Storage,
  KnownIssueAPI,
  MonitoringPlatform,
  KnownIssue,
} from 'onescreen/models';
import { MaterialColor } from 'onescreen/styles';

/*============================= Types =======================================*/
type Common = {
  device?: SolarGenerator | Storage;
};
type KnownIssueFormProps = Common & {
  dialogOpen: boolean;
  closeDialog: () => void;
};

type KnownIssueButtonProps = Common & {
  onClick: () => void;
};

const LowSeverity = { color: 'yellow', name: 'Low' };
const MediumSeverity = { color: 'orange', name: 'Medium' };
const HighSeverity = { color: 'red', name: 'High' };
const severityMap = { 1: LowSeverity, 2: MediumSeverity, 3: HighSeverity };
const defaultSeverity = 2;
type Severity = 1 | 2 | 3;

/*============================== Components =================================*/

export const KnownIssueForm: React.FC<KnownIssueFormProps> = ({
  device,
  dialogOpen,
  closeDialog,
}) => {
  const [severity, setSeverity] = useState<Severity>(defaultSeverity);
  const [identifier, setIdentifier] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    setSeverity(defaultSeverity);
    setIdentifier('');
    setDescription('');
  }, [device, setSeverity, setIdentifier, setDescription]);

  const onDelete = async (ki: KnownIssue) => {
    await KnownIssueAPI.destroy(ki.id);
    for (const m of _.truthy(device?.Monitors || [])) {
      MonitoringPlatform.api.retrieve(m.id, { include: ['storage.*', 'solar_generator.*'] });
    }
    closeDialog();
  };

  const onSubmit = async () => {
    if (!device || !identifier || !description) return;
    const body = {
      identifier,
      description,
      severity,
      owner_type: device.ctype_id,
      owner_id: device.id,
    };
    await KnownIssueAPI.create(body);
    for (const m of _.truthy(device?.Monitors || [])) {
      MonitoringPlatform.api.retrieve(m.id, { include: ['storage.*', 'solar_generator.*'] });
    }
    closeDialog();
  };

  return (
    <Drawer open={dialogOpen} onClose={closeDialog} anchor="bottom">
      <Dialog.Content>
        <Typography variant="h5">
          Known Issues for {device?.ServiceDrop?.Site?.Org?.name} -{' '}
          {device?.ServiceDrop?.Site?.name} {device?.ServiceDrop?.name}
        </Typography>
        <Grid>
          <Grid.Item span={4}>URL</Grid.Item>
          <Grid.Item span={5}>Description</Grid.Item>
          <Grid.Item span={2}>Severity</Grid.Item>
          <Grid.Item></Grid.Item>
        </Grid>

        {device?.known_issues.map((ki) => (
          <Grid key={ki.id}>
            <Grid.Item span={4}>
              <a href={ki.identifier} target="_blank" rel="noopener noreferrer">
                {ki.identifier}
              </a>
            </Grid.Item>
            <Grid.Item span={5}>{ki.description}</Grid.Item>
            <Grid.Item span={2}>
              <Typography style={{ color: severityMap[ki.severity].color }}>
                <Icon size="small" name="warning"></Icon>
              </Typography>
              {severityMap[ki.severity].name}
            </Grid.Item>
            <Grid.Item>
              <Button onClick={() => onDelete(ki)}>Delete</Button>
            </Grid.Item>
          </Grid>
        ))}
        <Grid>
          <Grid.Item span={4}>
            <TextField value={identifier} onChange={setIdentifier}></TextField>
          </Grid.Item>
          <Grid.Item span={5}>
            <TextField value={description} onChange={setDescription}></TextField>
          </Grid.Item>
          <Grid.Item span={2}>
            <Select
              options={[1, 2, 3]}
              value={severity}
              onChange={setSeverity}
              renderOption={(num: Severity) => severityMap[num].name}
            />
          </Grid.Item>
          <Grid.Item>
            <Button color="secondary" onClick={onSubmit} disabled={!identifier || !description}>
              Add
            </Button>
          </Grid.Item>
        </Grid>
      </Dialog.Content>
    </Drawer>
  );
};

export const KnownIssueButton: React.FC<KnownIssueButtonProps> = ({ device, onClick }) => {
  const [issues, setIssues] = useState<KnownIssue[]>(
    _.sortBy(device?.known_issues, 'severity').reverse()
  );
  useEffect(() => {
    setIssues(_.sortBy(device?.known_issues, 'severity').reverse());
  }, [device, setIssues]);
  const color = (issues?.length ? severityMap[issues[0].severity].color : 'grey') as MaterialColor;
  return (
    <Button
      _variant="text"
      size="small"
      onClick={onClick}
      icon={issues?.length ? 'warning' : 'radio_button_unchecked'}
      iconColor={color}
    ></Button>
  );
};
