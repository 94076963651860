import _ from 'lodash';

import { store } from 'onescreen/store';
import { Maybe, ObjectWithId, ObjectWithoutType } from 'onescreen/types';

import { ServicePeriod } from '.';
import { ServiceAgreement } from '../assets';
import { Bill } from '../bill';

/** ======================== Types ========================================= */
type FindPredicate = {
  serviceAgreement?: ServiceAgreement | ServiceAgreement['id'];
  servicePeriod?: ServicePeriod | ServicePeriod['id'];
};

type CommonAttrs = {
  bill: Bill['id'] | null;
  id: number;
  object_type: 'ServiceSimulation';
  service_agreement: ServiceAgreement['id'];
  service_period: ServicePeriod['id'];
};

export declare namespace ServiceSimulation {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}
}

/** ======================== Model ========================================= */
export interface ServiceSimulation extends CommonAttrs {}
export class ServiceSimulation {
  /** ====================== Static fields ================================= */
  static readonly rawFields = ['bill', 'id', 'service_agreement', 'service_period'] as const;

  /**
   * Parses an array of raw ServiceSimulation objects by passing each in turn to
   * ServiceSimulation.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {ServiceSimulation.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: ServiceSimulation.Raw[]): ServiceSimulation[];
  static fromObjects(raw: Maybe<ServiceSimulation.Raw[]>): Maybe<ServiceSimulation[]>;
  static fromObjects(raw: Maybe<ServiceSimulation.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: ServiceSimulation.Raw): ServiceSimulation;
  static fromObject(raw: Maybe<ServiceSimulation.Raw>): Maybe<ServiceSimulation>;
  static fromObject(raw: Maybe<ServiceSimulation.Raw>) {
    return raw ? new ServiceSimulation(raw) : undefined;
  }

  static fromStore(): ServiceSimulation[];
  static fromStore(id: Maybe<ServiceSimulation['id']>): Maybe<ServiceSimulation>;
  static fromStore(id?: ServiceSimulation['id']) {
    const { serviceSimulations } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(serviceSimulations)).map(ServiceSimulation.fromObject);
    } else {
      return id ? ServiceSimulation.fromObject(serviceSimulations[id]) : undefined;
    }
  }

  private static resolveId<T extends ObjectWithId>(obj?: T | T['id']): Maybe<T['id']> {
    return _.isObject(obj) ? obj.id : obj;
  }

  static find(predicate: FindPredicate): Maybe<ServiceSimulation> {
    return _.find(this.fromStore(), {
      service_agreement: this.resolveId(predicate.serviceAgreement),
      service_period: this.resolveId(predicate.servicePeriod),
    });
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'ServiceSimulation';
  constructor(raw: ServiceSimulation.Raw) {
    Object.assign(this, _.pick(raw, ServiceSimulation.rawFields));
  }

  serialize(): ServiceSimulation.Serialized {
    return {
      ..._.pick(this, ServiceSimulation.rawFields),
      object_type: this.object_type,
    };
  }
}
